import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultipleChoiceOption = ({ index, option, name, isSelected, isDisabled, onClick }) => {
  return (
    <button
      className={`relative border-2 border-gray-300 rounded-lg px-3 py-2 flex cursor-pointer focus:outline-none disabled:pointer-events-none ${
        isSelected
          ? 'bg-blue-200 border-blue-200 disabled:bg-opacity-50'
          : 'bg-white disabled:bg-gray-100 hover:bg-gray-50'
      }`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {/* Accessibility and testing */}
      <input
        type="radio"
        name={name}
        value={option}
        checked={isSelected}
        onChange={onClick}
        aria-labelledby={`${name}-${index}-label`}
        className="sr-only"
      />

      <span className="flex space-x-2">
        <span
          className={`self-center w-3 h-3 rounded-full ring-2 relative ${
            isSelected ? 'ring-blue-500 bg-blue-500' : 'ring-gray-300'
          }`}
        >
          <FontAwesomeIcon
            className={`absolute top-0 bottom-0 left-0 right-0 m-auto text-sm ${
              isSelected ? 'text-white' : 'text-transparent'
            }`}
            icon="check"
          />
        </span>

        <span className="flex-1 flex">
          <span id={`${name}-${index}-label`} className="block font-medium  text-gray-900">
            {option}
          </span>
        </span>
      </span>
    </button>
  );
};

const RadioGroupFieldSet = ({ field, isDisabled, onChange, value, options }) => {
  const isSelected = useCallback((option) => option === value, [value]);

  return (
    <fieldset>
      <div role="radiogroup" className="space-y-4">
        {options &&
          options.map((option, index) => (
            <MultipleChoiceOption
              key={option}
              index={index}
              option={option}
              name={field.name}
              isSelected={isSelected(option)}
              isDisabled={isDisabled}
              onClick={() => onChange(option)}
            />
          ))}
      </div>
    </fieldset>
  );
};

export default React.memo(RadioGroupFieldSet);

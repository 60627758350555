import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import Select, { components, Option } from 'react-select';
import ProcedureDiffCompareText from '../components/ProcedureDiffCompareText';
import { reactSelectNoBorderStyles, reactSelectStyles } from '../lib/styles';

/**
 * Do not use react-select to render a disabled selector, in order to improve performance
 */
interface SelectorProps {
  name: string;
  type: 'table-cell' | 'standalone';
  description: string;
  isDisabled: boolean;
  selectedOption: Option;
  selectOptions: Array<Option>;
  onChangeHandler: (option: Option) => void;
  placeholder?: string;
  isDiff?: boolean;
  showOptionsWhenDisabled?: boolean;
  fitValue?: boolean;
  itemRenderer?: (option: Option) => JSX.Element;
}

const Selector = ({
  name,
  type = 'standalone',
  description,
  isDisabled,
  selectedOption,
  selectOptions,
  onChangeHandler,
  placeholder,
  isDiff = false,
  showOptionsWhenDisabled = false,
  fitValue = false,
  itemRenderer,
}: SelectorProps) => {
  const enabledClass = useMemo(() => {
    if (type === 'table-cell') {
      return 'w-full h-full border-none';
    }

    return '';
  }, [type]);

  const optionRenderer = useCallback(
    (props) => {
      return (
        <components.Option {...props} className="w-full truncate">
          {itemRenderer?.(props)}
        </components.Option>
      );
    },
    [itemRenderer]
  );

  const valueRenderer = useCallback(
    (props) => {
      return (
        <components.SingleValue {...props} className="w-full truncate">
          {itemRenderer?.(props)}
        </components.SingleValue>
      );
    },
    [itemRenderer]
  );

  const disabledClass = useMemo(() => {
    if (type === 'table-cell') {
      return 'w-full h-full border-none';
    }

    return 'bg-gray-200 border rounded border-gray-400';
  }, [type]);

  const styles = useMemo(() => {
    const styleBase = type === 'table-cell' ? reactSelectNoBorderStyles : reactSelectStyles;
    if (fitValue) {
      return {
        ...styleBase,
        singleValue: (base) => ({
          ...base,
          position: 'static',
          transform: 'none',
          minWidth: 'fit-content',
          maxWidth: 'none',
        }),
        menu: (base) => ({
          ...base,
          width: 'max-content',
          zIndex: '2',
        }),
      };
    }

    return styleBase;
  }, [fitValue, type]);

  return (
    <>
      {isDisabled && (
        <div
          className={`relative px-2.5 py-1 min-h-[37px] flex items-center ${disabledClass}`}
          aria-label={`${description} Select`}
        >
          <div
            className={`mr-3  flex flex-grow justify-between items-center ${isDiff ? 'line-clamp-2' : 'line-clamp-1'}`}
          >
            {!isDiff && !showOptionsWhenDisabled && (
              <span className="truncate">{selectedOption?.label ?? placeholder}</span>
            )}
            {!isDiff && showOptionsWhenDisabled && (
              <div className="flex flex-wrap gap-1 w-full">
                {selectOptions.map((option) => (
                  <span className="p-1 bg-gray-200 rounded truncate max-w-64">{option.label}</span>
                ))}
              </div>
            )}
            {isDiff && <ProcedureDiffCompareText diffValue={selectedOption.label} />}
          </div>
          <FontAwesomeIcon icon="chevron-down" className="right-0 mr-0.5 text-gray-500" />
        </div>
      )}
      {!isDisabled && (
        <Select
          aria-label={`${description} Select`}
          components={{
            ...(type === 'table-cell' && { IndicatorSeparator: () => null }),
            ...(itemRenderer && { Option: optionRenderer, SingleValue: valueRenderer }),
          }}
          classNamePrefix="react-select"
          className={enabledClass}
          name={name}
          isDisabled={false}
          onChange={onChangeHandler}
          options={selectOptions}
          placeholder={placeholder ?? `Select ${description}`}
          styles={styles}
          value={selectedOption}
        />
      )}
    </>
  );
};

export default Selector;

import Tooltip from '../../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

const TimeFieldInputHelpIcon = () => {
  return (
    <>
      <Tooltip content="Daylight savings time is based on current date, but numeric time values entered will not change.">
        <span className=" text-gray-700">
          <FontAwesomeIcon icon={'fa-regular fa-circle-question' as IconProp} />
        </span>
      </Tooltip>
    </>
  );
};

export default TimeFieldInputHelpIcon;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position } from '@xyflow/react';

/**
 * A react-flow node for rendering a procedure section.
 *
 * @param data {Object} - A react-flow node data object.
 */
const SectionNode = ({ data }) => {
  return (
    <div className="flex flex-col h-full">
      <Handle type="target" position={Position.Top} />
      <div className="font-semibold border-b border-gray-300 p-1">
        {data.label}
        {data.isRepeat && (
          <span className="ml-1 whitespace-nowrap" aria-label={`repeat ${data.repeatKey}`}>
            <FontAwesomeIcon icon="redo" className="text-base" />
            {` Repeat ${data.repeatKey}`}
          </span>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
};

export default SectionNode;

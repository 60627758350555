import React from 'react';
import FieldSetInputWithIcon from './FieldSetInputWithIcon';
import { Icon } from '@fortawesome/fontawesome-svg-core';

interface FieldSetStepDetailTextProps {
  name: string;
  icon: Icon;
  placeholder: string;
  isDisabled?: boolean;
}

/**
 * Renders a text input with an icon on the left side.s
 */
const FieldSetStepDetailText = React.memo(({ name, icon, placeholder, isDisabled }: FieldSetStepDetailTextProps) => {
  return (
    <FieldSetInputWithIcon
      name={name}
      className=" border-1 border-gray-400 rounded"
      icon={icon}
      placeholder={placeholder}
      type="text"
      isDisabled={isDisabled}
    />
  );
});

export default FieldSetStepDetailText;

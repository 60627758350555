import { generateHiddenClassString } from '../../lib/styles';
import TextLinkify from '../TextLinkify';
import { DiffArrayChangeSymbol, ExpressionTokenDiffElement, WithDiffChange } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import { BlockProvider } from '../../contexts/BlockContext';
import DiffContainer from '../Diff/DiffContainer';
import TooltipOverlay from '../TooltipOverlay';
import ReviewReferenceDetail from './ReviewReferenceDetail';
import { useCallback } from 'react';

interface ReviewExpressionBlockProps {
  name: string;
  id: WithDiffChange<string>;
  tokens: Array<ExpressionTokenDiffElement>;
  isHidden: boolean;
  blockLabel?: string;
  isValid?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
}

const ReviewExpressionBlock = ({
  name,
  id,
  tokens,
  isHidden,
  blockLabel = '',
  isValid = true,
  diffChangeState,
  onContentRefChanged,
  scrollMarginTopValueRem,
}: ReviewExpressionBlockProps) => {
  const onExpressionRefChanged = useCallback(
    (element) => {
      return typeof onContentRefChanged === 'function' && onContentRefChanged(id, element);
    },
    [id, onContentRefChanged]
  );

  return (
    <BlockProvider value={{ isValid }}>
      <tr>
        <td />
        <td colSpan={2}>
          <div
            ref={onExpressionRefChanged}
            style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
            className={generateHiddenClassString('', isHidden)}
          >
            <div className={generateHiddenClassString('mt-2 mr-8 flex items-start page-break', isHidden)}>
              <Spacer />
              <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
              <DiffContainer label="Expression" diffChangeState={diffChangeState} isTextSticky={false}>
                {tokens && (
                  <div className="flex flex-col">
                    <div className="flex flex-wrap flex-col items-start w-full my-1 gap-x-2">
                      <div className="flex flex-row items-start w-full py-1 gap-x-2">
                        <TextLinkify>
                          <div
                            style={{
                              flex: '2',
                              minHeight: '2.25rem',
                            }}
                            className="flex self-top max-w-max"
                          >
                            <div className="flex self-center">
                              <div>{name}</div>
                            </div>
                          </div>
                        </TextLinkify>
                        <div className="flex items-center h-9">
                          <span>=</span>
                        </div>
                        <TooltipOverlay content={<ReviewReferenceDetail tokens={tokens} />} delayClose={true}>
                          <div className="group  px-2 py-1.5 border-2 border-gray-400 rounded bg-gray-200 bg-opacity-50 whitespace-wrap">
                            <div className="text-gray-400">Pending</div>
                          </div>
                        </TooltipOverlay>
                      </div>
                    </div>
                  </div>
                )}
              </DiffContainer>
            </div>
          </div>
        </td>
      </tr>
    </BlockProvider>
  );
};

export default ReviewExpressionBlock;

import { Activity } from 'shared/lib/types/postgres/util';
import DateTimeDisplay from '../../DateTimeDisplay';
import { OperationActivity } from 'shared/lib/types/operations';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import {
  AssigneeUpdatedActivityData,
  EventActivityData,
  OperationAction,
  OperationActorType,
} from 'shared/lib/types/postgres/operations';
import useUsers from '../../../hooks/useUsers';
import { EventMap } from '../../../screens/OperationDetail';
import { eventPath } from '../../../lib/pathUtil';
import { Link } from 'react-router-dom';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
export type WrappedActivity = Activity & { operation_activity: OperationActivity };

const getDisplayValue = (
  value: string | number | null | undefined,
  action: string,
  getUserDisplayText: (userId?: string) => string
) => {
  if (action === OperationAction.AssigneeUpdated) {
    return value === null ? 'Unassigned' : getUserDisplayText(value as string);
  }
  return value ? `"${value}"` : '';
};

const EVENT_ACTIONS = {
  [OperationAction.EventAdded]: 'added',
  [OperationAction.EventStarted]: 'started',
  [OperationAction.EventEnded]: 'ended',
  [OperationAction.EventDeleted]: 'deleted',
};

const EventActionDisplay = ({
  event,
  deletedEvent,
  teamId,
  action,
}: {
  event?: Event;
  deletedEvent?: Event;
  teamId: string;
  action: string;
}) => {
  if (!event) {
    if (!deletedEvent) {
      return <span className="font-medium">Event {action} </span>;
    }
    return (
      <span className="font-medium">
        Event <span className="italic text-gray-400">{deletedEvent.name}</span> {action}
      </span>
    );
  }
  return (
    <span className="font-medium">
      <span>Event </span>
      <Link to={eventPath(teamId, event.id)} className="underline text-blue-400 cursor-pointer">
        {event.name}
      </Link>
      <span> {action}</span>
    </span>
  );
};

const ActionClause = ({
  activity,
  eventMap,
  deletedEvents,
  getUserDisplayText,
}: {
  activity: OperationActivity;
  eventMap: EventMap;
  deletedEvents: Event[];
  getUserDisplayText: (userId?: string) => string;
}) => {
  const { currentTeamId: teamId } = useDatabaseServices();

  if (activity.action in EVENT_ACTIONS) {
    const event = eventMap[(activity.new_value as EventActivityData).event_id];
    const deletedEvent = deletedEvents.find((e) => e.id === (activity.new_value as EventActivityData).event_id);
    return (
      <EventActionDisplay
        event={event}
        deletedEvent={deletedEvent}
        teamId={teamId}
        action={EVENT_ACTIONS[activity.action as keyof typeof EVENT_ACTIONS]}
      />
    );
  }

  switch (activity.action) {
    case OperationAction.Created: {
      return <span className="font-medium">Operation created </span>;
    }
    case OperationAction.Started: {
      return <span className="font-medium">Operation started </span>;
    }
    case OperationAction.Ended: {
      return <span className="font-medium">Operation ended </span>;
    }
    case OperationAction.DescriptionUpdated: {
      return <span className="font-medium">Description updated </span>;
    }
    case OperationAction.AssigneeUpdated: {
      return (
        <>
          <span className="font-medium">Assignee updated </span>
          <span> from </span>
          <span className="font-medium">
            {getDisplayValue(
              (activity.old_value as AssigneeUpdatedActivityData).assignee_id,
              activity.action,
              getUserDisplayText
            )}
          </span>
          <span> to </span>
          <span className="font-medium">
            {getDisplayValue(
              (activity.new_value as AssigneeUpdatedActivityData).assignee_id,
              activity.action,
              getUserDisplayText
            )}
          </span>
        </>
      );
    }
    default:
      return <span>{activity.action}</span>;
  }
};

const ActorClause = ({
  activity,
  getUserDisplayText,
}: {
  activity: OperationActivity;
  getUserDisplayText: (userId?: string) => string;
}) => {
  switch (activity.actor_type) {
    case OperationActorType.Api:
      return (
        <>
          <span> by </span>
          <span className="font-medium">API</span>
        </>
      );
    case OperationActorType.User:
      return (
        <>
          <span> by </span>
          <span className="font-medium">{getUserDisplayText(activity.actor_id)}</span>
        </>
      );
    default:
      return <></>;
  }
};

type RendererProps = { activity: Activity; eventMap: EventMap; deletedEvents: Event[] };

const Renderer = ({ activity: wrapped, eventMap, deletedEvents }: RendererProps) => {
  const { getUserDisplayText } = useUsers();
  if (!('operation_activity' in wrapped)) {
    throw new Error('Operation activity renderer received unsupported activity');
  }
  const activity = (wrapped as WrappedActivity).operation_activity;
  return (
    <>
      <div>
        <ActionClause
          activity={activity}
          eventMap={eventMap}
          deletedEvents={deletedEvents}
          getUserDisplayText={getUserDisplayText}
        />
        <ActorClause activity={activity} getUserDisplayText={getUserDisplayText} />
      </div>
      <div className="text-sm text-gray-400">
        <DateTimeDisplay timestamp={activity.timestamp as string} />
      </div>
    </>
  );
};

export default Renderer;

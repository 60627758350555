import { Attachment } from '../../attachments';
import { ReviewerGroup } from '../../couch/procedures';
import { CreatedUpdatedAt, StringId, TeamId } from '../util';

// TODO name and revision should be required
export type OrderItem = {
  part_id: string;
  revision?: string;
  name?: string;
  part_revision_id?: string;
  image?: Attachment;
  unit_amount: number; // Unit amount in US cents
  quantity: number;
};

export enum PurchaseOrderState {
  Draft = 'draft',
  Submitted = 'submitted',
  Closed = 'closed',
  Canceled = 'canceled',
  InReview = 'in_review',
}

export type InsertPurchaseOrder =
  | StringId &
      TeamId & {
        doc: PurchaseOrderDoc;
        review_type_id?: string | null;
        reviewer_groups?: ReviewerGroup[] | null;
        state?: PurchaseOrderState | null;
      };

export type SelectPurchaseOrder = InsertPurchaseOrder & CreatedUpdatedAt;

// TODO refactor frontend to not use this type directly
export type PurchaseOrderDoc = {
  id: string;
  code: string;
  state: PurchaseOrderState;
  items: OrderItem[];
  vendor_id: string;
  ship_to_name: string;
  ship_to_address: string;
  delivery_date?: string;
  tags?: string[];
  attachments?: Attachment[];
  review_type_id?: string;
  reviewer_groups?: ReviewerGroup[];
} & CreatedUpdatedAt;

/**
 * Represents a row selected from the `orders` table after `doc` is flattened.
 */
export type PurchaseOrder = Omit<SelectPurchaseOrder, 'doc'> & PurchaseOrderDoc;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// The corresponding color class strings must be supported by the tailwind.css config.
const supportedIconTypes = {
  primary: 'text-blue-500 hover:text-blue-800 disabled:text-blue-500',
  secondary: 'text-gray-500 hover:text-gray-800 disabled:text-gray-500',
  caution: 'text-red-500 hover:text-red-800 disabled:text-red-500',
  neutral: 'text-black hover:text-blue-500 disabled:text-gray-500',
  warning: 'text-orange-600 hover:text-orange-800 disabled:text-gray-500',
};

const pendingActionIcon = {
  primary: 'text-blue-800',
  secondary: 'text-gray-800',
  caution: 'text-red-800',
  neutral: 'text-blue-500',
  warning: 'text-orange-800',
};

const DEFAULT_ICON_TYPE = 'primary';

type IconType = undefined | keyof typeof supportedIconTypes;

interface ButtonActionIconProps {
  icon: IconProp;
  onAction: () => void;
  ariaLabel?: string;
  label?: string;
  disabled?: boolean;
  iconType?: IconType;
  pendingAction?: boolean;
  size?: 'sm' | 'xl';
  pendingDotMatchesIcon?: boolean;
}

/**
 * ButtonActionIcon renders a button element with an icon and label.
 * Parameters:
 *  icon: String/Array with supported FontAwesome Icons.
 *  onAction: Function for onClick.
 *  ariaLabel: String.
 *  label (not required): String for label rendered besides icon.
 *  disabled (not required): Boolean flag to disable button element.
 *  iconType (not required): String representing icon types. Types supported at the moment are -
 *    ( primary | secondary | caution | neutral | warning )
 */
const ButtonActionIcon = ({
  icon,
  onAction,
  ariaLabel,
  label,
  disabled,
  iconType,
  pendingAction,
  size = 'sm',
  pendingDotMatchesIcon = false,
}: ButtonActionIconProps) => {
  const iconSize = useMemo(() => {
    const sizeClass = {
      sm: '',
      xl: 'text-2xl',
    };
    return sizeClass[size];
  }, [size]);
  // Checks for supported icon types, and if icon type is not supported, it returns default (primary).
  const textColorClasses = useMemo(() => {
    if (iconType && supportedIconTypes[iconType]) {
      return supportedIconTypes[iconType];
    }

    return supportedIconTypes[DEFAULT_ICON_TYPE];
  }, [iconType]);

  const pendingClass = useMemo(() => {
    if (!pendingDotMatchesIcon || !iconType || !pendingActionIcon[iconType]) {
      return 'text-red-700';
    }
    return pendingActionIcon[iconType];
  }, [iconType, pendingDotMatchesIcon]);

  return (
    <button
      aria-label={ariaLabel}
      className={`text-sm align-middle px-2 py-1 disabled:cursor-default disabled:opacity-40 ${textColorClasses}`}
      type="button"
      disabled={disabled}
      onClick={onAction}
    >
      <div className="flex flex-row flex-nowrap items-center">
        <div className="relative">
          <FontAwesomeIcon className={iconSize} icon={icon} />
          {pendingAction && (
            <FontAwesomeIcon
              className={`${pendingClass} absolute -top-1 -right-1.5`}
              icon={'fa-solid fa-circle' as IconProp}
              size="xs"
            />
          )}
        </div>
        {label && <span className=" ml-1 text-black font-medium uppercase">{label}</span>}
      </div>
    </button>
  );
};

export default ButtonActionIcon;

import React, { useCallback, useMemo, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import { useSettings } from '../../contexts/SettingsContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import FieldSetSignoffSelect from '../FieldSetSignoffSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tableInputUtil from '../../lib/tableInputUtil';
import { TableSignoff } from 'shared/lib/types/views/procedures';

interface EditSignoffCellProps {
  cell?: Array<TableSignoff>;
  onUpdateCell: (signoffs: Array<TableSignoff>) => void;
}

const EditSignoffCell = ({ cell = [], onUpdateCell }: EditSignoffCellProps) => {
  const { operatorRoles } = useSettings();
  const { mixpanel } = useMixpanel();
  const [showNewSignoffPicker, setShowNewSignoffPicker] = useState(false);

  const mixpanelTrack = useCallback(
    (trackingKey: string) => {
      if (mixpanel) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const operatorOptions = useMemo(() => {
    if (!operatorRoles || !operatorRoles.operators) {
      return [];
    }
    const allOperatorOptions = operatorRoles.operators.map((op) => op.code);
    return allOperatorOptions.map((op) => ({
      value: op,
      label: op,
    }));
  }, [operatorRoles]);

  const onUpdateSignoff = useCallback(
    (updatedValues, index) => {
      const updatedCell = cloneDeep(cell);

      if (updatedValues.length === 0) {
        updatedCell.splice(index, 1);
        if (isEmpty(updatedCell)) {
          updatedCell.push(tableInputUtil.getInitialSignoffObject());
        }
      } else {
        updatedCell[index].operators = updatedValues.map((option) => option.value);
      }
      onUpdateCell(updatedCell);
      mixpanelTrack('Update Table Signoffs');
    },
    [cell, onUpdateCell, mixpanelTrack]
  );

  const getValue = useCallback((signoff) => {
    return signoff.operators.map((operator) => {
      return {
        value: operator,
        label: operator,
      };
    });
  }, []);

  const enableAddSignoffButton = useMemo(() => {
    return !showNewSignoffPicker && !!(cell.length && cell[cell.length - 1].operators.length);
  }, [cell, showNewSignoffPicker]);

  const addNewSignoffBlock = useCallback(
    (updatedValues) => {
      if (isEmpty(updatedValues)) {
        return;
      }
      const newSignoff = tableInputUtil.getInitialSignoffObject();
      newSignoff.operators = updatedValues.map((option) => option.value);

      const updatedCell = cloneDeep(cell);
      updatedCell.push(newSignoff);
      onUpdateCell(updatedCell);
      mixpanelTrack('Add New Signoff Block');
      setShowNewSignoffPicker(false);
    },
    [cell, mixpanelTrack, onUpdateCell]
  );

  const removeSignoffBlock = useCallback(
    (index) => {
      const updatedCell = cloneDeep(cell);
      updatedCell.splice(index, 1);
      if (isEmpty(updatedCell)) {
        updatedCell.push(tableInputUtil.getInitialSignoffObject());
      }
      onUpdateCell(updatedCell);
      mixpanelTrack('Remove Table Signoffs');
    },
    [cell, onUpdateCell, mixpanelTrack]
  );

  const onChangeExisting = (updatedValues, index) => onUpdateSignoff(updatedValues, index);
  const onRemoveExisting = (index) => removeSignoffBlock(index);
  const SignoffPicker = ({ signoff, onChange, onRemove }) => (
    <div className="group/signoff relative">
      <FieldSetSignoffSelect
        options={operatorOptions}
        placeholder="ANY OPERATOR"
        opacity={0.9}
        value={getValue(signoff)}
        onChange={onChange}
        ariaLabel="Add Signoff Select"
      />
      <button
        className="absolute -right-1 -top-1 opacity-0 group-hover/signoff:opacity-100"
        type="button"
        onClick={onRemove}
        aria-label="Remove Signoffs"
      >
        <FontAwesomeIcon className="text-gray-400 hover:text-gray-500 bg-white" icon="circle-xmark" />
      </button>
    </div>
  );

  return (
    <div className="flex  font-medium px-2 flex-wrap grow gap-x-1 gap-y-1 items-start">
      {cell.length > 0 &&
        cell.map((signoff, index) => (
          <SignoffPicker
            key={signoff.id}
            signoff={signoff}
            onChange={(updatedValues) => onChangeExisting(updatedValues, index)}
            onRemove={() => onRemoveExisting(index)}
          />
        ))}
      {showNewSignoffPicker && (
        <SignoffPicker
          signoff={tableInputUtil.getInitialSignoffObject()}
          onChange={(updatedValues) => addNewSignoffBlock(updatedValues)}
          onRemove={() => setShowNewSignoffPicker(false)}
        />
      )}
      {enableAddSignoffButton && (
        <button
          className="self-center mx-1"
          aria-label="New Signoff"
          type="button"
          onClick={() => setShowNewSignoffPicker(true)}
        >
          <FontAwesomeIcon className="text-gray-400 hover:text-blue-500" icon="plus-circle" />
        </button>
      )}
    </div>
  );
};

export default React.memo(EditSignoffCell);

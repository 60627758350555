import { useCallback, useMemo } from 'react';
import { Column, RowHeightArgs } from 'react-data-grid';
import { useSelector } from 'react-redux';
import { isDraft, isInReview, isPending } from 'shared/lib/procedureUtil';
import { getIsReviewApproved } from 'shared/lib/reviewUtil';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { ProcedureMetadata } from 'shared/lib/types/views/procedures';
import { RowWithProjectName } from '../../../components/Home/GridExpandCollapseButton';
import renderAction from '../../../components/Home/Renderers/Action';
import renderDateTime from '../../../components/Home/Renderers/DateTime';
import renderRunStatus from '../../../components/Home/Renderers/Status';
import { RowAction } from '../../../components/Home/types';
import usePersistedView from '../../../components/Home/usePersistedView';
import { useAuth } from '../../../contexts/AuthContext';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useNavState } from '../../../contexts/NavContext';
import { selectProceduresMetadata } from '../../../contexts/proceduresSlice';
import { useSettings } from '../../../contexts/SettingsContext';
import Grid, { DEFAULT_GROUP_ROW_HEIGHT_PX } from '../../../elements/Grid';
import projectGroupCell from '../../../elements/renderers/ProjectGroupCell';
import ProjectRenderer from '../../../elements/renderers/ProjectRenderer';
import RunNameLinkRenderer from '../../../elements/renderers/RunNameLinkRenderer';
import { PERM } from '../../../lib/auth';
import {
  NO_TAGS_ROW_HEIGHT,
  TAGS_ROW_HIGHT,
  filterByField,
  filterBySearchTerm,
  projectGrouping,
} from '../../../lib/gridUtils';
import { procedureEditPath, procedureReviewPath } from '../../../lib/pathUtil';
import procedureUtil from '../../../lib/procedureUtil';
import projectUtil from '../../../lib/projectUtil';
import { goToProcedure } from '../../libs/testPlanUtil';
import { PLAN_VERTICAL_PADDING } from '../../screens/Plans';

export type PlanProcedureMetadata = ProcedureMetadata & {
  draftAction?: RowAction;
};

type PlanRow = RowWithProjectName & PlanProcedureMetadata;

export interface PlansGridProps {
  searchTerm?: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedProjects?: ReadonlySet<string | null>;
  viewTab?: ViewTab;
}

const ActivePlansGrid = ({
  searchTerm = '',
  setSearchTerm,
  selectedProjects,
  viewTab = ViewTab.List,
}: PlansGridProps) => {
  const { projectId } = useNavState();
  const { projects } = useSettings();
  const { auth } = useAuth();
  const { currentTeamId } = useDatabaseServices();
  const { expandedProjectNames, setExpandedProjectNames } = usePersistedView();

  const grouping = useMemo(
    () => projectGrouping<PlanRow>({ viewTab, expandedProjectNames, setExpandedProjectNames }),
    [expandedProjectNames, setExpandedProjectNames, viewTab]
  );
  const rowHeightGetter = useMemo(() => {
    const nonGroupedHeightGetter = (row: PlanRow) => (row?.tags?.length ? TAGS_ROW_HIGHT : NO_TAGS_ROW_HEIGHT);
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }: RowHeightArgs<PlanRow>) => {
        if (type === 'GROUP') {
          return DEFAULT_GROUP_ROW_HEIGHT_PX;
        }
        return nonGroupedHeightGetter(row);
      };
    }
    return nonGroupedHeightGetter;
  }, [viewTab]);

  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));

  const testPlanProcedures = useMemo(() => {
    return Object.entries(proceduresMetadata)
      .map(([, procedure]) => procedure)
      .filter(
        (procedure) =>
          procedure.procedure_type && !procedure.archived && (!projectId || projectId === procedure.project_id)
      );
  }, [proceduresMetadata, projectId]);

  const getPendingProcedure = useCallback((procedure: ProcedureMetadata, procedures: ProcedureMetadata[]) => {
    if (isPending(procedure)) {
      return procedure;
    }
    return procedures.find((p) => p.procedure_id === procedure._id);
  }, []);

  const hasReviewVersion = useCallback(
    (procedure: ProcedureMetadata, procedures: ProcedureMetadata[]) => {
      const pending = getPendingProcedure(procedure, procedures);
      if (pending) {
        return isInReview(pending);
      }
      return false;
    },
    [getPendingProcedure]
  );

  const editDraftAction = useCallback(
    (procedureId: string): RowAction => {
      return {
        componentType: 'buttonWithIcon',
        label: 'Edit Draft',
        icon: 'edit',
        to: procedureEditPath(currentTeamId, procedureId),
      };
    },
    [currentTeamId]
  );

  const reviewAction = useCallback(
    (procedureId: string, isReviewApproved: boolean): RowAction => {
      return {
        componentType: 'buttonWithIcon',
        label: 'Review',
        icon: isReviewApproved ? 'clipboard-check' : 'clipboard',
        to: procedureReviewPath(currentTeamId, procedureId),
      };
    },
    [currentTeamId]
  );

  const getDraftAction = useCallback(
    (procedure: ProcedureMetadata, procedures: ProcedureMetadata[]): RowAction | null => {
      const pending = getPendingProcedure(procedure, procedures);
      if (!pending) {
        return null;
      }
      const procedureId = procedureUtil.getProcedureId(pending);
      if (isDraft(pending)) {
        return editDraftAction(procedureId);
      }
      if (isInReview(pending)) {
        const isReviewApproved = getIsReviewApproved(pending);
        return reviewAction(procedureId, isReviewApproved);
      }
      return null;
    },
    [getPendingProcedure, editDraftAction, reviewAction]
  );

  const testPlanMetadata = useMemo(() => {
    return procedureUtil.getMasterProcedureList(testPlanProcedures).map((metadata) => {
      const hasEntityEditPermission = auth.hasPermission(PERM.PROCEDURES_EDIT, metadata.project_id);

      let draftAction;
      if (hasEntityEditPermission || hasReviewVersion(metadata, testPlanProcedures)) {
        // all users can review procedures
        draftAction = getDraftAction(metadata, testPlanProcedures);
      }

      return {
        ...metadata,
        projectName: projectUtil.getProjectName(projects, metadata.project_id),
        draftAction,
      } as PlanRow;
    });
  }, [auth, getDraftAction, hasReviewVersion, projects, testPlanProcedures]);

  const displayProcedures = useMemo(() => {
    const filteredRows = filterBySearchTerm({
      searchTerm,
      allData: testPlanMetadata,
      getStrings: (procedure: PlanRow) => [procedure.name, procedure.code, procedure.projectName, procedure.version],
    });
    return filterByField({
      rows: filteredRows,
      fieldName: 'project_id',
      values: selectedProjects,
    });
  }, [searchTerm, testPlanMetadata, selectedProjects]);

  const columns: Array<Column<PlanRow>> = [
    {
      key: 'name',
      name: 'Plan',
      width: projectId ? '42%' : '30%',
      sortable: true,
      renderCell: ({ row }) =>
        RunNameLinkRenderer({
          code: row.code,
          link: goToProcedure(row, currentTeamId),
          name: row.name,
          tags: row.tags || [],
        }),
    },
    ...(projectId
      ? []
      : [
          {
            key: 'projectName',
            name: 'Project',
            width: '12%',
            sortable: true,
            renderGroupCell: projectGroupCell,
            renderCell: ({ row }) => ProjectRenderer({ row, setSearchTerm }),
          },
        ]),
    {
      key: 'status',
      name: 'Status',
      width: '12%',
      renderCell: ({ row }) => {
        if (row.draftAction) {
          return renderAction(row.draftAction);
        }

        let output = row.state?.toString();
        if (output?.includes('_')) {
          output = output
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('');
        }
        return renderRunStatus(output, false);
      },
    },
    {
      key: 'editedAt',
      name: 'Last Updated',
      sortable: true,
      renderCell: ({ row }) => renderDateTime(row.editedAt),
    },
    {
      key: 'version',
      name: 'Version',
      width: '15%',
    },
  ];

  return (
    <Grid<PlanRow>
      key={projectId}
      columns={columns}
      rows={displayProcedures}
      rowHeight={rowHeightGetter}
      emptyRowMessage="No matching plans found"
      usedVerticalSpace={PLAN_VERTICAL_PADDING}
      rowGrouping={grouping}
    />
  );
};

export default ActivePlansGrid;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateTimeDisplay from '../components/DateTimeDisplay';
import { getActionDescription } from '../lib/runUtil';
import { ACTION_TYPE } from 'shared/lib/runUtil';

const SignoffAction = ({ action }) => {
  return (
    <div className="flex flex-row gap-x-1 items-center justify-start ">
      {action.type === ACTION_TYPE.SIGNOFF && (
        <FontAwesomeIcon icon={['fas', 'check-circle']} className="text-green-500" />
      )}
      {action.type === ACTION_TYPE.REVOKE_SIGNOFF && <FontAwesomeIcon icon="times-circle" className="text-gray-900" />}
      <div className="font-normal whitespace-nowrap">{getActionDescription(action)} by</div>
      <div className="font-semibold whitespace-nowrap">
        {action.operator ? `${action.operator.toUpperCase()}` : ''} {action.user_id}
      </div>
      <div className="font-normal">
        <DateTimeDisplay timestamp={action.timestamp} />
      </div>
    </div>
  );
};

export default React.memo(SignoffAction);

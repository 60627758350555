import { ReactNode } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Avatar from '../../elements/Avatar';
import DateTimeDisplay from '../DateTimeDisplay';
import Label from '../Label';
import SidebarLayout from '../../elements/SidebarLayout';
import { Snippet } from 'shared/lib/types/views/procedures';
import { procedureViewSectionPath, procedureViewStepPath } from '../../lib/pathUtil';
import { capitalize } from 'lodash';

interface SnippetSidebarProps {
  snippet: Snippet | null;
  currentTeamId: string;
  showPadding?: boolean;
  children: ReactNode;
}

const SnippetSidebarWithContent = ({ snippet, currentTeamId, showPadding = false, children }: SnippetSidebarProps) => {
  return (
    <SidebarLayout paddingTop={showPadding ? 'pt-10' : ''}>
      <SidebarLayout.Sidebar>
        <Accordion activeIndex={[0, 1]} multiple className="mt-10">
          <AccordionTab key="details" headerTemplate={<div className="py-3">Details</div>}>
            <>
              {snippet && (
                <div className="flex flex-col pb-2 gap-y-2 lg:w-64">
                  <div className="whitespace-nowrap text-sm">
                    <span className="font-medium mr-1">Type</span>
                    <Label text={capitalize(snippet.snippet_type)} />
                  </div>
                  {snippet.created_by && (
                    <div className="flex items-center gap-x-2 whitespace-nowrap">
                      <span className="font-medium">Created By</span>
                      <Avatar userId={snippet.created_by} />
                      {snippet.created_at && <DateTimeDisplay timestamp={snippet.created_at} />}
                    </div>
                  )}
                  {snippet.edited_at && snippet.edited_by && (
                    <div className="flex items-center gap-x-2 whitespace-nowrap mt-2">
                      <span className="font-medium">Last Edited</span>
                      <Avatar userId={snippet.edited_by} />
                      <DateTimeDisplay timestamp={snippet.edited_at} />
                    </div>
                  )}
                </div>
              )}
              {!snippet && <div>Loading snippet details...</div>}
            </>
          </AccordionTab>
          <AccordionTab key="where-used" headerTemplate={<div className="py-3">Where Used</div>}>
            {snippet && snippet.procedures && snippet.procedures.length > 0 && (
              <div className="flex flex-col max-h-64 overflow-y-auto">
                {snippet.procedures.map((procedure) => {
                  const link = procedure.step_id
                    ? procedureViewStepPath(currentTeamId, procedure.procedure_id, procedure.step_id)
                    : procedureViewSectionPath(currentTeamId, procedure.procedure_id, procedure.section_id);

                  return (
                    <div key={procedure.procedure_id} className="flex flex-wrap items-center mb-2 space-x-1">
                      <span className="font-semibold">{procedure.code}</span>
                      <span>{procedure.name}</span>
                      {procedure.state !== 'released' && <Label text="Draft" color="bg-gray-200" />}
                      <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        {procedure.step_id ? '[Step]' : '[Section]'}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
            {(!snippet || !snippet.procedures || snippet.procedures.length === 0) && (
              <div>No related procedures found.</div>
            )}
          </AccordionTab>
        </Accordion>
      </SidebarLayout.Sidebar>
      <SidebarLayout.Content>
        <div>{children}</div>
      </SidebarLayout.Content>
    </SidebarLayout>
  );
};

export default SnippetSidebarWithContent;

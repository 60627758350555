import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import UpdateOnDirty from './UpdateOnDirty';
import TextareaAutosize from 'react-textarea-autosize';
import { Mention } from 'primereact/mention';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { useSettings } from '../contexts/SettingsContext';
import { Suggestion } from '../lib/types';
import { CommentInterface } from 'shared/lib/comment';

export enum MentionPosition {
  Above = 'above',
  Below = 'below',
}
interface FormStepCommentProps {
  /** Function called when submitting comment. */
  onSubmit?: (values: CommentInterface, formikHelpers: FormikHelpers<FormikValues>) => void;
  /** Function called upon pressing cancel button. No cancel button if onCancel is undefined. */
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  /** Called with boolean indicating if form is dirty. */
  onDirtyChanged?: (dirty: boolean) => void;
  /** Function called when pasting file as a comment */
  onFilePasted?: (file: File) => void;
  /** Function called when user types text into the comment box */
  onTextChanged?: (containsText: boolean) => void;
  /** Placeholder text for comment form. */
  placeholder: string;
  /** Optional for children comments only. */
  parentId?: string;
  /** Determines whether or not to use mention component */
  mentionEnabled?: boolean;
  mentionPosition?: MentionPosition;
  autoFocus?: boolean;
  /** Initial values for the form */
  initialValues?: {
    comment: string;
    mentions: Array<{ username: string; email: string }>;
  };
  disabled?: boolean;
}

const getPastedFile = function (e) {
  const items = (e.clipboardData || e.originalEvent.clipboardData).items;
  for (const item of items) {
    if (item.kind === 'file') {
      return item.getAsFile();
    }
  }
};

const containsText = function (text) {
  if (text && typeof text === 'string') {
    return text.length > 0;
  }
  return false;
};

const itemTemplate = (suggestion) => {
  return (
    <div className="flex">
      <span className="flex flex-col  text-black">
        {suggestion.username}
        <small className="text-sm text-slate-500">{suggestion.email}</small>
      </span>
    </div>
  );
};

const FormStepComment = ({
  onSubmit,
  onCancel,
  onDirtyChanged,
  onFilePasted,
  placeholder,
  onTextChanged,
  parentId,
  mentionEnabled = true,
  mentionPosition = MentionPosition.Above,
  autoFocus = true,
  initialValues,
  disabled = false,
}: FormStepCommentProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const { users } = useSettings();
  const userEmails = useMemo(() => {
    if (!users) {
      return [];
    }
    return Object.keys(users.users);
  }, [users]);

  useEffect(() => {
    const textInput = textAreaRef.current;
    if (autoFocus && textInput) {
      textInput.focus();
      const length = textInput.value.length;
      textInput.setSelectionRange(length, length);
    }
  }, [autoFocus]);

  const usernames = useMemo(() => {
    const result: Array<string> = [];
    for (const email of userEmails) {
      const testUsername = email.split('@')[0];
      const username = testUsername.replace(/[^a-zA-Z0-9-]/g, '-');
      result.push(username);
    }
    return result;
  }, [userEmails]);

  const toSuggestion = useCallback(
    (user) => {
      // test the username here against regex and replace unsupported chars
      const testUsername = user.split('@')[0];
      let username = testUsername.replace(/[^a-zA-Z0-9-]/g, '-');
      if (usernames.filter((user) => user === username).length > 1) {
        username = `${username}-${user.split('@')[1].split('.')[0]}`;
      }

      return {
        email: user,
        username,
      };
    },
    [usernames]
  );

  const userList = useMemo<Array<Suggestion>>(() => {
    return userEmails.map(toSuggestion);
  }, [userEmails, toSuggestion]);

  const onSearch = useCallback(
    (e) => {
      const query = e.query;
      const filteredSuggestions = userList.filter((mention) => {
        return mention.email.toLowerCase().includes(query.toLowerCase());
      });

      setSuggestions(filteredSuggestions);
      setIsSuggestionVisible(true);
    },
    [userList]
  );

  const pasteHandler = useCallback(
    (e) => {
      const file = getPastedFile(e);
      if (!file) {
        return;
      }
      e.preventDefault();
      onFilePasted?.(file);
    },
    [onFilePasted]
  );

  const textInputChange = useCallback(
    (e) => {
      const text = e.target.value;
      onTextChanged?.(containsText(text));
    },
    [onTextChanged]
  );

  useEffect(() => {
    const textAreaRefCurrent = textAreaRef.current;
    if (!textAreaRefCurrent) {
      return;
    }
    textAreaRefCurrent.addEventListener('paste', pasteHandler);
    return () => {
      textAreaRefCurrent?.removeEventListener('paste', pasteHandler);
    };
  }, [textAreaRef, pasteHandler]);

  const onKeyDownHandler = (e, form, values) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (isSuggestionVisible) {
        return;
      }
      if (values.comment.trim().length > 0) {
        form.submitForm();
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues ?? { comment: '', parentId, mentions: [] }}
      onSubmit={(values, formikHelpers) => {
        //@ts-ignore
        onSubmit(values, formikHelpers);
        formikHelpers.resetForm();
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue, values, dirty }) => (
        <Form className="w-full">
          <UpdateOnDirty onDirtyChanged={onDirtyChanged} />
          <div className="flex items-center my-1">
            <div className="flex grow relative">
              {/* Show text commenting area */}
              {mentionEnabled && (
                <Field className="py-2" name="comment">
                  {({ field, form }) => (
                    <Mention
                      rows="1"
                      className={`${mentionPosition}`}
                      inputRef={textAreaRef}
                      trigger="@"
                      field="username"
                      suggestions={suggestions}
                      onSearch={onSearch}
                      placeholder={placeholder}
                      itemTemplate={itemTemplate}
                      autoResize
                      {...field}
                      disabled={isSubmitting || disabled}
                      onSelect={(e) => {
                        setFieldValue('mentions', [
                          ...values.mentions,
                          { username: e.suggestion.username, email: e.suggestion.email },
                        ]);
                        setIsSuggestionVisible(false);
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        textInputChange(e);
                      }}
                      onKeyDown={(e) => onKeyDownHandler(e, form, values)}
                    />
                  )}
                </Field>
              )}
              {!mentionEnabled && (
                <Field className="py-2" name="comment">
                  {({ field, form }) => (
                    <TextareaAutosize
                      ref={textAreaRef}
                      placeholder={placeholder}
                      className="w-full px-3 py-2 resize-none pr-16 rounded border-2 border-gray-500"
                      {...field}
                      disabled={isSubmitting || disabled}
                      onChange={(e) => {
                        field.onChange(e);
                        textInputChange(e);
                      }}
                      onKeyDown={(e) => onKeyDownHandler(e, form, values)}
                    />
                  )}
                </Field>
              )}
              {onSubmit && (
                <button
                  type="submit"
                  aria-label="comment-submit"
                  className="px-3 m-0.5  btn-link absolute right-0 bottom-0 top-0 rounded-sm hover:bg-blue-100 disabled:bg-gray-100 disabled:text-gray-600 disabled:cursor-default"
                  disabled={!dirty || isSubmitting || disabled || values.comment.trim().length === 0}
                >
                  <div className="flex flex-row items-center">
                    {isSubmitting ? 'Saving' : 'Post'}
                    {!isSubmitting && <FontAwesomeIcon icon="chevron-right" size="xs" className="ml-1 align-middle" />}
                  </div>
                </button>
              )}
            </div>
            {onCancel && (
              <button
                aria-label="cancel-form"
                className="ml-2 app-bg-gray-5 rounded-full w-5 h-5 flex justify-center items-center disabled:bg-gray-100"
                onClick={onCancel}
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon="times" size="xs" className="text-white" />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormStepComment;

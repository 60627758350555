import { PurchaseOrderState } from 'shared/lib/types/postgres/manufacturing/orders';
import Tag from '../../components/Tag';
import { startCase } from 'lodash';

const ORDER_STATE_COLOR_MAP: Record<PurchaseOrderState, string> = {
  draft: 'bg-gray-200',
  submitted: 'bg-blue-200',
  canceled: 'bg-red-200',
  closed: 'bg-green-200',
  in_review: 'bg-yellow-200',
};

type PurchaseOrderStateTagProps = {
  id: string;
  orderState: PurchaseOrderState;
  setSearchTerm?: (searchTerm: string) => void;
};

const PurchaseOrderStateTag = ({ id, orderState, setSearchTerm }: PurchaseOrderStateTagProps) => {
  const name = startCase(orderState.replace('_', ' '));

  return (
    <Tag
      onClick={setSearchTerm ? () => setSearchTerm?.(name) : undefined}
      id={id}
      name={name}
      color={ORDER_STATE_COLOR_MAP[orderState]}
    />
  );
};

export default PurchaseOrderStateTag;

import React, { useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import runUtil from '../lib/runUtil';
import { useRunContext } from '../contexts/RunContext';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';

const NextStepButton = ({ run, scrollTo, showLabel = false }) => {
  const { nextStepIds } = useRunContext();

  const hasNextStep = useMemo(() => {
    return nextStepIds !== null;
  }, [nextStepIds]);

  const nextStepLabel = useMemo(() => {
    let nextStepCode = '—';

    if (runUtil.isRunStateActive(run?.state) && hasNextStep) {
      nextStepCode = nextStepIds?.nextStepCode;
    }

    return `${showLabel ? 'Next Step' : ''} ${nextStepCode}`;
  }, [run, hasNextStep, nextStepIds, showLabel]);

  const onScrollToNext = useCallback(() => {
    if (!hasNextStep) {
      return;
    }
    scrollTo({
      sectionId: nextStepIds.nextSectionId,
      stepId: nextStepIds.nextStepId,
      stepHeaderId: nextStepIds.nextStepHeaderId,
    });
  }, [hasNextStep, nextStepIds, scrollTo]);

  return (
    <button
      className={`flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(!hasNextStep, false)}`}
      type="button"
      title="Next Step"
      aria-label="Next Step Button"
      disabled={!hasNextStep}
      onClick={onScrollToNext}
    >
      <FontAwesomeIcon fixedWidth={true} icon="level-down-alt" />
      <div className=" ml-2">{nextStepLabel}</div>
    </button>
  );
};

export default React.memo(NextStepButton);

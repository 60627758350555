import { useRef } from 'react';
import QRCode from 'react-qr-code';
import Button, { BUTTON_TYPES } from '../../components/Button';
import ModalBase from '../../components/ModalBase';
import ModalActionBar from '../../components/ModalActionBar';
import ModalContent from '../../components/ModalContent';
import { useReactToPrint } from 'react-to-print';

const QRModal = ({ name, url, onClose }) => {
  const printRef = useRef(null);
  const onPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${name} QR Code`,
  });

  const printDisplay = (
    <div className="mt-10 flex-col w-full" ref={printRef}>
      <div className="text-xl px-32 truncate text-wrap break-all max-w-[100ch]">{name}</div>
      <div className="text-gray-400 italic truncate text-wrap break-all max-w-[100ch] px-32">{url}</div>

      <div className="flex w-full justify-center mt-2">
        <QRCode value={url} size={128} />
      </div>
    </div>
  );

  return (
    <ModalBase>
      <ModalContent>
        <div className="flex-col w-full">
          <div className="text-xl truncate text-wrap break-all max-w-[100ch]">{name}</div>
          <div className="text-gray-400 italic text-base truncate text-wrap break-all max-w-[100ch]">{url}</div>

          <div className="flex w-full justify-center mt-2">
            <QRCode value={url} size={128} />
          </div>
        </div>
      </ModalContent>
      <div className="hidden">{printDisplay}</div>
      <ModalActionBar>
        <div className="flex flex-row gap-x-2 text-base">
          <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Close" isDisabled={false} onClick={onClose}>
            Close
          </Button>
          <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Print" isDisabled={false} onClick={onPrint}>
            Print
          </Button>
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default QRModal;

import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { useNavState } from '../../contexts/NavContext';

type TestingAuth = {
  hasEditPermission: (projectId?: string) => boolean;
  hasCreatePermission: boolean;
  hasProjectOnlyPermission: boolean;
};

const useTestingAuth = (): TestingAuth => {
  const { auth } = useAuth();
  const { projectId } = useNavState();
  const hasEditPermission = (projectId?: string) => {
    return auth.hasPermission(PERM.TESTING_EDIT, projectId);
  };

  const hasCreatePermission = auth.hasPermission(PERM.TESTING_EDIT, projectId);

  return {
    hasEditPermission,
    hasCreatePermission,
    hasProjectOnlyPermission: auth.hasProjectOnlyEditPermissions(),
  };
};

export default useTestingAuth;

import Tag from '../../components/Tag';
import { capitalize } from 'lodash';
import getHashBucket from '../../lib/hashUtil';

const ORDER_TAG_COLORS = ['bg-violet-200', 'bg-cyan-200', 'bg-lime-200', 'bg-stone-200'];

type PurchaseOrderTagProps = {
  id: string;
  name: string;
  setSearchTerm?: (searchTerm: string) => void;
};

const PurchaseOrderTag = ({ id, name, setSearchTerm }: PurchaseOrderTagProps) => {
  const color = getHashBucket(name, ORDER_TAG_COLORS);
  return (
    <Tag
      onClick={setSearchTerm ? () => setSearchTerm?.(name) : undefined}
      id={id}
      name={capitalize(name)}
      color={color}
    />
  );
};

export default PurchaseOrderTag;

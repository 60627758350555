import React from 'react';
import AttachmentPreview from './Attachments/AttachmentPreview';
import DateTimeDisplay from './DateTimeDisplay';
import ChildrenComments from './ChildrenComments';
import TextLinkify from './TextLinkify';
import { RunStepComment } from 'shared/lib/types/views/procedures';
import EditCommentButton from './EditCommentButton';
import FormStepComment from './FormStepComment';
import useCommentHandler from '../hooks/useCommentHandler';
import { CommentInterface, wasEdited } from 'shared/lib/comment';
import { isNil } from 'lodash';

/*
 * Component for rendering all Parent Child Comments.
 *
 */

interface ParentCommentProps {
  childComments?: Array<RunStepComment>;
  parentId: string;
  parentComment: RunStepComment;
  sectionId: string;
  stepId: string;
  editComment: (comment: CommentInterface, commentId?: string) => Promise<void>;
  saveNewComment?: (comment) => void;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
}

const ParentComment = ({
  childComments,
  parentId,
  parentComment,
  sectionId,
  stepId,
  saveNewComment,
  editComment,
  isRun,
  isCommentingDisabled,
}: ParentCommentProps) => {
  const { isSubmittingComment, editCommentContext, submitComment, handleEditClick, handleCancel } = useCommentHandler({
    onEditComment: editComment,
    autoFocus: true,
  });

  return (
    <>
      <div className="bg-slate-200 rounded-lg mt-2 relative" aria-label="Comment Parent">
        <div key={parentId} className="mt-1">
          <div className="flex justify-between items-center px-2 ">
            <div className="flex items-center">
              <span className="mr-1 font-semibold">{parentComment.user}</span>
              <DateTimeDisplay timestamp={parentComment.timestamp} />
              {wasEdited(parentComment.timestamp, parentComment.updated_at) && (
                <span className="ml-1 text-gray-400">(edited)</span>
              )}
            </div>
            {!isNil(editComment) && !isCommentingDisabled && (
              <EditCommentButton comment={parentComment} onEdit={handleEditClick} />
            )}
          </div>
          <div className="px-2">
            {editCommentContext.id === parentComment.id ? (
              <FormStepComment
                autoFocus={true}
                placeholder="Edit comment"
                initialValues={{ comment: editCommentContext.text, mentions: editCommentContext.mentions }}
                onSubmit={submitComment}
                mentionEnabled={true}
                onCancel={handleCancel}
              />
            ) : (
              <>
                {parentComment.text && (
                  <TextLinkify mentionsEnabled={true} mentionList={parentComment.mention_list}>
                    <span className="whitespace-pre-line">{parentComment.text}</span>
                  </TextLinkify>
                )}
                {!parentComment.text && parentComment.attachment && (
                  <AttachmentPreview attachment={parentComment.attachment} />
                )}
              </>
            )}
          </div>
        </div>

        {/* Render Children Comments handling backwards compatibility for older run comments */}
        {parentComment.id && (
          <ChildrenComments
            childComments={childComments}
            parentId={parentId}
            sectionId={sectionId}
            stepId={stepId}
            saveNewComment={saveNewComment}
            editComment={editComment}
            isRun={isRun}
            isCommentingDisabled={isCommentingDisabled}
          />
        )}
      </div>
      {isSubmittingComment && <div>Saving Comment...</div>}
    </>
  );
};

export default React.memo(ParentComment);

import PinSettings from '../components/ProfileSettings/PinSettings';

const Profile = () => {
  return (
    <div className="flex flex-grow h-full p-12">
      <div className="flex flex-col gap-y-8">
        <div className="text-2xl font-medium">Profile</div>
        <PinSettings />
      </div>
    </div>
  );
};

export default Profile;

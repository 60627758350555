import React, { useMemo } from 'react';
import MenuContext, { MenuContextAction, MenuContextRadioButtonAction } from './MenuContext';
import useMenu from '../hooks/useMenu';
import Button from './Button';

interface MenuSubmitRedlineProps {
  isRedlineSubmitDisabled: boolean;
  isRedlineCancelDisabled: boolean;
  onRedlineCancel: () => void;
  onSubmit: () => void;
  runOnly?: boolean;
}

const BLUELINE: MenuContextRadioButtonAction['data'][0] = {
  label: 'Blueline',
  value: 'Blueline',
  description: 'Add for use in this Run only',
};

const MenuSubmitSuggestedEdit = ({
  isRedlineSubmitDisabled,
  onSubmit,
  isRedlineCancelDisabled,
  onRedlineCancel,
  runOnly = false,
}: MenuSubmitRedlineProps) => {
  const { isMenuVisible, setIsMenuVisible } = useMenu();

  const menuActions: Array<MenuContextAction> = useMemo(
    () => [
      {
        type: 'radioButtons',
        path: 'editType',
        data: runOnly
          ? [BLUELINE]
          : [
              BLUELINE,
              {
                label: 'Redline',
                value: 'Redline',
                description: 'Add to this Run and include in next procedure draft',
              },
            ],
      },
      {
        type: 'submitButtons',
        data: [
          {
            onClick: () => setIsMenuVisible(false),
            label: 'Cancel',
            buttonType: 'secondary',
            ariaLabel: 'Cancel',
          },
          {
            onClick: onSubmit,
            label: 'Confirm',
            buttonType: 'primary',
            ariaLabel: 'Confirm',
          },
        ],
      },
    ],
    [onSubmit, runOnly, setIsMenuVisible]
  );

  return (
    <div className="flex relative justify-end text-lg space-x-2 mt-1">
      <Button onClick={onRedlineCancel} type="secondary" isDisabled={isRedlineCancelDisabled || isMenuVisible}>
        Cancel
      </Button>
      <Button
        onClick={() => setIsMenuVisible(true)}
        type="primary"
        isDisabled={isRedlineSubmitDisabled || isMenuVisible}
        trailingIcon="caret-down"
      >
        Save
      </Button>
      {isMenuVisible && (
        <div className="absolute top-10 w-96 bg-white shadow-md border z-40 rounded">
          <MenuContext menuContextActions={[menuActions]} className="font-medium text-sm" hasDividers={true} />
        </div>
      )}
    </div>
  );
};

export default MenuSubmitSuggestedEdit;

import Pluralize from 'pluralize';
import IssuesGrid from '../components/IssuesGrid';
import useRealtimeIssues from '../hooks/useRealtimeIssues';
import { search, countOpenIssues } from '../lib/issues';
import { IssueRow, StatusTab } from '../types';
import { useSettings } from '../../contexts/SettingsContext';
import projectUtil from '../../lib/projectUtil';
import useUsers from '../../hooks/useUsers';
import usePersistedView from '../../components/Home/usePersistedView';

type OpenIssuesGridWithCountProps = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  verticalPadding?: number;
};

const OpenIssuesGridWithCount = ({
  searchTerm = '',
  setSearchTerm,
  verticalPadding = 0,
}: OpenIssuesGridWithCountProps) => {
  const { getUserDisplayText } = useUsers();
  const { projects } = useSettings();
  const { issues } = useRealtimeIssues();
  const { expandedProjectNames, setExpandedProjectNames } = usePersistedView();
  const mapped: Array<IssueRow> = issues.map((issue) => ({
    ...issue,
    assignee: getUserDisplayText(issue.assignee),
    projectName: projectUtil.getProjectName(projects, issue.projectId) || '',
  }));
  const filtered = search(mapped, searchTerm);
  const numIssues = countOpenIssues(filtered);

  return (
    <>
      <div className="my-2  text-gray-400">
        {numIssues} {Pluralize('issue', numIssues)}
      </div>
      <IssuesGrid
        issues={filtered}
        selectedStatus={StatusTab.Open}
        verticalPadding={verticalPadding}
        setSearchTerm={setSearchTerm}
        expandedProjectNames={expandedProjectNames}
        setExpandedProjectNames={setExpandedProjectNames}
      />
    </>
  );
};

export default OpenIssuesGridWithCount;

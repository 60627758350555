import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotFound from '../components/NotFound';
import SettingsHeaderRow from '../components/Settings/SettingsHeaderRow';
import SettingsNavigation from '../components/Settings/SettingsNavigation';
import SettingsTable from '../components/Settings/SettingsTable';
import { useSettings } from '../contexts/SettingsContext';

import ProjectUsersService from '../api/projects';
import FlashMessage from '../components/FlashMessage';
import ProjectUserRow from '../components/Settings/ProjectUserRow';
import { ProjectUserRoles, WorkspaceRoles } from '../components/Settings/types';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useUserInfo } from '../contexts/UserContext';
import apm from '../lib/apm';
import { PERM } from '../lib/auth';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import { homePath, teamSettingsProjectsPath } from '../lib/pathUtil';

const ProjectSettingsSections = [
  {
    name: 'General',
    key: 'general',
    icon: 'cog',
  },
];

ProjectSettingsSections.push({
  name: 'Users',
  key: 'users',
  icon: 'user-plus',
});

const ProjectSettings = () => {
  const { auth } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [saveSuccess, setSaveSuccess] = useState<string | null>(null);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [projectUsers, setProjectUsers] = useState<ProjectUserRoles[] | null>(null);
  const { services, currentTeamId } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const [isNewUser, setIsNewUser] = useState(false);
  const history = useHistory();
  const { projects, updateProject } = useSettings();
  const project = useMemo(() => projects?.projects[id], [id, projects]);
  const [projectNameInput, setProjectNameInput] = useState(project?.name ?? '');
  const [projectCodeInput, setProjectCodeInput] = useState(project?.code ?? '');
  const [availableRoles, setAvailableRoles] = useState<WorkspaceRoles>([]);

  useEffect(() => {
    if (!services.roles) {
      return;
    }
    services.roles
      .listRoles()
      .then((users) => setAvailableRoles(users))
      .catch((reason) => Promise.reject(reason));
  }, [services.roles]);

  const projectUserService = useMemo(() => {
    if (!currentTeamId || !id) {
      return null;
    }
    return new ProjectUsersService(currentTeamId, id);
  }, [currentTeamId, id]);

  useEffect(() => {
    if (!projectUserService) {
      return;
    }
    projectUserService
      .listProjectUsers()
      .then((users) => setProjectUsers(users))
      .catch((err) => apm.captureError(err));
  }, [projectUserService]);

  const addNewUser = useCallback(() => {
    setIsNewUser(true);
  }, []);

  const deleteUser = useCallback(
    async (userId) => {
      let retPromise;
      await projectUserService
        ?.deleteProjectUser([userId])
        .then(() => {
          retPromise = Promise.resolve('User Removed');
        })
        .catch(() => Promise.reject(REFRESH_TRY_AGAIN_MESSAGE));

      setSaveSuccess('User Removed');

      // Fetch users again to re-render the table
      try {
        const users = await projectUserService?.listProjectUsers();
        users && setProjectUsers(users);
      } catch {
        /*
         * A user could delete their own permissions to the project, if this happens, the request to list the project
         * users will fail, and they should be redirected away from the page.
         */
        history.push(homePath(currentTeamId));
        history.goForward();
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }
      return retPromise;
    },
    [history, projectUserService, currentTeamId]
  );

  const save = useCallback(
    async (updatedUser, isNewUser) => {
      let retPromise;
      try {
        await projectUserService?.upsertProjectUser(updatedUser.id, updatedUser.project_roles);
        setIsNewUser(false);
        retPromise = Promise.resolve('Save successful.');
      } catch {
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }

      setSaveSuccess(isNewUser ? 'User Added' : 'User Updated');

      // Fetch users again to re-render the table
      try {
        const users = await projectUserService?.listProjectUsers();
        users && setProjectUsers(users);
      } catch {
        /*
         * A user could potentially lower their own permissions to the point that they don't have
         * roles to the project settings anymore, if this happens, the request to list the project
         * users will fail, and they should be redirected away from the page.
         */
        history.push(homePath(currentTeamId));
        history.goForward();
        return Promise.reject(REFRESH_TRY_AGAIN_MESSAGE);
      }

      return retPromise;
    },
    [history, projectUserService, currentTeamId]
  );

  // Returns true if user has permission to edit users
  const canEdit = useMemo(() => auth.hasPermission(PERM.USERS_EDIT, id), [auth, id]);

  const onDeleteUser = useCallback(
    (user) => {
      const userId = user.id.toLowerCase();

      return deleteUser(userId);
    },
    [deleteUser]
  );

  // Returns user id of the logged-in user
  const currentUserId = useMemo(() => userInfo.session.user_id, [userInfo]);

  const validateUser = useCallback(
    (updatedNewUser) => {
      const userId = updatedNewUser.id.toLowerCase();

      // If this is a new user then it must have at least one role
      if (updatedNewUser.roles.length === 0) {
        return Promise.reject('At least one role must be selected.');
      }

      // If user email already exists, return a rejected promise.
      if (projectUsers && projectUsers.some((user) => user.id === userId)) {
        return Promise.reject('User already exists.');
      }

      return Promise.resolve();
    },
    [projectUsers]
  );

  const cancelNewUser = useCallback(() => {
    setIsNewUser(false);
  }, []);

  const isSaveDisabled = useMemo(() => {
    return projectCodeInput === project?.code && projectNameInput === project?.name;
  }, [projectCodeInput, projectNameInput, project]);

  const onSave = useCallback(() => {
    if (!project) {
      return;
    }
    setSaveError(null);

    updateProject({
      id: project.id,
      name: projectNameInput,
      code: projectCodeInput,
    })
      .then(() => {
        setSaveSuccess('Settings Saved');
      })
      .catch((error) => {
        if (error?.message) {
          setSaveError(error.message);
        }
      });
  }, [project, updateProject, projectNameInput, projectCodeInput]);

  const navigateToSettings = useCallback(() => {
    // Insert the location of the settings screen and go to it
    history.push(teamSettingsProjectsPath(currentTeamId));
    history.goForward();
  }, [history, currentTeamId]);

  if (!project) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col flex-grow">
      {/* Project Banner*/}
      <div className="relative flex flex-row items-center py-2 border-b border-gray-300">
        <button className="px-3 py-1 mr-2 rounded hover:bg-slate-200" onClick={navigateToSettings}>
          <FontAwesomeIcon className="text-gray-400" icon="caret-left" size={'xl' as SizeProp} />
        </button>
        <div className="text-2xl font-medium">{project?.name}</div>
        <FlashMessage message={saveError} messageUpdater={setSaveError} type="warning" />
        {/* Settings saved chip */}
        <FlashMessage message={saveSuccess} messageUpdater={setSaveSuccess} />
      </div>
      {/* Project  */}
      <SettingsNavigation title="Project Settings" sections={ProjectSettingsSections}>
        {({ sectionKey }) => (
          <div className="flex flex-col gap-y-2">
            {sectionKey === 'general' && (
              <div className="flex flex-col gap-y-2 p-4">
                <div className="text-2xl">General</div>
                <div className="flex flex-col gap-y-1 w-64">
                  <div className="flex flex-col">
                    <div className=" font-medium uppercase">Name</div>
                    <input
                      id={id}
                      onChange={(value) => setProjectNameInput(value.target.value)}
                      defaultValue={project?.name}
                      type="text"
                      className="py-1.5 px-2 border border-gray-400 rounded"
                      name="name"
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className=" font-medium uppercase">Code</div>
                    <input
                      id={id}
                      onChange={(value) => setProjectCodeInput(value.target.value)}
                      defaultValue={project?.code}
                      type="text"
                      className="py-1.5 px-2 border border-gray-400 rounded"
                      name="code"
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="flex flex-none items-center space-x-1 px-3 py-2 rounded text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600 disabled:text-gray-400 disabled:bg-gray-200 disabled:border-transparent"
                    type="submit"
                    disabled={isSaveDisabled}
                    onClick={onSave}
                  >
                    Save General Settings
                  </button>
                </div>
              </div>
            )}
            {sectionKey === 'users' && (
              <div className="flex flex-col gap-y-2 p-4">
                <div className="text-2xl">{project?.name} Users</div>
                <SettingsTable
                  header={
                    <SettingsHeaderRow>
                      <div className="table-cell w-4/12">
                        <div className="ml-3 my-3 border-2 border-transparent">User</div>
                      </div>
                      <div className="table-cell w-4/12">
                        <div className="ml-3 my-3 border-2 border-transparent">Project Access</div>
                      </div>
                      <div className="table-cell">{/* empty cell for action buttons column */}</div>
                    </SettingsHeaderRow>
                  }
                >
                  {projectUsers?.map((user) => (
                    <ProjectUserRow
                      key={user.id}
                      user={user}
                      isEnabled={canEdit}
                      onSave={save}
                      onDeleteUser={canEdit && onDeleteUser}
                      isUserRemovalDisabled={currentUserId === user.id}
                      availableRoles={availableRoles}
                    />
                  ))}
                  {isNewUser && (
                    <ProjectUserRow
                      key="new-user"
                      isEnabled={true}
                      isNewUser={true}
                      onSave={save}
                      onCancel={cancelNewUser}
                      onValidate={validateUser}
                      existingUsers={projectUsers && new Set(projectUsers.map((user) => user.id))}
                      availableRoles={availableRoles}
                    />
                  )}
                </SettingsTable>
                {canEdit && (
                  <div>
                    <button
                      type="button"
                      className="group mt-1 p-2  text-gray-500 hover:text-gray-800 disabled:opacity-50 disabled:cursor-default"
                      disabled={isNewUser} // Disabled if there is already a new user row, that has not been saved.
                      onClick={addNewUser}
                    >
                      <FontAwesomeIcon className="text-blue-500 group-hover:text-blue-800" icon="user-plus" />
                      <span className="font-medium uppercase  ml-2">Add User</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </SettingsNavigation>
    </div>
  );
};

export default React.memo(ProjectSettings);

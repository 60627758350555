import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OverlaySize = Object.freeze({
  small: 0,
  large: 1,
});

type OverlayUploadProps = {
  hintText?: string;
  isDragActive: boolean;
  size: number;
  isDisabled?: boolean;
};

// Renders an absolutely positioned overlay to show file dropzone.
export const OverlayUpload = ({
  hintText = 'Drop file here',
  isDragActive,
  size,
  isDisabled = false,
}: OverlayUploadProps) => {
  const borderSize = size === OverlaySize.small ? 'border-2' : 'border-4';
  const flexDirection = size === OverlaySize.small ? 'flex-row' : 'flex-col';
  const iconSize = size === OverlaySize.small ? 'text-xl' : 'text-5xl';
  const textSize = size === OverlaySize.small ? 'text-lg' : 'text-2xl';
  const textColor = isDisabled ? 'text-gray-400' : 'text-gray-500';

  return (
    <div
      className={`flex ${flexDirection} justify-center items-center absolute left-0 right-0 top-0 bottom-0 ${borderSize} border-dashed ${
        isDisabled ? 'border-gray-300 bg-gray-100' : 'border-gray-400 bg-white'
      } bg-opacity-95 z-0 pointer-events-none ${isDragActive ? '' : 'hidden'}`}
    >
      <FontAwesomeIcon icon="file-upload" className={`self-center ${iconSize} ${textColor}`} />
      <div className={`m-2 self-center ${textSize} font-semibold ${textColor}`}>{hintText}</div>
    </div>
  );
};

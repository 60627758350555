export type TabStatus = 'pending' | 'complete' | 'failed' | 'skipped';

export type BatchStepHeaderTabConfig = {
  label: string;
  status: TabStatus;
};

type BatchStepHeaderTabProps = {
  config: BatchStepHeaderTabConfig;
  isActive: boolean;
  onClick: () => void;
};

const getBackgroundColor = (status: TabStatus, isActive: boolean): string => {
  switch (status) {
    case 'complete':
      return 'bg-app-green-200';
    case 'failed':
      return 'bg-red-200';
    case 'skipped':
      return 'bg-app-gray-400';
    default:
      return isActive ? 'bg-white' : '';
  }
};

const BatchStepHeaderTab = ({ config: { label, status }, isActive, onClick }: BatchStepHeaderTabProps) => {
  const bgColor = getBackgroundColor(status, isActive);
  const border = isActive ? 'border-b-2 border-blue-600' : '';
  const textStyle = isActive ? 'text-blue-600' : 'text-gray-500 cursor-pointer';
  return (
    <div
      className={`flex items-center justify-center h-8 py-1 px-2  ${bgColor} ${border} ${textStyle}`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default BatchStepHeaderTab;

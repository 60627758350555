import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Formik, Form, FieldArray, setIn } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FieldSetText from './FieldSetText';
import FieldSetAttachment from './FieldSetAttachment';
import FieldSetProcedureBlock from './Blocks/FieldSetProcedureBlock';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import OverlayUploadFileDrop from './OverlayUploadFileDrop';
import ProcedureEditCommentsList from './ProcedureEditCommentsList';
import { arrayMoveMutable } from 'array-move';
import getFileInputBlock from '../hooks/useFileInput';
import FieldSetProcedureField from './FieldSetProcedureField';
import { Actions } from './ContentMenu/addContentTypes';
import { capitalizeFirstLetter } from 'shared/lib/text';
import AddContentMenu from './ContentMenu/AddContentMenu';
import getBlockAddContentItems from './ContentMenu/blockAddContentItems';
import Selectable, { Boundary } from './Selection/Selectable';
import contentDragHandleVisibilityClasses from './Selection/selectionUtil';
import { copyItemToClipboard, selectClipboardItem } from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import FlashMessage from './FlashMessage';
import clipboardUtil from '../lib/clipboardUtil';
import procedureUtil from '../lib/procedureUtil';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const enabledContentTypes = [
  ProcedureContentBlockTypes.Alert,
  ProcedureContentBlockTypes.Text,
  ProcedureContentBlockTypes.Attachment,
];

const FieldSetProcedureSectionHeader = ({
  sectionHeader,
  errors,
  sectionIndex,
  index,
  isSectionHeaderCollapsed,
  onSectionHeaderCollapse,
  onFieldRefChanged,
  onSectionHeaderFormChanged,
  comments,
  onSaveReviewComment,
  onResolveComment,
  onUnresolveComment,
}) => {
  const fileInputRef = useRef(null);
  const { currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const [addFileIndex, setAddFileIndex] = useState(-1);

  const handleOnSectionHeaderFormChanged = useCallback(
    (values) => {
      onSectionHeaderFormChanged(values, sectionIndex, index);
    },
    [onSectionHeaderFormChanged, sectionIndex, index]
  );

  const syncSectionHeaderToProcedure = useCallback(
    (updatedSectionHeader) => {
      handleOnSectionHeaderFormChanged(updatedSectionHeader);
    },
    [handleOnSectionHeaderFormChanged]
  );

  // TODO: DRY this up, possibly by creating a MixpanelContext.js
  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    // @ts-ignore enabledContentTypes should be of type string[]
    if (!clipboardBlock || (enabledContentTypes && !enabledContentTypes.includes(clipboardBlock.type))) {
      return undefined;
    }
    return clipboardBlock;
  }, [clipboardItem]);

  const onRemoveContent = useCallback(
    (index, block) => {
      const blockCopy = cloneDeep(block);
      blockCopy.content.splice(index, 1);
      syncSectionHeaderToProcedure(blockCopy);
    },
    [syncSectionHeaderToProcedure]
  );

  const onCopyBlock = useCallback(
    ({ block }) => {
      mixpanelTrack('Copy Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      setFlashMessage('Content block copied');
    },
    [currentTeamId, dispatch, mixpanelTrack]
  );

  const onCutBlock = useCallback(
    ({ block, blockIndex, localHeader }) => {
      mixpanelTrack('Cut Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      onRemoveContent(blockIndex, localHeader);
      setFlashMessage('Content block cut');
    },
    [currentTeamId, dispatch, mixpanelTrack, onRemoveContent]
  );

  const onPasteBlock = useCallback(
    ({ blockIndex }) => {
      if (!clipboardBlock) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedHeader = cloneDeep(sectionHeader);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedHeader.content.splice(blockIndex + 1, 0, blockCopy);

      syncSectionHeaderToProcedure(updatedHeader);
    },
    [clipboardBlock, mixpanelTrack, sectionHeader, syncSectionHeaderToProcedure]
  );

  const toggleIsSectionHeaderCollapsed = useCallback(() => {
    onSectionHeaderCollapse(sectionHeader.id, !isSectionHeaderCollapsed);
  }, [isSectionHeaderCollapsed, onSectionHeaderCollapse, sectionHeader.id]);

  const onDragStart = useCallback(() => {
    // Blur any active inputs, otherwise they behave weirdly during dragging

    // @ts-expect-error Blur is defined for HTMLElement but not for Element
    document.activeElement.blur();
  }, []);

  // TODO (Deep): Create a drag and drop library and reuse across sections/steps/headers.
  const onDragEnd = useCallback(
    (result, arrayHelpers) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      // Need arrayHelpers to update formik state right away, to avoid flashing of old positions.
      arrayHelpers.move(source.index, destination.index);

      const sectionHeaderCopy = cloneDeep(sectionHeader);
      arrayMoveMutable(sectionHeaderCopy.content, source.index, destination.index);

      syncSectionHeaderToProcedure(sectionHeaderCopy);
    },
    [sectionHeader, syncSectionHeaderToProcedure]
  );

  const fieldRef = useCallback(
    (field) => (element) => {
      if (onFieldRefChanged) {
        onFieldRefChanged(field, element);
      }
    },
    [onFieldRefChanged]
  );

  const initialErrors = useMemo(() => {
    return errors;
  }, [errors]);

  const getContentErrors = useCallback(
    (contentId) => {
      if (!errors || !errors.content || !(contentId in errors.content)) {
        return null;
      }
      return errors.content[contentId];
    },
    [errors]
  );

  const getHandleFileInputChange = useCallback(
    (fileInputRef) => {
      return (event) => {
        const fileInputBlock = getFileInputBlock(event, fileInputRef);
        const headerCopy = cloneDeep(sectionHeader);

        headerCopy.content.splice(addFileIndex + 1, 0, fileInputBlock);

        syncSectionHeaderToProcedure(headerCopy);
      };
    },
    [addFileIndex, sectionHeader, syncSectionHeaderToProcedure]
  );

  const addAttachment = useCallback(
    (attachmentBlock) => {
      const sectionHeaderCopy = cloneDeep(sectionHeader);
      sectionHeaderCopy.content.push(attachmentBlock);
      syncSectionHeaderToProcedure(sectionHeaderCopy);
    },
    [sectionHeader, syncSectionHeaderToProcedure]
  );

  const getSetFieldValueAndSync = useCallback(() => {
    return (path, value) => {
      const updatedSectionHeader = setIn(sectionHeader, path, value);
      syncSectionHeaderToProcedure(updatedSectionHeader);
    };
  }, [sectionHeader, syncSectionHeaderToProcedure]);

  //TODO
  const nameRedlines = useCallback((values) => {
    if (!values.header_field_redlines) {
      return null;
    }
    return values.header_field_redlines.name;
  }, []);

  const onAcceptRedlineField = useCallback(
    (field, redlineValue) => {
      const updatedSectionHeader = setIn(sectionHeader, field, redlineValue);

      // remove redline
      updatedSectionHeader.header_field_redlines[field] = [];
      syncSectionHeaderToProcedure(updatedSectionHeader);
    },
    [sectionHeader, syncSectionHeaderToProcedure]
  );

  const onRejectRedlineField = useCallback(
    (field) => {
      const sectionHeaderCopy = cloneDeep(sectionHeader);
      sectionHeaderCopy.header_field_redlines[field] = [];

      syncSectionHeaderToProcedure(sectionHeaderCopy);
    },
    [sectionHeader, syncSectionHeaderToProcedure]
  );

  const getOnAddBlock = useCallback((container, syncBlock, index, blockType, subType) => {
    const containerCopy = cloneDeep(container);
    const contentBlock = procedureUtil.newInitialBlock(blockType, subType);

    if (index === undefined) {
      containerCopy.content.push(contentBlock);
    } else {
      containerCopy.content.splice(index + 1, 0, contentBlock);
    }

    syncBlock(containerCopy);
  }, []);

  const onContentMenuClick = useCallback(
    (menuItem, blockIndex, localHeader) => {
      switch (menuItem.action) {
        case Actions.DeleteBlock:
          return onRemoveContent(blockIndex, localHeader);
        case Actions.CopyBlock:
          return onCopyBlock({ block: localHeader.content[blockIndex] });
        case Actions.CutBlock:
          return onCutBlock({ block: localHeader.content[blockIndex], blockIndex, localHeader });
        case Actions.PasteBlock:
          return onPasteBlock({ blockIndex });
        case Actions.AddBlock:
          mixpanelTrack(`Add ${capitalizeFirstLetter(menuItem.subType || menuItem.blockType)} To Section Header`);
          if (menuItem.blockType === ProcedureContentBlockTypes.Attachment) {
            setAddFileIndex(blockIndex);
            return fileInputRef && fileInputRef.current && fileInputRef.current.click();
          }
          return getOnAddBlock(
            sectionHeader,
            syncSectionHeaderToProcedure,
            blockIndex,
            menuItem.blockType,
            menuItem.subType
          );
        default:
          return;
      }
    },
    [
      onRemoveContent,
      onCopyBlock,
      onCutBlock,
      onPasteBlock,
      mixpanelTrack,
      getOnAddBlock,
      sectionHeader,
      syncSectionHeaderToProcedure,
    ]
  );

  const onKeyboard = useCallback(
    (event, blockIndex, header) => {
      if (event.code === 'Backspace' || event.code === 'Delete') {
        onRemoveContent(blockIndex, header);
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopyBlock({ block: header.content[blockIndex] });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
        onCutBlock({ block: header.content[blockIndex], blockIndex, localHeader: header });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteBlock({ blockIndex });
      }
    },
    [onCopyBlock, onCutBlock, onPasteBlock, onRemoveContent]
  );

  return (
    <div className="flex flex-col w-full">
      <Formik
        initialValues={sectionHeader}
        initialErrors={initialErrors}
        onSubmit={() => {}}
        validateOnChange={false}
        validate={handleOnSectionHeaderFormChanged}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <div aria-label="Section Header" role="region" className="relative group">
              <OverlayUploadFileDrop
                className="flex flex-col grow"
                isEnabled={true} // Always enabled because this is an edit procedure component.
                onUpdate={addAttachment}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  id={`sectionHeaders[${index}]._file_input`}
                  onChange={getHandleFileInputChange(fileInputRef)}
                  className="hidden"
                />

                <div className="flex flex-nowrap pr-2">
                  <div className="h-9">
                    <ExpandCollapseCaret
                      isExpanded={!isSectionHeaderCollapsed}
                      onClick={toggleIsSectionHeaderCollapsed}
                      ariaLabel={isSectionHeaderCollapsed ? 'Expand header' : 'Collapse header'}
                    />
                  </div>
                  <div className="flex items-center text-left w-32 h-9">
                    <span className="w-32 shrink-0 font-medium text-md">Section Header</span>
                  </div>
                  <div
                    className="w-full flex flex-col"
                    ref={fieldRef(`${sectionHeader.id}.name`)}
                    style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM + 3}rem` }}
                  >
                    <FieldSetProcedureField
                      fieldName="name"
                      fieldValue={values.name}
                      redlines={nameRedlines(values)}
                      fieldValueType="text"
                      placeholder="Section Header name*"
                      acceptRedline={onAcceptRedlineField}
                      rejectRedline={onRejectRedlineField}
                    />
                    {errors && errors.name && <div className="text-red-700">{errors.name}</div>}
                  </div>
                </div>
                {/* Section Header Body */}
                {!isSectionHeaderCollapsed && (
                  <div className="flex">
                    <FieldArray
                      name="content"
                      key={`content[${index}]`}
                      className=""
                      render={(contentArrayHelpers) => (
                        <div className="pb-2 mt-2 w-full">
                          {values.content.length === 0 && (
                            <div className="ml-12 mt-2 pl-2">
                              <AddContentMenu
                                menuItems={getBlockAddContentItems({
                                  enabledContentTypes,
                                  canDeleteBlock: false,
                                  canCopyBlock: false,
                                  canCutBlock: false,
                                  canPasteBlock: !!clipboardBlock,
                                })}
                                onClick={(menuItem) => onContentMenuClick(menuItem, -1, values)}
                                includeTextLabel={true}
                              />
                            </div>
                          )}

                          <DragDropContext
                            onDragStart={onDragStart}
                            onDragEnd={(result) => onDragEnd(result, contentArrayHelpers)}
                          >
                            <Droppable droppableId={`sectionHeader.${index}`}>
                              {(provided, snapshot) => (
                                <div
                                  className={`border-2 border-dashed ${
                                    snapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {values.content &&
                                    values.content.map((content, contentIndex) => (
                                      <Selectable
                                        key={content.id}
                                        boundary={Boundary.ContentBlock}
                                        block={content}
                                        onKeyboard={(event) => onKeyboard(event, contentIndex, values)}
                                      >
                                        {({ isSelected, styles }) => (
                                          <Draggable key={content.id} draggableId={content.id} index={contentIndex}>
                                            {(provided, draggableSnapshot) => (
                                              <div
                                                aria-label="Content Block"
                                                role="region"
                                                className="group/content flex"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                              >
                                                <div
                                                  className={`h-9 flex flex-row gap-x-2 items-center ml-2 ${
                                                    isSelected
                                                      ? 'opacity-100'
                                                      : 'opacity-0 group-hover/content:opacity-50'
                                                  }`}
                                                >
                                                  <AddContentMenu
                                                    menuItems={getBlockAddContentItems({
                                                      enabledContentTypes,
                                                      canPasteBlock: !!clipboardBlock,
                                                    })}
                                                    onClick={(menuItem) =>
                                                      onContentMenuClick(menuItem, contentIndex, values)
                                                    }
                                                  />

                                                  {/* Drag Handle */}
                                                  <div
                                                    {...provided.dragHandleProps}
                                                    className={`relative flex shrink-0 pr-2 text-gray-400 ${contentDragHandleVisibilityClasses(
                                                      snapshot,
                                                      draggableSnapshot,
                                                      isSelected
                                                    )}`}
                                                  >
                                                    <FontAwesomeIcon icon="grip-vertical" />
                                                  </div>
                                                </div>

                                                <div
                                                  className={`my-0.5 p-1 w-full ${styles}`}
                                                  ref={fieldRef(`${sectionHeader.id}.content[${content.id}]`)}
                                                  style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                                >
                                                  {/* Render text content block */}
                                                  {content.type.toLowerCase() === 'text' && (
                                                    <FieldSetText
                                                      block={content}
                                                      path={`content[${contentIndex}]`}
                                                      contentErrors={getContentErrors(content.id)}
                                                      setFieldValue={getSetFieldValueAndSync()}
                                                    />
                                                  )}
                                                  {/* Note: Blocks are being refactored to use new architecture, once
                                                    refactor is complete they will all use ProcedureBlockWithRedlining */}
                                                  {(content.type.toLowerCase() === 'input' ||
                                                    content.type.toLowerCase() === 'alert' ||
                                                    content.type.toLowerCase() === 'requirement') && (
                                                    <FieldSetProcedureBlock
                                                      block={content}
                                                      path={`content[${contentIndex}]`}
                                                      contentErrors={getContentErrors(content.id)}
                                                      setFieldValue={getSetFieldValueAndSync()}
                                                    />
                                                  )}
                                                  {/* Render file attachment */}
                                                  {content.type.toLowerCase() === 'attachment' && (
                                                    <FieldSetAttachment
                                                      attachment={content}
                                                      errors={getContentErrors(content.id)}
                                                      path={`content[${contentIndex}]`}
                                                      setFieldValue={getSetFieldValueAndSync()}
                                                      isToTheSideImageEnabled={false}
                                                      isPending={false}
                                                      source="Section Header"
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className={`pl-1 pr-2 justify-end ${
                                                    isSelected
                                                      ? 'opacity-100'
                                                      : 'opacity-0 group-hover/content:opacity-100'
                                                  } `}
                                                >
                                                  <button
                                                    type="button"
                                                    title="Remove Section Header Content"
                                                    tabIndex={-1}
                                                    onClick={() => onRemoveContent(contentIndex, values)}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon="times-circle"
                                                      className="self-center text-gray-400 hover:text-gray-500"
                                                    ></FontAwesomeIcon>
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )}
                                      </Selectable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    />
                  </div>
                )}
              </OverlayUploadFileDrop>
            </div>
            <FlashMessage
              message={flashMessage}
              // @ts-ignore
              messageUpdater={setFlashMessage}
            />
          </Form>
        )}
      </Formik>

      {/* Review comments */}
      {/* When using comments.length by itself, JSX will convert that into a text in the div with the value 0 */}
      {comments && comments.length > 0 && (
        <div className="flex w-full px-2">
          <ProcedureEditCommentsList
            comments={comments}
            stepId={sectionHeader.id}
            isCollapsed={isSectionHeaderCollapsed}
            onResolveComment={onResolveComment}
            onSaveReviewComment={onSaveReviewComment}
            onUnresolveComment={onUnresolveComment}
          />
        </div>
      )}
    </div>
  );
};

export default FieldSetProcedureSectionHeader;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ToolbarButtonWithHoverLabel from '../../elements/ToolbarButtonWithHoverLabel';
import { StringSelectOption as SelectOption } from '../../lib/formik';
import UnitSelector from '../Settings/Units/UnitSelector';
import { SIGNOFF_COLUMN_TYPE } from './TableInputConstants';
import tableInputUtil from '../../lib/tableInputUtil';
import { TableCell, TableColumn } from 'shared/lib/types/views/procedures';
import ListSelectFieldSet from '../Blocks/ListSelectFieldSet';
import { Field } from 'formik';

interface MenuContextColumnHeaderProps {
  column: TableColumn;
  onAddColumn: () => void;
  onRemoveColumn: () => void;
  isRemoveColumnDisabled: boolean;
  isUnitsFieldDisabled: boolean;
  onUpdateColumn: (
    columnUpdateObject: {
      [columnPath: string]: string | boolean | number | undefined;
    },
    index?: number,
    resetValue?: TableCell
  ) => void;
  columnIndex: number;
  onMoveColumnLeft?: () => void;
  onMoveColumnRight?: () => void;
  isReadOnly: boolean;
}

/**
 * This component renders a context menu used in Table Input Column Headers.
 */
const MenuContextColumnHeader = React.memo(
  ({
    column,
    onAddColumn,
    onRemoveColumn,
    isRemoveColumnDisabled,
    isUnitsFieldDisabled,
    onUpdateColumn,
    columnIndex,
    onMoveColumnLeft,
    onMoveColumnRight,
    isReadOnly = false,
  }: MenuContextColumnHeaderProps) => {
    const [units, setUnits] = useState(column.units);

    useEffect(() => {
      setUnits((oldValue) => {
        if (column.units !== oldValue) {
          return column.units;
        }
        return oldValue;
      });
    }, [column.units]);

    const updateInternalUnitsState = useCallback(
      (value: SelectOption | undefined) => {
        if (!value) {
          setUnits('');
          onUpdateColumn({ units: '' });
          return;
        }
        setUnits(value.label);
        onUpdateColumn({ units: value.label });
      },
      [onUpdateColumn]
    );

    const updateColumnAndReset = useCallback(
      (
        columnUpdateObject: {
          [columnPath: string]: string | boolean | number;
        },
        index?: number,
        resetValue?: TableCell
      ) => {
        const resetObject = {
          units: '',
          list: '',
          allow_input_after_signoff: undefined,
        };
        const columnUpdateObjectWithResetValues = {
          ...resetObject, // Place the reset values first, so they can be overridden by passed-in values.
          ...columnUpdateObject,
        };
        onUpdateColumn(columnUpdateObjectWithResetValues, index, resetValue);
      },
      [onUpdateColumn]
    );

    return (
      <div className="absolute p-2 w-64 flex flex-col bg-white border border-gray-300 shadow-lg  font-normal rounded-md z-50">
        <ul>
          <li className="flex flex-row">
            <ToolbarButtonWithHoverLabel
              icon="arrow-left"
              ariaLabel="Move Left"
              label="Move"
              disabled={!onMoveColumnLeft}
              onClick={onMoveColumnLeft}
            />
            <ToolbarButtonWithHoverLabel
              icon="arrow-right"
              ariaLabel="Move Right"
              label="Move"
              disabled={!onMoveColumnRight}
              onClick={onMoveColumnRight}
            />
            <ToolbarButtonWithHoverLabel
              icon="plus"
              ariaLabel="Add Column"
              label="Add"
              disabled={false}
              onClick={onAddColumn}
            />
            <ToolbarButtonWithHoverLabel
              icon="x"
              ariaLabel="Remove Column"
              label="Remove"
              disabled={isRemoveColumnDisabled}
              onClick={onRemoveColumn}
            />
          </li>
          {/* Separator */}
          <li className="w-full bg-gray-100 h-0.5 my-1"></li>
          {/* Column Type */}
          <li onClick={(e) => e.stopPropagation()}>
            <div className="py-1 text-sm text-gray-500">Column Type</div>
            <div className="grid grid-cols-2 grid-flow-row">
              <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                <input
                  type="radio"
                  className="mr-1 cursor-pointer"
                  onChange={(event) => {
                    updateColumnAndReset({ column_type: event.target.value }, columnIndex);
                  }}
                  value="text"
                  checked={column.column_type === 'text'}
                />
                Text
              </label>
              {!isReadOnly && (
                <>
                  <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                    <input
                      type="radio"
                      className="mr-1 cursor-pointer"
                      onChange={(event) => {
                        updateColumnAndReset(
                          {
                            column_type: event.target.value,
                          },
                          columnIndex
                        );
                      }}
                      value="input"
                      checked={column.column_type === 'input'}
                    />
                    Input
                  </label>
                  <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                    <input
                      type="radio"
                      className="mr-1 cursor-pointer"
                      onChange={(event) => {
                        updateColumnAndReset({ column_type: event.target.value }, columnIndex, [
                          tableInputUtil.getInitialSignoffObject(),
                        ]);
                      }}
                      value={SIGNOFF_COLUMN_TYPE}
                      checked={column.column_type === SIGNOFF_COLUMN_TYPE}
                    />
                    Signoffs
                  </label>
                  <label className="w-fit flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                    <input
                      type="radio"
                      className="mr-1 cursor-pointer"
                      onChange={(event) => {
                        updateColumnAndReset(
                          {
                            column_type: event.target.value,
                            width: 7,
                          },
                          columnIndex,
                          []
                        );
                      }}
                      value="comment"
                      checked={column.column_type === 'comment'}
                    />
                    Comments
                  </label>
                </>
              )}
            </div>
          </li>
          {column.column_type === 'input' && (
            <Fragment>
              {/* Separator */}
              <li className="w-full bg-gray-100 h-0.5 my-1"></li>
              {/* Input Settings */}
              <li onClick={(e) => e.stopPropagation()}>
                <div className="py-1 text-sm text-gray-500">Input Settings</div>
                <ul>
                  <li>
                    <div className="grid grid-cols-2 grid-flow-row">
                      <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                        <input
                          type="radio"
                          className="mr-1 cursor-pointer"
                          onChange={(event) => updateColumnAndReset({ input_type: event.target.value })}
                          value="text"
                          checked={column.input_type === 'text'}
                        />
                        Text
                      </label>
                      <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                        <input
                          type="radio"
                          className="mr-1 cursor-pointer"
                          onChange={(event) => updateColumnAndReset({ input_type: event.target.value })}
                          value="number"
                          checked={column.input_type === 'number'}
                        />
                        Number
                      </label>
                      <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                        <input
                          type="radio"
                          className="mr-1 cursor-pointer"
                          onChange={(event) => {
                            updateColumnAndReset({ input_type: event.target.value });
                          }}
                          value="checkbox"
                          checked={column.input_type === 'checkbox'}
                        />
                        Checkbox
                      </label>
                      <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                        <input
                          type="radio"
                          className="mr-1 cursor-pointer"
                          onChange={(event) => {
                            updateColumnAndReset({ input_type: event.target.value });
                          }}
                          value="list"
                          checked={column.input_type === 'list'}
                        />
                        List
                      </label>
                    </div>
                    {column.input_type === 'list' && (
                      <div className="mt-1 flex flex-col">
                        <div className="py-1 text-sm text-gray-500">List</div>
                        <Field
                          name={`columns[${columnIndex}].list`}
                          value={column.list}
                          component={ListSelectFieldSet}
                          onChange={(updatedListValue) => {
                            updateColumnAndReset({ list: updatedListValue });
                          }}
                          isDisabled={false}
                        />
                      </div>
                    )}
                  </li>
                  <li className="flex flex-col" onClick={(e) => e.stopPropagation()}>
                    <div className="py-1 text-sm text-gray-500">Units</div>
                    <UnitSelector
                      name="Units input"
                      value={units}
                      onChange={updateInternalUnitsState}
                      isDisabled={isUnitsFieldDisabled}
                      customClear={true}
                    />
                  </li>
                </ul>
              </li>
            </Fragment>
          )}
          {column.column_type === 'signoff' && (
            <Fragment>
              {/* Separator */}
              <li className="w-full bg-gray-100 h-0.5 my-1"></li>
              {/* Signoff Settings */}
              <li onClick={(e) => e.stopPropagation()}>
                <div className="py-1 text-sm text-gray-500">Signoff Settings</div>
                <ul>
                  <li>
                    <label className="flex py-2 px-3 items-center cursor-pointer rounded-md hover:bg-gray-100">
                      <input
                        type="checkbox"
                        className="mr-1 cursor-pointer"
                        onChange={(event) =>
                          updateColumnAndReset({
                            allow_input_after_signoff: event.target.checked,
                          })
                        }
                        checked={column.allow_input_after_signoff === true}
                      />
                      Allow Input After Signoff
                    </label>
                  </li>
                </ul>
              </li>
            </Fragment>
          )}
        </ul>
      </div>
    );
  }
);

export default MenuContextColumnHeader;

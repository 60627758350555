import { useEffect, useState } from 'react';
import { useRunContext } from '../contexts/RunContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import Select from 'react-select';
import { useSettings } from '../contexts/SettingsContext';

type RoleOption = {
  label: string;
  value: string;
};

export default function PreviewRoleSelector() {
  const { updatePreviewUserRoles } = useRunContext();
  const { mixpanel } = useMixpanel();
  const { operatorRoles } = useSettings();
  const [availableRoles, setAvailableRoles] = useState<Array<string>>([]);
  const [currentRoles, setCurrentRoles] = useState<Array<string>>([]);

  const getSelectArray = (roleList: Array<string>) => {
    return roleList.map((value) => ({
      value,
      label: value,
    }));
  };

  useEffect(() => {
    if (!operatorRoles?.operators) {
      return;
    }
    const roles = operatorRoles.operators.map((operator) => operator.code);
    setAvailableRoles(roles);
    updatePreviewUserRoles(roles);
  }, [operatorRoles, updatePreviewUserRoles]);

  useEffect(() => {
    updatePreviewUserRoles(currentRoles);
  }, [currentRoles, updatePreviewUserRoles]);

  const updateCurrent = (values: Array<RoleOption>) => {
    mixpanel?.track('Change Preview Roles');
    const updated = values.map((val) => val.value);
    setCurrentRoles(updated);
  };

  return (
    <div className="bg-cyan-100 p-3 rounded-md  my-2">
      <label className="font-semibold" htmlFor="roleSelector">
        Previewing as
      </label>
      <Select
        className="mt-2"
        aria-label="Role Selector"
        placeholder="All available roles"
        name="roleSelector"
        classNamePrefix="settings-select react-select"
        value={getSelectArray(currentRoles)}
        options={getSelectArray(availableRoles)}
        isMulti={true}
        onChange={(values: Array<RoleOption>) => updateCurrent(values)}
      />
    </div>
  );
}

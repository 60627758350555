import Pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CouchLikeOperations, OperationState } from 'shared/lib/types/operations';
import Button from '../Button';
import OperationsTable from '../Operations/OperationsTable';
import TabBar, { TabProps } from '../TabBar/TabBar';
import { OPERATIONS_PAGE_SIZE } from '../../config';
import { useSettings } from '../../contexts/SettingsContext';
import SearchInputControlled from '../../elements/SearchInputControlled';

const MAIN_VERTICAL_PADDING = 230;

const TABS: ReadonlyArray<TabProps<OperationState>> = [
  { id: 'planning', label: 'Planning' },
  { id: 'running', label: 'Running' },
  { id: 'ended', label: 'Ended' },
];

interface OperationsGridProps {
  mainVerticalPadding?: number;
}

const OperationsGrid = ({ mainVerticalPadding = MAIN_VERTICAL_PADDING }: OperationsGridProps) => {
  const { operations, operationsPagination, setOperationsPagination, operationsSearchTerm, setOperationsSearchTerm } =
    useSettings();
  const [searchTerm, setSearchTerm] = useState(operationsSearchTerm ?? '');
  const [state, setState] = useState<OperationState>('running');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!setOperationsPagination) {
      return;
    }

    // page was previously loaded, do nothing to preserve previous pagination
    if (operationsPagination?.limit === OPERATIONS_PAGE_SIZE) {
      return;
    }

    setOperationsPagination({
      planning_page: 0,
      running_page: 0,
      ended_page: 0,
      limit: OPERATIONS_PAGE_SIZE,
    });
  }, [operationsPagination, setOperationsPagination]);

  useEffect(() => {
    if (operations && !operations.summary) {
      setLoading(false);
    }
  }, [operations]);

  const operationsList = useMemo(() => {
    if (!operations) {
      return [];
    }

    if (operations.summary) {
      return [];
    }

    return Object.values((operations as CouchLikeOperations).operations);
  }, [operations]);

  const visibleOperations = useMemo(() => operationsList.filter((op) => op.state === state), [operationsList, state]);

  const totals = useMemo(() => {
    if (!operations || operations.summary || loading) {
      return null;
    }
    return (operations as CouchLikeOperations).totals;
  }, [loading, operations]);

  const stats = useCallback(
    (state: OperationState) => {
      if (operations?.summary || !totals) {
        return;
      }
      let stats = '';
      let total = 0;
      switch (state) {
        case 'planning':
          total = totals.num_planning;
          break;
        case 'running':
          total = totals.num_running;
          break;
        case 'ended':
          total = totals.num_ended;
          break;
      }
      stats += `${total.toLocaleString()} ${Pluralize('Operation', total)} ${operationsSearchTerm ? 'found' : ''} ${
        state === 'planning' ? 'in' : ''
      } ${state}`;
      return stats;
    },
    [operations, operationsSearchTerm, totals]
  );

  const [page, numTotalOperations] = useMemo(() => {
    if (!operationsPagination || !operations || operations.summary) {
      return [0, 0];
    }
    const totals = (operations as CouchLikeOperations).totals;
    switch (state) {
      case 'planning':
        return [operationsPagination.planning_page, totals.num_planning];
      case 'running':
        return [operationsPagination.running_page, totals.num_running];
      case 'ended':
        return [operationsPagination.ended_page, totals.num_ended];
    }
  }, [operations, operationsPagination, state]);

  const hasPrevPage = useMemo(() => {
    return page > 0;
  }, [page]);

  const hasNextPage = useMemo(() => {
    if (!operations || !operationsPagination) {
      return false;
    }
    return page * operationsPagination.limit + operationsPagination.limit < numTotalOperations;
  }, [numTotalOperations, page, operations, operationsPagination]);

  const pageInfo = useMemo(() => {
    return `${Math.min(page * (operationsPagination?.limit ?? 0) + 1, numTotalOperations)} - 
    ${Math.min(
      page * (operationsPagination?.limit ?? 0) + (operationsPagination?.limit ?? 0),
      numTotalOperations
    )} of ${numTotalOperations}`;
  }, [numTotalOperations, operationsPagination?.limit, page]);

  const goToPage = useCallback(
    (go: 'prev' | 'next') => {
      if (!setOperationsPagination || !operationsPagination) {
        return;
      }
      const newPage = go === 'prev' ? page - 1 : page + 1;
      switch (state) {
        case 'planning':
          setOperationsPagination({ ...operationsPagination, planning_page: newPage });
          break;
        case 'running':
          setOperationsPagination({ ...operationsPagination, running_page: newPage });
          break;
        case 'ended':
          setOperationsPagination({ ...operationsPagination, ended_page: newPage });
          break;
      }
      setLoading(true);
    },
    [setOperationsPagination, operationsPagination, page, state]
  );

  const resetPagination = useCallback(() => {
    if (setOperationsPagination && operationsPagination) {
      setOperationsPagination({
        ...operationsPagination,
        planning_page: 0,
        running_page: 0,
        ended_page: 0,
      });
    }
  }, [operationsPagination, setOperationsPagination]);

  const onKeyUpSearchTerm = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!setOperationsSearchTerm) {
        return;
      }
      if (searchTerm === operationsSearchTerm) {
        return;
      }
      if (event.key === 'Enter') {
        setOperationsSearchTerm(searchTerm);
        resetPagination();
      }
    },
    [setOperationsSearchTerm, searchTerm, operationsSearchTerm, resetPagination]
  );

  const onChangeSearchTerm = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
      if (searchTerm === '') {
        if (setOperationsSearchTerm) {
          setOperationsSearchTerm('');
        }
        resetPagination();
      }
    },
    [resetPagination, setOperationsSearchTerm]
  );

  const updateTab = useCallback((state: OperationState) => {
    setState(state);
  }, []);

  const tabs = useMemo(() => {
    return TABS.map((tab) => ({
      ...tab,
      ...{ count: totals?.[`num_${tab.id}`] },
      ...{ tooltip: stats(tab.id) },
    }));
  }, [stats, totals]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row pb-2 items-center">
          <SearchInputControlled
            searchTerm={searchTerm}
            setSearchTerm={onChangeSearchTerm}
            onKeyUp={onKeyUpSearchTerm}
            placeholder="Search operations (hit enter to search)"
          />
          {operationsSearchTerm && <div className="ml-2 text-gray-500">Current search: {operationsSearchTerm}</div>}
        </div>

        <div className="mb-2 mt-2 flex w-full">
          <div className="w-1/2">
            <TabBar tabs={tabs} selectedTab={state} setSelectedTab={updateTab} />
          </div>
          <div className="text-right w-1/2">
            <Button
              type="secondary"
              isDisabled={!hasPrevPage || loading}
              size="sm"
              onClick={() => goToPage('prev')}
              leadingIcon="caret-left"
            />
            <span className="pr-2"></span>
            <span className=" text-gray-400 text-center">{pageInfo}</span>
            <span className="pr-2"></span>
            <Button
              type="secondary"
              isDisabled={!hasNextPage || loading}
              size="sm"
              onClick={() => goToPage('next')}
              leadingIcon="caret-right"
            />
          </div>
        </div>

        <OperationsTable
          operations={visibleOperations}
          loading={loading}
          setState={setState}
          state={state}
          mainVerticalPadding={mainVerticalPadding}
        />
      </div>
    </>
  );
};

export default OperationsGrid;

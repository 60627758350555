import Tooltip from '../../elements/Tooltip';
import isNumber from '../../lib/number';

export interface TabProps<TId extends string = string> {
  id: TId;
  label: string;
  count?: number;
  tooltip?: string;
}

interface FullTabProps<TId extends string> extends TabProps<TId> {
  isSelected: boolean;
  onClick: (id: TId) => void;
  isHeader?: boolean;
}

export interface TabBarProps<TId extends string> {
  tabs: ReadonlyArray<TabProps<TId>>;
  selectedTab: string;
  setSelectedTab: (selectedTab: TId) => void;
  ariaLabel?: string;
  /** Optional count of results for the selected tab */
  count?: number;
  /** Optional header styling */
  isHeader?: boolean;
}

const Tab = function <TId extends string>({
  id,
  label,
  count,
  tooltip,
  onClick,
  isSelected,
  isHeader,
}: FullTabProps<TId>) {
  const selectedClassNames = `border-b-2 ${
    isSelected
      ? `font-medium border-b-blue-500
      ${isHeader ? 'text-blue-500' : 'text-gray-700'}`
      : `hover:brightness-75 text-gray-400 hover:cursor-pointer border-b-gray-200`
  }`;

  const headerClassNames = isHeader ? 'w-48 text-lg pb-1' : 'w-36  rounded-sm';

  return (
    <Tooltip content={tooltip} visible={Boolean(tooltip)}>
      <div
        role="tab"
        aria-label={label}
        aria-selected={isSelected}
        onClick={() => onClick(id)}
        className={`${selectedClassNames} ${headerClassNames} pl-2 pt-1 whitespace-nowrap`}
      >
        {label} {isNumber(count) ? `(${count})` : ''}
      </div>
    </Tooltip>
  );
};

const TabBar = function <TId extends string = string>({
  tabs,
  selectedTab,
  setSelectedTab,
  ariaLabel,
  isHeader,
}: TabBarProps<TId>) {
  const onChangeHandler = (id: TId) => {
    setSelectedTab(id);
  };

  return (
    <div className="flex flex-row items-end" role="tablist" aria-label={ariaLabel}>
      {tabs.map(({ id, label, count, tooltip }: TabProps<TId>) => {
        return (
          <Tab
            id={id}
            key={id}
            count={count}
            label={label}
            tooltip={tooltip}
            onClick={onChangeHandler}
            isSelected={id === selectedTab}
            isHeader={isHeader}
          />
        );
      })}
    </div>
  );
};

export default TabBar;

import React, { ReactNode } from 'react';

interface InvalidMessageProps {
  children: ReactNode;
}

const InvalidMessage = ({ children }: InvalidMessageProps) => {
  return <span className="text-red-700  font-medium">{children}</span>;
};

export default React.memo(InvalidMessage);

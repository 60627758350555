import { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { DiffArrayChangeSymbol } from 'shared/lib/types/views/procedures';
import { DIFF_COLOR_CLASSES } from '../../../lib/diffUtil';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ReviewExpressionTokenDisplayProps {
  referenceName: string;
  referenceIcon?: IconProp;
  onClick: () => void;
  referencedFromStepKey?: string;
  referencedFromSectionKey?: string;
  isVariable: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  isLight?: boolean;
}

const ReviewExpressionTokenDisplay = ({
  referenceName,
  referenceIcon,
  onClick,
  referencedFromStepKey,
  isVariable,
  diffChangeState,
  isLight = false,
}: ReviewExpressionTokenDisplayProps) => {
  const chipStatusColor = useMemo(() => {
    if (!diffChangeState || diffChangeState === ARRAY_CHANGE_SYMBOLS.UNCHANGED) {
      return isLight ? 'bg-white' : 'bg-gray-200';
    }
    const backgroundColor = DIFF_COLOR_CLASSES[diffChangeState ?? ''].backgroundColor;
    const borderColor = DIFF_COLOR_CLASSES[diffChangeState ?? ''].borderColor;
    return `${backgroundColor ?? ''} ${borderColor ?? ''}`;
  }, [diffChangeState, isLight]);

  const referencedFromPrefix = isVariable ? 'Variable' : referencedFromStepKey;

  const diffValueReferenceName = sharedDiffUtil.getDiffValue({ referenceName }, 'referenceName', 'new');

  return (
    <div className="flex flex-row items-start">
      <button
        onClick={onClick}
        className={`font-medium text-sm px-1.5 py-0.5 rounded-xl whitespace-nowrap ${chipStatusColor}`}
      >
        {referencedFromPrefix} {referenceIcon && <FontAwesomeIcon icon={referenceIcon} />} {diffValueReferenceName}
      </button>
    </div>
  );
};

export default ReviewExpressionTokenDisplay;

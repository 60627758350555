import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { reactSelectStyles } from '../lib/styles';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Dictionary } from '../lib/models/postgres/dictionary';
import { FieldInputProps, FormikProps } from 'formik';
import { DatabaseServices } from '../contexts/proceduresSlice';

type DictionaryOption = {
  value: number;
  label: string;
};

interface FieldSetDictionarySelectProps {
  value: number;
  field: FieldInputProps<string>;
  form: FormikProps<string>;
}

const FieldSetDictionarySelect = React.memo(({ value, field, form }: FieldSetDictionarySelectProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { mixpanel } = useMixpanel();

  const [options, setOptions] = useState<Array<DictionaryOption>>([]);
  const [initialValue, setInitialValue] = useState<number>();

  const onChangeHandler = useCallback(
    (option) => {
      // Skip onChange when re-selecting the same value.
      if (option?.value === value) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Change Procedure Dictionary');
      }

      // allow unsetting the dictionary by sending empty string
      const updatedValue = option?.value || '';

      form.setFieldValue(field.name, updatedValue);
    },
    [value, form, field.name, mixpanel]
  );

  useEffect(() => {
    if (value && value === initialValue) {
      return;
    }
    setInitialValue(value);
    services.dictionary
      .listDictionaries()
      .then((results: Array<Dictionary>) => {
        setOptions(
          results.map((result) => ({
            value: result.id,
            label: result.name,
          }))
        );
      })
      .catch(() => []);
  }, [initialValue, services.dictionary, value]);

  const dictionaryMap = useMemo(() => {
    const map = {};
    if (!options) {
      return map;
    }
    options?.forEach((option) => {
      map[option.value] = option;
    });
    return map;
  }, [options]);

  const selectValue = useMemo(() => {
    return dictionaryMap[value] || '';
  }, [dictionaryMap, value]);

  return (
    <div className="grow w-full">
      <div className="grow relative ">
        <div>
          <Select
            aria-label="Data Sources"
            classNamePrefix="react-select"
            name={field.name}
            isClearable={true}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            options={options}
            styles={reactSelectStyles}
            value={selectValue}
          />
        </div>
      </div>
    </div>
  );
});

export default FieldSetDictionarySelect;

import React from 'react';
import PropTypes from 'prop-types';

import { DAYS } from '../../../constants';

const RepeatMonthlyOnThe = ({ id, mode, onThe, hasMoreModes, handleChange }) => {
  const isActive = mode === 'on the';

  return (
    <div className={`flex flex-row items-center space-x-2 ${!isActive && 'opacity-50'}`}>
      {hasMoreModes && (
        <input
          id={id}
          type="radio"
          name="repeat.monthly.mode"
          aria-label="Repeat monthly on the"
          value="on the"
          checked={isActive}
          onChange={handleChange}
        />
      )}

      <span>on the</span>

      <select
        id={`${id}-which`}
        name="repeat.monthly.onThe.which"
        aria-label="Repeat monthly on the which"
        className="rounded  border-gray-300"
        value={onThe.which}
        disabled={!isActive}
        onChange={handleChange}
      >
        <option value="First">First</option>
        <option value="Second">Second</option>
        <option value="Third">Third</option>
        <option value="Fourth">Fourth</option>
        <option value="Last">Last</option>
      </select>

      <select
        id={`${id}-day`}
        name="repeat.monthly.onThe.day"
        aria-label="Repeat monthly on the day"
        className="rounded  border-gray-300"
        value={onThe.day}
        disabled={!isActive}
        onChange={handleChange}
      >
        {DAYS.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};
RepeatMonthlyOnThe.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['on', 'on the']).isRequired,
  onThe: PropTypes.shape({
    which: PropTypes.oneOf(['First', 'Second', 'Third', 'Fourth', 'Last']).isRequired,
    day: PropTypes.oneOf(DAYS).isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatMonthlyOnThe;

import { Link } from 'react-router-dom';
import { getPartLabel, getPartRevisionId, isPartRestricted } from '../lib/parts';
import { partPath } from '../../lib/pathUtil';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';

interface PartTopLabelProps {
  teamId: string;
  part: Part | undefined;
}

const PartTopLabel = ({ teamId, part }: PartTopLabelProps) => {
  const numberRevLabel = getPartLabel(part);

  if (!part) {
    return <div className="">{numberRevLabel}</div>;
  }
  if (isPartRestricted(part)) {
    return (
      <div className="mt-2">
        <RestrictedInfo text={RESTRICTED_TEXT} />
      </div>
    );
  }

  const partRevisionId = getPartRevisionId(part);
  const path = partPath(teamId, part.id, partRevisionId);

  return (
    <Link to={path} className="inline-block">
      <div className="text-blue-600">{numberRevLabel}</div>
    </Link>
  );
};

export default PartTopLabel;

import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import SourceField from './SourceField';
import TargetField from './TargetField';
import ConditionalStateTag from './ConditionalStateTag';
import { Conditional, DraftFieldInputBlock, Step, StepConditional } from 'shared/lib/types/views/procedures';
import signoffUtil from 'shared/lib/signoffUtil';
import VirtualizedElement from '../../elements/Virtualized/VirtualizedElement';
import { MainScrollPanelId } from '../../elements/SidebarLayout';
import { PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS } from '../../screens/ProcedureEdit';

interface FieldSetStepConditionalsProps {
  conditionals: StepConditional[];
  errors?: string[];
  content: DraftFieldInputBlock[];
  step: Step;
  onFieldRefChanged: (field: string, element: string) => void;
  setFieldValue: (name: string, value: string | Array<Conditional>) => void;
  optimize?: boolean;
  isDisabled?: boolean;
}

/**
 * Edit fieldset for step conditional flow control.
 */
const FieldSetStepConditionals = ({
  conditionals,
  errors,
  content,
  step,
  onFieldRefChanged,
  setFieldValue,
  optimize = false,
  isDisabled = false,
}: FieldSetStepConditionalsProps) => {
  const { getInitialConditionals } = useProcedureContext();
  /**
   * Notify parent that the ref for this field has changed.
   *
   * @param {String} field - Name of field for the given HTML element reference.
   */
  const fieldRef = useCallback(
    (field) => (element) => onFieldRefChanged && onFieldRefChanged(field, element),
    [onFieldRefChanged]
  );

  const onSourceChange = useCallback(
    (sourceType, contentId) => {
      const block = contentId && content.find((block) => block.id === contentId);
      const conditionals = getInitialConditionals(step, block, sourceType);
      setFieldValue('conditionals', conditionals);
    },
    [step, content, getInitialConditionals, setFieldValue]
  );

  const isConditionalDisabled = useCallback(
    (conditional, index) => {
      return !signoffUtil.isSignoffRequired(step.signoffs) && index > 0 && conditional.source_type === 'step';
    },
    [step.signoffs]
  );

  const stepId = step.id;

  if (!conditionals || conditionals.length === 0) {
    return null;
  }

  const primaryConditional = conditionals[0];

  return (
    <div className="flex flex-col w-full p-2 group-hover/content:rounded group-hover/content:bg-gray-600/10">
      <VirtualizedElement
        key={primaryConditional.id}
        scrollElementId={MainScrollPanelId}
        optimize={optimize}
        onRefChanged={fieldRef(`${stepId}.conditionals`)}
        namespace={PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS}
      >
        <span className="field-title">Conditionals</span>
        <div className="flex flex-wrap space-x-1 items-start scroll-mt-28">
          <div className="my-1">
            <SourceField
              content={content}
              name="conditionals[0].source_id"
              onSourceChange={onSourceChange}
              stepId={stepId}
              conditional={primaryConditional}
              disabled={isDisabled}
              includeDuration={Boolean(step.duration) && Boolean(step.expected_duration)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-2 w-full">
          {conditionals.map((conditional, index) => (
            <div key={conditional.id} className="flex flex-row items-center w-full">
              <div className="flex w-5/12 truncate justify-end">
                <ConditionalStateTag conditional={conditional} size="base" conditionalStepId={stepId} />
              </div>
              <div className="flex w-1/12 justify-center">
                <FontAwesomeIcon className="w-4 text-gray-600" icon="arrow-right" />
              </div>
              <div className="flex w-6/12 justify-start">
                <TargetField
                  name={`conditionals[${index}].target_id`}
                  stepId={stepId}
                  value={conditional.target_id}
                  disabled={isDisabled || isConditionalDisabled(conditional, index)}
                  setFieldValue={setFieldValue}
                />
                {errors && errors[index] && <div className="text-red-700 normal-case font-normal">{errors[index]}</div>}
              </div>
            </div>
          ))}
        </div>
      </VirtualizedElement>
    </div>
  );
};

export default FieldSetStepConditionals;

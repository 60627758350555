import { CouchLikeOperation } from 'shared/lib/types/operations';
import { ProcedureMap, StartProcedureParams } from './types';
import { Link, useLocation } from 'react-router-dom';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import NoDataPlaceholder from '../NoDataPlaceholder';
import { procedureSnapshotViewPathWithSourceUrl } from '../../../lib/pathUtil';
import Button, { BUTTON_TYPES } from '../../Button';
import Tooltip from '../../../elements/Tooltip';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useAuth } from '../../../contexts/AuthContext';
import procedureUtil from '../../../lib/procedureUtil';

type PlanningEventStatusColumnProps = {
  event: Event;
  operation: CouchLikeOperation;
  startProcedure: (params: StartProcedureParams) => Promise<void>;
  procedureMap: ProcedureMap;
};

const PlanningEventStatusColumn = ({
  event,
  operation,
  startProcedure,
  procedureMap,
}: PlanningEventStatusColumnProps) => {
  const { auth } = useAuth();
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  const location = useLocation();
  const procedure = procedureMap[event.procedure_id || ''];
  if (!procedure) {
    return <NoDataPlaceholder />;
  }
  const truncateCode = (code: string, maxLength: number) => {
    return code.length > maxLength ? `${code.substring(0, maxLength)}...` : code;
  };
  const truncatedProcedureCode = truncateCode(procedure.code, 18);

  const canRunProcedure = procedureUtil.userHasStartRunSignoffs(
    procedure.start_run_signoffs_groups,
    auth.getOperatorRolesSet()
  );

  const procedureLink = procedureSnapshotViewPathWithSourceUrl({
    teamId: currentTeamId,
    procedureId: event.procedure_id || '',
    sectionId: event.procedure_section_id,
    sourceUrl: location.pathname,
    sourceName: `Operation ${operation.name}`,
  });

  return (
    <div className="flex flex-row items-center text-base">
      <Button
        type={BUTTON_TYPES.PRIMARY}
        size="sm"
        onClick={() => startProcedure({ procedure, sectionId: event.procedure_section_id })}
        isDisabled={!canRunProcedure}
      >
        Start
      </Button>
      <Tooltip content={procedure.code} visible={truncatedProcedureCode !== procedure.code}>
        <Link to={procedureLink} className="text-sm ml-2 text-blue-600 hover:underline truncate">
          {truncatedProcedureCode}
        </Link>
      </Tooltip>
    </div>
  );
};

export default PlanningEventStatusColumn;

import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useSettings } from '../contexts/SettingsContext';
import { reactSelectStyles } from '../lib/styles';

const FieldSetUserSelect = React.memo(({ value, field, form }) => {
  const { users } = useSettings();

  const userOptions = useMemo(() => {
    if (!users || !users.users) {
      return [];
    }
    return Object.keys(users.users).map((user) => ({
      value: user,
      label: user,
    }));
  }, [users]);

  // Look for project that matches project key from the saved procedure document.
  const selectValue = useMemo(() => {
    if (!value || !userOptions.length) {
      return '';
    }

    return userOptions.find((user) => user.value === value);
  }, [userOptions, value]);

  const onChangeHandler = useCallback(
    (option) => {
      const userId = option.value;

      // Skip onChange when re-selecting the same value.
      if (value === userId) {
        return;
      }

      form.setFieldValue(field.name, userId);
    },
    [value, form, field.name]
  );

  return (
    <div className="grow w-full">
      <div className="grow relative ">
        <div>
          <Select
            classNamePrefix="react-select"
            isClearable={false}
            name={field.name}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            placeholder="Select user"
            options={userOptions}
            styles={reactSelectStyles}
            value={selectValue}
          />
        </div>
      </div>
    </div>
  );
});

export default FieldSetUserSelect;

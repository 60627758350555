import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertSubtypes } from './AlertRun';

const AlertIcon = ({ alert }) => {
  if (!alert || !alert.subtype) {
    return null;
  }

  if (alert.subtype === AlertSubtypes.NOTE) {
    return <FontAwesomeIcon icon={['far', 'sticky-note']} className="text-xl" />;
  } else if (alert.subtype === AlertSubtypes.CAUTION) {
    return <FontAwesomeIcon icon={['fas', 'info-circle']} className="text-xl" />;
  } else if (alert.subtype === AlertSubtypes.WARNING) {
    return <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="text-xl" />;
  }

  return null;
};

export default AlertIcon;

import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { useNavState } from '../../contexts/NavContext';

type ManufacturingAuth = {
  hasAdminPermission: boolean;
  hasEditPermission: boolean;
  hasWorkspaceEditPermission: boolean;
  hasWorkspaceViewerPermission: boolean;
  hasEditPermissionForProject: (projectId: string | null) => boolean;
  hasOperatorPermission: boolean;
};

const useManufacturingAuth = (): ManufacturingAuth => {
  const { auth } = useAuth();
  const { projectId } = useNavState();
  const hasAdminPermission = auth.hasPermission(PERM.BUILDS_IMPORT_PARTS, null);

  const hasWorkspaceEditPermission = auth.hasPermission(PERM.BUILDS_EDIT, null);

  const hasWorkspaceViewerPermission = auth.hasPermission(PERM.VIEW_SETTINGS);

  const hasEditPermission =
    hasWorkspaceEditPermission ||
    auth.hasPermission(PERM.BUILDS_EDIT, projectId);

  const hasOperatorPermission =
    auth.hasPermission(PERM.BUILDS_ADD_INVENTORY, null) ||
    auth.hasProjectsWithOperatorPermission();

  const hasEditPermissionForProject = (
    projectId: string | undefined | null
  ) => {
    if (auth.hasPermission(PERM.BUILDS_EDIT, projectId)) {
      return true;
    }
    if (!projectId) {
      return false;
    }
    const editorPermissionProjects = auth.projectsWithEditPermission();
    return editorPermissionProjects.includes(projectId);
  };

  return {
    hasAdminPermission,
    hasEditPermission,
    hasWorkspaceEditPermission,
    hasWorkspaceViewerPermission,
    hasOperatorPermission,
    hasEditPermissionForProject,
  };
};

export default useManufacturingAuth;

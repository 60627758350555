import { useMemo } from 'react';
import { PartList as PartListContent } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import useParts from '../hooks/useParts';
import usePartUnits from '../hooks/usePartUnits';
import PartBadge from './PartBadge';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo from './RestrictedInfo';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import inventoryUtil from '../lib/inventoryUtil';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';

type PartListProps = {
  content: PartListContent;
};

const PartList = ({ content }: PartListProps) => {
  const { currentTeamId } = useDatabaseServices();

  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content ?? {}), [content]);

  const { parts, getPart, getPartByRevisionId, getPartByRevision } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });
  const { getUnits } = usePartUnits({ partIds: partIdsInBlock });

  if (!parts) {
    return null;
  }

  let bomPart: Part | undefined = undefined;
  let bomPartRestricted = false;
  if (content.part_id) {
    bomPart = getPartByRevision(content.part_id, content.part?.rev);

    const retrievedPart = getPart(content.part_id || '');
    bomPartRestricted = retrievedPart ? isPartRestricted(retrievedPart) : false;
  }

  return (
    <div className="flex flex-wrap page-break">
      <div className="flex items-start w-full py-1 mr-8">
        <div className="w-full mx-8">
          <div>
            <span className=" font-medium uppercase">Part to Build</span>
          </div>
          <PartBadge part={bomPart} teamId={currentTeamId} partRestricted={bomPartRestricted} />
          {/* Kit items */}
          {content.items.length > 0 && (
            <table className="table-auto w-full mt-3">
              <thead>
                <tr>
                  <td>
                    <div className="pr-2 py-1">
                      <label htmlFor="components" className=" font-medium uppercase">
                        Component Materials
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="px-2 py-1">
                      <span className=" font-medium uppercase">Quantity</span>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {content.items.map((component, index) => {
                  const partRestricted = isPartRestricted(getPart(component.part_id));
                  return (
                    <tr key={index} className="h-10  border-b items-center bg-white">
                      <td>
                        <PartBadge
                          teamId={currentTeamId}
                          part={getPartByRevisionId(component.revision_id ?? '') ?? getPart(component.part_id)}
                          partRestricted={partRestricted}
                        />
                      </td>
                      <td>
                        {!partRestricted && (
                          <div className="flex items-center gap-x-1 px-2 py-1">
                            {component.amount}
                            {component.revision_id && getUnits(component.revision_id) && (
                              <UnitDisplay unit={getUnits(component.revision_id) as string} />
                            )}
                          </div>
                        )}
                        {partRestricted && (
                          <div className="px-2 py-1">
                            <RestrictedInfo />
                          </div>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartList;

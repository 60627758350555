import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useRef } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StringSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';
import useUnits from '../../../hooks/useUnits';

export interface UnitSelectorProps {
  name: string;
  value?: string | Array<string>;
  isDisabled?: boolean;
  onChange: (value: SelectOption | undefined) => void;
  required?: boolean;
  isMulti?: boolean;
  customClear?: boolean;
}

const UnitSelector = ({
  name,
  value,
  isDisabled = false,
  onChange,
  required = false,
  isMulti = false,
  customClear = false,
}: UnitSelectorProps) => {
  const { settingsUnits, findDefinedUnit } = useUnits();
  if (typeof value === 'string' && value.trim() === '') {
    value = undefined; // set to undefined so that an empty option is not added to unitsOptions
  }
  // selectInputRef.current is of type StateManagerProps but we don't have react select types available
  const selectInputRef = useRef();

  const unitsOptions: Array<SelectOption> = useMemo(() => {
    const options = settingsUnits.map((unit) => ({
      value: unit.name,
      label: unit.name,
    }));
    if (Array.isArray(value)) {
      value.forEach((value) => {
        if (!options.find((option) => option.label === value)) {
          options.push({
            value,
            label: value,
          });
        }
      });
    } else if (typeof value === 'string' && !options.find((option) => option.label === value)) {
      options.push({
        value,
        label: value,
      });
    }

    return options;
  }, [value, settingsUnits]);

  const selectedValue: Array<SelectOption> | SelectOption | undefined = useMemo(() => {
    if (Array.isArray(value)) {
      return unitsOptions.filter((option) => value?.includes(option.label));
    }
    return unitsOptions.find((option) => option.label === value);
  }, [unitsOptions, value]);

  const clearUnits = useCallback(() => {
    onChange(undefined);
    // this line was taken from https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select#comment117483466_64348438
    // eslint-disable-next-line multiline-comment-style
    // @ts-ignore selectInputRef can't be typed; see declaration above
    selectInputRef?.current?.select.select.clearValue();
  }, [onChange]);

  return (
    <div className="relative">
      <CreatableSelect
        ref={selectInputRef}
        classNamePrefix="react-select"
        styles={reactSelectStyles}
        isMulti={isMulti}
        options={unitsOptions}
        components={{
          Option: (props) => {
            const label: string = props.data.label;
            const settingsUnit = findDefinedUnit(label);
            return (
              <components.Option {...props} className="truncate">
                {label.startsWith('Use custom unit') && label}
                {!label.startsWith('Use custom unit') && (
                  <>
                    <div className="h-4  font-bold">{label}</div>
                    <div className="h-4 ">
                      {settingsUnit && `${settingsUnit.plural} (${settingsUnit.abbreviation})`}
                      {!settingsUnit && <span className="italic">custom unit</span>}
                    </div>
                  </>
                )}
              </components.Option>
            );
          },
        }}
        name={name}
        value={selectedValue}
        onChange={onChange}
        isDisabled={isDisabled}
        isClearable={!customClear}
        placeholder={`Input units${required ? '*' : ''}`}
        aria-label="Input units"
        formatCreateLabel={(label: string) => `Use custom unit "${label}"`}
      />
      {customClear && selectedValue && (
        <FontAwesomeIcon
          icon="x"
          className="text-gray-400 hover:text-gray-500 font-bold text-sm absolute right-12 top-[14px]"
          onClick={clearUnits}
        />
      )}
    </div>
  );
};

export default UnitSelector;

import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import ModalBase from '../ModalBase';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';
import SnippetField from './SnippetField';
import { SnippetType } from 'shared/lib/types/views/procedures';

interface ModalSnippetSelectProps {
  onSelectSnippet: (snippet: string) => void;
  onSecondaryAction: () => void;
  secondaryActionTitle?: string;
  snippetType?: SnippetType;
  isTestSnippet?: boolean;
}

const ModalSnippetSelect = ({
  onSelectSnippet,
  onSecondaryAction,
  secondaryActionTitle,
  snippetType = 'step',
  isTestSnippet = false,
}: ModalSnippetSelectProps) => {
  const onSubmit = useCallback(
    (values) => {
      onSelectSnippet && onSelectSnippet(values.snippet);
    },
    [onSelectSnippet]
  );

  const validate = async (values) => {
    if (!values.snippet) {
      return { snippet: 'Required' };
    }
    return {};
  };

  return (
    <ModalBase>
      <Formik initialValues={{ snippet: '' }} onSubmit={onSubmit} validate={validate}>
        {({ dirty, isValid }) => (
          <Form>
            <ModalContent title="Insert Snippet">
              <div className="flex flex-col w-full">
                <label htmlFor="snippet">Snippet</label>
                <Field
                  id="snippet"
                  name="snippet"
                  component={SnippetField}
                  snippetType={snippetType}
                  isTestSnippet={isTestSnippet}
                />
              </div>
            </ModalContent>
            <ModalActionBar>
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm: disabled:bg-gray-300"
                disabled={!isValid || !dirty}
              >
                Insert Snippet
              </button>
              {typeof onSecondaryAction === 'function' && (
                <button
                  onClick={onSecondaryAction}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:"
                >
                  {secondaryActionTitle || 'Cancel'}
                </button>
              )}
            </ModalActionBar>
          </Form>
        )}
      </Formik>
    </ModalBase>
  );
};

export default ModalSnippetSelect;

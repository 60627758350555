import { Helmet } from 'react-helmet-async';
import { ViewTab } from 'shared/lib/types/postgres/users';
import SearchInputControlled from '../../elements/SearchInputControlled';
import { RUN_HISTORY_KEY } from '../../screens/RunList';
import Button, { BUTTON_TYPES } from '../Button';
import FlashMessage from '../FlashMessage';
import LoadingBadge from '../LoadingBadge';
import TabBar, { TabProps } from '../TabBar/TabBar';
import { FilterOption } from '../lib/SearchFilter';
import FilterItems, { Filter } from './FilterItems';
import GridExpandCollapseButton, { RowWithProjectName } from './GridExpandCollapseButton';
import ViewTabToggle from './ViewTabToggle';
import { usePersistedViewReturns } from './usePersistedView';

interface ListHeaderProps {
  name: string;
  tabs?: ReadonlyArray<TabProps<string>>;
  rows?: ReadonlyArray<RowWithProjectName>;
  isLoading: boolean;
  persistedView: usePersistedViewReturns;
  filters: ReadonlySet<Filter>;
  /** because procedures and runs use different tags... */
  tagOptions: Array<FilterOption<string>>;
  actions?: JSX.Element;

  navigatedSection?: string;
  updateTab?: (tab: string) => void;
  canSaveDefaultView?: boolean;
  viewTabLabelOverride?: Record<ViewTab, string>;

  // temporarily until search bar revamp
  priorityOptions?: Array<FilterOption<string>>;
  typeOptions?: Array<FilterOption<string>>;
  showViewTabToggle?: boolean;
}

const ListHeaderNoProjectNav = ({
  name,
  tabs,
  rows,
  isLoading,
  persistedView,
  filters,
  tagOptions,
  priorityOptions,
  typeOptions,
  navigatedSection,
  updateTab,
  actions,
  canSaveDefaultView,
  showViewTabToggle = true,
  viewTabLabelOverride,
}: ListHeaderProps) => {
  return (
    <>
      <Helmet>
        <title>{name} - List</title>
      </Helmet>
      <FlashMessage message={persistedView.saveError} messageUpdater={persistedView.setSaveError} type="warning" />
      <FlashMessage message={persistedView.saveSuccess} messageUpdater={persistedView.setSaveSuccess} />

      {isLoading && (
        <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
          <LoadingBadge />
        </div>
      )}

      <div className="pt-4 flex flex-col w-full top-0">
        <div className="flex flex-row justify-between items-start dark:text-white">
          <h1 className="pl-2 pt-2 mb-0 pb-0 whitespace-nowrap">{name}</h1>
          {actions}
        </div>
        <div className="flex flex-row pb-0 pt-2 bg-gray-50">
          <div className="flex w-full">
            <div className="flex flex-row pb-2 items-center">
              {showViewTabToggle && (
                <ViewTabToggle
                  viewTab={persistedView.viewTab}
                  setViewTab={persistedView.setViewTab}
                  viewTabLabelOverride={viewTabLabelOverride}
                />
              )}
              <SearchInputControlled
                placeholder={`Search ${name}`}
                searchTerm={persistedView.searchTerm}
                setSearchTerm={persistedView.setSearchTerm}
              />
              <FilterItems
                persistedView={persistedView}
                filters={filters}
                tagOptions={tagOptions}
                priorityOptions={priorityOptions}
                typeOptions={typeOptions}
                includeEndedOperations={navigatedSection === RUN_HISTORY_KEY}
              />
            </div>
            {canSaveDefaultView && (
              <>
                <div className="flex grow"></div>
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  ariaLabel="Save View as Default"
                  leadingIcon="save"
                  onClick={persistedView.saveDefaultView}
                  isDisabled={!persistedView.isSaveDefaultViewEnabled}
                >
                  Save Default View
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between mr-2">
          {tabs?.length && navigatedSection && updateTab ? (
            <div className="my-2">
              <TabBar tabs={tabs} selectedTab={navigatedSection} setSelectedTab={updateTab} />
            </div>
          ) : (
            <div />
          )}

          {showViewTabToggle && persistedView.viewTab === ViewTab.Tree && rows && (
            <GridExpandCollapseButton
              rows={rows as Array<RowWithProjectName>}
              expandedProjectNames={persistedView.expandedProjectNames}
              setExpandedProjectNames={persistedView.setExpandedProjectNames}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListHeaderNoProjectNav;

import React from 'react';
import { STEP_STATE, isStepEnded, getStepState } from 'shared/lib/runUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRunContext } from '../../contexts/RunContext';
import { Step } from 'shared/lib/types/views/procedures';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SectionStepKeyWithStatusProps {
  sectionStepKey: string;
  currentStep: Step;
}

const SectionStepKeyWithStatus = React.memo(({ sectionStepKey, currentStep }: SectionStepKeyWithStatusProps) => {
  const { viewStepAsIncomplete } = useRunContext();

  const getStepIconName = (step: Step): IconProp => {
    if (isStepEnded(step) && !viewStepAsIncomplete(step.id)) {
      return 'circle';
    }
    //Case where step is not completed nor skipped
    return ['far', 'circle'];
  };

  const getStepStatusClass = (step: Step) => {
    const currentStepState = getStepState(step);
    if (currentStepState === STEP_STATE.COMPLETED && !viewStepAsIncomplete(step.id)) {
      return 'mr-1 text-sm text-emerald-500';
    }
    if (currentStepState === STEP_STATE.SKIPPED) {
      return 'mr-1 text-sm text-gray-400';
    }
    if (currentStepState === STEP_STATE.FAILED) {
      return 'mr-1 text-sm text-red-500';
    }
    //Case where step is not completed nor a repeat
    return 'mr-1 text-sm font-extrabold text-gray-400';
  };

  return (
    <div className="flex flex-row font-bold mr-2">
      <div className="w-4 my-1">
        {/* Overlay a background-colored circle underneath the actual status icon circle so that the blue line does not show through */}
        <FontAwesomeIcon icon="circle" className="absolute mr-1 text-sm text-white" />
        <FontAwesomeIcon
          icon={getStepIconName(currentStep)}
          className={`absolute ${getStepStatusClass(currentStep)}`}
        />
      </div>
      <div>{`${sectionStepKey} `}</div>
    </div>
  );
});

export default SectionStepKeyWithStatus;

import { useId, useState } from 'react';
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import FlashMessage from './FlashMessage';

type EphemeralSecretModalProps = {
  secretName: string;
  secretId?: string;
  fullSecret: string;
  onDismiss: () => void;
  size?: 'sm' | 'md' | 'lg'; // defaults to 'lg'
};

const EphemeralSecretModal = ({
  secretName,
  secretId,
  fullSecret,
  onDismiss,
  size = 'lg',
}: EphemeralSecretModalProps) => {
  const dialogId = useId();
  const [flashText, setFlashText] = useState<string | null>(null);

  const title = `Your new ${secretName}`;
  const textCopiedToClipboard = `${secretName} copied to clipboard`;
  const textSaveThisSecret = `Save this ${secretName}. It cannot be retrieved after dismissing this dialog box.`;

  return (
    <Modal title={title} primaryActionTitle="Dismiss" onPrimaryAction={onDismiss} size={size}>
      <div className="w-full">
        {secretId && (
          <div className="flex flex-col space-y-1 py-2">
            <label
              id={`${dialogId}-secret-id-label`}
              htmlFor={`${dialogId}-secret-id`}
              className=" font-medium uppercase"
            >
              ID
            </label>
            <input
              id={`${dialogId}-secret-id`}
              type="text"
              readOnly={true}
              value={secretId}
              className="py-1.5 px-2 bg-slate-100 border border-gray-400 rounded"
            />
          </div>
        )}
        <label id={`${dialogId}-secret-label`} htmlFor={`${dialogId}-secret`} className=" font-medium uppercase">
          {secretName}
        </label>
        <div className="flex flex-row rounded bg-slate-100 border border-gray-400 p-4 justify-between items-center focus-within:ring">
          <input
            id={`${dialogId}-secret`}
            type="text"
            readOnly={true}
            value={fullSecret}
            className="font-mono ml-2 mr-2 flex-1 border-none bg-transparent outline-none focus:outline-none"
            // Hide the outline on focus. Instead, the focus outline is shown on the parent div.
            style={{ boxShadow: 'none' }}
          />
          <button
            className="p-1 rounded text-gray-500 hover:bg-slate-300 hover:text-gray-600 active:bg-slate-500 active:text-gray-50"
            onClick={async () => {
              await navigator.clipboard.writeText(fullSecret);
              setFlashText(textCopiedToClipboard);
            }}
            title="Copy to clipboard"
          >
            <FontAwesomeIcon icon={'fa-copy' as IconProp} />
          </button>
        </div>
        <div className="flex flex-col justify-between content-center">
          <span className="text-gray-600">{textSaveThisSecret}</span>
        </div>
      </div>
      <FlashMessage message={flashText} messageUpdater={setFlashText} type="info" />
    </Modal>
  );
};

export default EphemeralSecretModal;

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ModalBase from '../ModalBase';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';

const SnippetSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string(),
});

interface ModalSaveSnippetProps {
  onPrimaryAction: (values) => void;
  onSecondaryAction: () => void;
  secondaryActionTitle?: string;
  currentName?: string;
  currentDescription?: string;
}

const ModalSaveSnippet = ({
  onPrimaryAction,
  onSecondaryAction,
  secondaryActionTitle = '',
  currentName,
  currentDescription,
}: ModalSaveSnippetProps) => {
  return (
    <ModalBase>
      <Formik
        initialValues={{
          name: currentName ? currentName : '',
          description: currentDescription ? currentDescription : '',
        }}
        onSubmit={onPrimaryAction}
        validationSchema={SnippetSchema}
      >
        {({ dirty, isValid, errors, touched, isSubmitting }) => (
          <Form>
            <ModalContent title="Save As Snippet">
              <div className="flex flex-col w-full">
                <label htmlFor="name" className="font-semibold  font-medium uppercase">
                  Name
                </label>
                <Field
                  id="name"
                  name="name"
                  placeholder="Name"
                  type="text"
                  className=" border-1 border-gray-400 rounded"
                />
                {errors.name && touched.name ? <div className="text-red-700">{errors.name}</div> : null}

                <label htmlFor="description" className="mt-2 font-semibold  font-medium uppercase">
                  Description
                </label>
                <Field
                  id="description"
                  name="description"
                  placeholder="Description"
                  type="text"
                  className=" border-1 border-gray-400 rounded"
                />
                {errors.description && touched.description ? (
                  <div className="text-red-700">{errors.description}</div>
                ) : null}

                <span className="mt-2  font-medium text-red-800">
                  Note: Snippets are visible to all users in the current workspace.
                </span>
              </div>
            </ModalContent>
            <ModalActionBar>
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-lg font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm: disabled:bg-gray-300"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </button>
              {typeof onSecondaryAction === 'function' && (
                <button
                  onClick={onSecondaryAction}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-lg font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:"
                >
                  {secondaryActionTitle || 'Cancel'}
                </button>
              )}
            </ModalActionBar>
          </Form>
        )}
      </Formik>
    </ModalBase>
  );
};

export default ModalSaveSnippet;

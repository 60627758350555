import React, { useCallback } from 'react';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import { generateHiddenClassString } from '../../lib/styles';
import reviewUtil from '../../lib/reviewUtil';
import ReviewCommenting from '../ReviewCommenting';
import ProcedureFieldNoRedlines from '../ProcedureFieldNoRedlines';
import DiffContainer from '../Diff/DiffContainer';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { DraftRedlineComment, Header, HeaderDiffElement, ReviewComment } from 'shared/lib/types/views/procedures';
import ReviewBlockText from './ReviewBlockText';
import { AxiosResponse } from 'axios';
import ReviewTableInput from '../TableInput/ReviewTableInput';
import CommentWrapper from '../CommentWrapper';

interface ReviewProcedureHeaderParams {
  projectId?: string | null;
  header: Header;
  isCollapsed: boolean;
  onCollapse: (id, collapse) => void;
  docState?: string;
  isRedlineFeatureEnabled?: boolean;
  saveHeaderRedline?: (header, headerRedlineMetadata, isRedline) => undefined | Promise<void>;
  onAcceptPendingRedline?: (headerId, redlineIndex) => Promise<AxiosResponse | void>;
  onRefChanged?: (id: string, element: HTMLElement) => void;
  scrollToBufferRem?: number;
  isPreviewMode?: boolean;
  onResolveReviewComment?: (commentId: string) => void;
  onUnresolveReviewComment?: (commentId: string) => void;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  comments?: Array<DraftRedlineComment | ReviewComment>;
  showReviewComments?: boolean;
}

const ReviewProcedureHeader = ({
  comments,
  header,
  isCollapsed,
  onCollapse,
  saveReviewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  showReviewComments = false,
  onRefChanged,
  scrollToBufferRem = 0,
}: ReviewProcedureHeaderParams) => {
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  const onHeaderRefChanged = useCallback(
    (element) => {
      if (!onRefChanged) {
        return;
      }
      onRefChanged(header.id, element);
    },
    [header.id, onRefChanged]
  );

  const onExpandCollapse = useCallback(() => {
    onCollapse(header.id, !isCollapsed);
  }, [header, isCollapsed, onCollapse]);

  return (
    <DiffContainer
      label="Procedure header"
      diffChangeState={(header as HeaderDiffElement).diff_change_state}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(header.id, element)}
    >
      <table
        aria-label="Procedure Header"
        role="region"
        className="table-fixed w-full border-collapse"
        cellSpacing="0"
        cellPadding="0"
        border={0}
      >
        <thead>
          <tr>
            <th className="w-4"></th>
            <th className="w-full"></th>
            <th className="w-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top">
              {/* Header name */}
              <div className="mt-3" ref={onHeaderRefChanged} style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}>
                <ExpandCollapseCaret
                  isExpanded={!isCollapsed}
                  onClick={onExpandCollapse}
                  ariaLabel={isCollapsed ? 'Expand header' : 'Collapse header'}
                />
              </div>
            </td>
            <td className="break-words">
              <div className="mt-3 ml-4 text-2xl min-w-0">
                <ProcedureFieldNoRedlines
                  fieldName="name"
                  fieldValue={header.name}
                  redlines={[]}
                  onLabelClick={onExpandCollapse}
                />
              </div>
            </td>
          </tr>
        </tbody>
        {/* Header content */}
        <tbody aria-label="Content Block" role="region" className={generateHiddenClassString('', isCollapsed)}>
          {header.content &&
            header.content.map((content, contentIndex) => {
              return (
                <CommentWrapper key={contentIndex} content={content}>
                  {/* Check type of content.text because empty string is falsey */}
                  {content.type.toLowerCase() === 'text' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'old') === 'string' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'new') === 'string' && (
                      <ReviewBlockText
                        block={content}
                        isHidden={false}
                        onRefChanged={onRefChanged}
                        scrollMarginTopValueRem={scrollToBufferRem}
                      />
                    )}
                  {content.type.toLowerCase() === 'alert' && (
                    <ProcedureBlockReview
                      block={content}
                      blockLabel={undefined}
                      isHidden={false}
                      isDark={true}
                      onContentRefChanged={onRefChanged}
                      scrollMarginTopValueRem={scrollToBufferRem}
                      recorded={undefined}
                      isEnabled={false}
                      isSpacerHidden={false}
                    />
                  )}
                  {content.type.toLowerCase() === 'attachment' && (
                    <ReviewBlockAttachment attachment={content} isHidden={isCollapsed} isSpacerHidden={false} />
                  )}
                  {content.type.toLowerCase() === 'table_input' && (
                    <ReviewTableInput
                      content={content}
                      blockLabel={null}
                      isHidden={false}
                      isSpacerHidden={null}
                      onSelectCell={undefined}
                      selectedCell={undefined}
                      onContentRefChanged={undefined}
                      scrollMarginTopValueRem={undefined}
                    />
                  )}
                </CommentWrapper>
              );
            })}
          {/* Review comments */}
          {showReviewComments && onResolveReviewComment && onUnresolveReviewComment && saveReviewComment && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}></div>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}>
                  <ReviewCommenting
                    stepId={header.id}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    reviewComments={reviewUtil.getStepReviewComments(comments ?? [], header.id)}
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </DiffContainer>
  );
};

export default React.memo(ReviewProcedureHeader);

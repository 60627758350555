import { isNil } from 'lodash';
import { FieldInputNumberBlockDiffElement } from 'shared/lib/types/views/procedures';
import FieldInputNameDisplay from '../../../Blocks/FieldInput/FieldInputNameDisplay';
import TooltipOverlay from '../../../TooltipOverlay';
import ReviewRule from '../ReviewRule';
import RuleStatusIcon from '../../../Blocks/RuleStatusIcon';
import ProcedureFieldDiff from '../../../ProcedureFieldDiff';
import { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../../../Diff/DiffContainer';
import diffUtil from '../../../../lib/diffUtil';

interface ReviewNumberFieldInputProps {
  block: FieldInputNumberBlockDiffElement;
  label?: React.ReactNode;
  isReference?: boolean;
}

const ReviewNumberFieldInput = ({ block, label, isReference = false }: ReviewNumberFieldInputProps) => {
  const hasRule = Boolean(block.rule?.op);

  const newName = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'name', 'new') ?? '';
  }, [block]);
  const oldName = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'name', 'old') ?? '';
  }, [block]);

  const newUnits = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'units', 'new') ?? '';
  }, [block]);
  const oldUnits = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'units', 'old') ?? '';
  }, [block]);

  const ruleDiffChangeState = useMemo(() => {
    const { hasChanged: ruleChanged } = diffUtil.getRuleDiffValues(block.rule);

    return ruleChanged ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED;
  }, [block.rule]);

  return (
    <DiffContainer label="Rule" diffChangeState={ruleDiffChangeState} isTextSticky={false}>
      <div className="flex flex-row gap-x-2 items-center">
        <FieldInputNameDisplay blockName={oldName} newBlockName={newName} />
        <div>=</div>
        <TooltipOverlay
          content={
            <div>
              {isReference && !hasRule && label}
              {hasRule && <ReviewRule block={block} newLabel={label} />}
            </div>
          }
          delayClose={isReference}
          visible={hasRule || isReference}
        >
          <div className="flex flex-wrap grow justify-between items-center gap-2" aria-label={`${newName ?? ''} value`}>
            <div className="flex flex-no-wrap gap-2 items-center">
              <div className="relative w-fit">
                <div
                  className={` pl-2 ${hasRule ? 'pr-7' : 'pr-2'} ${
                    isReference ? 'w-fit' : 'w-36'
                  } h-9 py-1.5 border border-gray-400 text-gray-400 rounded bg-gray-300 bg-opacity-50 italic`}
                >
                  {isReference ? 'Pending' : ''}
                </div>
                {hasRule && (
                  <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
                    <RuleStatusIcon isPassing={null} />
                  </div>
                )}
              </div>
              {!isNil(block.units) && (
                <div className="self-center whitespace-pre-wrap">
                  <ProcedureFieldDiff original={oldUnits} redlined={newUnits} />
                </div>
              )}
            </div>
          </div>
        </TooltipOverlay>
      </div>
    </DiffContainer>
  );
};

export default ReviewNumberFieldInput;

import React from 'react';
import { Formik, Form, Field } from 'formik';
import FieldSetViewModeEditSelect from './FieldSetViewModeEditSelect';
import FieldSetUserSelect from './FieldSetUserSelect';
import FieldSetPrintSettings from './FieldSetPrintSettings';
import { Draft, SignoffType } from 'shared/lib/types/views/procedures';
import FieldSetSignoffArray from './FieldSetSignoffArray';
import FieldSetDictionarySelect from './FieldSetDictionarySelect';
import ProcedureSettingsTagSelector from './ProcedureSettingsTagSelector';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import FieldSetProcedureProject from './FieldSetProcedureProject';
import ProcedureSettingsToggles from './ProcedureSettingsToggles';

interface FieldSetProcedureSettingsProps {
  procedure: Draft;
  onProcedureSettingsFormChanged: (values) => void;
  isProjectClearable?: boolean;
}

const FieldSetProcedureSettings = ({
  procedure,
  onProcedureSettingsFormChanged,
  isProjectClearable = true,
}: FieldSetProcedureSettingsProps) => {
  const { isAutoProcedureIdEnabled } = useAutoProcedureId(procedure);

  return (
    <Formik
      initialValues={procedure}
      onSubmit={() => {
        /* no op */
      }}
      validateOnChange={true}
      validate={onProcedureSettingsFormChanged}
      enableReinitialize
    >
      {({ values, setFieldValue, setValues }) => (
        <Form>
          <div className="mt-16 pb-16 ml-2 mr-2 ">
            <div className="w-full flex flex-col items-start gap-y-2">
              <div className="w-full flex flex-col gap-y-2">
                <ProcedureSettingsToggles
                  values={values}
                  procedure={procedure}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                />
              </div>
              {/* Owner */}
              <div className="w-full flex flex-col">
                <label className="mb-1 font-semibold ">Owner</label>
                <Field
                  name="owner"
                  className="h-full border border-gray-400 rounded"
                  component={FieldSetUserSelect}
                  value={values.owner}
                />
              </div>

              {/* Tags */}
              <div className="w-full flex flex-col">
                <Field
                  name="tags"
                  className="h-full border border-gray-400 rounded"
                  component={ProcedureSettingsTagSelector}
                  value={values.tags}
                />
              </div>
              {/* Default View */}
              <div className="w-full flex flex-col">
                <label className="mb-1 font-semibold ">Default View</label>
                <Field
                  name="default_view_mode"
                  className="h-full border border-gray-400 rounded"
                  component={FieldSetViewModeEditSelect}
                  value={values.default_view_mode}
                />
              </div>
              {/* Project */}
              <div className="w-full flex flex-col">
                <label className="mb-1 font-semibold ">Project</label>
                <FieldSetProcedureProject
                  isProjectClearable={isProjectClearable}
                  isAutoProcedureIdEnabled={isAutoProcedureIdEnabled}
                  warnUserOnIdChange={true}
                />
              </div>
              {/* Start Procedure */}
              <div className="w-full mt-2 flex items-start space-x-2">
                <div className="flex flex-col">
                  <label className="mb-1 font-semibold ">Start Procedure</label>
                  <Field
                    name="start_run_signoffs_groups"
                    className="h-full border border-gray-400 rounded"
                    component={FieldSetSignoffArray}
                    signoffs={values.start_run_signoffs_groups}
                    signoffType={SignoffType.Start}
                  />
                </div>
              </div>
              {/* End Procedure */}
              <div className="w-full mt-2 flex items-start space-x-2">
                <div className="flex flex-col">
                  <label className="mb-1 font-semibold ">End Procedure</label>
                  <Field
                    name="end_run_signoffs_groups"
                    className="h-full border border-gray-400 rounded"
                    component={FieldSetSignoffArray}
                    signoffs={values.end_run_signoffs_groups}
                    signoffType={SignoffType.End}
                  />
                </div>
              </div>
              {/* Data Sources */}
              <div className="w-full flex flex-col">
                <div className="flex flex-col">
                  <label htmlFor="dictionary_id" className="mb-1 font-semibold ">
                    Data Sources
                  </label>
                  <Field
                    name="dictionary_id"
                    className="h-full border border-gray-400 rounded"
                    component={FieldSetDictionarySelect}
                    value={values.dictionary_id}
                    isClearable={true}
                  />
                </div>
              </div>

              {/* Print Settings */}
              <div className="w-full mt-2 flex items-start space-x-1">
                <div className="w-full flex flex-col mt-1 ">
                  <Field
                    name="settings.print_settings"
                    className="h-full border border-gray-400 rounded"
                    component={FieldSetPrintSettings}
                    value={values.settings?.print_settings}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(FieldSetProcedureSettings);

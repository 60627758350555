import Tag from '../../components/Tag';
import { ItemStatus } from '../types';

interface ItemStatusTagProps {
  itemStatus: ItemStatus;
}

const ItemStatusTag = ({ itemStatus }: ItemStatusTagProps) => {
  let tagColor;
  let name;
  switch (itemStatus) {
    case ItemStatus.InInventory:
      tagColor = 'bg-green-200';
      name = 'In Inventory';
      break;
    case ItemStatus.Scrap:
      tagColor = 'bg-red-200';
      name = 'Scrap';
      break;
    case ItemStatus.CheckedOut:
      tagColor = 'bg-yellow-200';
      name = 'Checked Out';
      break;
    case ItemStatus.Consumed:
      tagColor = 'bg-gray-200';
      name = 'Consumed';
      break;
    default:
      tagColor = 'bg-yellow-200';
      name = 'Unavailable';
      break;
  }
  return (
    <span className="text-lg capitalize">
      <Tag id={itemStatus} name={name} color={tagColor} />
    </span>
  );
};

export default ItemStatusTag;

import React, { useCallback, useMemo } from 'react';
import Button, { BUTTON_TYPES } from './Button';
import { faGear, faLinkSlash, faRedo } from '@fortawesome/free-solid-svg-icons';
import PopupListWithSearch from '../elements/PopupListWithSearch';
import FieldSetToggle from './FieldSetToggle';
import { StepSettings } from 'shared/lib/types/views/procedures';
import Tooltip from '../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pick } from 'lodash';
import { ToggleOption } from '../elements/ListWithSearch';

interface ProcedureStepSettingsMenuProps {
  setSettings: (settings: StepSettings) => void;
  settings: {
    [setting in keyof Required<StepSettings>]: {
      value: boolean | undefined;
      isDefault?: boolean;
      isDisabled: boolean;
      isVisible: boolean;
      tooltip?: string;
    };
  };
  canResetSettings?: boolean;
}

const ProcedureStepSettingsMenu = ({
  setSettings,
  settings,
  canResetSettings = true,
}: ProcedureStepSettingsMenuProps) => {
  const options = useMemo(
    () => [
      {
        name: 'Skip Step',
        ...pick(settings.skip_step_enabled, ['isDefault', 'isDisabled', 'tooltip', 'value']),
        onClick: () =>
          setSettings({
            skip_step_enabled: !settings.skip_step_enabled.value,
          }),
        onRestoreDefault: () => setSettings({ skip_step_enabled: undefined }),
      },
      {
        name: 'Repeat Step',
        ...pick(settings.repeat_step_enabled, ['isDefault', 'isDisabled', 'tooltip', 'value']),
        onClick: () =>
          setSettings({
            repeat_step_enabled: !settings.repeat_step_enabled.value,
          }),
        onRestoreDefault: () => setSettings({ repeat_step_enabled: undefined }),
      },
      {
        name: 'Suggest Edits',
        ...pick(settings.step_suggest_edits_enabled, ['isDefault', 'isDisabled', 'tooltip', 'value']),
        onClick: () =>
          setSettings({
            step_suggest_edits_enabled: !settings.step_suggest_edits_enabled.value,
          }),
        onRestoreDefault: () => setSettings({ step_suggest_edits_enabled: undefined }),
      },
      ...(settings.runAsBatch.isVisible
        ? [
            {
              name: 'Batch Step',
              ...pick(settings.runAsBatch, ['isDefault', 'tooltip', 'value']),
              onClick: () =>
                setSettings({
                  runAsBatch: !settings.runAsBatch.value,
                  // Reset the disabled values if switching runAsBatch to OFF
                  repeat_step_enabled: settings.runAsBatch.value ? undefined : false,
                  step_suggest_edits_enabled: settings.runAsBatch.value ? undefined : false,
                }),
            },
          ]
        : []),
    ],
    [settings, setSettings]
  );

  const reset = useCallback(() => {
    return setSettings({
      skip_step_enabled: undefined,
      repeat_step_enabled: undefined,
      step_suggest_edits_enabled: undefined,
      runAsBatch: undefined,
    });
  }, [setSettings]);

  return (
    <PopupListWithSearch<boolean, ToggleOption<boolean>>
      title="Step Settings"
      Components={{
        Trigger: () => <Button leadingIcon={faGear} type={BUTTON_TYPES.TERTIARY} />,
        TitleActionButton: () => (
          <Tooltip content="Reset to default values" position="top">
            <Button
              ariaLabel="Reset to default values"
              leadingIcon={faRedo}
              type={BUTTON_TYPES.TERTIARY}
              removePadding={true}
              onClick={reset}
              isDisabled={!canResetSettings}
            />
          </Tooltip>
        ),
        ListItem: <T,>(option: ToggleOption<T>) => {
          return (
            <div className="w-full flex flex-row">
              <FieldSetToggle
                label={option.name ?? ''}
                value={Boolean(option.value)}
                onChange={option.onClick}
                tooltip={option.tooltip}
                isDisabled={option.isDisabled}
                hasPadding={true}
              />
              <div className="flex pr-1 w-6 self-center justify-end">
                {option.isDefault === false && !option.isDisabled && (
                  <Tooltip content="Does not match procedure setting" position="top">
                    <FontAwesomeIcon className="text-sm" icon={faLinkSlash} />
                  </Tooltip>
                )}
              </div>
            </div>
          );
        },
      }}
      options={options}
      disableSearch={true}
      pt={{
        Trigger: {
          'aria-label': 'Step Settings',
        },
      }}
      showCursorPointer={false}
      closeOnScroll={true}
    />
  );
};

export default ProcedureStepSettingsMenu;

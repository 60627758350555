import { RenderGroupCellProps } from 'react-data-grid';
import ExpandCollapseCaret from '../../components/ExpandCollapse/ExpandCollapseCaret';
import { RowWithProjectName } from '../../components/Home/GridExpandCollapseButton';

const projectGroupCell = <T extends RowWithProjectName>({ childRows, isExpanded }: RenderGroupCellProps<T>) => {
  const displayName = childRows[0].projectName || 'Unassigned';
  const displayStyle = childRows[0].projectName ? '' : 'italic text-gray-400';

  return (
    <>
      <ExpandCollapseCaret isExpanded={isExpanded} ariaLabel="Expand item" />
      <div className={` truncate ${displayStyle}`}>{displayName}</div>
    </>
  );
};

export default projectGroupCell;

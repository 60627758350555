import React, { useEffect, useMemo, useCallback, useState, useRef, MutableRefObject } from 'react';
import { useFormik } from 'formik';
import { generateHiddenClassString } from '../../../lib/styles';
import commandingUtil from '../../../lib/commanding';
import { Command } from '../../../lib/models/postgres/commanding';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import DateTimeDisplay from '../.././DateTimeDisplay';
import { escapeArgName } from '../../FieldSetCommanding';
import { RunCommandingBlock, CommandingBlockDiffElement } from 'shared/lib/types/views/procedures';
import ResultsLabel from '../../ResultsLabel';
import SubstepNumber from '../../SubstepNumber';
import Spacer from '../../Spacer';
import { useSelector } from 'react-redux';
import { selectOfflineInfo } from '../../../app/offline';
import DiffContainer from '../../Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';

interface BlockCommandingProps {
  commanding: CommandingBlockDiffElement;
  isHidden: boolean;
  isSpacerHidden: boolean;
  blockLabel: string;
}

// Component for rendering a commanding rule.
const ReviewBlockCommanding = ({ commanding, isHidden, isSpacerHidden, blockLabel }: BlockCommandingProps) => {
  const { services } = useDatabaseServices();
  const fileInputRef = useRef<Map<string, MutableRefObject<HTMLInputElement>>>();
  const [standardCommand, setStandardCommand] = useState<Command>();
  const online = useSelector((state) => selectOfflineInfo(state).online);
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  // Load standard command definition
  useEffect(() => {
    if (!commandingUtil.isStandardCommand(commanding)) {
      return;
    }
    services.commanding
      .getCommandByName(
        sharedDiffUtil.getDiffValue(commanding, 'name', 'new'),
        sharedDiffUtil.getDiffValue(commanding, 'dictionary_id', 'new')
      )
      .then((command) => isMounted.current && setStandardCommand(command))
      .catch(() => {
        /* no-op */
      });
  }, [commanding, services.commanding]);

  const getArgument = useCallback(
    (argName) => {
      if (!standardCommand || !standardCommand.arguments) {
        return null;
      }
      return standardCommand.arguments.find((arg) => arg.name === argName);
    },
    [standardCommand]
  );

  const getArgumentType = useCallback(
    (argName) => {
      const argument = getArgument(argName);
      return argument && argument.type;
    },
    [getArgument]
  );

  const commandName = useMemo(() => commandingUtil.getCommandName(commanding), [commanding]);

  /**
   * Computes a timestamp suitable for rendering as "Received: {timestamp}".
   *
   * Prefers the explicit `received_at` timestamp from the command results, and
   * falls back to the `timestamp` field of when results were recorded (legacy).
   *
   * recorded: A results object from sending a command.
   * returns: String, an ISO8601 timestamp with milliseconds, or null if no
   *          timestamp is available.
   */
  const receivedAtTimestamp = (recorded) => {
    if (!recorded) {
      return null;
    }
    if (recorded.results && recorded.results.received_at) {
      return recorded.results.received_at;
    }
    if (recorded.timestamp) {
      return recorded.timestamp;
    }
    return null;
  };

  const recordedCommand = useMemo(() => {
    const command = commanding as RunCommandingBlock;
    if (!commanding || !command.recorded || !(command.recorded?.length > 0)) {
      return null;
    }
    return command.recorded && command.recorded[0];
  }, [commanding]);

  const initialValues = useMemo(() => {
    if (recordedCommand) {
      return recordedCommand.results?.arguments || {};
    }
    return commanding.arguments || {};
  }, [commanding, recordedCommand]);

  // Returns true if the given argument name has a predefined value.
  const hasPredefinedValue = useCallback(
    (name) => {
      if (!commanding.arguments || !(name in commanding.arguments)) {
        return false;
      }
      /*
       * Argument is represented in the arguments list. If null or an empty string,
       * allow user to specify a real value. Note that '0' or other falsey values
       * can still be valid argument values.
       */
      const value = commanding.arguments?.[name];
      return !(value === null || value === '');
    },
    [commanding]
  );

  // Returns true if any command arguments need to be specified at runtime.
  const hasRuntimeArgs = useMemo(() => {
    if (!commanding.arguments) {
      return false;
    }
    return Object.keys(commanding.arguments).some((name) => !hasPredefinedValue(name));
  }, [commanding, hasPredefinedValue]);

  const isCommandEnabled = false; // Commands disabled in Review

  // Requires `validateOnMount` to handle partially predefined command args.
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      /* Commands disabled in Review */
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  if (!commanding) {
    return null;
  }

  return (
    <>
      {/* Command definition row */}
      <div className={generateHiddenClassString('', isHidden)}></div>
      <div className={generateHiddenClassString('flex flex-row mt-2 page-break', isHidden)}>
        <Spacer isHidden={isSpacerHidden} />
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
        {/* Command definition */}
        <DiffContainer
          label="Commanding"
          diffChangeState={commanding.diff_change_state}
          isTextSticky={false}
          width="fit"
        >
          <div>
            <div className="flex flex-row flex-wrap py-0 mt-4 bg-yellow-100d">
              {commandName && <div className="flex mr-2">{commandName}</div>}
              <div>
                <button
                  className="btn-small mr-2"
                  disabled={!isCommandEnabled || (hasRuntimeArgs && !formik.isValid) || !online}
                  onClick={formik.submitForm}
                  type="button"
                >
                  Send
                </button>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="mt-2">
              {commanding.arguments &&
                Object.keys(commanding.arguments).map((name) => (
                  <div key={name} className="flex space-x-2 items-start ">
                    <div className="self-center mb-0.9">
                      <label htmlFor={`${sharedDiffUtil.getDiffValue(commanding, 'id', 'new')}-${name}`}>{name}</label>
                    </div>

                    <div className="mt-0.5 mb-1">
                      {getArgumentType(name) &&
                        getArgumentType(name) !== 'enum' &&
                        getArgumentType(name) !== 'file' && (
                          <input
                            id={`${sharedDiffUtil.getDiffValue(commanding, 'id', 'new')}-${name}`}
                            name={`[${escapeArgName(name)}]`}
                            disabled={!isCommandEnabled || hasPredefinedValue(name)}
                            type="text"
                            placeholder={name}
                            className=" border-1 border-gray-400 rounded disabled:bg-gray-300 disabled:bg-opacity-50"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={sharedDiffUtil.getDiffValue<string>({ name: formik.values[name] }, 'name', 'new')}
                          />
                        )}
                      {getArgumentType(name) === 'file' && (
                        <>
                          <div>
                            <input
                              id={`${sharedDiffUtil.getDiffValue(commanding, 'id', 'new')}-${name}`}
                              name={`[${escapeArgName(name)}]`}
                              ref={fileInputRef.current?.get(name)}
                              type="file"
                              accept="*" // for Safari, otherwise onChange handler is not triggered
                              disabled={!isCommandEnabled}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </>
                      )}
                      {getArgumentType(name) === 'enum' && (
                        <select
                          name={`[${escapeArgName(name)}]`}
                          disabled={!isCommandEnabled || hasPredefinedValue(name)}
                          className=" border border-gray-400 rounded disabled:bg-gray-200"
                          onChange={formik.handleChange}
                          value={formik.values[name]}
                        >
                          <option value="">Select</option>
                          {getArgument(name)?.values?.map((enumValue) => (
                            <option key={enumValue.value} value={enumValue.value}>
                              {enumValue.value} ({enumValue.string})
                            </option>
                          ))}
                        </select>
                      )}
                      {/* For file upload, no need to show error message since most browsers show "no file chosen" */}
                      {getArgumentType(name) !== 'file' && formik.errors?.[name] && formik.touched?.[name] && (
                        <div className="text-red-700">{formik.errors?.[name]}</div>
                      )}
                    </div>
                  </div>
                ))}
            </form>
            <div className="mb-2">
              {/* Recorded command results rows */}
              {(commanding as RunCommandingBlock).recorded?.map((recorded) => (
                <div key={recorded.timestamp}>
                  {/* Legacy COSMOS command results */}
                  {Array.isArray(recorded.results) && recorded.results.map((item) => JSON.stringify(item)).join(', ')}
                  {/* Unrecognized command results - TODO deprecate/remove this code path */}
                  {!Array.isArray(recorded.results) && typeof recorded.results !== 'object' && recorded.results}
                  {/* Standard command results */}
                  {!Array.isArray(recorded.results) && typeof recorded.results === 'object' && (
                    <>
                      <ResultsLabel success={recorded.results.success} successText="Success" failText="Error" />
                      {recorded.results.message && <span className="ml-2 text-sm">{recorded.results.message}</span>}
                      {receivedAtTimestamp(recorded) && (
                        <span className="ml-2 text-sm text-gray-500">
                          Received <DateTimeDisplay timestamp={receivedAtTimestamp(recorded)} />
                        </span>
                      )}
                      {recorded.results.results && (
                        <div className="ml-2">
                          <pre>results: {JSON.stringify(recorded.results.results, null, 4)}</pre>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </DiffContainer>
      </div>
    </>
  );
};

export default ReviewBlockCommanding;

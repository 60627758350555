import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Interweave } from 'interweave';
import { capitalize, debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SearchResult, SearchRunMetadata } from 'shared/lib/types/search';
import RunLabel from '../components/RunLabel';
import TabBar, { TabProps } from '../components/TabBar/TabBar';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import Grid, { GridColumn } from '../elements/Grid';
import SearchInputControlled from '../elements/SearchInputControlled';
import apm from '../lib/apm';
import { procedureViewPath, runSummaryPath, runViewPath } from '../lib/pathUtil';
import { BlockType, getScrollToUrlParams } from '../lib/scrollToUtil';

const MAIN_VERTICAL_PADDING = 148;
const ROW_HEIGHT = 50;

enum SearchTab {
  All = 'all',
  Procedures = 'procedures',
  Runs = 'runs',
}

const SEARCH_TABS: ReadonlyArray<TabProps<SearchTab>> = [
  { id: SearchTab.All, label: 'All' },
  { id: SearchTab.Procedures, label: 'Procedures' },
  { id: SearchTab.Runs, label: 'Runs' },
];

type Filters = {
  tab: SearchTab;
};

type COLUMNS = 'RESULT' | 'LAST_UPDATED';

const COLUMN_PROPS: { [col in COLUMNS]: GridColumn<SearchResult> } = {
  RESULT: {
    key: 'metadata.code',
    name: 'Result',
    width: '90%',
    sortable: true,
  },
  LAST_UPDATED: {
    key: 'metadata.editedAt',
    name: 'Last Updated',
    width: '10%',
    sortable: true,
  },
};

const DEBOUNCE_DELAY = 500;

const Search = () => {
  const history = useHistory<{ searchTerm: string; filters: Filters }>();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>([]);
  const [activeTab, setActiveTab] = useState<SearchTab>(SearchTab.All);

  const columns: readonly GridColumn<SearchResult>[] = [
    {
      ...COLUMN_PROPS.RESULT,
      renderCell({ row }) {
        let path: string;
        const icon = {
          icon: '' as IconProp,
          iconLink: '',
          iconTitle: '',
        };

        switch (row.type) {
          case 'procedure': {
            path = procedureViewPath(currentTeamId, row.id);
            if (row.sub_id && (row.sub_type === 'section' || row.sub_type === 'step')) {
              path = `${path}${getScrollToUrlParams({ id: row.sub_id, type: row.sub_type as BlockType })}`;
            }
            break;
          }
          case 'run': {
            path = runViewPath(currentTeamId, row.id);
            if (row.sub_id && row.sub_type) {
              path = `${path}${getScrollToUrlParams({ id: row.sub_id, type: row.sub_type as BlockType })}`;
            }
            if (!row.metadata) {
              return null;
            }
            if (row.metadata.state === 'completed') {
              icon.icon = 'file-alt' as IconProp;
              icon.iconLink = runSummaryPath(currentTeamId, row.id);
              icon.iconTitle = 'Go to run summary';
            }
            break;
          }
        }

        return (
          <div className="flex flex-col leading-4">
            <div className="flex flex-row">
              <span className="text-slate-400 pr-2">
                {capitalize(row.type)} {row.sub_type && `/ ${capitalize(row.sub_type)}`}
              </span>
              {row.type === 'run' && row.metadata && (
                <RunLabel
                  code={row.metadata.code}
                  link={path}
                  runNumber={(row.metadata as SearchRunMetadata).run_number}
                  {...icon}
                />
              )}
              {row.type === 'procedure' && row.metadata && (
                <Link to={path}>
                  <div className="text-sm text-blue-600 tracking-wider hover:underline">{row.metadata.code}</div>
                </Link>
              )}
            </div>
            <Interweave content={row.headline} />
          </div>
        );
      },
    },
    {
      ...COLUMN_PROPS.LAST_UPDATED,
      renderCell({ row }) {
        if (!row.metadata?.editedAt) {
          return null;
        }
        return new Date(row.metadata.editedAt).toLocaleDateString();
      },
    },
  ];

  const loadSearchResults = useMemo(
    () =>
      debounce((query: string) => {
        if (query.trim()) {
          services.search
            .getSearchResults(query, activeTab)
            .then((results: Array<SearchResult>) => {
              setSearchResults(results);
            })
            .catch((err) => {
              apm.captureError(err);
              setSearchResults([]);
            });
        } else {
          setSearchResults([]);
        }
      }, DEBOUNCE_DELAY),
    [activeTab, services.search]
  );

  useEffect(() => {
    if (history.location.search) {
      const params = new URLSearchParams(history.location.search);

      const searchTerm = params.get('term') ?? '';
      setSearchTerm(searchTerm);
      switch (params.get('resource_type')) {
        case SearchTab.All:
          setActiveTab(SearchTab.All);
          break;
        case SearchTab.Procedures:
          setActiveTab(SearchTab.Procedures);
          break;
        case SearchTab.Runs:
          setActiveTab(SearchTab.Runs);
          break;
      }
      loadSearchResults(searchTerm);
    }
  }, [history.location.search, loadSearchResults, searchTerm]);

  const updateSearchParams = useCallback(
    (searchTerm: string, activeTab: SearchTab) => {
      const params = new URLSearchParams();
      if (searchTerm) {
        params.set('term', searchTerm);
      }
      params.set('resource_type', activeTab);
      history.replace({ search: params.toString() });
    },
    [history]
  );

  const onSearchTermChange = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
      updateSearchParams(searchTerm, activeTab);
      if (!searchTerm.trim()) {
        setSearchResults([]);
        return;
      }
      loadSearchResults(searchTerm);
    },
    [activeTab, loadSearchResults, updateSearchParams]
  );

  const onTabChange = useCallback(
    (selectedTab: SearchTab) => {
      setActiveTab(selectedTab);
      updateSearchParams(searchTerm, selectedTab);
    },
    [searchTerm, updateSearchParams]
  );

  return (
    <div className="flex flex-col px-4 pt-2 w-full">
      <div className="flex justify-between">
        <div className="text-2xl py-2">
          <label htmlFor="">Search</label>
        </div>
      </div>
      <div className="flex flex-row gap-x-2 mb-4 items-center">
        <SearchInputControlled
          placeholder="Search runs or procedures"
          searchTerm={searchTerm}
          setSearchTerm={onSearchTermChange}
          focusOnLoad={true}
        />
      </div>
      <TabBar tabs={SEARCH_TABS} selectedTab={activeTab} setSelectedTab={onTabChange} />
      <div className="mt-2">
        <Grid
          columns={columns}
          rows={searchResults}
          usedVerticalSpace={MAIN_VERTICAL_PADDING}
          rowHeight={ROW_HEIGHT}
          emptyRowMessage="No results found"
        />
      </div>
    </div>
  );
};

export default Search;

import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import ParentComment from './ParentComment';
import StepActionDisplay from './StepAction';
import Button from './Button';
import { getStepState, ACTION_TYPE, STEP_STATE } from 'shared/lib/runUtil';
import { cloneDeep, isNil } from 'lodash';
import { OverlaySize, OverlayUpload } from './OverlayUpload';
import AttachmentPreview from './Attachments/AttachmentPreview';
import FormStepComment from './FormStepComment';
import useAttachmentCommentActions from '../hooks/useAttachmentCommentActions';
import { RunIssue, RunStep, RunStepComment, StepAction } from 'shared/lib/types/views/procedures';
import CommentButton from './CommentButton';
import { commentToActivity } from 'shared/lib/comment';
import { timestampSort } from 'shared/lib/sort';
import { AUTO_COLLAPSE_STEP_TIMELINE_KEY, useSettings } from '../contexts/SettingsContext';

interface StepTimelineProps {
  step: RunStep;
  isRun: boolean;
  sectionId: string;
  issues?: Record<string, RunIssue>;
  isEditing?: boolean;
  isCommentingDisabled?: boolean;
  saveNewComment?: (comment: RunStepComment) => void;
  editComment?: (comment: RunStepComment) => void;
}

interface StepCommentAction {
  type: 'comment';
  timestamp: string;
  content: {
    id: string;
  };
}

type LocalStepAction = StepCommentAction | StepAction;

const StepTimeline = ({
  step,
  issues,
  isRun,
  sectionId,
  isCommentingDisabled,
  saveNewComment,
  editComment,
}: StepTimelineProps) => {
  const { getSetting } = useSettings();
  const isCollapsedByDefault = useMemo(() => getSetting(AUTO_COLLAPSE_STEP_TIMELINE_KEY, false), [getSetting]);

  const { currentTeamId } = useDatabaseServices();
  const [showActivities, setShowActivities] = useState(!isCollapsedByDefault);
  const [autoFocus, setAutoFocus] = useState(false);

  const {
    isAddingText,
    uploadFile,
    setUploadFile,
    isSubmittingAttachment,
    isAttachmentRenderingImage,
    fileInputRef,
    onFileInputChange,
    onSaveTextComment,
    onSubmitAttachment,
    onShouldRenderImageChange,
  } = useAttachmentCommentActions({
    sectionId,
    stepId: step.id,
    onSaveNewComment: saveNewComment,
  });

  const toggleActivities = () => {
    setShowActivities(!showActivities);
    setAutoFocus(false);
  };

  const handleCommentClick = () => {
    setShowActivities(!showActivities);
    setAutoFocus(true);
  };

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setUploadFile(acceptedFiles[0]);
    },
    noClick: true,
    noKeyboard: true,
  });

  const stepComments = useMemo(() => {
    return step.comments?.filter((comment) => !comment.reference_id);
  }, [step.comments]);

  const stepCommentActivities = useMemo(() => (stepComments ?? []).map(commentToActivity), [stepComments]);

  const { actions, parentChildMap, parentCommentsMap } = useMemo(() => {
    const stepState = getStepState(step);
    const actionsList: LocalStepAction[] = step.actions ? cloneDeep(step.actions) : [];
    const parentChildMap = {};
    const parentCommentsMap = {};

    if (stepState === STEP_STATE.SKIPPED) {
      actionsList.push({
        type: ACTION_TYPE.SKIP,
        user_id: step.skippedUserId ?? '',
        timestamp: step.skippedAt ?? '',
      });
    }

    if (stepComments && stepComments.length > 0) {
      stepComments.forEach((comment) => {
        if (!comment.parent_id) {
          parentCommentsMap[comment.id] = comment;
          parentChildMap[comment.id] = parentChildMap[comment.id] || [];
          actionsList.push({
            type: 'comment',
            timestamp: comment.timestamp,
            content: comment,
          });
        } else {
          if (!parentChildMap[comment.parent_id]) {
            parentChildMap[comment.parent_id] = [];
          }
          parentChildMap[comment.parent_id].push(comment);
        }
      });
    }

    return {
      actions: actionsList.sort(timestampSort()),
      parentChildMap,
      parentCommentsMap,
    };
  }, [step, stepComments]);

  let saveEditComment;
  if (!isNil(editComment)) {
    saveEditComment = async (editedComment, commentId) => {
      const existingComment = stepComments?.find((comment) => comment.id === commentId);
      if (existingComment) {
        const comment = {
          ...existingComment,
          sectionId,
          stepId: step.id,
          text: editedComment.comment,
          mention_list: editedComment.mentions,
          parent_id: existingComment?.parent_id ?? '',
          updated_at: editedComment.updated_at,
        };
        return editComment(comment);
      }
    };
  }

  const attachButton = !isNil(saveNewComment) ? (
    <button
      className="btn-link px-3 py-2 my-1.5  font-bold rounded hover"
      aria-label="attach-button"
      onClick={() => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      }}
    >
      <FontAwesomeIcon icon="paperclip" className="text-gray-500 text-xl" />
    </button>
  ) : undefined;

  return (
    <div className="relative">
      <div className="flex justify-between items-center">
        <Button
          type="tertiary"
          leadingIcon={showActivities ? 'minus' : 'plus'}
          onClick={toggleActivities}
          ariaLabel="activity-button"
          removePadding={true}
        >
          {showActivities ? 'Hide Activity' : 'Show Activity'}
        </Button>
        {!isCommentingDisabled && stepCommentActivities.length > 0 && (
          <div className="absolute -top-1 right-1">
            <CommentButton comments={stepCommentActivities} onClick={handleCommentClick} showNewCommentIcon={true} />
          </div>
        )}
      </div>
      {actions.length > 1 && (
        <div className="absolute top-12 bottom-12 bg-gray-300 z-0" style={{ left: '1.2rem', width: '0.1rem' }} />
      )}
      {showActivities && (
        <>
          <div className="ml-3 mr-8" aria-label="timeline">
            {actions.map((item, index) => (
              <div key={index} className="flex w-full items-start relative">
                {item.type === 'comment' ? (
                  <>
                    <div className="flex-shrink-0 mt-1">
                      <div className="rounded-full bg-gray-200 w-4 h-4 inline-flex items-center justify-center">
                        <FontAwesomeIcon icon="comment" className="text-gray-700 text-xs" />
                      </div>
                    </div>
                    <div className="flex-grow ml-1">
                      <ParentComment
                        key={item.content.id}
                        childComments={parentChildMap[item.content.id]}
                        parentId={item.content.id}
                        parentComment={parentCommentsMap[item.content.id]}
                        sectionId={sectionId}
                        stepId={step.id}
                        saveNewComment={saveNewComment}
                        editComment={saveEditComment}
                        isRun={isRun}
                        isCommentingDisabled={isCommentingDisabled}
                      />
                    </div>
                  </>
                ) : (
                  <StepActionDisplay item={item} issues={issues} currentTeamId={currentTeamId} />
                )}
              </div>
            ))}
            {actions.length === 0 && <div className="flex justify-center items-center italic">No Actions</div>}
          </div>
          {isRun && !isCommentingDisabled && (
            <div className="mt-1 mb-2 mr-2 ml-2 relative flex items-center" {...getRootProps({ role: 'none' })}>
              <OverlayUpload isDragActive={isDragActive} size={OverlaySize.small} />
              {!uploadFile && saveNewComment && (
                <FormStepComment onSubmit={onSaveTextComment} placeholder="Add comment" autoFocus={autoFocus} />
              )}
              {!uploadFile && (
                <div className="flex">
                  <div>
                    {/* Hidden input for triggering file picker */}
                    <input
                      ref={fileInputRef}
                      type="file"
                      id={`sections[${sectionId}].steps[${step.id}]._file_input`}
                      onChange={(e) => onFileInputChange(e)}
                      className="hidden"
                      data-testid="file_attachment_input"
                    />
                    {attachButton}
                  </div>
                </div>
              )}
              {uploadFile && (
                <div className="w-full flex items-center relative">
                  <div className="flex relative">
                    <div className="w-full p-2">
                      <AttachmentPreview
                        file={uploadFile}
                        onShouldRenderImageChange={onShouldRenderImageChange}
                        attachment={undefined}
                      />
                    </div>
                  </div>
                  <div className={`${isAttachmentRenderingImage ? '' : 'flex flex-row items-center'}`}>
                    <button
                      className={`${
                        isAttachmentRenderingImage ? 'absolute' : ''
                      } ml-2 app-bg-gray-5 top-2.5 align-top rounded-full w-5 h-5 flex justify-center items-center disabled:bg-gray-100`}
                      onClick={() => setUploadFile(null)}
                      disabled={isSubmittingAttachment}
                    >
                      <FontAwesomeIcon icon="times" size="xs" className="text-white" />
                    </button>
                    {!isAddingText && (
                      <button
                        className={`${
                          isAttachmentRenderingImage ? 'absolute' : 'ml-2'
                        } hover:bg-blue-100 px-3 py-2.5  rounded-md btn-link bottom-0 disabled:bg-gray-100 disabled:text-gray-600 disabled:cursor-default`}
                        onClick={() => onSubmitAttachment()}
                        disabled={isSubmittingAttachment}
                      >
                        <div className="flex flex-row items-center">
                          {isSubmittingAttachment ? 'Saving' : 'Post'}
                          {!isSubmittingAttachment && (
                            <FontAwesomeIcon icon="chevron-right" size="xs" className="ml-1 align-middle" />
                          )}
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StepTimeline;

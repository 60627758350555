import React, { Fragment, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAttachment, { Attachment } from '../../hooks/useAttachment';

type AttachmentImageProps = {
  attachment?: Attachment;
  file?: File;
  showDownload?: boolean;
  showBorder?: boolean;
};

const AttachmentImage = ({ attachment, file, showBorder = false, showDownload = false }: AttachmentImageProps) => {
  const { downloadAttachment, url, name } = useAttachment({
    attachment,
    file,
    isDiff: true, // TODO split out when review version of this component is created
  });

  const isPending = useMemo(() => Boolean(file), [file]);

  if (!url) {
    return <div className="w-full h-full bg-gray-200"></div>;
  }

  if (!attachment && !file) {
    return <div className="w-full h-full bg-gray-200"></div>;
  }

  const borderStyle = showBorder ? 'border-2 border-blue-500' : '';

  return (
    <>
      <a className="w-full h-full" target="_blank" rel="noreferrer" href={url}>
        <div className={`w-full h-full bg-gray-200 ${borderStyle}`}>
          <img src={url} alt={name} className="w-full h-full object-cover" />
        </div>
      </a>
      {!isPending && showDownload && (
        <Fragment>
          {/* Radial gradiant shadow background with no click handling */}
          <div className="absolute top-0 right-0 overflow-hidden pointer-events-none">
            <div className="w-20 h-20 rounded-full translate-x-10 -translate-y-10 bg-gradient-radial from-gray-800 to-transparent"></div>
          </div>
          {/* Download button */}
          <button
            className="absolute top-0 right-0 p-1 group"
            onClick={downloadAttachment}
            aria-label={name && `Download ${name}`}
          >
            <FontAwesomeIcon icon="cloud-download-alt" className="text-xl text-white group-hover:text-gray-100" />
          </button>
        </Fragment>
      )}
    </>
  );
};

export default AttachmentImage;

import React, { useMemo } from 'react';

import Avatar from './Avatar';
import DateTimeDisplay from '../components/DateTimeDisplay';
import useUsers from '../hooks/useUsers';

const MAX_LENGTH = 20;

interface UserIdProps {
  userId: string;
  timestamp?: string;
}

const UserId = ({ userId, timestamp }: UserIdProps) => {
  const { getUserDisplayText } = useUsers();

  const displayText = useMemo(() => {
    return getUserDisplayText(userId);
  }, [getUserDisplayText, userId]);

  const shouldEllipsis = useMemo(() => {
    return displayText.length >= MAX_LENGTH;
  }, [displayText]);

  const _displayText = useMemo(() => {
    if (shouldEllipsis) {
      return `${displayText.slice(0, MAX_LENGTH)}...`;
    }

    return displayText;
  }, [displayText, shouldEllipsis]);

  return (
    <div className="flex flex-row flex-nowrap items-center gap-x-1">
      <Avatar userId={displayText} />
      <div className="text-sm text-gray-400 flex flex-col">
        <div className="text-gray-700 font-medium">{_displayText}</div>
        {timestamp && <DateTimeDisplay timestamp={timestamp} />}
      </div>
    </div>
  );
};

export default React.memo(UserId);

import { faCaretDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef } from 'react';
import Button, { BUTTON_TYPES } from './Button';
import FilterItems, { Filter } from './Home/FilterItems';
import { usePersistedViewReturns } from './Home/usePersistedView';
import { FilterOption } from './lib/SearchFilter';

export type FiltersButtonProps = {
  filters: ReadonlySet<Filter>;
  tagOptions: Array<FilterOption<string>>;
  priorityOptions?: Array<FilterOption<string>>;
  typeOptions?: Array<FilterOption<string>>;
  includeEndedOperations?: boolean;
  persistedView: usePersistedViewReturns;
};

const FiltersButton = ({
  persistedView,
  filters,
  tagOptions,
  includeEndedOperations = false,
  priorityOptions,
  typeOptions,
}: FiltersButtonProps) => {
  const op = useRef<OverlayPanel>(null);
  return (
    <>
      <Button
        leadingIcon={faFilter}
        trailingIcon={faCaretDown}
        type={BUTTON_TYPES.SECONDARY}
        size="sm"
        ariaLabel="Show Filters"
        onClick={(e) => op?.current?.toggle(e)}
      >
        Filters
      </Button>

      <OverlayPanel className="p-1" ref={op}>
        <div className="flex flex-row gap-x-2 text-sm font-medium items-center">
          <FilterItems
            persistedView={persistedView}
            filters={filters}
            tagOptions={tagOptions}
            priorityOptions={priorityOptions}
            typeOptions={typeOptions}
            includeEndedOperations={includeEndedOperations}
          />
        </div>
      </OverlayPanel>
    </>
  );
};

export default FiltersButton;

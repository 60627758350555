import React from 'react';

interface SimpleDisplayTableProps {
  tableName: string;
  columnNames: Array<string>;
  tableCellsByRow?: Array<Array<JSX.Element>>;
  widthByColumn?: Array<string>;
  tableStyle?: 'borders' | 'default';
  // Required when combined with field array so that key for row is unique, otherwise there may be some rendering issues.
  names?: Array<string>;
}

const SimpleDisplayTable = ({
  tableName,
  columnNames,
  tableCellsByRow,
  widthByColumn,
  tableStyle = 'default',
  names,
}: SimpleDisplayTableProps) => {
  const getTableRowClasses = (index, array) => {
    return ` text-black ${
      tableStyle === 'borders' ? 'border' : index !== array.length - 1 ? 'border-b' : ''
    } items_center`;
  };

  return (
    <table className="w-full">
      <thead>
        <tr className={`${tableStyle === 'borders' ? 'border bg-gray-100' : 'border-b'} items-center`}>
          {columnNames.map((title, index) => (
            <td
              key={title}
              className={`${tableStyle === 'borders' ? 'border' : ''} ${
                widthByColumn && index < widthByColumn.length ? `${widthByColumn[index]}` : ''
              }`}
            >
              <div className="p-1 w-1/2">
                <span className="field-title">{title}</span>
              </div>
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableCellsByRow?.map((tableCells, index) => (
          <tr
            key={`${tableName}-${names ? names[index] : ''}-${index}`}
            className={getTableRowClasses(index, tableCellsByRow)}
          >
            {tableCells.map((cell, cellIndex) => (
              <td key={`${tableName}-cell-${cellIndex}`} className={`${tableStyle === 'borders' ? 'border' : ''} p-2`}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SimpleDisplayTable;

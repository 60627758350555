import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import FieldSetInputWithIcon from './FieldSetInputWithIcon';
import { DefaultValuesByStepDetailType, StepDetailType } from './StepDetailTypes';
import AntToggle from '../elements/AntToggle';

const DURATION_NAME = 'duration';

interface FieldSetStepDurationProps {
  values: {
    duration?: {
      duration: string;
      started_at: string;
    };
    expected_duration?: string;
  };
  onRemove: (detailId: string) => void;
  setFieldValue: (path: string, value: unknown) => void;
  errors?: string;
  isDisabled?: boolean;
}

const FieldSetStepDuration = ({ values, onRemove, setFieldValue, errors, isDisabled }: FieldSetStepDurationProps) => {
  const remove = useCallback(() => {
    if (onRemove) {
      onRemove(DURATION_NAME);
    }
  }, [onRemove]);

  const onToggle = useCallback(() => {
    const newValue = values.duration ? undefined : DefaultValuesByStepDetailType[StepDetailType.Duration];
    setFieldValue(DURATION_NAME, newValue);
  }, [setFieldValue, values.duration]);

  return (
    <div className="group relative flex flex-col grow">
      <div className="flex flex-col shrink-0" style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
        <div className="flex w-full gap-x-1">
          <div className="flex flex-col">
            <div className="h-6"></div>
            <div className="flex gap-x-1 p-0.5">
              <AntToggle
                checked={Boolean(values.duration)}
                onChange={onToggle}
                sx={{ input: { cursor: 'pointer' } }}
                inputProps={{
                  'aria-label': `Record Duration Toggle`,
                  'aria-checked': Boolean(values.duration),
                }}
                disabled={isDisabled}
              />
              <div className="text-sm">Record Duration</div>
            </div>
          </div>

          <div className="flex flex-col">
            <span className="field-title">Expected Duration</span>
            <FieldSetInputWithIcon
              className=" border-1 border-gray-400 rounded"
              name="expected_duration"
              icon="stopwatch"
              placeholder="HH:MM:SS"
              type="text"
              onBlur={(e) => setFieldValue('expected_duration', e.target.value)}
              isDisabled={isDisabled}
            />
            {errors && <span className=" text-red-700 font-normal capitalize">{errors}</span>}
          </div>
        </div>
      </div>
      {!isDisabled && (
        <FontAwesomeIcon
          className="absolute top-0.5 right-0 text-gray-400 hover:text-gray-600 cursor-pointer opacity-0 group-hover:opacity-100"
          icon="times-circle"
          aria-label="Remove Detail"
          onClick={remove}
        />
      )}
    </div>
  );
};

export default FieldSetStepDuration;

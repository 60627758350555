import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Formik, Form, FieldArray, setIn } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FieldSetAttachment from './FieldSetAttachment';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import OverlayUploadFileDrop from './OverlayUploadFileDrop';
import ProcedureEditCommentsList from './ProcedureEditCommentsList';
import { arrayMoveMutable } from 'array-move';
import getFileInputBlock from '../hooks/useFileInput';
import FieldSetProcedureField from './FieldSetProcedureField';
import { getHeaderBlockRedlineMap } from '../lib/redlineUtil';
import FieldSetText from './FieldSetText';
import FieldSetProcedureBlock from './Blocks/FieldSetProcedureBlock';
import getBlockAddContentItems from './ContentMenu/blockAddContentItems';
import { capitalizeFirstLetter } from 'shared/lib/text';
import AddContentMenu from './ContentMenu/AddContentMenu';
import { Actions } from './ContentMenu/addContentTypes';
import contentDragHandleVisibilityClasses from './Selection/selectionUtil';
import Selectable, { Boundary } from './Selection/Selectable';
import { copyItemToClipboard, selectClipboardItem } from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import FlashMessage from './FlashMessage';
import clipboardUtil from '../lib/clipboardUtil';
import procedureUtil from '../lib/procedureUtil';
import TableInputEdit from './TableInput/TableInputEdit';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import { getRedlineFromDoc } from 'shared/lib/redlineUtil';
import { RunHeaderFieldRedline } from 'shared/lib/types/views/procedures';
import { HeaderFieldRedline } from 'shared/lib/types/views/redlines';

const enabledContentTypes = [
  ProcedureContentBlockTypes.Alert,
  ProcedureContentBlockTypes.Text,
  ProcedureContentBlockTypes.Attachment,
  ProcedureContentBlockTypes.TableInput,
];

const FieldSetProcedureHeader = ({
  header,
  errors,
  index,
  isHeaderCollapsed,
  onHeaderCollapse,
  onFieldRefChanged,
  onHeaderFormChanged,
  comments,
  onSaveReviewComment,
  onResolveComment,
  onUnresolveComment,
  onAcceptRedlineField,
  onRejectRedlineField,
  onAcceptRedlineBlock,
  onRejectRedlineBlock,
  headerRedlines,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const [addFileIndex, setAddFileIndex] = useState(-1);

  const handleOnHeaderFormChanged = useCallback(
    (values) => {
      onHeaderFormChanged(values, index);
    },
    [onHeaderFormChanged, index]
  );

  const syncHeaderToProcedure = useCallback(
    (updatedHeader) => {
      handleOnHeaderFormChanged(updatedHeader);
    },
    [handleOnHeaderFormChanged]
  );

  // TODO: DRY this up, possibly by creating a MixpanelContext.js
  const mixpanelTrack = useCallback(
    (name: string, options?: object) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    // @ts-ignore enabledContentTypes should be of type string[]
    if (!clipboardBlock || (enabledContentTypes && !enabledContentTypes.includes(clipboardBlock.type))) {
      return undefined;
    }

    if (
      clipboardBlock.type === 'table_input' &&
      clipboardBlock.columns.some((column) => column.column_type === 'input')
    ) {
      return undefined;
    }

    return clipboardBlock;
  }, [clipboardItem]);

  const onRemoveContent = useCallback(
    (index, block) => {
      const blockCopy = cloneDeep(block);
      blockCopy.content.splice(index, 1);
      syncHeaderToProcedure(blockCopy);
    },
    [syncHeaderToProcedure]
  );

  const onCopyBlock = useCallback(
    ({ block }) => {
      mixpanelTrack('Copy Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      setFlashMessage('Content block copied');
    },
    [currentTeamId, dispatch, mixpanelTrack]
  );

  const onCutBlock = useCallback(
    ({ block, blockIndex, header }) => {
      mixpanelTrack('Cut Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      onRemoveContent(blockIndex, header);
      setFlashMessage('Content block cut');
    },
    [currentTeamId, dispatch, mixpanelTrack, onRemoveContent]
  );

  const onPasteBlock = useCallback(
    ({ blockIndex }) => {
      if (!clipboardBlock) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedHeader = cloneDeep(header);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedHeader.content.splice(blockIndex + 1, 0, blockCopy);

      syncHeaderToProcedure(updatedHeader);
    },
    [clipboardBlock, header, mixpanelTrack, syncHeaderToProcedure]
  );

  const toggleIsHeaderCollapsed = useCallback(() => {
    onHeaderCollapse(header.id, !isHeaderCollapsed);
  }, [isHeaderCollapsed, onHeaderCollapse, header.id]);

  const onDragStart = useCallback(() => {
    // Blur any active inputs, otherwise they behave weirdly during dragging

    // @ts-expect-error Blur is defined for HTMLElement but not for Element
    document.activeElement.blur();
  }, []);

  // TODO (Deep): Create a drag and drop library and reuse across sections/steps/headers.
  const onDragEnd = useCallback(
    (result, arrayHelpers) => {
      const { source, destination } = result;
      if (!destination) {
        return;
      }
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      // Need arrayHelpers to update formik state right away, to avoid flashing of old positions.
      arrayHelpers.move(source.index, destination.index);

      const headerCopy = cloneDeep(header);
      arrayMoveMutable(headerCopy.content, source.index, destination.index);

      syncHeaderToProcedure(headerCopy);
    },
    [header, syncHeaderToProcedure]
  );

  const fieldRef = useCallback(
    (field) => (element) => {
      if (onFieldRefChanged) {
        onFieldRefChanged(field, element);
      }
    },
    [onFieldRefChanged]
  );

  const initialErrors = useMemo(() => {
    return errors;
  }, [errors]);

  const getContentErrors = useCallback(
    (contentId) => {
      if (!errors || !errors.content || !(contentId in errors.content)) {
        return null;
      }
      return errors.content[contentId];
    },
    [errors]
  );

  const getHandleFileInputChange = useCallback(
    (fileInputRef) => {
      return (event) => {
        const fileInputBlock = getFileInputBlock(event, fileInputRef);
        const headerCopy = cloneDeep(header);

        headerCopy.content.splice(addFileIndex + 1, 0, fileInputBlock);

        syncHeaderToProcedure(headerCopy);
      };
    },
    [addFileIndex, header, syncHeaderToProcedure]
  );

  const addAttachment = useCallback(
    (attachmentBlock) => {
      const headerCopy = cloneDeep(header);
      headerCopy.content.push(attachmentBlock);
      syncHeaderToProcedure(headerCopy);
    },
    [header, syncHeaderToProcedure]
  );

  const getSetFieldValueAndSync = useCallback(() => {
    return (path, value) => {
      const updatedHeader = setIn(header, path, value);
      syncHeaderToProcedure(updatedHeader);
    };
  }, [header, syncHeaderToProcedure]);

  const onAcceptHeaderRedlineField = useCallback(
    (redline) => onAcceptRedlineField(`headers[${index}]`, 'header', redline),
    [onAcceptRedlineField, index]
  );
  const onRejectHeaderRedlineField = useCallback(
    (redline) => onRejectRedlineField(`headers[${index}]`, 'header', redline),
    [onRejectRedlineField, index]
  );

  const onAcceptHeaderRedlineBlock = useCallback(
    (path, block, redline) => onAcceptRedlineBlock(`headers[${index}].${path}`, block, redline),
    [onAcceptRedlineBlock, index]
  );
  const onRejectHeaderRedlineBlock = useCallback(
    (path, block, redline) => onRejectRedlineBlock(`headers[${index}].${path}`, block, redline),
    [onRejectRedlineBlock, index]
  );

  /**
   * No map is needed since currently there is only one field type (`name`)
   */
  const headerFieldRedlines: Array<HeaderFieldRedline> = useMemo(() => {
    if (!headerRedlines) {
      return [];
    }
    return headerRedlines.filter((redline) => (getRedlineFromDoc(redline) as RunHeaderFieldRedline).field);
  }, [headerRedlines]);

  const headerBlockRedlineMap = useMemo(() => getHeaderBlockRedlineMap(headerRedlines), [headerRedlines]);

  /**
   * @type {(contentId: string) => Array<import('shared/lib/types/views/redlines').HeaderBlockRedline>}
   */
  const getHeaderBlockRedlines = useCallback(
    (contentId) => headerBlockRedlineMap.get(contentId) ?? [],
    [headerBlockRedlineMap]
  );

  const getOnAddBlock = useCallback((container, syncBlock, index, blockType, subType) => {
    const containerCopy = cloneDeep(container);
    const readOnly = true;
    const contentBlock = procedureUtil.newInitialBlock(blockType, subType, readOnly);

    if (index === undefined) {
      containerCopy.content.push(contentBlock);
    } else {
      containerCopy.content.splice(index + 1, 0, contentBlock);
    }

    syncBlock(containerCopy);
  }, []);

  const onContentMenuClick = useCallback(
    (menuItem, blockIndex, localHeader) => {
      switch (menuItem.action) {
        case Actions.DeleteBlock:
          return onRemoveContent(blockIndex, localHeader);
        case Actions.CopyBlock:
          return onCopyBlock({ block: localHeader.content[blockIndex] });
        case Actions.CutBlock:
          return onCutBlock({ block: localHeader.content[blockIndex], blockIndex, header: localHeader });
        case Actions.PasteBlock:
          return onPasteBlock({ blockIndex });
        case Actions.AddBlock:
          mixpanelTrack(`Add ${capitalizeFirstLetter(menuItem.subType || menuItem.blockType)} To Header`);

          if (menuItem.blockType === ProcedureContentBlockTypes.Attachment) {
            setAddFileIndex(blockIndex);
            return fileInputRef && fileInputRef.current && fileInputRef.current.click();
          }

          return getOnAddBlock(header, syncHeaderToProcedure, blockIndex, menuItem.blockType, menuItem.subType);
        default:
          return;
      }
    },
    [
      getOnAddBlock,
      header,
      mixpanelTrack,
      onCopyBlock,
      onCutBlock,
      onPasteBlock,
      onRemoveContent,
      syncHeaderToProcedure,
    ]
  );

  const onKeyboard = useCallback(
    (event, blockIndex, header) => {
      if (event.code === 'Backspace' || event.code === 'Delete') {
        onRemoveContent(blockIndex, header);
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopyBlock({ block: header.content[blockIndex] });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
        onCutBlock({ block: header.content[blockIndex], blockIndex, header });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteBlock({ blockIndex });
      }
    },
    [onCopyBlock, onCutBlock, onPasteBlock, onRemoveContent]
  );

  return (
    <div className="flex flex-col w-full">
      <Formik
        initialValues={header}
        initialErrors={initialErrors}
        onSubmit={() => {
          /* no-op */
        }}
        validateOnChange={false}
        validate={handleOnHeaderFormChanged}
        enableReinitialize
      >
        {({ values }) => (
          <Form className="w-full">
            <div aria-label="Procedure Header" role="region" className="relative">
              <OverlayUploadFileDrop
                className="flex flex-col grow"
                isEnabled={true} // Always enabled because this is an edit procedure component.
                onUpdate={addAttachment}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  id={`headers[${index}]._file_input`}
                  onChange={getHandleFileInputChange(fileInputRef)}
                  className="hidden"
                />

                <div className="flex flex-nowrap">
                  <div className="h-9">
                    <ExpandCollapseCaret
                      isExpanded={!isHeaderCollapsed}
                      onClick={toggleIsHeaderCollapsed}
                      ariaLabel={isHeaderCollapsed ? 'Expand header' : 'Collapse header'}
                    />
                  </div>
                  <div className="flex items-center text-left text-xl w-40 h-9">
                    <span className="w-40 shrink-0 font-semibold">Procedure Header</span>
                  </div>
                  <div
                    className="w-full flex flex-col"
                    ref={fieldRef(`${header.id}.name`)}
                    style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                  >
                    <FieldSetProcedureField
                      fieldName="name"
                      fieldValue={values.name}
                      redlines={headerFieldRedlines}
                      fieldValueType="text"
                      error={errors?.redlineName}
                      placeholder="Header name*"
                      acceptRedline={onAcceptHeaderRedlineField}
                      rejectRedline={onRejectHeaderRedlineField}
                    />
                    {errors && errors.name && <div className="text-red-700">{errors.name}</div>}
                  </div>
                </div>
                {/* Header Body */}
                {!isHeaderCollapsed && (
                  <div>
                    <FieldArray
                      name="content"
                      key={`content[${index}]`}
                      render={(contentArrayHelpers) => (
                        <div className="pb-2 mt-1 w-full">
                          {values.content.length === 0 && (
                            <div className="ml-12 mt-2">
                              <AddContentMenu
                                menuItems={getBlockAddContentItems({
                                  enabledContentTypes,
                                  canDeleteBlock: false,
                                  canCopyBlock: false,
                                  canCutBlock: false,
                                  canPasteBlock: !!clipboardBlock,
                                  isReadOnly: true,
                                })}
                                onClick={(menuItem) => onContentMenuClick(menuItem, -1, values)}
                                includeTextLabel={true}
                              />
                            </div>
                          )}

                          <DragDropContext
                            onDragStart={onDragStart}
                            onDragEnd={(result) => onDragEnd(result, contentArrayHelpers)}
                          >
                            <Droppable droppableId={`header.${index}`}>
                              {(provided, snapshot) => (
                                <div
                                  className={`border-2 border-dashed ${
                                    snapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  {values.content &&
                                    values.content.map((content, contentIndex) => (
                                      <Selectable
                                        boundary={Boundary.ContentBlock}
                                        key={content.id}
                                        block={content}
                                        onKeyboard={(event) => onKeyboard(event, contentIndex, values)}
                                      >
                                        {({ isSelected, styles }) => (
                                          <Draggable key={content.id} draggableId={content.id} index={contentIndex}>
                                            {(provided, draggableSnapshot) => (
                                              <div
                                                aria-label="Content Block"
                                                role="region"
                                                className="group/content flex"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                              >
                                                <div
                                                  className={`h-9 flex flex-row gap-x-2 items-center ml-2 ${
                                                    isSelected
                                                      ? 'opacity-100'
                                                      : 'opacity-0 group-hover/content:opacity-50'
                                                  }`}
                                                >
                                                  <AddContentMenu
                                                    menuItems={getBlockAddContentItems({
                                                      enabledContentTypes,
                                                      isReadOnly: true,
                                                      canPasteBlock: !!clipboardBlock,
                                                    })}
                                                    onClick={(menuItem) =>
                                                      onContentMenuClick(menuItem, contentIndex, values)
                                                    }
                                                  />

                                                  {/* Drag Handle */}
                                                  <div
                                                    className={`relative flex shrink-0 pr-2 text-gray-400 ${contentDragHandleVisibilityClasses(
                                                      snapshot,
                                                      draggableSnapshot,
                                                      isSelected
                                                    )}`}
                                                  >
                                                    <div {...provided.dragHandleProps} tabIndex={undefined}>
                                                      <FontAwesomeIcon icon="grip-vertical" />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className={`my-0.5 p-1 w-full ${styles}`}
                                                  ref={fieldRef(`${header.id}.content[${content.id}]`)}
                                                  style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                                >
                                                  {/* Render text content block */}
                                                  {content.type.toLowerCase() === 'text' && (
                                                    <>
                                                      <FieldSetText
                                                        block={content}
                                                        redlines={getHeaderBlockRedlines(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        contentErrors={getContentErrors(content.id)}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        acceptRedline={onAcceptHeaderRedlineBlock}
                                                        rejectRedline={onRejectHeaderRedlineBlock}
                                                      />
                                                    </>
                                                  )}
                                                  {/* Note: Blocks are being refactored to use new architecture, once
                                                    refactor is complete they will all use ProcedureBlockWithRedlining */}
                                                  {(content.type.toLowerCase() === 'input' ||
                                                    content.type.toLowerCase() === 'alert' ||
                                                    content.type.toLowerCase() === 'requirement') && (
                                                    <>
                                                      <FieldSetProcedureBlock
                                                        block={content}
                                                        redlines={getHeaderBlockRedlines(content.id)}
                                                        path={`content[${contentIndex}]`}
                                                        contentErrors={getContentErrors(content.id)}
                                                        setFieldValue={getSetFieldValueAndSync()}
                                                        acceptRedline={onAcceptHeaderRedlineBlock}
                                                        rejectRedline={onRejectHeaderRedlineBlock}
                                                      />
                                                    </>
                                                  )}
                                                  {/* Render file attachment */}
                                                  {content.type.toLowerCase() === 'attachment' && (
                                                    <FieldSetAttachment
                                                      attachment={content}
                                                      errors={getContentErrors(content.id)}
                                                      path={`content[${contentIndex}]`}
                                                      setFieldValue={getSetFieldValueAndSync()}
                                                      isToTheSideImageEnabled={false}
                                                      isPending={false}
                                                      source="Procedure Header"
                                                    />
                                                  )}
                                                  {content.type.toLowerCase() === 'table_input' && (
                                                    <TableInputEdit
                                                      content={content}
                                                      path={`content[${contentIndex}]`}
                                                      setFieldValue={getSetFieldValueAndSync()}
                                                      isReadOnly={true}
                                                    />
                                                  )}
                                                </div>

                                                <div
                                                  className={`pl-1 pr-2 justify-end ${
                                                    isSelected
                                                      ? 'opacity-100'
                                                      : 'opacity-0 group-hover/content:opacity-100'
                                                  } `}
                                                >
                                                  <button
                                                    type="button"
                                                    title="Remove Procedure Header Content"
                                                    tabIndex={-1}
                                                    onClick={() => onRemoveContent(contentIndex, values)}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon="times-circle"
                                                      className="self-center text-gray-400 hover:text-gray-500"
                                                    ></FontAwesomeIcon>
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )}
                                      </Selectable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      )}
                    />
                  </div>
                )}
              </OverlayUploadFileDrop>
            </div>
            <FlashMessage
              message={flashMessage}
              // @ts-ignore
              messageUpdater={setFlashMessage}
            />
          </Form>
        )}
      </Formik>

      {/* Review comments */}
      {/* When using comments.length by itself, JSX will convert that into a text in the div with the value 0 */}
      {comments && comments.length > 0 && (
        <div className="flex w-full pl-2">
          <ProcedureEditCommentsList
            comments={comments}
            isCollapsed={isHeaderCollapsed}
            onResolveComment={onResolveComment}
            onSaveReviewComment={onSaveReviewComment}
            onUnresolveComment={onUnresolveComment}
            onFieldRefChanged={onFieldRefChanged}
            stepId={header.id}
            errors={null}
          />
        </div>
      )}
    </div>
  );
};

export default FieldSetProcedureHeader;

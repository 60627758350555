import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, LinkProps, useHistory } from 'react-router-dom';

export const BROWSER_BACK = 'back';
type BACK = typeof BROWSER_BACK;

export interface BackToLinkProps {
  to: LinkProps['to'] | BACK;
  name: string | React.ReactElement;
}

const BackToLink = ({ to, name }: BackToLinkProps) => {
  const history = useHistory();

  return to === BROWSER_BACK ? (
    <button onClick={() => history.goBack()} className="text-sm flex flex-row">
      <FontAwesomeIcon icon="chevron-left" size="xs" className="text-blue-500 mr-1 pt-1" />
      {name}
    </button>
  ) : (
    <Link to={to} className="text-sm flex flex-row">
      <FontAwesomeIcon icon="chevron-left" size="xs" className="text-blue-500 mr-1 pt-1" />
      {name}
    </Link>
  );
};

export default React.memo(BackToLink);

import { RunStep, RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import Modal from '../Modal';
import SuggestedEditIcon from './SuggestedEditIcon';
import DateTimeDisplay from '../DateTimeDisplay';
import React, { useCallback, useMemo, useState } from 'react';
import ReviewProcedureStep from '../Review/ReviewProcedureStep';
import { useAuth } from '../../contexts/AuthContext';
import { useRunContext } from '../../contexts/RunContext';
import { PERM } from '../../lib/auth';
import CommentsList from '../CommentsList';
import FieldLabel from '../../elements/internal/FieldLabel';

import VersionCompare from '../VersionCompare';
import SuggestedEditHistoryItem from './SuggestedEditHistoryItem';
import ExpandCollapseButtons from '../ExpandCollapse/ExpandCollapseButtons';
import { getRedlineId } from 'shared/lib/redlineUtil';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt as fasExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import TooltipOverlay from '../TooltipOverlay';
import { canIncludeRedlines } from 'shared/lib/runUtil';
import useFullStepRedline from '../../hooks/useFullStepRedline';

interface FullStepSuggestedEditsModalProps {
  currentStep: RunStep;
  redlines: Array<RunStepFullRedline>;
  onClose: () => void;
  acceptAction?: (redline: RunStepRedline) => void;
  contextUrl?: string;
  editComment?: ({
    redlineId,
    commentId,
    updatedText,
    updatedAt,
  }: {
    redlineId: string;
    commentId: string;
    updatedText: string;
    updatedAt: string;
  }) => void;
  sectionId: string;
  stepKey?: string;
}

const FullStepSuggestedEditsModal = ({
  currentStep,
  redlines = [],
  onClose,
  acceptAction,
  contextUrl,
  editComment,
  sectionId,
  stepKey = '--',
}: FullStepSuggestedEditsModalProps) => {
  const { auth } = useAuth();
  const { isUserParticipant, isRunning, run } = useRunContext();
  const [isAccepting, setIsAccepting] = useState(false);

  const {
    currentStepCleaned,
    latestRedline,
    latestStepDiff,
    reversedRedlines,
    isCurrentLatest,
    confirmRedlineDataLoss,
  } = useFullStepRedline({
    currentStep,
    redlines,
    sectionId,
    formattedStepKey: stepKey,
  });

  const hasIncludeRedlinePermissions = useMemo(() => {
    return run && auth.hasPermission(PERM.PROCEDURES_EDIT, run.project_id);
  }, [auth, run]);

  const isAcceptDisplayed = useMemo(
    () =>
      Boolean(
        isRunning &&
          hasIncludeRedlinePermissions &&
          acceptAction &&
          latestRedline &&
          latestRedline.pending &&
          canIncludeRedlines(currentStep)
      ),
    [acceptAction, currentStep, hasIncludeRedlinePermissions, isRunning, latestRedline]
  );

  const isAcceptEnabled = useMemo(() => {
    return isUserParticipant && !isAccepting;
  }, [isAccepting, isUserParticipant]);

  const onAcceptLatest = useCallback(() => {
    setIsAccepting(true);
    if (!acceptAction || !latestRedline || !confirmRedlineDataLoss()) {
      setIsAccepting(false);
      return;
    }
    acceptAction(latestRedline);
    onClose();
  }, [acceptAction, confirmRedlineDataLoss, latestRedline, onClose]);

  const [redlineExpandedMap, setRedlineExpandedMap] = useState(
    new Map(
      (reversedRedlines ?? []).map((redline, index) => {
        const isExpanded = isCurrentLatest && index === 0;
        return [getRedlineId(redline), isExpanded];
      })
    )
  );

  const setExpandedForAll = useCallback(
    (isExpanded) => {
      setRedlineExpandedMap(
        new Map(
          (reversedRedlines ?? []).map((redline) => {
            return [redline.redline_id, isExpanded];
          })
        )
      );
    },
    [reversedRedlines]
  );

  const toggleExpanded = useCallback(
    (redlineId) => {
      setRedlineExpandedMap((prev) => {
        const updated = new Map(prev);
        updated.set(redlineId, !prev.get(redlineId));
        return updated;
      });
    },
    [setRedlineExpandedMap]
  );

  return (
    <Modal isPrimaryActionVisible={false} onSecondaryAction={onClose} size="lg" secondaryActionTitle="Close">
      <div className="flex flex-col w-full max-h-[80vh] overflow-y-auto px-4">
        <div className="flex justify-between items-end mb-2">
          <div className="flex flex-row items-center">
            <h1 className="mb-0">Suggested Edits</h1>
          </div>
        </div>

        <div>
          {latestRedline && latestStepDiff && !isCurrentLatest && (
            <div className="flex flex-col gap-y-2">
              <FieldLabel label="Latest Suggested Edit" />
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row ">
                  <span className="font-semibold mr-1">
                    {[latestRedline.user_id ?? latestRedline.userId].join(', ')}
                  </span>
                  <DateTimeDisplay timestamp={latestRedline.created_at ?? latestRedline.createdAt ?? ''} />
                  {contextUrl && (
                    <div className="ml-2">
                      <TooltipOverlay
                        content={
                          <div>
                            See{' '}
                            <Link className="link group" to={{ pathname: contextUrl }} target="_blank">
                              source run
                              <FontAwesomeIcon
                                className="px-1 text-blue-500 group-hover:text-blue-600"
                                icon={fasExternalLinkAlt}
                              />
                            </Link>
                            for context.
                          </div>
                        }
                        delayClose={true}
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </TooltipOverlay>
                    </div>
                  )}
                </div>

                <SuggestedEditIcon isBlueline={Boolean(latestRedline.run_only)} />
              </div>
              <VersionCompare
                label="Latest Suggested Edit"
                showLabel={false}
                isCurrentLatest={isCurrentLatest}
                currentVersion={
                  <div key="current-step-unchanged">
                    <table>
                      {/* TODO: When ProcedureStep is updated to .tsx, update fields to be optional where necessary. Until then, ignore the missing attributes TS error. */}
                      {/* @ts-ignore */}
                      <ReviewProcedureStep
                        step={currentStepCleaned}
                        showModifiedDiffContainer={false}
                        isCollapsed={false}
                        isHidden={false}
                        isFullWidth={true}
                        defaultFormattedStepKey={stepKey}
                      />
                    </table>
                    {isCurrentLatest && (
                      <CommentsList
                        comments={(latestRedline as RunStepFullRedline).comments ?? []}
                        onEdit={
                          editComment
                            ? ({ comment: updatedText, updated_at: updatedAt }, commentId) =>
                                editComment({
                                  redlineId: getRedlineId(latestRedline) ?? '',
                                  commentId,
                                  updatedText,
                                  updatedAt,
                                })
                            : undefined
                        }
                        isRedlineAnnotation={true}
                      />
                    )}
                  </div>
                }
                latestVersion={
                  <div key="current-step-latest">
                    <table>
                      <>
                        {latestStepDiff?.map((stepDiff) => (
                          <>
                            {/* TODO: When ProcedureStep is updated to .tsx, update fields to be optional where necessary. Until then, ignore the missing attributes TS error. */}
                            {/* @ts-ignore */}
                            <ReviewProcedureStep
                              key={`suggested-edits-diff${stepDiff.id}-${stepDiff.diff_change_state ?? ''}`}
                              step={stepDiff}
                              showModifiedDiffContainer={false}
                              isCollapsed={false}
                              isFullWidth={true}
                              isHidden={false}
                              defaultFormattedStepKey={stepKey}
                            />
                          </>
                        ))}
                      </>
                    </table>
                    <CommentsList
                      comments={(latestRedline as RunStepFullRedline).comments ?? []}
                      onEdit={
                        editComment
                          ? ({ comment: updatedText, updated_at: updatedAt }, commentId) =>
                              editComment({
                                redlineId: getRedlineId(latestRedline) ?? '',
                                commentId,
                                updatedText,
                                updatedAt,
                              })
                          : undefined
                      }
                      isRedlineAnnotation={true}
                    />
                  </div>
                }
                onAcceptLatest={onAcceptLatest}
                isAcceptDisplayed={isAcceptDisplayed}
                isAcceptEnabled={isAcceptEnabled}
              />
            </div>
          )}

          {reversedRedlines.length > 1 && (
            <>
              <div className="self-center my-2 h-px bg-gray-300 w-full" />

              <div className="flex flex-col gap-y-2">
                <div className="flex flex-row items-center justify-between w-full">
                  <FieldLabel label="Suggested Edits History" />
                  <div className="flex flex-row gap-x-2 divide-x">
                    <ExpandCollapseButtons
                      buttonClasses="p-2"
                      onExpand={() => setExpandedForAll(true)}
                      onCollapse={() => setExpandedForAll(false)}
                      isDisabled={false}
                      labelText="Suggested Edits"
                    />
                  </div>
                </div>
                {reversedRedlines.map((redline, index) => {
                  if (index === reversedRedlines.length - 1) {
                    return <></>;
                  }
                  return (
                    <SuggestedEditHistoryItem
                      key={redline.redline_id}
                      redline={redline}
                      previousRedline={reversedRedlines[index + 1]}
                      isExpanded={Boolean(redlineExpandedMap.get(getRedlineId(redline)))}
                      onToggleExpandCollapse={() => toggleExpanded(getRedlineId(redline))}
                      editComment={editComment}
                      stepKey={stepKey}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FullStepSuggestedEditsModal;

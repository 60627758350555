import superlogin, { getSession } from './superlogin';
import { E3Session } from 'shared/lib/types/api/util';
import { API_URL } from '../config';
import axios from 'axios';
import { apm } from '@elastic/apm-rum';

export type SSOProvider = {
  provider: string | null;
  message: string | null;
};

export type SamlIdPChoice = {
  uid: string;
  name: string;
};

export type SamlIdpResponse = {
  providers: SamlIdPChoice[];
  message: string;
};

export type LastSamlIdpResponse = {
  provider: SamlIdPChoice | null;
  message: string;
};

const getRequestHeaders = async (): Promise<{ Authorization: string }> => {
  const session = getSession();
  if (!session) {
    throw new Error('No session found');
  }
  const encoded = btoa(`${session.token}:${session.password}`);
  return { Authorization: `Basic ${encoded}` };
};

const getSSOProvider = async (email: string): Promise<SSOProvider> => {
  return axios
    .post(`${API_URL}/sso/provider`, { email }, { withCredentials: true })
    .then((response) => response.data);
};

const getSSOProviders = async (email: string): Promise<SamlIdpResponse> => {
  return axios
    .post(`${API_URL}/sso/providers`, { email }, { withCredentials: true })
    .then((response) => response.data);
};

/**
 * Get the last SAML idp used by this browser.
 * Any failures with this should just be ignored because it is a convenience.
 *
 * @returns the last SAML idp used if any, used for faster login
 */
const getLastSSOProvider = async (): Promise<SamlIdPChoice | null> => {
  try {
    const response = await axios.get<LastSamlIdpResponse>(
      `${API_URL}/sso/providers/last`
    );
    return response.data.provider;
  } catch (err) {
    apm.captureError(err);
  }
  return null;
};

const loginWithSSO = async (token: string): Promise<E3Session> => {
  return axios
    .post(`${API_URL}/sso/session`, { token }, { withCredentials: true })
    .then((response) => response.data)
    .then((session) => {
      // Imitate superlogin-client and manually perform socialLogin().
      session.serverTimeDiff = session.issued - Date.now();
      superlogin.setSession(session);
      superlogin._onLogin(session);
      return session;
    });
};

const agreeToDisclaimer = async (): Promise<E3Session> => {
  const url = `${API_URL}/auth/disclaimer/agree`;
  return superlogin
    .getHttp()
    .post(url)
    .then((response) => response.data)
    .then((session) => {
      // Imitate superlogin-client and manually perform socialLogin().
      session.serverTimeDiff = session.issued - Date.now();
      superlogin.setSession(session);
      superlogin._onLogin(session);
      return session;
    });
};

export default getRequestHeaders;
export {
  getSSOProvider,
  getSSOProviders,
  loginWithSSO,
  agreeToDisclaimer,
  getLastSSOProvider,
};

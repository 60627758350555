import React, { useCallback, useMemo, useState } from 'react';
import ViewReviewerGroups from './ViewReviewerGroups';
import { useSettings } from '../../contexts/SettingsContext';
import { useAuth } from '../../contexts/AuthContext';
import { PERM } from '../../lib/auth';
import Tooltip from '../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../Button';
import { ReviewerGroup, ReviewerId } from 'shared/lib/types/couch/procedures';
import { getHasReviewers, getHaveAllReviewerGroupsApproved } from 'shared/lib/reviewUtil';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReviewBanner from './ReviewBanner';

const REVIEWERS_NOTIFIED_MESSAGE_TIMEOUT_MS = 5000;

interface ReviewersProps {
  children?: React.ReactNode;
  reviewName?: string;
  reviewerGroups: Array<ReviewerGroup>;
  onReviewerApprove?: (reviewerGroupId: string, reviewerId: string, operatorRole: string) => void;
  onRevokeApproval?: (reviewerGroupId: string, reviewerId: ReviewerId) => void;
  onRelease: () => void;
  onNotifyReviewers?: () => void;
  hasReviewPermission: (reviewerId: ReviewerId) => boolean;
  hasReviewCommentsAcknowledged: boolean;
  hasReleasePermission: boolean;
  canRelease: boolean;
  projectId: string | null;
  allowEditing?: boolean;
  onEdit?: () => void;
  releaseLabel?: string;
  releaseIcon?: IconProp | null;
}

const Reviewers = ({
  children,
  reviewName,
  reviewerGroups,
  onReviewerApprove,
  onRevokeApproval,
  onRelease,
  onNotifyReviewers,
  hasReviewPermission,
  hasReviewCommentsAcknowledged,
  hasReleasePermission,
  canRelease,
  allowEditing = false,
  onEdit = () => undefined,
  projectId = null,
  releaseLabel = 'Release',
  releaseIcon = null,
}: ReviewersProps) => {
  const [reviewersNotified, setReviewersNotified] = useState(false);

  const { users } = useSettings();
  const { auth } = useAuth();

  const isApproved = useMemo(() => {
    return getHaveAllReviewerGroupsApproved(reviewerGroups);
  }, [reviewerGroups]);

  const handleOnReviewerApprove = useCallback(
    (reviewerGroupId, reviewerId, operatorRole) => {
      return onReviewerApprove && onReviewerApprove(reviewerGroupId, reviewerId, operatorRole);
    },
    [onReviewerApprove]
  );

  const handleOnRevokeApproval = useCallback(
    (reviewerGroupId, reviewerId) => {
      return onRevokeApproval && onRevokeApproval(reviewerGroupId, reviewerId);
    },
    [onRevokeApproval]
  );

  const notifyReviewers = useCallback(() => {
    if (!users || !onNotifyReviewers) {
      return;
    }

    setReviewersNotified(true);

    setTimeout(() => {
      setReviewersNotified(false);
    }, REVIEWERS_NOTIFIED_MESSAGE_TIMEOUT_MS);

    return onNotifyReviewers && onNotifyReviewers();
  }, [onNotifyReviewers, users]);

  const isNotifyReviewersDisabled = useMemo(() => {
    return reviewersNotified || !getHasReviewers(reviewerGroups) || isApproved;
  }, [isApproved, reviewerGroups, reviewersNotified]);

  const showEditActions = useMemo(() => auth.hasPermission(PERM.PROCEDURES_EDIT, projectId), [auth, projectId]);

  const reviewTitle = useMemo(() => {
    const name = reviewName ?? reviewerGroups?.[0]?.name ?? '';
    return isApproved ? `Ready to Release${name ? `: ${name}` : ''}` : `Review${name ? `: ${name}` : ''}`;
  }, [isApproved, reviewName, reviewerGroups]);

  return (
    <ReviewBanner isApproved={isApproved}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-x-1">
          <div className="font-bold">{reviewTitle}</div>
        </div>
        {showEditActions && onNotifyReviewers && (
          <div className="whitespace-nowrap">
            <Button
              type="tertiary"
              onClick={notifyReviewers}
              isDisabled={isNotifyReviewersDisabled}
              leadingIcon="envelope"
              iconTextColor="text-blue-500"
            >
              {reviewersNotified ? 'Reviewers Notified' : 'Notify Reviewers'}
            </Button>
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-y-2">
          <ViewReviewerGroups
            reviewerGroups={reviewerGroups}
            onReviewerApprove={handleOnReviewerApprove}
            onRevokeApproval={onRevokeApproval && handleOnRevokeApproval}
            hasPermission={hasReviewPermission}
            onEdit={onEdit}
            showEditActions={allowEditing}
            showSignoffCheckmark={true}
            showSettings={false}
          />
          {children}
          {canRelease && (
            <div className="flex flex-row gap-x-2">
              <Tooltip
                content="Please resolve all review comments."
                position="bottom"
                visible={!hasReviewCommentsAcknowledged}
              >
                <Button
                  type={BUTTON_TYPES.PRIMARY}
                  ariaLabel="Release Procedure"
                  isDisabled={!isApproved || !hasReleasePermission || !hasReviewCommentsAcknowledged}
                  onClick={onRelease}
                >
                  {releaseIcon && <FontAwesomeIcon icon={releaseIcon} size="sm" className="mr-1" />}
                  {releaseLabel}
                </Button>
              </Tooltip>
            </div>
          )}
          {showEditActions && !hasReleasePermission && isApproved && (
            <div className="mt-1 leading-5 whitespace-pre-line text-gray-600 ">
              None of your operator roles can release procedures
            </div>
          )}
        </div>
      </div>
    </ReviewBanner>
  );
};

export default React.memo(Reviewers);

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSettings } from '../../contexts/SettingsContext';
import { Resources } from 'shared/schedule/types/event';
import { isEqual } from 'lodash';

type Option = {
  optionType: string;
  label: string;
  value: string;
};

const ResourceSelect = ({ field, form }) => {
  const { setFieldValue } = form;
  const { users } = useSettings();
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options: Option[] = [];

    // User Options
    if (users?.users) {
      const userList = Object.keys(users.users);
      const userOptions = userList.map((user) => ({
        optionType: 'users',
        label: user,
        value: user,
      }));
      options.push(...userOptions);
    }

    setOptions((currentOptions) => {
      if (!isEqual(currentOptions, options)) {
        return options;
      }
      return currentOptions;
    });
  }, [users?.users]);

  useEffect(() => {
    const options: Option[] = [];
    if (field.value) {
      for (const [optionType, labels] of Object.entries(field.value)) {
        if (labels) {
          const typeOptions = (labels as string[]).map((label) => ({ optionType, label, value: label }));
          options.push(...typeOptions);
        }
      }
    }
    setSelectedOptions(options);
  }, [field.value]);

  const onSelectResource = async (value: Option[]) => {
    if (value.length !== 0) {
      const resources: Resources = value.reduce((acc, entry) => {
        if (!acc[entry.optionType]) {
          acc[entry.optionType] = [];
        }
        acc[entry.optionType]?.push(entry.label);
        return acc;
      }, {});
      setFieldValue(field.name, resources);
    } else {
      setFieldValue(field.name, {});
    }
  };

  return (
    <div className="flex flex-row items-center relative w-full">
      <Select
        value={selectedOptions}
        placeholder="Select Resources..."
        classNamePrefix="react-select"
        className="w-full  border-1 border-gray-400 rounded"
        onChange={(value) => onSelectResource(value)}
        options={options}
        isMulti
        isClearable
      />
    </div>
  );
};

export default ResourceSelect;

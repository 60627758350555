import React, { useCallback, useMemo } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import Toggle from 'react-toggle';
import './Toggle.css';
import { useRunContext } from '../contexts/RunContext';

/**
 * Toggle for specifying whether a user is a participant in the run.
 *
 * Participants can complete actions like signing off without being prompted.
 */
const ToggleIsUserParticipant = () => {
  const { mixpanel } = useMixpanel();
  const { isUserParticipant, toggleIsUserParticipant } = useRunContext();

  // Wrapper function for mixpanel tracking
  const handleToggleIsUserParticipant = useCallback(() => {
    if (mixpanel && isUserParticipant) {
      mixpanel.track('Participant Removed');
    }
    if (mixpanel && !isUserParticipant) {
      mixpanel.track('Participant Added');
    }
    toggleIsUserParticipant();
  }, [mixpanel, isUserParticipant, toggleIsUserParticipant]);

  const textColorGray = useMemo(() => (isUserParticipant ? '' : 'text-gray-400'), [isUserParticipant]);
  return (
    <label>
      <div className="flex flex-row items-center">
        <Toggle
          checked={isUserParticipant}
          onChange={handleToggleIsUserParticipant}
          aria-label="Participant Toggle"
          icons={false}
        />
        <div className={`ml-2  ${textColorGray}`}>Participating</div>
      </div>
    </label>
  );
};

export default ToggleIsUserParticipant;

import React, { ReactNode } from 'react';
import { MARKDOWN_MARGIN_CLASS } from './MarkdownView';
import TooltipOverlay from '../TooltipOverlay';
import ReferenceToken from '../Blocks/Display/ReferenceToken';
import { ExpressionToken } from 'shared/lib/types/views/procedures';

const codeClassName = 'bg-gray-200 font-mono  py-1 px-2 rounded whitespace-pre-wrap';

interface MarkdownCodeWrapperProps {
  isInline?: boolean;
  children: ReactNode;
}

/**
 * For inline code we render a code tag,
 * but for non-inline/blocks,
 * we wrap the code tag in a div to give it block styling.
 *
 * code tags work like spans, with inline styles.
 */
const MarkdownCodeWrapper = React.memo(({ isInline = false, children }: MarkdownCodeWrapperProps) => {
  // align with expressionUtil _getRichText definition for tokens
  const regex = /🌜(.+)🌓(.+)🌗(.*)🌛/g;
  const match = regex.exec(children?.[0] as string);
  if (match) {
    const referenceId = match[1];
    const resolvedValue = match[2];
    const fieldIndex = match[3] !== '' ? parseInt(match[3]) : undefined;
    const fakeToken: ExpressionToken = {
      field_index: fieldIndex,
      type: 'reference',
      value: '',
    };

    return (
      <TooltipOverlay
        delayClose={true}
        visible={true}
        content={<ReferenceToken originalReferencedContentId={referenceId} originalToken={fakeToken} />}
      >
        <div className="hover:bg-slate-200 hover:rounded inline-block text-blue-500">{resolvedValue}</div>
      </TooltipOverlay>
    );
  }

  if (isInline) {
    return <code className={codeClassName}>{children}</code>;
  }

  return (
    <div className={`${codeClassName} ${MARKDOWN_MARGIN_CLASS}`}>
      <code>{children}</code>
    </div>
  );
});

export default MarkdownCodeWrapper;

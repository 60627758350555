import { Link } from 'react-router-dom';

interface TitleCellRendererProps {
  id: string | number;
  title: string;
  link: string;
  from: string;
}

const TitleCellRenderer = ({ id, title, link, from }: TitleCellRendererProps) => {
  return (
    <div className="leading-4 w-full">
      <div>
        <Link to={{ pathname: link, state: { from } }} className="font-medium text-sm text-blue-600 hover:underline">
          {id}
        </Link>
      </div>
      <div>
        <Link className="text-gray-900 truncate" to={{ pathname: link, state: { from } }}>
          {title}
        </Link>
      </div>
    </div>
  );
};

export default TitleCellRenderer;

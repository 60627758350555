import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STEP_STATE } from 'shared/lib/runUtil';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isNil } from 'lodash';
import sharedDiffUtil from 'shared/lib/diffUtil';

const DELIMITER = ', ';
interface ContainerKeyProps {
  containerKey?: string | number;
  repeatKey?: string | number;
}

const KeyWithRepeat = ({ containerKey, repeatKey }: ContainerKeyProps) => {
  return (
    <>
      {!repeatKey && containerKey}
      {repeatKey && containerKey && (
        <>
          {containerKey} <FontAwesomeIcon icon="redo" className="text-xxs" /> {repeatKey}
        </>
      )}
    </>
  );
};

interface ExpressionTokenDisplayProps {
  referenceName: string;
  referenceIcon?: IconProp;
  onClick: () => void;
  primaryKey?: string | number;
  primaryRepeatKey?: string | number;
  secondaryKey?: string | number;
  secondaryRepeatKey?: string | number;
  tertiaryKey?: string | number;
  stepRecordedState?: string;
  inRunningRun: boolean;
}

const ExpressionTokenDisplay = ({
  referenceName,
  referenceIcon,
  onClick,
  primaryKey,
  primaryRepeatKey,
  secondaryKey,
  secondaryRepeatKey,
  tertiaryKey,
  stepRecordedState,
  inRunningRun,
}: ExpressionTokenDisplayProps) => {
  const chipStatusColor = useMemo(() => {
    const base = 'bg-slate-300';
    const complete = 'bg-emerald-200';

    if (inRunningRun) {
      if (stepRecordedState === STEP_STATE.COMPLETED) {
        return complete;
      }
      if (stepRecordedState === STEP_STATE.SKIPPED) {
        return base;
      }
      if (stepRecordedState === STEP_STATE.FAILED) {
        return 'bg-red-100';
      }
    }
    //Case where step is not ended or procedure is not running
    return base;
  }, [stepRecordedState, inRunningRun]);

  const diffValueReferenceName = sharedDiffUtil.getDiffValue({ referenceName }, 'referenceName', 'new');

  return (
    <div className="flex flex-row items-start">
      <button
        onClick={onClick}
        className={`font-medium text-sm px-1.5 py-0.5 rounded-xl whitespace-pre-wrap text-left ${chipStatusColor} truncate`}
      >
        <KeyWithRepeat containerKey={primaryKey} repeatKey={primaryRepeatKey} />
        {!isNil(primaryKey) && primaryKey !== '' && !isNil(secondaryKey) && secondaryKey !== '' && DELIMITER}
        <KeyWithRepeat containerKey={secondaryKey} repeatKey={secondaryRepeatKey} />
        {!isNil(secondaryKey) && secondaryKey !== '' && !isNil(tertiaryKey) && tertiaryKey !== '' && DELIMITER}
        {tertiaryKey}
        {referenceIcon && (
          <>
            {' '}
            <FontAwesomeIcon icon={referenceIcon} />
          </>
        )}{' '}
        {diffValueReferenceName}
      </button>
    </div>
  );
};

export default ExpressionTokenDisplay;

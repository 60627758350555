import { useCallback, useMemo } from 'react';
import { SamlIdPChoice } from '../api/auth';
import Button from './Button';
import { API_URL } from '../config';
import useLocationParams from '../hooks/useLocationParams';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../screens/types';

export const SamlIdpChoiceButton = ({ idpChoice }: { idpChoice: SamlIdPChoice }) => {
  const location = useLocation<LocationState>();
  const { searchParams } = useLocationParams(location);
  const redirect = useMemo(() => searchParams.get('redirect') || '', [searchParams]);
  const onClick = useCallback(() => {
    const url = `${API_URL}/sso/initiate-idp-login-uid?uid=${idpChoice.uid}&redirect=${redirect}`;
    window.location.assign(url);
  }, [idpChoice, redirect]);
  return (
    <Button onClick={onClick} type="primary">
      SSO Log In via {idpChoice.name}
    </Button>
  );
};

import { Dialog } from 'primereact/dialog';
import Button from '../../components/Button';
import { Field, Form, Formik, FormikProps } from 'formik';
import TextAreaAutoHeight from '../../components/TextAreaAutoHeight';
import { useCallback, useRef } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DataType } from '../api/annotation';
import { AnnotateModalState } from './Plot';
import useStorageTrack from '../hooks/useStorageTrack';

const DIALOG_WIDTH = 384; // pixels -- w-96

type FormValues = {
  title?: string;
  description?: string;
  applyToChannel?: boolean;
};

const formatXValue = (xValue?: number): string => {
  if (!xValue) {
    return '';
  }
  const date = new Date(Math.round(xValue));
  return date.toISOString();
};

interface AnnotateDataModalProps {
  annotateModal: AnnotateModalState;
  hideModal: () => void;
  refreshAnnotations: () => void;
}

const AnnotateDataModal = ({ annotateModal, hideModal, refreshAnnotations }: AnnotateDataModalProps) => {
  const { services } = useDatabaseServices();
  const storageTrack = useStorageTrack();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { xValue, clientX, clientY, channel, type, identifier, annotation, visible, isCreating } = annotateModal;

  const calculateModalLeftPosition = () => {
    return clientX + DIALOG_WIDTH > window.innerWidth ? clientX - DIALOG_WIDTH : clientX;
  };

  const Footer = ({ resetForm }: { resetForm: () => void }) => {
    if (!isCreating) {
      return (
        <div className="sm:flex sm:flex-row-reverse">
          <Button type="secondary" onClick={hideModal}>
            Close
          </Button>
        </div>
      );
    }
    return (
      <div className="sm:flex sm:flex-row-reverse gap-1 sm:gap-2">
        <Button type="primary" onClick={onCreateClick}>
          Create
        </Button>
        <Button type="secondary" onClick={() => toggleHideModal(resetForm)}>
          Cancel
        </Button>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className="flex justify-between items-center mb-2">
        <span className="text-left">{isCreating ? 'Annotate Data' : annotation?.title}</span>
        <span className="text-right text-sm text-gray-500">
          {xValue ? formatXValue(xValue) : annotation?.start_time}
        </span>
      </div>
    );
  };

  const onCreateClick = useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const onSubmit = useCallback(
    async (values, { resetForm }) => {
      const newAnnotation = {
        title: values.title,
        description: values.description,
        start_time: formatXValue(xValue),
        data_type: type,
        ...(values.applyToChannel && channel !== undefined && { channel }),
      };
      if (type === DataType.RunTelemetry) {
        newAnnotation['run_id'] = identifier;
      } else if (type === DataType.Upload) {
        newAnnotation['file_id'] = identifier;
      } else if (type === DataType.Parameter) {
        newAnnotation['dictionary_id'] = identifier;
      }

      await services.annotation.createAnnotation(newAnnotation).then((_res) => {
        refreshAnnotations();
        hideModal();
        resetForm();
        storageTrack('Annotation Created');
      });
    },
    [xValue, type, channel, services, identifier, refreshAnnotations, hideModal, storageTrack]
  );

  const onValidate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.title || values.title.trim() === '') {
      errors.title = 'Required';
    }
    return errors;
  };

  const toggleHideModal = (resetForm: () => void) => {
    resetForm();
    hideModal();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ title: '', description: '', applyToChannel: false }}
      onSubmit={onSubmit}
      validate={onValidate}
    >
      {({ errors, resetForm }) => (
        <Dialog
          header={Header}
          visible={visible}
          className="w-96"
          onHide={hideModal}
          footer={<Footer resetForm={resetForm} />}
          modal={false}
          closable={false}
          style={{
            position: 'fixed',
            left: `${calculateModalLeftPosition()}px`,
            top: `${clientY}px`,
          }}
          resizable={false}
        >
          <Form aria-label="Annotate Data">
            <div className="flex flex-col gap-2 w-full">
              {isCreating && (
                <div className="flex flex-col">
                  <label className="text-sm text-gray-500">Title</label>
                  <Field
                    aria-label="Title"
                    maxLength={255}
                    name="title"
                    type="text"
                    className=" border-1 border-gray-400 rounded"
                  />
                  {errors.title && <div className="text-sm text-red-700">{errors.title}</div>}
                </div>
              )}
              {isCreating && (
                <div className="flex flex-col">
                  <label className="text-sm text-gray-500">Description</label>
                  <Field id="description" type="text" name="description">
                    {({ field }) => <TextAreaAutoHeight defaultRows={2} aria-label="Description" {...field} />}
                  </Field>
                </div>
              )}
              {!isCreating && <div>{annotation?.description}</div>}
              {channel && isCreating && (
                <label className="cursor-pointer">
                  <Field type="checkbox" name="applyToChannel" className="cursor-pointer" />
                  <span className="ml-1 ">
                    Apply only to channel - <span className="italic">{channel}</span>
                  </span>
                </label>
              )}
              {annotation?.channel && !isCreating && (
                <div className="ml-1 ">
                  Channel - <span className="italic">{annotation?.channel}</span>
                </div>
              )}
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default AnnotateDataModal;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from './DateTimeDisplay';
import { getActionDescription, getColorForActionType, getIconForActionType } from '../lib/runUtil';
import { Link } from 'react-router-dom';
import { IssueType } from 'shared/lib/types/views/procedures';
import { issuePath } from '../lib/pathUtil';

// The text and link to render for an "issue" action type
const StepIssueAction = ({ action, issues, currentTeamId }) => {
  if (issues && issues[action.issue_id]) {
    const issue = issues[action.issue_id];

    return (
      <span>
        <span>{'Issue '}</span>
        {issue.type === IssueType.Jira && (
          <a href={issue.url} target="_blank" rel="noreferrer" className="link">
            {issue.text}
          </a>
        )}
        {issue.type === IssueType.Internal && currentTeamId && (
          <Link
            to={{ pathname: issuePath(currentTeamId, issue.id), state: { from: 'run' } }}
            title={`${issue.id} - ${issue.text}`}
            className="text-lg text-blue-600 hover:underline"
          >
            {`${issue.id} - ${issue.text}`}
          </Link>
        )}
        <span> </span>
      </span>
    );
  }

  return (
    <span>
      <span>{'Issue '}</span>
      <em>(Removed)</em>
      <span> </span>
    </span>
  );
};

const StepAction = ({ item, issues, currentTeamId }) => {
  const icon = getIconForActionType(item.type);
  const color = getColorForActionType(item.type);
  return (
    <div className="flex items-center my-1 relative">
      <div className={`rounded-full ${color} w-4 h-4 inline-flex items-center justify-center`}>
        <FontAwesomeIcon icon={icon} className="fa-fw text-xs text-gray-700" />
      </div>
      <div className="flex-grow ml-1" data-testid="stepActionsText">
        {item.type === 'issue' && <StepIssueAction issues={issues} action={item} currentTeamId={currentTeamId} />}
        <span>{getActionDescription(item)} by </span>
        <strong className="mr-1">
          {item.operator && ` ${item.operator}`.toUpperCase()} {item.user_id}
        </strong>
        {item.conditional_value && <span className="mr-1">with conditional value {item.conditional_value}</span>}
        <DateTimeDisplay timestamp={item.timestamp} />
      </div>
    </div>
  );
};

export default StepAction;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '../hooks/useAuth';
import { Redirect, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getPendingInvites, PendingInvite } from '../api/invites';
import apm from '../lib/apm';

const DEFAULT_NO_TEAM_TITLE_STR = 'Sorry, you have not been assigned to any workspaces.';
const DEFAULT_NO_TEAM_MESSAGE_STR = 'Please contact your administrator about workspace access.';

const NoTeamAccess = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const [invites, setInvites] = useState<Array<PendingInvite> | null>(null);
  const doLogout = async () => {
    await logout();
    history.replace({ pathname: '/login' });
  };

  // Check if user has pending invites
  useEffect(() => {
    if (invites === null) {
      (async () => {
        const pendingInvites = await getPendingInvites();
        setInvites(pendingInvites);
      })().catch((err) => apm.captureError(err));
    }
  }, [invites]);

  // User doesn't have any access, but they have pending invites, show them the first.
  if (invites && invites.length > 0) {
    const firstInvite = invites[0];
    const registerPath = `/register/${firstInvite.token}`;
    return <Redirect to={registerPath} />;
  }

  return (
    <div className="flex flex-col items-center justify-center space-y-6 my-2 absolute top-0 bottom-0 left-0 right-0">
      <div>
        <FontAwesomeIcon className="mr-2 text-blue-500 text-6xl" icon="user-lock" />
      </div>
      <div className="flex flex-col items-center text-2xl">
        <div className="text-xl">{DEFAULT_NO_TEAM_TITLE_STR}</div>
        <div className="">{DEFAULT_NO_TEAM_MESSAGE_STR}</div>
      </div>
      <div className="flex flex-col items-center text-2xl">
        <button className="py-3 btn" onClick={doLogout}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default NoTeamAccess;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageSidebar = ({ title, children, onClose, hasTopBuffer = false, hasTopDivider = true }) => {
  /*
   * The `top` value needs to be hard-coded because tailwind classes cannot be interpolated.
   * The value should be equal to REVIEW_STICKY_HEADER_HEIGHT_REM and the height of the Edit Toolbar.
   * See https://tailwindcss.com/docs/content-configuration#dynamic-class-names
   */
  return (
    <div
      className={`${
        hasTopBuffer ? 'top-[2.5rem]' : 'top-0'
      } bottom-0 fixed right-0 flex-0 overflow-y-auto w-72 bg-white print:hidden z-20`}
    >
      <div className={`fixed right-0 w-72 z-10 flex items-center h-14 bg-white ${hasTopDivider && 'border-b'}`}>
        <div className="grow font-semibold p-2">{title}</div>
        <div className="align-self-end mr-2">
          <button className="w-9 h-9 rounded hover:bg-gray-200" onClick={onClose}>
            <FontAwesomeIcon icon="times" className="text-black text-xl" />
          </button>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default PageSidebar;

import React, { useEffect, useState, useMemo } from 'react';
import Error from '../../components/Error';
import Select from 'react-select';
import { capitalize } from 'lodash';
import Tooltip from '../../elements/Tooltip';

const allOptions = [
  { value: 'planning', label: 'Planning' },
  { value: 'running', label: 'Running' },
  { value: 'paused', label: 'Paused' },
  { value: 'success', label: 'Success' },
  { value: 'abort', label: 'Abort' },
  { value: 'failure', label: 'Failure' },
  { value: 'completed', label: 'Completed' },
];

const StatusInput = ({ field, form }) => {
  const { setFieldValue } = form;
  const error = form.errors[field.name];
  const [isDisabled, setIsDisabled] = useState(false);
  const { start, run_id } = form.values;
  const [placeholder, setPlaceholder] = useState(field.value || 'planning');

  useEffect(() => {
    setPlaceholder(field.value || 'planning');
  }, [field.value]);

  useEffect(() => {
    if (!start && !run_id) {
      setIsDisabled(true);
      setFieldValue(field.name, 'planning');
    } else if (run_id) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [start, run_id, setFieldValue, field.name]);

  const handleSelect = (selectedOption) => {
    form.setFieldValue(field.name, selectedOption.value);
  };

  const options = useMemo(() => {
    if (form.values.start && !form.values.end) {
      return allOptions.filter((o) => !['running', 'paused'].includes(o.value));
    }
    return allOptions;
  }, [form.values.start, form.values.end]);

  return (
    <div className="flex flex-row items-center relative">
      <Tooltip
        content="Editing of event status is disabled with unscheduled events and events that are attached to a run"
        visible={isDisabled}
      >
        <Select
          placeholder={capitalize(placeholder)}
          className="w-48 max-w-prose  border-1 border-gray-400 rounded"
          onChange={handleSelect}
          options={options}
          isDisabled={isDisabled}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </Tooltip>
      {error && <Error text={error} />}
    </div>
  );
};

export default StatusInput;

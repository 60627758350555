import PartTopLabel from './PartTopLabel';

const PartLabel = ({ teamId, part }) => {
  const nameLabel = part?.name;

  return (
    <div className="p-1">
      <PartTopLabel teamId={teamId} part={part} />
      <div>{nameLabel}</div>
    </div>
  );
};

export default PartLabel;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Run } from 'shared/lib/types/views/procedures';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useRunContext } from '../contexts/RunContext';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import { VIEW_MODES } from './FieldSetViewModeEditSelect';
import Label from './Label';
import RunLabel from './RunLabel';
import RunHeaderActions from './StickyHeader/RunHeaderActions';
import BackToLink from './Toolbar/BackToLink';
import Divider from './Toolbar/Divider';
import LeftSide from './Toolbar/LeftSide';
import RightSide from './Toolbar/RightSide';
import Toolbar from './Toolbar/Toolbar';
import { runViewPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';

// TODO fill in these props
export type RunStickyHeaderProps = {
  run: Run;
  scrollTo;
  scrollToId;
  expandAll;
  collapseAll;
  setShowPauseModal;
  endRun;
  onReopenRun: (comment: string) => void;
  viewMode;
  setViewMode;
  projectId;
  isPreviewMode;
  onEdit;
  onReview;
  onAddIssue;
  onAutomationChange;
  backTo?: {
    title: string | React.ReactElement;
    sourceLink: string;
  };
};

export default function RunStickyHeader({
  run,
  scrollTo,
  scrollToId,
  expandAll,
  collapseAll,
  setShowPauseModal,
  endRun,
  onReopenRun,
  viewMode,
  setViewMode,
  projectId,
  isPreviewMode,
  onEdit,
  onReview,
  onAddIssue,
  onAutomationChange,
  backTo,
}: RunStickyHeaderProps) {
  const { mixpanel } = useMixpanel();
  const { isUserParticipant } = useRunContext();
  const { currentTeamId } = useDatabaseServices();

  // The below checks are used to determine if the user is in the preview mode of edit draft flow or review draft flow.
  const { url } = useRouteMatch();
  const isEditPreview = useMemo<boolean>(() => url.endsWith('/edit/run-preview'), [url]);
  const isReviewPreview = useMemo<boolean>(() => url.endsWith('/review/run-preview'), [url]);

  const bgStyle = isPreviewMode ? 'bg-cyan-50' : 'bg-gray-100';

  const op = useRef<OverlayPanel>(null);

  const handleChangeViewMode = useCallback(
    (viewMode) => {
      if (mixpanel) {
        mixpanel.track('View Mode Changed', { 'View Mode': viewMode });
      }
      setViewMode(viewMode);
    },
    [mixpanel, setViewMode]
  );

  const handlePauseClick = useCallback(
    (show: boolean) => {
      op?.current?.hide();
      setShowPauseModal(show);
    },
    [setShowPauseModal]
  );

  const qrCodeUrl = useMemo(() => {
    const baseUrl = window.location.origin;
    const path = runViewPath(currentTeamId, run._id);
    return `${baseUrl}${path}`;
  }, [currentTeamId, run]);

  return (
    <Toolbar bg={bgStyle} includeVerticalGap={false}>
      <LeftSide>
        {backTo && (
          <>
            <BackToLink name={backTo.title} to={backTo.sourceLink} />
            <Divider ml={3} />
          </>
        )}
        {isPreviewMode && (
          <Fragment>
            {isEditPreview && (
              <button
                className={stickyHeaderUtil.getButtonClassNames(false, false)}
                type="button"
                title="Edit Draft"
                disabled={false}
                onClick={() => onEdit('list')}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </button>
            )}
            {isReviewPreview && (
              <button
                className={stickyHeaderUtil.getButtonClassNames(false, false)}
                type="button"
                title="Review Draft"
                disabled={false}
                onClick={() => onReview('')}
              >
                <FontAwesomeIcon icon="clipboard" />
              </button>
            )}
            <button
              className={stickyHeaderUtil.getButtonClassNames(false, true)}
              type="button"
              title="Preview Procedure"
              aria-label="Preview Procedure"
              disabled={true}
            >
              <FontAwesomeIcon icon="eye" />
            </button>
            <button
              className={stickyHeaderUtil.getButtonClassNames(false, false)}
              type="button"
              title="Flow View"
              disabled={false}
              onClick={() => {
                if (isEditPreview) {
                  onEdit('chart');
                } else if (isReviewPreview) {
                  onReview('/flow-view');
                }
              }}
            >
              <FontAwesomeIcon icon="sitemap" />
            </button>
            <Divider ml={1} mr={2} />
            <Label text="Preview Run" color="bg-cyan-200" enableTruncate={false} />
          </Fragment>
        )}
        <button
          className={`${stickyHeaderUtil.getButtonClassNames(false, viewMode === VIEW_MODES.LIST)}`}
          type="button"
          title="Switch to List View"
          disabled={viewMode === VIEW_MODES.LIST}
          onClick={() => handleChangeViewMode(VIEW_MODES.LIST)}
        >
          <FontAwesomeIcon icon="list" />
        </button>
        <button
          className={stickyHeaderUtil.getButtonClassNames(false, viewMode === VIEW_MODES.SINGLE_CARD)}
          type="button"
          title="Switch to Single Card View"
          disabled={viewMode === VIEW_MODES.SINGLE_CARD}
          onClick={() => handleChangeViewMode(VIEW_MODES.SINGLE_CARD)}
          style={{}}
        >
          <FontAwesomeIcon icon="list-alt" />
        </button>
        <div className="flex flex-row truncate ">
          <div className="font-bold pr-1 mt-[2px]">
            <RunLabel code={run.code} runNumber={run.run_number} runNumberBg="medium" />
          </div>
          <div>{run.name}</div>
        </div>
      </LeftSide>

      <RightSide>
        <div className="lg:hidden h-full flex items-center">
          <button
            type="button"
            title="More actions"
            className={` ${stickyHeaderUtil.getButtonClassNames(false, false)} p-2`}
            aria-label="More actions"
            onClick={(e) => op?.current?.toggle(e)}
          >
            <FontAwesomeIcon fixedWidth={true} icon="ellipsis-v" />
          </button>
          <OverlayPanel ref={op} className="p-4">
            <RunHeaderActions
              run={run}
              scrollTo={scrollTo}
              scrollToId={scrollToId}
              expandAll={expandAll}
              collapseAll={collapseAll}
              setShowPauseModal={(show: boolean) => handlePauseClick(show)}
              endRun={endRun}
              onReopenRun={onReopenRun}
              viewMode={viewMode}
              projectId={projectId}
              isPreviewMode={isPreviewMode}
              onAddIssue={onAddIssue}
              onAutomationChange={onAutomationChange}
              isVertical={true}
              isUserParticipant={isUserParticipant}
              qrCodeUrl={qrCodeUrl}
            />
          </OverlayPanel>
        </div>

        <div className="lg:flex hidden">
          <RunHeaderActions
            run={run}
            scrollTo={scrollTo}
            scrollToId={scrollToId}
            expandAll={expandAll}
            collapseAll={collapseAll}
            setShowPauseModal={setShowPauseModal}
            endRun={endRun}
            onReopenRun={onReopenRun}
            viewMode={viewMode}
            projectId={projectId}
            isPreviewMode={isPreviewMode}
            onAddIssue={onAddIssue}
            onAutomationChange={onAutomationChange}
            isVertical={false}
            isUserParticipant={isUserParticipant}
            qrCodeUrl={qrCodeUrl}
          />
        </div>
      </RightSide>
    </Toolbar>
  );
}

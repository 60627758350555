import { Option } from 'react-select';
import { Severity, StatusType } from 'shared/lib/types/postgres/issues';
import { filterBySearchTerm } from '../../lib/gridUtils';
import { IssueRow } from '../types';

const getSeverityOptions = (
  severities: Array<Severity> | undefined
): Array<Option> => {
  if (!severities) {
    return [];
  }
  const options: Array<Option> = [];
  for (const severity of severities) {
    options.push({
      value: severity.id,
      label: severity.name,
    });
  }
  return options.sort((a, b) => (a.value > b.value ? -1 : 1));
};

export const countOpenIssues = (issues: Array<IssueRow>): number => {
  const openIssues = issues.filter(isOpenIssue);
  return openIssues.length;
};

export const countClosedIssues = (issues: Array<IssueRow>): number => {
  const closedIssues = issues.filter(isClosedIssue);
  return closedIssues.length;
};

export const isOpenIssue = (issue: IssueRow): boolean => {
  return isDraftIssue(issue) || isActiveIssue(issue);
};

export const isDraftIssue = (issue: IssueRow): boolean => {
  return issue.status.status === StatusType.Draft;
};

export const isActiveIssue = (issue: IssueRow): boolean => {
  return issue.status.status === StatusType.Active;
};

export const isClosedIssue = (issue: IssueRow): boolean => {
  return issue.status.status === StatusType.Closed;
};

export const search = (
  issues: Array<IssueRow>,
  searchTerm: string
): Array<IssueRow> => {
  return filterBySearchTerm({
    searchTerm,
    allData: issues,
    getStrings: (issue: IssueRow) => {
      const searchableTerms = [
        issue.title,
        issue.assignee,
        `${issue.id}`,
        issue.severity?.name,
        issue.referenceType,
        issue.projectName,
      ];
      return searchableTerms;
    },
  });
};

export const sortStatusColumn = (a: IssueRow, b: IssueRow): number => {
  let statusA = `${a.status.status}` || '';
  let statusB = `${b.status.status}` || '';
  if (a.status.status === StatusType.Closed) {
    statusA = a.subStatus?.value || 'closed';
    statusB = b.subStatus?.value || 'closed';
  }
  return statusA.localeCompare(statusB);
};

export default getSeverityOptions;

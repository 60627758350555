import React, { useCallback, useMemo, useState } from 'react';
import ButtonActionIcon from '../ButtonActionIcon';
import { ChangeEntry } from '../../lib/diffUtil';

/**
 * @param changes - An array of ids of items that have changed
 */
interface NavigationChangesProps {
  changes: Array<ChangeEntry>;
  scrollToDiff: (ChangeEntry) => void;
  isEnabled: boolean;
}

const NavigationChanges = ({ changes, scrollToDiff, isEnabled }: NavigationChangesProps) => {
  const [currentChangeIndex, setCurrentChangeIndex] = useState<null | number>(null);
  const hasChanges = useMemo(() => changes.length > 0, [changes.length]);
  const numChanges = useMemo(() => changes.length, [changes.length]);

  const currentChangeNumberDisplay = useMemo(() => {
    if (currentChangeIndex === null) {
      return '';
    }

    return `${currentChangeIndex + 1}/`;
  }, [currentChangeIndex]);

  const onGoToPrevious = useCallback(() => {
    let updatedIndex = currentChangeIndex;
    if (updatedIndex === null) {
      updatedIndex = changes.length - 1; // For the very first click, set the index to the last change index.
    } else {
      updatedIndex = updatedIndex === 0 ? changes.length - 1 : updatedIndex - 1;
    }
    const changeEntry = changes[updatedIndex];
    setCurrentChangeIndex(updatedIndex);
    scrollToDiff(changeEntry);
  }, [changes, currentChangeIndex, scrollToDiff]);

  const onGoToNext = useCallback(() => {
    let updatedIndex = currentChangeIndex;
    if (updatedIndex === null) {
      updatedIndex = 0; // For the very first click, set the index to the first change index.
    } else {
      updatedIndex = (updatedIndex + 1) % changes.length;
    }
    const changeEntry = changes[updatedIndex];
    setCurrentChangeIndex(updatedIndex);
    scrollToDiff(changeEntry);
  }, [changes, currentChangeIndex, scrollToDiff]);

  return (
    <div className="flex items-center gap-x-2 rounded">
      <span className={`whitespace-nowrap  ${isEnabled ? '' : 'text-gray-400'} `}>
        {currentChangeNumberDisplay}
        {numChanges} Change{numChanges !== 1 && 's'}
      </span>
      <span className="flex items-center">
        <ButtonActionIcon
          ariaLabel="Go To Previous Change"
          icon="angle-up"
          onAction={onGoToPrevious}
          disabled={!isEnabled || !hasChanges}
          size="xl"
        />
        <ButtonActionIcon
          ariaLabel="Go To Next Change"
          icon="angle-down"
          onAction={onGoToNext}
          disabled={!isEnabled || !hasChanges}
          size="xl"
        />
      </span>
    </div>
  );
};

export default NavigationChanges;

import React, { useState, useMemo, useCallback, useRef, MouseEvent } from 'react';
import { Field, getIn } from 'formik';
import validateUtil from '../../lib/validateUtil';
import TextAreaAutoHeight from '../TextAreaAutoHeight';
import { ExpressionToken } from 'shared/lib/types/views/procedures';
import { TypedFieldSetProps } from '../Blocks/BlockTypes';
import ReferenceSelectMenu from './ReferenceSelectMenu';
import FieldSetCheckbox from '../FieldSetCheckbox';
import expression, { ALLOWED_EXPRESSION_REFERENCE_SUBTYPES } from '../../lib/expression';
import { useRunContext } from '../../contexts/RunContext';
import SelectTableCellModal from './SelectTableCellModal';
import useReferenceTokens from '../../hooks/useReferenceTokens';

const ExpressionEditPlainText = ({
  setFieldValue,
  path,
  content,
  contentErrors,
  disabledFields,
  pendingStep,
  precedingStepId,
}: TypedFieldSetProps<'expression'>) => {
  const { isRun } = useRunContext();
  const [menuState, setMenuState] = useState({ isMenuOpen: false });
  const [parseError, setParseError] = useState(expression.validate(content.tokens));

  const menuRef = useRef(false);

  const isSummaryHidden = useMemo(() => getIn(disabledFields, 'include_in_summary'), [disabledFields]);

  const onReferencesChanged = useCallback(
    (updatedTokens: Array<ExpressionToken>) => {
      setParseError(expression.validate(updatedTokens));
    },
    [setParseError]
  );
  const setField = (updatedTokens) => {
    const parseError = expression.validate(updatedTokens);
    setFieldValue && setFieldValue(`${path}.tokens`, updatedTokens);
    setParseError(parseError);
  };
  const validateField = (updatedTokens) => {
    setParseError(expression.validate(updatedTokens));
  };

  const closeMenuHandler = useCallback(() => {
    menuRef.current = false;
    setMenuState(() => {
      return { isMenuOpen: false };
    });
  }, []);

  const {
    inputRef,
    selectionStartRef,
    selectionEndRef,
    tokens,
    setIsAutocomplete,
    onSetField,
    onValidateField,
    referenceOptions,
    selectedTable,
    setSelectedTable,
    onSelectReference,
    onSelectTableCellReference,
  } = useReferenceTokens<HTMLInputElement>({
    initialTokens: content.tokens,
    block: content,
    onReferencesChanged,
    setField,
    validateField,
    pendingStep,
    precedingStepId,
    allowedReferenceSubTypes: ALLOWED_EXPRESSION_REFERENCE_SUBTYPES,
    selectReferenceCallback: closeMenuHandler,
  });

  const documentClickHandler = useCallback(() => {
    document.removeEventListener('click', documentClickHandler);
    if (menuRef.current) {
      closeMenuHandler();
      onSetField();
    }
  }, [closeMenuHandler, onSetField]);

  const openMenuHandler = useCallback(
    (fromAutocomplete = false) => {
      if (inputRef && inputRef.current) {
        selectionStartRef.current = inputRef.current.selectionStart;
        selectionEndRef.current = inputRef.current.selectionEnd;
      }
      menuRef.current = true;
      setIsAutocomplete(fromAutocomplete);
      setMenuState(() => {
        document.addEventListener('click', documentClickHandler);
        return { isMenuOpen: true };
      });
    },
    [documentClickHandler, inputRef, selectionEndRef, selectionStartRef, setIsAutocomplete]
  );

  const handleAddReferenceClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openMenuHandler();
  };

  const handleBlur = () => {
    // Interacting with the reference menu with the mouse should not trigger a field update
    if (!menuState.isMenuOpen) {
      onSetField();
    }
  };

  const DisplayToken = ({ token }: { token: ExpressionToken }) => {
    if (token.type === 'reference' && token.reference_id) {
      return (
        <div className="font-medium text-sm px-1.5 py-0.5 my-1 rounded-xl bg-slate-300">
          {expression.getNamespacedTokenText({
            token,
            options: referenceOptions,
          })}
        </div>
      );
    } else {
      return <div className="py-1 mx-0.5 ">{token.value}</div>;
    }
  };

  const Preview = () => {
    return (
      <div className="flex flex-col">
        <div>
          <div data-testid="formula-preview" className="flex flex-row items-center flex-wrap">
            {tokens && tokens.length > 0 ? (
              tokens.map((token, index) => <DisplayToken key={index} token={token} />)
            ) : (
              <div className=" h-7"></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ErrorDisplay = () => {
    let errorMessage = '';
    let errorDetail = '';
    if (contentErrors) {
      if (contentErrors.formula) {
        errorMessage = contentErrors.formula;
      } else if (contentErrors.parse) {
        errorMessage = 'Expression Error';
        errorDetail = contentErrors.parse;
      } else if (contentErrors.cyclic) {
        errorMessage = contentErrors.cyclic;
      }
    } else if (parseError) {
      errorMessage = 'Expression Error';
      errorDetail = parseError;
    }
    if (errorMessage) {
      return (
        <span title={errorDetail} className="ml-2 text-sm font-medium text-red-700">
          {errorMessage}
        </span>
      );
    }
    return <></>;
  };

  return (
    <div className="flex flex-wrap w-full">
      <SelectTableCellModal
        selectedTable={selectedTable}
        isVisible={Boolean(selectedTable)}
        onClose={() => {
          setSelectedTable(null);
        }}
        onSelectCell={onSelectTableCellReference}
      />
      <div className="flex flex-col mr-2">
        <div>
          <span className="field-title">Expression</span>
        </div>
        <Field name={path ? `${path}.name` : 'name'} validate={validateUtil.validateFieldInputName}>
          {({ field }) => (
            <>
              <TextAreaAutoHeight
                aria-label="Expression name"
                placeholder="Expression name*"
                disabled={getIn(disabledFields, 'name') ? true : null}
                {...field}
                style={{ minWidth: '20rem' }}
              />
              {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
            </>
          )}
        </Field>
      </div>

      <div className="flex grow flex-col">
        <div>
          <span className="field-title">Formula</span>
          <ErrorDisplay />
        </div>
        <div className="flex-row">
          <div
            data-testid="formula-builder-field"
            className="flex flex-row bg-white border border-gray-400 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600 rounded p-0.5 w-full items-center relative"
          >
            <div>
              {menuState.isMenuOpen && (
                <ReferenceSelectMenu
                  onSelect={onSelectReference}
                  close={closeMenuHandler}
                  menuOptions={referenceOptions}
                />
              )}
            </div>
            <input
              aria-label="Formula"
              ref={inputRef}
              className="outline-none w-full py-1 px-2 "
              placeholder="Formula*"
              onChange={onValidateField}
              onBlur={handleBlur}
              defaultValue={expression.tokensToRawText(content.tokens, referenceOptions)}
            />
            <div>
              <button
                type="button"
                className="bg-blue-500 text-white text-xl font-semibold tracking-wide uppercase whitespace-nowrap px-2 py-0.5 rounded"
                onClick={handleAddReferenceClick}
                title="Insert reference"
              >
                +
              </button>
            </div>
          </div>
          <Preview />
        </div>
      </div>
      {/* Include in summary checkbox */}
      <div className={`ml-2 mb-9 self-end flex flex-row ${isSummaryHidden ? 'hidden' : ''}`}>
        <FieldSetCheckbox
          text="Include in Summary"
          fieldName={path ? `${path}.include_in_summary` : 'include_in_summary'}
          setFieldValue={setFieldValue}
          isDisabled={isRun}
        />
      </div>
    </div>
  );
};

export default React.memo(ExpressionEditPlainText);

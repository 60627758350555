import React, { Fragment, useEffect, useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'formik';
import { isEmptyValue } from 'shared/lib/text';
import MarkdownView from '../Markdown/MarkdownView';
import { ReactComponent as Checked } from '../../images/checked-checkbox.svg';
import tableUtil from 'shared/lib/tableUtil';
import Selector from '../../elements/Selector';
import Tooltip from '../../elements/Tooltip';
import { useRunContext } from '../../contexts/RunContext';

const TableInputCheckbox = React.memo(({ isDisabled, value, name, setFieldValue, onBlur }) => {
  /**
   * Using onChange instead of onBlur for checkboxes because
   * of a known bug with safari.
   */
  const setCheckedValue = useCallback(
    (event) => {
      const value = event.target.checked;

      // Update recorded value.
      setFieldValue(name, value);
      onBlur(name, value);
    },
    [name, onBlur, setFieldValue]
  );

  return (
    <div>
      {/* Do not use Field if the checkbox is disabled, because rendering Field is expensive. */}
      {isDisabled && (
        <>
          {value && <Checked title="checked" className="w-6 h-6" />}
          {!value && (
            <div className="w-6 h-6 m-auto text-gray-500 border-2 border-gray-500 rounded-sm" title="not checked" />
          )}
        </>
      )}
      {!isDisabled && (
        <Field
          name={name}
          aria-label="Cell Checkbox"
          type="checkbox"
          className={`w-6 h-6 m-auto text-gray-500 border-2 border-gray-500 rounded-sm ${
            value ? '' : 'disabled:bg-gray-200'
          }`}
          onChange={setCheckedValue}
        />
      )}
    </div>
  );
});

const TableInputLabel = React.memo(({ label }) => (
  <div className="m-auto pl-2 ml-1  font-medium text-gray-600 text-right">{label}</div>
));

const TableInputTextField = React.memo(
  ({ isDisabled, name, type, onBlur, label, value, error, setFieldValue, evaluatedExpression }) => {
    const [state, setState] = useState({
      active: false,
      value,
    });

    useEffect(() => {
      if (state.active) {
        return;
      }
      setState({
        active: false,
        value,
      });
    }, [value, state.active]);

    const validateValue = useCallback(
      (e) => {
        let value = e.target.value;

        if (type === 'number') {
          /**
           * Store values as strings so we preserve number of significant digits.
           * However, trim any leading and trailing whitespace since Number allows it.
           */
          if (tableUtil.isValidNumber(value)) {
            value = value.trim();
          }
        }

        return value;
      },
      [type]
    );

    const handleOnBlur = useCallback(
      (e) => {
        const _value = validateValue(e);

        setState({
          value: _value,
          active: false,
        });

        // Update recorded value.
        setFieldValue(name, _value);
        onBlur(name, _value);
      },
      [name, setFieldValue, onBlur, validateValue]
    );

    const handleOnChange = useCallback((e) => {
      setState((state) => ({
        ...state,
        value: e.target.value,
      }));
    }, []);

    const handleOnFocus = useCallback(() => {
      setState((state) => ({
        ...state,
        active: true,
      }));
    }, []);

    if (type === 'expression') {
      return (
        <Tooltip visible={true} content={value}>
          <div>
            {!isEmptyValue(evaluatedExpression) ? evaluatedExpression : <span className="text-gray-400">Pending</span>}
          </div>
        </Tooltip>
      );
    }

    if (isDisabled) {
      return (
        <Fragment>
          <div className="h-full w-full  px-3 py-2 flex items-center">
            <div className="flex flex-row h-full w-full items-center">
              {/* If there is no text, add a height to the div to prevent cell height change */}
              {isEmptyValue(value) && <span className="h-5"></span>}
              {!isEmptyValue(value) && <MarkdownView text={`${value}`} />}
            </div>

            {label && <TableInputLabel label={label} />}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className={`flex flex-row h-full w-full items-center ${label ? 'pr-3' : ''}`}>
            {type === 'text' && (
              <div className="flex flex-col h-full grow min-w-0">
                <Field name={name}>
                  {({ field }) => (
                    <TextareaAutosize
                      data-testid="text-area"
                      className="grow  border border-transparent resize-none"
                      {...field}
                      onBlur={handleOnBlur}
                      onChange={handleOnChange}
                      onFocus={handleOnFocus}
                      value={state.value}
                    />
                  )}
                </Field>
              </div>
            )}

            {type === 'number' && (
              <Field
                name={name}
                type="text"
                className={`grow min-w-0  border ${
                  error ? 'border-red-700 bg-red-100 bg-opacity-50' : 'border-transparent'
                }`}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                value={state.value}
              />
            )}

            {label && <TableInputLabel label={label} />}
          </div>
        </Fragment>
      );
    }
  }
);

const RunFieldSetTableCell = React.memo(
  ({ name, type, label, options, onBlur, value, isDisabled, error, setFieldValue, evaluatedExpression }) => {
    const { isRun, isPreviewMode } = useRunContext();

    return (
      <div className="flex flex-col w-full h-full justify-center items-center">
        {type === 'checkbox' && (
          /*
           * Wrap the label around the checkbox and the following text in order to make the entire cell clickable to toggle the check.
           * Ensure minimum clickable area of 44pt x 44pt on mobile.
           */
          <label className="flex w-full h-full justify-center items-center">
            <TableInputCheckbox
              isDisabled={isDisabled}
              name={name}
              value={value}
              setFieldValue={setFieldValue}
              onBlur={onBlur}
            />
          </label>
        )}
        {type === 'list' && (
          <Field name={name}>
            {({ field }) => (
              <Selector
                name={field.name}
                type="table-cell"
                description="List Value"
                onChangeHandler={(option) => {
                  onBlur(name, option.value);
                }}
                showOptionsWhenDisabled={!(isRun || isPreviewMode)}
                selectOptions={(options ?? []).map((option) => ({
                  label: option,
                  value: option,
                }))}
                selectedOption={{
                  label: value,
                  value,
                }}
                isDisabled={isDisabled}
              />
            )}
          </Field>
        )}
        {!['checkbox', 'list'].includes(type) && (
          <TableInputTextField
            isDisabled={isDisabled}
            name={name}
            type={type}
            label={label}
            value={value}
            error={error}
            setFieldValue={setFieldValue}
            onBlur={onBlur}
            evaluatedExpression={evaluatedExpression}
          />
        )}
      </div>
    );
  }
);

export default RunFieldSetTableCell;

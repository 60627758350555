import { Fragment, useMemo } from 'react';
import { AttachmentDisplayStyle, AttachmentSize } from './FieldSetAttachment';
import AnnotationEditor from './Annotation/AnnotationEditor';
import { AttachmentImageDisplayStyle, AttachmentImageSize } from './Blocks/BlockTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AttachmentBlock } from 'shared/lib/types/views/procedures';

type ImageSizeStyle = {
  maxHeight?: string;
  maxWidth?: string;
};

export type InlineImageContentAttachmentBlock = Pick<
  AttachmentBlock,
  'display_style' | 'size' | 'name' | 'content_type'
>;

interface InlineImageContentProps {
  attachment: InlineImageContentAttachmentBlock;
  url?: string;
  onSave?: (file: File) => void;
  onClose?: () => void;
  isAnnotating?: boolean;
}

const InlineImageContent = ({ attachment, url, onSave, onClose, isAnnotating = false }: InlineImageContentProps) => {
  const isToTheSide = useMemo(() => {
    return (attachment.display_style as AttachmentDisplayStyle) === AttachmentImageDisplayStyle.ToTheSide;
  }, [attachment]);

  const image = useMemo(() => {
    const img = new window.Image();
    if (url) {
      img.src = url;
    }
    return img;
  }, [url]);

  /**
   * Generates direct CSS style for image display.
   *
   * Tailwind doesn't have great options for max width, so we make our own.
   * "Small" applies a max width and height of a thumbnail size. "BestFit"
   * applies a max width and height of a size about what we want the "content
   * well width" to be in the future. Original applies no constraints.
   */
  const getSizeStyle = (element: 'img' | 'div'): ImageSizeStyle | undefined => {
    const attachmentSize = attachment.size;
    if (
      !attachment ||
      !attachment.size ||
      (!isToTheSide && (attachmentSize as AttachmentSize) === AttachmentImageSize.Original)
    ) {
      return {};
    }
    /**
     * Using min(100%, breakpoint) fixes CSS grid blowout issue where image width
     * blows out the other columns.
     */
    if (isToTheSide || attachmentSize === AttachmentImageSize.BestFit) {
      // 768px is based on max-w-screen-md pixel value from tailwind.
      return {
        maxHeight: '768px',
        maxWidth: element === 'img' ? 'min(100%, 768px)' : '768px',
      };
    }
    if (attachmentSize === AttachmentImageSize.Small) {
      // Size to match attachment as comment image thumbnail.
      return {
        maxHeight: '8rem',
        maxWidth: element === 'img' ? 'min(100%, 8rem)' : '8rem',
      };
    }
  };

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOnSave = (file: File) => {
    if (onSave) {
      onSave(file);
    }
  };

  const attachmentName: string = useMemo(() => attachment?.name ?? '', [attachment]);
  const contentType: string = useMemo(() => attachment.content_type ?? '', [attachment]);

  const download = () => {
    if (!url) {
      return;
    }

    const a = document.createElement('a');
    a.href = url;
    a.download = attachmentName || 'download';
    a.click();
  };

  // Wrap img in plain div to fix nested flexbox issue on Safari
  return (
    <div className="relative" style={getSizeStyle('div')}>
      <a target="_blank" rel="noreferrer" href={url}>
        <img src={url} style={getSizeStyle('img')} alt={attachmentName} />
      </a>
      {/* Radial gradiant shadow background with no click handling */}
      <Fragment>
        <div className="absolute top-0 right-0 overflow-hidden pointer-events-none">
          <div className="w-20 h-20 rounded-full translate-x-10 -translate-y-10 bg-gradient-radial from-gray-800 to-transparent" />
        </div>
        <button
          className="absolute top-0 right-0 p-1 group"
          onClick={() => {
            download();
          }}
          title={attachmentName && `Download ${attachmentName}`}
          aria-label={attachmentName && `Download ${attachmentName}`}
        >
          <FontAwesomeIcon icon="cloud-download-alt" className="text-xl text-white group-hover:text-gray-100" />
        </button>
      </Fragment>
      {isAnnotating && (
        <div
          aria-label="Annotation Editor"
          role="region"
          className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-60 z-200 p-5 "
        >
          <AnnotationEditor
            onClose={handleOnClose}
            onSave={handleOnSave}
            mimeType={contentType}
            image={image}
            name={attachmentName || ''}
          />
        </div>
      )}
    </div>
  );
};

export default InlineImageContent;

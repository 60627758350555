import React, { useMemo } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import { FieldInputProps, FormikProps } from 'formik';
import TagsSelector from './TagsSelector';
import { Tag } from 'shared/lib/types/couch/settings';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import apm from '../lib/apm';
import { useSettings } from '../contexts/SettingsContext';

interface ProcedureSettingsTagSelectorProps {
  value: Array<Tag>;
  field: FieldInputProps<string>;
  form: FormikProps<string>;
}

const ProcedureSettingsTagSelector = React.memo(({ value, field, form }: ProcedureSettingsTagSelectorProps) => {
  const { mixpanel } = useMixpanel();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tags } = useSettings();
  const onSaveTags = async (tags) => {
    if (mixpanel) {
      mixpanel.track('Change Procedure Tags');
    }

    if (services.settings) {
      await services.settings.saveTags(tags).catch((err) => apm.captureError(err));
    }

    form.setFieldValue(field.name, tags);
  };

  const globalTags = useMemo(() => {
    if (tags && tags.tags) {
      return Object.values(tags.tags);
    }
    return [];
  }, [tags]);

  return (
    <div className="grow w-full">
      <div className="grow relative ">
        <div>
          <TagsSelector
            tags={value}
            globalTags={globalTags}
            onSaveTags={onSaveTags}
            isDisabled={false}
            didTagsChange={false}
          />
        </div>
      </div>
    </div>
  );
});

export default ProcedureSettingsTagSelector;

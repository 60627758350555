import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { reactSelectStyles } from '../lib/styles';
import { useMixpanel } from '../contexts/MixpanelContext';

export const VIEW_MODES = {
  LIST: 'LIST_CARDS',
  SINGLE_CARD: 'SINGLE_CARD',
};

export const VIEW_MODE_LABELS = {
  [VIEW_MODES.LIST]: 'List',
  [VIEW_MODES.SINGLE_CARD]: 'Single Card',
};

export const DEFAULT_VIEW_MODE_DEFAULT = VIEW_MODES.LIST;

/**
 * Formik custom select component that allows users to set the default view
 * mode for the procedure in run.
 *
 * @param {String} value - Current default view mode
 * @param {Object} field - Formik field.
 * @param {Object} form - Formik form.
 */
const FieldSetViewModeEditSelect = React.memo(({ value, field, form }) => {
  const { mixpanel } = useMixpanel();

  const options = useMemo(
    () =>
      Object.keys(VIEW_MODE_LABELS).map((key) => ({
        value: key,
        label: VIEW_MODE_LABELS[key],
      })),
    []
  );

  // Look for project that matches project key from the saved procedure document.
  const selectValue = useMemo(() => {
    if (!value) {
      return {
        value: DEFAULT_VIEW_MODE_DEFAULT,
        label: VIEW_MODE_LABELS[DEFAULT_VIEW_MODE_DEFAULT],
      };
    }
    return {
      value,
      label: VIEW_MODE_LABELS[value],
    };
  }, [value]);

  const onChangeHandler = useCallback(
    (option) => {
      // Skip onChange when re-selecting the same value.
      if (value === option.value) {
        return;
      }

      if (mixpanel) {
        mixpanel.track('Default View Mode Changed', { 'View Mode': option.value });
      }

      form.setFieldValue(field.name, option.value);
    },
    [value, form, field.name, mixpanel]
  );

  return (
    <div className="grow w-full">
      <div className="grow relative ">
        <div>
          <Select
            classNamePrefix="react-select"
            name={field.name}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            options={options}
            styles={reactSelectStyles}
            value={selectValue}
          />
        </div>
      </div>
    </div>
  );
});

export default FieldSetViewModeEditSelect;

import Toggle from 'react-toggle';
import { Fragment, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react-markdown';
import Button from './Button';

interface VersionCompareProps {
  label: string;
  onAcceptLatest: () => void;
  isCurrentLatest: boolean;
  currentVersion: ReactNode;
  latestVersion: ReactNode;
  isAcceptDisplayed?: boolean;
  isAcceptEnabled?: boolean;
  showLabel?: boolean;
}

const VersionCompare = ({
  label,
  onAcceptLatest,
  isCurrentLatest,
  currentVersion,
  latestVersion,
  isAcceptDisplayed = true,
  isAcceptEnabled = true,
  showLabel = true,
}: VersionCompareProps) => {
  const [isToggledToLatest, setIsToggledToLatest] = useState(true);

  const onChangeToggle = useCallback(() => {
    setIsToggledToLatest((value) => !value);
  }, []);

  return (
    <>
      {isCurrentLatest && currentVersion}
      {!isCurrentLatest && (
        <div className="flex flex-col gap-y-2 py-2 px-3 mb-2 w-fit rounded border bg-blue-50 border-blue-100">
          <div className="flex gap-x-2 justify-between items-center  mb-1">
            {showLabel && (
              <div className="flex flex-row gap-x-1 items-center text-red-600">
                <FontAwesomeIcon icon="exclamation-circle" />
                <span>{label} has changes</span>
              </div>
            )}
            {!showLabel && <div />}
            <div className="flex flex-row gap-x-2 items-center">
              <span className={`${isToggledToLatest ? 'text-gray-400' : 'text-gray-700'}`}>Current Version</span>
              <Toggle
                checked={isToggledToLatest}
                onChange={onChangeToggle}
                icons={false}
                aria-label={`${label} Version Toggle`}
              />
              <span className={`${isToggledToLatest ? 'text-gray-700' : 'text-gray-400'}`}>Latest Version</span>
            </div>
          </div>

          {!isToggledToLatest ? (
            <Fragment key={isToggledToLatest ? 'current-hidden' : 'current-visible'}>{currentVersion}</Fragment>
          ) : (
            <Fragment key={isToggledToLatest ? 'latest-visible' : 'latest-hidden'}>{latestVersion}</Fragment>
          )}

          {isAcceptDisplayed && (
            <div className="flex justify-end  mb-1">
              <div className="space-x-4">
                <Button
                  type="secondary"
                  isDisabled={!isToggledToLatest || !isAcceptEnabled}
                  onClick={onAcceptLatest}
                  leadingIcon="check"
                >
                  Accept Changes
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VersionCompare;

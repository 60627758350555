import { Field, FormikHelpers, FormikValues } from 'formik';
import { RangeError } from '../lib/types';
import FieldSetMathOperation from './FieldSetMathOperation';
import { useCallback } from 'react';

interface FieldSetRangeProps {
  path: string;
  isDisabled: boolean;
  rangeError?: RangeError;
  onChange: (value: string, path: string) => void;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

// Renders fields for range min and max
const FieldSetRange = ({ path, isDisabled, rangeError, onChange, setFieldValue }: FieldSetRangeProps) => {
  const onChangeRangeOp = useCallback(
    (value, path) => {
      if (value === '<') {
        setFieldValue(path, false);
      }
      if (value === '≤') {
        setFieldValue(path, true);
      }

      return;
    },
    [setFieldValue]
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-nowrap items-center gap-x-2">
        <div className="flex flex-col">
          <span className="field-title">Minimum</span>
          <Field name={`${path}.range.min`} validate={(value) => onChange(value, `${path}.range.min`)}>
            {({ field }) => (
              <input
                {...field}
                type="text"
                disabled={isDisabled}
                placeholder="Min"
                className="w-20  border-1 border-gray-400 rounded disabled:bg-gray-300"
              />
            )}
          </Field>
        </div>
        <Field name={`${path}.range.include_min`}>
          {({ field }) => (
            <FieldSetMathOperation
              value={field.value ? '≤' : '<'}
              description="Min Op"
              operationOptions={['<', '≤']}
              isDisabled={isDisabled}
              onChange={(option) => onChangeRangeOp(option.value, field.name)}
            />
          )}
        </Field>
        <span className="mt-2 font-medium">(input)</span>
        <Field name={`${path}.range.include_max`}>
          {({ field }) => (
            <FieldSetMathOperation
              value={field.value ? '≤' : '<'}
              description="Max Op"
              operationOptions={['<', '≤']}
              isDisabled={isDisabled}
              onChange={(option) => onChangeRangeOp(option.value, field.name)}
            />
          )}
        </Field>
        <div className="flex flex-col">
          <span className="field-title">Maximum</span>
          <Field name={`${path}.range.max`} validate={(value) => onChange(value, `${path}.range.max`)}>
            {({ field }) => (
              <input
                {...field}
                type="text"
                disabled={isDisabled}
                placeholder="Max"
                className="w-20  border-1 border-gray-400 rounded disabled:bg-gray-300"
              />
            )}
          </Field>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        {rangeError?.min && <div className="text-red-700">{rangeError.min}</div>}
        {!rangeError?.min && rangeError?.max && <div />} {/* To ensure the error for the max is always right-aligned */}
        {rangeError?.max && <div className="text-red-700">{rangeError.max}</div>}
      </div>
    </div>
  );
};

export default FieldSetRange;

import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStepTimer from '../FieldStepTimer';
import FieldStepDuration from '../FieldStepDuration';
import { Duration } from 'luxon';
import ReviewDependencies from './ReviewDependencies';
import ProcedureFieldDiff from '../ProcedureFieldDiff';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import signoffUtil from 'shared/lib/signoffUtil';
import { isEmpty } from 'lodash';
import { DiffArrayChangeSymbol, SignoffDiffElement } from 'shared/lib/types/views/procedures';

type SignoffChange = {
  value: string;
  change: DiffArrayChangeSymbol;
};

const getSignoffChanges = (signoffs: Array<SignoffDiffElement>): Array<SignoffChange> => {
  return signoffs.flatMap((signoff) =>
    signoff.operators.map((operator) => {
      const oldOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'old');
      const newOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'new');

      const isAdded =
        signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED || (isEmpty(oldOperator) && !isEmpty(newOperator));
      const isRemoved =
        signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED || (isEmpty(newOperator) && !isEmpty(oldOperator));
      const operatorLabel = isRemoved ? oldOperator ?? '' : newOperator ?? '';
      return {
        value: operatorLabel,
        change: isAdded
          ? ARRAY_CHANGE_SYMBOLS.ADDED
          : isRemoved
          ? ARRAY_CHANGE_SYMBOLS.REMOVED
          : ARRAY_CHANGE_SYMBOLS.UNCHANGED,
      };
    })
  );
};

const OperatorDisplay = ({ signoffChange, isLast }: { signoffChange: SignoffChange; isLast: boolean }) => {
  let style = '';

  if (signoffChange.change === ARRAY_CHANGE_SYMBOLS.REMOVED) {
    style = 'bg-red-100 text-red-600 line-through';
  }
  if (signoffChange.change === ARRAY_CHANGE_SYMBOLS.ADDED) {
    style = 'bg-emerald-100 text-emerald-800';
  }
  return (
    <div className="pl-1">
      <div className={style}>
        {signoffChange.value}
        {!isLast && ','}
      </div>
    </div>
  );
};

const ReviewProcedureStepBanner = ({
  step,
  hasDependencies,
  onRefChanged,
  scrollMarginTopValueRem,
  updateStepDetail,
  baseRunningCondition,
  isStepActive,
}) => {
  const bgAndBorderColor = useMemo(() => {
    return 'bg-white border-x-2 border-t-2 border-app-gray-400';
  }, []);

  const timerMessage = useCallback(
    (getNew = true) => {
      if (!step.timer) {
        return '';
      }

      let output = 'Start Timer';
      if (step.timer.completed) {
        output = 'Timer Elapsed';
      } else if (step.timer.started_at) {
        output = 'Timer Running';
      }

      let timeLeft = step.timer.time_left;
      if (!timeLeft) {
        return output;
      }

      if (getNew) {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'new');
      } else {
        timeLeft = sharedDiffUtil.getDiffValue(step.timer, 'time_left', 'old');
      }

      const formattedTimeLeft = Duration.fromISO(timeLeft).toFormat('hh:mm:ss');
      return `${output} (${formattedTimeLeft})`;
    },
    [step.timer]
  );

  const signoffsNeeded = useMemo(() => {
    const incompleteSignoffs = signoffUtil.getIncompleteSignoffs(step);
    const operatorChangeList = getSignoffChanges(incompleteSignoffs).flatMap((changes) => changes);

    return (
      <div className="flex flex-row flex-nowrap gap-x-2 items-center">
        {incompleteSignoffs && (
          <div className="flex flex-row flex-nowrap gap-x-1 text-sm items-center">
            <FontAwesomeIcon icon="headset" />
            <div className="text-gray-600">Needs</div>
            <div className="flex flex-row font-semibold">
              {operatorChangeList.map((signoffChange, index) => (
                <OperatorDisplay
                  key={index}
                  signoffChange={signoffChange}
                  isLast={index === operatorChangeList.length - 1}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }, [step]);

  return (
    <tbody
      data-testid="header"
      className={`${bgAndBorderColor} shadow z-0`}
      ref={onRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      aria-label="Step Header"
      role="region"
    >
      <tr>
        <td colSpan={3}>
          <div className="flex flex-col">
            <div className="py-2 px-2 w-full flex flex-row justify-between text-sm items-center gap-x-1">
              <div className="flex flex-row flex-grow items-center">
                {step.requires_previous && (
                  <span className="ml-4 mr-3 font-semibold text-sm text-gray-600 whitespace-nowrap">
                    Requires Previous Step
                  </span>
                )}
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon icon="stopwatch" />
                    <div className="ml-1 text-gray-600">{step.duration.started_at ? 'Step Started' : 'Start Step'}</div>
                  </div>
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon icon="hourglass-start" />
                    <div className="ml-1 mr-3 text-gray-600">
                      <ProcedureFieldDiff original={timerMessage()} redlined={timerMessage(false)} />
                    </div>
                  </div>
                )}
                {hasDependencies && (
                  <div className="ml-2">
                    <ReviewDependencies dependencies={step.dependencies} blockId={step.id} />
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center">
                {step['duration'] && typeof step['duration'] === 'object' && (
                  <FieldStepDuration
                    value={step['duration']}
                    disabled={!baseRunningCondition}
                    hideStartButton={typeof step['timer'] === 'object'}
                    updateStepDetail={updateStepDetail}
                    isPaused={!isStepActive}
                  />
                )}
                {step['timer'] && typeof step['timer'] === 'object' && (
                  <FieldStepTimer
                    value={step['timer']}
                    disabled={!baseRunningCondition}
                    updateStepDetail={updateStepDetail}
                  />
                )}
              </div>

              {signoffsNeeded}
            </div>

            <div className="h-px bg-gray-200"></div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ReviewProcedureStepBanner;

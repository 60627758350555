import TooltipOverlay from '../../TooltipOverlay';
import Rule from '../Rule';
import RuleStatusIcon from '../RuleStatusIcon';
import UnitDisplay from '../../Settings/Units/UnitDisplay';
import RuleStatus from '../RuleStatus';
import React, { useMemo } from 'react';
import { FieldInputBlock, FieldInputNumberBlock, RunFieldInputRecorded } from 'shared/lib/types/views/procedures';
import fieldInputUtil from '../../../lib/fieldInputUtil';
import { isEmptyValue } from 'shared/lib/text';
import { RecordedNumber } from '../BlockTypes';
import FieldInputNameDisplay from './FieldInputNameDisplay';
import { isNil } from 'lodash';

interface NumberFieldInputDisplayProps {
  block: FieldInputNumberBlock;
  recorded?: RunFieldInputRecorded<number>;
  label?: React.ReactNode;
  isReference?: boolean;
}

const NumberFieldInputDisplay = ({
  block,
  recorded,
  label = block.name,
  isReference = false,
}: NumberFieldInputDisplayProps) => {
  const isPassing = useMemo(() => {
    return fieldInputUtil.isNumberFieldInputPassing(block as FieldInputBlock, recorded);
  }, [block, recorded]);

  const hasRule = Boolean(block.rule?.op);

  return (
    <div className="flex flex-row gap-x-2 items-center">
      <FieldInputNameDisplay blockName={block?.name ?? ''} />
      <div>=</div>
      <TooltipOverlay
        content={
          <div>
            {isReference && !hasRule && label}
            {hasRule && (
              <Rule
                block={block}
                label={label}
                isPassing={null} // Do not show status in the overlay here.
              />
            )}
          </div>
        }
        delayClose={isReference}
        visible={hasRule || isReference}
      >
        <div className="flex flex-wrap grow justify-between items-center gap-2">
          <div className="flex flex-no-wrap gap-2 items-center">
            <div className="relative w-fit" aria-label={`${block?.name ?? ''} value`}>
              {(!recorded || isEmptyValue(recorded.value)) && (
                <div
                  className={` pl-2 ${hasRule ? 'pr-7' : 'pr-2'} ${
                    isReference ? 'w-fit' : 'w-36'
                  } h-9 py-1.5 border border-gray-400 rounded bg-gray-300 bg-opacity-50 text-gray-400 italic`}
                >
                  {isReference ? 'Pending' : ''}
                </div>
              )}
              {recorded && !isEmptyValue(recorded.value) && (
                <div
                  className={` py-1.5 pl-2 ${
                    hasRule ? 'pr-7' : 'pr-2'
                  } border border-gray-400 rounded bg-gray-300 bg-opacity-50 whitespace-wrap`}
                >
                  {recorded?.value as RecordedNumber}
                </div>
              )}
              {hasRule && (
                <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
                  <RuleStatusIcon isPassing={isPassing} />
                </div>
              )}
            </div>
            {!isNil(block.units) && (
              <div className="self-center whitespace-pre-wrap">
                <UnitDisplay unit={block.units} />
              </div>
            )}
          </div>
          {hasRule && (
            <div>
              <RuleStatus isPassing={isPassing} />
            </div>
          )}
        </div>
      </TooltipOverlay>
    </div>
  );
};

export default NumberFieldInputDisplay;

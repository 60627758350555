import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { homePath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { getLockMessage } from '../lib/lock';

const ProcedureEditLocked = ({ editedUserId, onProceed }) => {
  const { currentTeamId } = useDatabaseServices();
  const lockMessage = getLockMessage(editedUserId);

  return (
    <div className="flex flex-col items-center justify-center space-y-6 my-2 absolute top-0 bottom-0 left-0 right-0">
      <div className="flex flex-col items-center text-2xl">
        <div className="text-xl">{lockMessage}</div>
      </div>
      <div className="flex flex-row gap-x-2">
        <Link
          className=" font-semibold uppercase bg-white py-2 px-4 text-blue-500 border-2 border-blue-500 rounded"
          to={homePath(currentTeamId)} // Redirect to homepage for the current team.
        >
          <FontAwesomeIcon className="mr-2" icon="home" />
          Back to home
        </Link>

        <button
          className=" font-semibold uppercase bg-white py-2 px-4 text-blue-500 border-2 border-blue-500 rounded"
          onClick={onProceed}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

export default React.memo(ProcedureEditLocked);

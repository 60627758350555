import { useCallback, useEffect, useMemo, useState } from 'react';
import { SortColumn } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RunMetadata } from 'shared/lib/types/views/procedures';
import { CancelFunc } from '../api/procedures';
import { RowWithProjectName } from '../components/Home/GridExpandCollapseButton';
import HomeScreenTableRDG from '../components/Home/HomeScreenTableRDG';
import ListHeader from '../components/Home/ListHeader';
import { getColumns, getRows } from '../components/Home/runGridUtils';
import usePersistedView from '../components/Home/usePersistedView';
import { TabProps } from '../components/TabBar/TabBar';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useSettings } from '../contexts/SettingsContext';
import { fetchAllActiveRunsMetadata, selectActiveRunsLoading, selectActiveRunsMetadata } from '../contexts/runsSlice';
import useLocationParams from '../hooks/useLocationParams';
import apm from '../lib/apm';
import homeUtil from '../lib/homeUtil';
import { runsPath } from '../lib/pathUtil';
import projectUtil from '../lib/projectUtil';
import { getTagNames, getTagsAsOptions } from '../lib/tagsUtil';
import { useNavState } from '../contexts/NavContext';
import { Filter } from '../components/Home/FilterItems';

const emptyListText = 'No Runs';
const RUNNING_PROCEDURES_KEY = 'running-procedures';
export const RUN_HISTORY_KEY = 'run-history';

const DEFAULT_SORT: Record<string, Array<SortColumn>> = {
  [RUNNING_PROCEDURES_KEY]: [
    {
      columnKey: 'startTime',
      direction: 'DESC',
    },
  ],
  [RUN_HISTORY_KEY]: [
    {
      columnKey: 'startTime',
      direction: 'DESC',
    },
  ],
};

const Runs = () => {
  const { projectId } = useNavState();
  const [loading, setLoading] = useState(false);
  const { services, currentTeamId } = useDatabaseServices();
  const { config, projects, tags, runTags, setOperationsPagination } = useSettings();
  const isActiveRunsLoading = useSelector((state) => selectActiveRunsLoading(state, currentTeamId));
  const activeRuns = useSelector((state) => selectActiveRunsMetadata(state, currentTeamId));
  const dispatch = useDispatch();
  const history = useHistory();
  const [allRunSummaries, setAllRunSummaries] = useState<Array<RunMetadata>>([]);

  const persistedView = usePersistedView();

  const location = useLocation();
  const { hash } = useLocationParams(location);
  const navigatedSection = useMemo(() => {
    return hash === RUNNING_PROCEDURES_KEY || hash === RUN_HISTORY_KEY ? hash : RUNNING_PROCEDURES_KEY;
  }, [hash]);
  const [sortPreferenceByTab, setSortPreferenceByTab] = useState(DEFAULT_SORT);
  const sortPreference = useMemo<Array<SortColumn>>(() => {
    return sortPreferenceByTab[navigatedSection];
  }, [navigatedSection, sortPreferenceByTab]);

  const setSortPreference = useCallback(
    (newSortPreference: Array<SortColumn>) => {
      setSortPreferenceByTab((oldSortPreferenceByTab) => {
        return {
          ...oldSortPreferenceByTab,
          [navigatedSection]: newSortPreference,
        };
      });
    },
    [navigatedSection]
  );

  useEffect(() => {
    setOperationsPagination &&
      setOperationsPagination({
        planning_page: 0,
        running_page: 0,
        ended_page: 0,
        limit: Number.MAX_SAFE_INTEGER,
      });
  }, [setOperationsPagination]);

  // Realtime sync for run and procedure docs from backend.
  useEffect(() => {
    if (!services.runs) {
      return;
    }

    setLoading(true);
    const refreshActiveRuns = async () => {
      const nonNullOperationKeys: Array<string> = [];
      persistedView.selectedOperationKeys.forEach((key) => {
        if (key !== null) {
          nonNullOperationKeys.push(key);
        }
      });
      await dispatch(
        fetchAllActiveRunsMetadata({
          services,
          params: { operationKeys: nonNullOperationKeys },
        })
      );
      setLoading(false);
    };

    const refreshRunHistory = () =>
      services.runs
        .getCompletedRunSummaries(Array.from(persistedView.selectedOperationKeys))
        .then((summaries: RunMetadata[]) => {
          setAllRunSummaries(summaries);
          setLoading(false);
        });

    let observer: CancelFunc;

    if (navigatedSection === RUNNING_PROCEDURES_KEY) {
      observer = services.runs.onActiveRunsChanged(refreshActiveRuns);
      refreshActiveRuns().catch((err) => apm.captureError(err));
    } else {
      observer = services.runs.onRunsChanged(refreshRunHistory);
      refreshRunHistory();
    }

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, dispatch, currentTeamId, persistedView.selectedOperationKeys, navigatedSection]);

  const getFilteredRows = useCallback(
    (dataset: Array<RunMetadata>) =>
      getRows({
        runs: dataset,
        projectId,
        searchTerm: persistedView.searchTerm,
        selectedOperationKeys: persistedView.selectedOperationKeys,
        context: {
          projects,
          config,
          currentTeamId,
        },
      }),
    [projectId, persistedView.searchTerm, persistedView.selectedOperationKeys, projects, config, currentTeamId]
  );

  const runningRows = useMemo(() => getFilteredRows(Object.values(activeRuns)), [activeRuns, getFilteredRows]);
  const endedRows = useMemo(() => getFilteredRows(allRunSummaries), [allRunSummaries, getFilteredRows]);
  const rows = navigatedSection === RUNNING_PROCEDURES_KEY ? runningRows : endedRows;

  const tagOptions = useMemo(() => getTagsAsOptions(tags, runTags), [runTags, tags]);
  const selectedTagNames = useMemo(
    () => getTagNames(persistedView.selectedRunTagKeys, tags, runTags),
    [runTags, persistedView.selectedRunTagKeys, tags]
  );

  const selectedProjectNames = useMemo(() => {
    return projectUtil.getProjectNames(persistedView.selectedProjectIds, projects);
  }, [projects, persistedView.selectedProjectIds]);

  const TABS: ReadonlyArray<TabProps<string>> = [
    { id: RUNNING_PROCEDURES_KEY, label: 'Running', count: homeUtil.tabCount(runningRows.length) },
    { id: RUN_HISTORY_KEY, label: 'Ended', count: homeUtil.tabCount(endedRows.length) },
  ];

  const updateTab = useCallback(
    (tab: string) => {
      switch (tab) {
        case RUNNING_PROCEDURES_KEY:
          history.push(runsPath(currentTeamId));
          break;
        case RUN_HISTORY_KEY:
          history.push(runsPath(currentTeamId, RUN_HISTORY_KEY));
          break;
      }
    },
    [currentTeamId, history]
  );

  const headers = useMemo(() => {
    if (navigatedSection === RUNNING_PROCEDURES_KEY) {
      return getColumns({
        state: 'Running',
        includeProject: !projectId,
        includeOperations: persistedView.selectedOperationKeys.size > 0,
      });
    } else if (navigatedSection === RUN_HISTORY_KEY) {
      return getColumns({
        state: 'Ended',
        includeProject: !projectId,
        includeOperations: persistedView.selectedOperationKeys.size > 0,
      });
    }
    return [];
  }, [navigatedSection, persistedView.selectedOperationKeys.size, projectId]);

  const isLoading = useMemo(() => {
    // Active runs tab loading state is handled by redux runsSlice.
    if (navigatedSection === RUNNING_PROCEDURES_KEY) {
      return isActiveRunsLoading && loading;
    }

    return loading;
  }, [navigatedSection, loading, isActiveRunsLoading]);

  const gridKey = useMemo(
    () => `${navigatedSection} ${projectId} ${persistedView.selectedOperationKeys.size === 0 ? 'no-ops' : 'full'}`,
    [navigatedSection, persistedView.selectedOperationKeys.size, projectId]
  );

  return (
    <div className="flex flex-col flex-grow px-5">
      <ListHeader
        isLoading={isLoading}
        persistedView={persistedView}
        filters={new Set([...(projectId ? [] : [Filter.Projects]), Filter.RunTags, Filter.Operations])}
        tagOptions={tagOptions}
        name="Runs"
        tabs={TABS}
        rows={rows as ReadonlyArray<RowWithProjectName>}
        navigatedSection={navigatedSection}
        updateTab={updateTab}
        canSaveDefaultView={true}
      />

      <HomeScreenTableRDG
        key={gridKey}
        headers={headers}
        rows={rows}
        emptyListText={emptyListText}
        searchTerm={persistedView.searchTerm}
        setSearchTerm={persistedView.setSearchTerm}
        projectNamesFilter={selectedProjectNames}
        tagNamesFilter={selectedTagNames}
        sortPreference={sortPreference}
        setSortPreference={setSortPreference}
        showParentChildRelation={true}
        viewTab={persistedView.viewTab}
        expandedProjectNames={persistedView.expandedProjectNames}
        setExpandedProjectNames={persistedView.setExpandedProjectNames}
      />
    </div>
  );
};

export default Runs;

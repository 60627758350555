import { useCallback, useEffect, useState } from 'react';
import { PurchaseOrderDoc as PurchaseOrder } from 'shared/lib/types/postgres/manufacturing/orders';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';

interface OrdersState {
  orders?: PurchaseOrder[];
  tags: string[];
  refreshOrders: () => Promise<void>;
  refreshOrder: (orderId: string) => Promise<void>;
  getOrder: (orderId: string) => PurchaseOrder | undefined;
}

const collectAllTags = (orders: Array<PurchaseOrder>): string[] => {
  const tags: Set<string> = new Set();
  orders.forEach((order) => {
    if (order.tags) {
      order.tags.forEach((tag) => tags.add(tag));
    }
  });
  return Array.from(tags).sort();
};

const useOrders = (): OrdersState => {
  const [orders, setOrders] = useState<Array<PurchaseOrder>>();
  const [tags, setTags] = useState<Array<string>>([]);
  const { services } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const refreshOrders = useCallback(() => {
    return services.manufacturing
      .listOrders()
      .then((orders) => {
        // Sort by created date
        const sorted = orders.sort((order1, order2) =>
          order1.created_at.localeCompare(order2.created_at)
        );
        setOrders(sorted);
        setTags(collectAllTags(sorted));
      })
      .catch(() => undefined);
  }, [services.manufacturing]);

  const refreshOrder = useCallback(
    (orderId) => {
      return services.manufacturing
        .getOrder(orderId)
        .then((order) => {
          const updated = [...(orders ?? [])];
          const index = updated.findIndex((order) => order.id === orderId);
          if (index !== -1) {
            updated[index] = order;
          }
          setOrders(updated);
          setTags(collectAllTags(updated));
        })
        .catch(() => undefined);
    },
    [services.manufacturing, orders]
  );

  useEffect(() => {
    if (!isManufacturingEnabled?.()) {
      return;
    }
    refreshOrders();
  }, [isManufacturingEnabled, refreshOrders]);

  const getOrder = (orderId) => orders?.find((order) => order.id === orderId);

  return {
    orders,
    tags,
    refreshOrder,
    refreshOrders, // TODO: Build realtime for postgres
    getOrder,
  };
};

export default useOrders;

import React, { useMemo } from 'react';
import Tooltip from '../elements/Tooltip';
import { MAX_LENGTH, MAX_LENGTH_MOBILE } from '../lib/tableTextUtil';
import { getHashColor } from '../lib/hashUtil';

interface TagProps {
  id: string;
  name: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  clickable?: boolean;
  color?: string;
}

/**
 * Renders a tag for a procedure
 *
 * @param {string} id - Tag key
 * @param {string} name - Tag name
 * @param {Function} onClick - onClick handler
 * @param {string} color - specific tag color, otherwise, one will be assigned
 */
const Tag = ({ id, name, onClick, clickable, color }: TagProps) => {
  const tagColor = useMemo(() => {
    if (color) {
      return color;
    }

    return getHashColor(name);
  }, [color, name]);

  const tagCursor = useMemo(() => {
    return clickable || onClick ? 'cursor-pointer' : 'cursor-default';
  }, [clickable, onClick]);

  const truncate = useMemo(() => {
    return name.length > MAX_LENGTH.sm;
  }, [name]);

  const truncateMobile = useMemo(() => {
    return name.length > MAX_LENGTH_MOBILE.sm;
  }, [name]);

  const displayText = useMemo(() => {
    return truncate ? `${name.slice(0, MAX_LENGTH.sm - 1)}...` : name;
  }, [truncate, name]);

  const displayTextMobile = useMemo(() => {
    return truncateMobile ? `${name.slice(0, MAX_LENGTH_MOBILE.sm - 1)}...` : name;
  }, [truncateMobile, name]);

  return (
    <>
      <Tooltip content={name} visible={truncate}>
        <div
          key={id}
          className={`tag hidden lg:inline-block font-medium text-gray-600 text-sm px-1.5 py-0.5 group mr-1 rounded-xl max-w-max ${tagColor} ${tagCursor}`}
          onClick={onClick}
          aria-label={name}
        >
          {displayText}
        </div>
      </Tooltip>

      <Tooltip content={name} visible={truncateMobile}>
        <div
          className={`tag lg:hidden font-medium text-gray-600 text-sm px-1.5 py-0.5 group mr-1 rounded-xl max-w-max ${tagColor} ${tagCursor}`}
          onClick={onClick}
          aria-label={name}
        >
          {displayTextMobile}
        </div>
      </Tooltip>
    </>
  );
};

export default Tag;

import React, { useCallback } from 'react';
import ReviewBlockAttachment from './Blocks/ReviewBlockAttachment';
import ProcedureBlockReview from '../Blocks/ProcedureBlockReview';
import { generateHiddenClassString } from '../../lib/styles';
import reviewUtil from '../../lib/reviewUtil';
import ReviewCommenting from '../ReviewCommenting';
import { useRunContext } from '../../contexts/RunContext';
import ProcedureFieldNoRedlines from '../ProcedureFieldNoRedlines';
import DiffContainer from '../Diff/DiffContainer';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ReviewBlockText from './ReviewBlockText';
import CommentWrapper from '../CommentWrapper';

const ReviewSectionHeader = ({
  comments,
  sectionHeader,
  isCollapsed,
  saveReviewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  onRefChanged,
  showReviewComments = false,
  scrollToBufferRem = 0,
}) => {
  const { isIntroductionVisible, isRun } = useRunContext();
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  const onSectionHeaderRefChanged = useCallback(
    (element) => {
      if (!onRefChanged) {
        return;
      }
      onRefChanged(sectionHeader.id, element);
    },
    [sectionHeader.id, onRefChanged]
  );

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  return (
    <DiffContainer
      label="Section Header"
      diffChangeState={sectionHeader.diff_change_state}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(sectionHeader.id, element)}
    >
      <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
        <thead>
          <tr>
            <th className="w-4"></th>
            <th className="w-full"></th>
            <th className="w-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="align-top">
              {/* Section header name */}
              <div
                className="mt-3"
                ref={onSectionHeaderRefChanged}
                style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
              ></div>
            </td>
            <td className="break-words">
              <div className="ml-4 text-2xl min-w-0">
                <ProcedureFieldNoRedlines
                  fieldName="name"
                  fieldValue={sectionHeader.name}
                  redlines={[]}
                  onLabelClick={() => null}
                />
              </div>
            </td>
          </tr>
        </tbody>

        {/* Section header content */}
        <tbody className={generateHiddenClassString('', isCollapsed)}>
          {sectionHeader.content &&
            sectionHeader.content.map((content) => {
              return (
                <CommentWrapper content={content}>
                  {content.type.toLowerCase() === 'text' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'old') === 'string' &&
                    typeof sharedDiffUtil.getDiffValue(content, 'text', 'new') === 'string' && (
                      <ReviewBlockText block={content} isHidden={false} />
                    )}
                  {(content.type.toLowerCase() === 'alert' || content.type.toLowerCase() === 'requirement') && (
                    <ProcedureBlockReview
                      block={content}
                      isHidden={false}
                      isDark={true}
                      isEnabled={false}
                      blockLabel={undefined}
                      recorded={undefined}
                      isSpacerHidden={undefined}
                      onContentRefChanged={undefined}
                      scrollMarginTopValueRem={undefined}
                    />
                  )}
                  {content.type.toLowerCase() === 'attachment' && (
                    <ReviewBlockAttachment attachment={content} isHidden={isCollapsed} isSpacerHidden={false} />
                  )}
                </CommentWrapper>
              );
            })}
          {/* Review comments */}
          {showReviewComments && (
            <tr>
              <td></td>
              <td colSpan={2}>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}></div>
                <div className={generateHiddenClassString('mb-2', isCollapsed)}>
                  <ReviewCommenting
                    stepId={sharedDiffUtil.getDiffValue(sectionHeader, 'id', 'new')}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    reviewComments={reviewUtil.getStepReviewComments(
                      comments,
                      sharedDiffUtil.getDiffValue(sectionHeader, 'id', 'new')
                    )}
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </DiffContainer>
  );
};

export default React.memo(ReviewSectionHeader);

import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useIntercom } from 'react-use-intercom';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { RESET_APP_STATE } from '../app/store';
import { INTERCOM_ENABLED } from '../config';
import superlogin, { getDefaultTeamIdFromSession } from '../api/superlogin';
import { auth as authUtil } from '../lib/auth';
import { setCurrentUserId, selectCurrentUserId } from '../contexts/usersSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useUserInfo, updateUserInfo } from '../contexts/UserContext';
import { useSettings } from '../contexts/SettingsContext';
import UserSessionRefresh from '../components/UserSessionRefresh';
import apm from '../lib/apm';

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(superlogin.authenticated());
  const { updateTeamIds, clearLocalData } = useDatabaseServices();
  const { userInfo, setUserInfo } = useUserInfo();
  const currentUserId = useSelector((state) => selectCurrentUserId(state));
  const { currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const { users, isProjectNavigationEnabled } = useSettings();
  const [auth, setAuth] = useState(null);
  const { boot, shutdown } = useIntercom();
  const dispatch = useDispatch();

  const resetAppState = useCallback(async () => {
    // Reset redux-offline state.
    dispatch({ type: RESET_STATE });
    // Reset all redux state.
    dispatch({ type: RESET_APP_STATE });
    // Clear any local data from local storage.
    await clearLocalData();
  }, [clearLocalData, dispatch]);

  // Handle superlogin login events.
  const onLogin = useCallback(
    (session) => {
      setIsAuthenticated(true);
      // Clear app data if user id has changed.
      if (session && session.user_id !== currentUserId) {
        resetAppState().catch((err) => apm.captureError(err));
      }
      dispatch(setCurrentUserId(session.user_id));
    },
    [currentUserId, resetAppState, dispatch]
  );

  const onLogout = useCallback(() => {
    // Trigger redirect to login
    setIsAuthenticated(false);

    // Clear Mixpanel services and data
    if (mixpanel) {
      mixpanel.reset();
    }
    /*
     * Clear Intercom user data
     * https://github.com/devrnt/react-use-intercom#useintercom
     * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
     */
    if (INTERCOM_ENABLED) {
      shutdown();
      // Shutdown hides the launch icon, let's re-show it
      boot();
    }
    updateTeamIds([]);
    updateUserInfo(null, setUserInfo);
    if (userInfo.session.sign_out_url && userInfo.session.intentional_logout) {
      window.location.href = userInfo.session.sign_out_url;
    }
  }, [mixpanel, boot, shutdown, updateTeamIds, setUserInfo, userInfo.session]);

  // Register superlogin authentication event observers.
  useEffect(() => {
    superlogin.on('login', onLogin);
    superlogin.on('logout', onLogout);

    // Cleanup listeners
    return () => {
      superlogin.removeListener('login', onLogin);
      superlogin.removeListener('logout', onLogout);
    };
  }, [onLogin, onLogout]);

  useEffect(() => {
    if (!isAuthenticated || !users || !userInfo.session) {
      setAuth({ isAuthenticated });
    } else {
      const teamId = getDefaultTeamIdFromSession(userInfo.session);
      setAuth({
        teamId,
        hasPermission: (permission, projectId) =>
          authUtil.hasPermission(users.users, userInfo.session, permission, currentTeamId, projectId) ||
          (!isProjectNavigationEnabled?.() && authUtil.hasProjectsWithEditPermission(users.users, userInfo.session)),
        isOrgAdmin: (orgId) => authUtil.isOrgAdmin(users.users, userInfo.session, orgId),
        projectsWithEditPermission: () => authUtil.projectsWithEditPermission(users.users, userInfo.session),
        hasProjectsWithEditPermission: () => authUtil.hasProjectsWithEditPermission(users.users, userInfo.session),
        hasProjectOnlyEditPermissions: () =>
          authUtil.hasProjectOnlyEditPermissions(users.users, userInfo.session, currentTeamId),
        projectsWithOperatorPermission: () => authUtil.projectsWithOperatorPermission(users.users, userInfo.session),
        hasProjectsWithOperatorPermission: () =>
          authUtil.hasProjectsWithOperatorPermission(users.users, userInfo.session),
        hasProjectOnlyOperatorPermissions: () =>
          authUtil.hasProjectOnlyOperatorPermissions(users.users, userInfo.session, currentTeamId),
        projectsWithGreaterThanViewerPermission: () =>
          authUtil.projectsWithGreaterThanViewerPermission(users.users, userInfo.session),
        noWorkspaceAccess: () => authUtil.noWorkspaceAccess(users.users, userInfo.session, currentTeamId),
        hasOperatorRole: (role) => authUtil.hasOperatorRole(users.users, userInfo.session, role),
        getOperatorRoles: () => authUtil.getOperatorRoles(users.users, userInfo.session),
        getOperatorRolesSet: () => authUtil.getOperatorRolesSet(users.users, userInfo.session),
        isAuthenticated,
      });
    }
  }, [users, userInfo.session, isAuthenticated, currentTeamId, isProjectNavigationEnabled]);

  return (
    <AuthContext.Provider value={{ auth }}>
      <UserSessionRefresh />
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };

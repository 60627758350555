import { TreeNode } from 'primereact/treenode';
import { TreeSelect, TreeSelectEventNodeEvent } from 'primereact/treeselect';
import { useMemo, useState } from 'react';
import { LocationMap } from '../types';
import { LocationNode, getLocationGraph } from '../lib/locations';
import './styles/TreeSelect.css';

export const CLEAR_KEY = '__clear__';
export const NO_LOCATION_KEY = '__none__';

const CLEAR_FILTER_NODE: TreeNode = { key: CLEAR_KEY, label: '(All)' };
const NO_LOCATION_NODE: TreeNode = { key: NO_LOCATION_KEY, label: 'Not Set' };

type LocationTreeSelectProps = {
  locations: LocationMap;
  selected?: string;
  onSelect: (selectedIds: string[]) => void;
};

const nodeTemplate = (node: TreeNode) => {
  const textStyle = node.key === NO_LOCATION_KEY ? 'text-gray-800 italic' : 'text-black';
  return <div className={`w-48 break-words  ${textStyle}`}>{node.label}</div>;
};

const asTreeNode = (node: LocationNode): TreeNode => {
  return {
    key: node.id,
    label: `${node.full_code} ${node.name}`,
    children: node.children.map((n) => asTreeNode(n)),
  };
};

const getDescendantIds = (node: TreeNode): string[] => {
  const bfsQueue = [...(node.children || [])];
  const ids: string[] = [];
  let n: TreeNode | undefined;
  while ((n = bfsQueue.shift())) {
    ids.push(n.key as string);
    bfsQueue.push(...(n.children || []));
  }
  return ids;
};

const LocationTreeSelect = ({ locations, selected, onSelect }: LocationTreeSelectProps) => {
  const [value, setValue] = useState<string>(selected ?? CLEAR_KEY);

  const rootNodes = useMemo(() => getLocationGraph(locations).map(asTreeNode), [locations]);

  const onNodeSelect = (e: TreeSelectEventNodeEvent) => {
    if (e.node.key && typeof e.node.key === 'string') {
      const allSelected = e.node.key === CLEAR_KEY ? [] : [e.node.key, ...getDescendantIds(e.node)];
      onSelect(allSelected);
      setValue(e.node.key);
    }
  };

  return (
    <div className="w-80">
      <TreeSelect
        value={value}
        options={[CLEAR_FILTER_NODE, ...rootNodes, NO_LOCATION_NODE]}
        onNodeSelect={onNodeSelect}
        placeholder="Select location filter..."
        nodeTemplate={nodeTemplate}
        filter
        resetFilterOnHide
      />
    </div>
  );
};

export default LocationTreeSelect;

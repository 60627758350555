import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../elements/Tooltip';
import VersionDisplay, { VersionSelectOption } from './VersionDisplay';
import useMenu from '../../../hooks/useMenu';
import { Link } from 'react-router-dom';

type VersionHistoryProps = {
  version: VersionSelectOption;
  isLatestVersion?: boolean;
  versionOptions?: VersionSelectOption[];
};

const VersionHistory = ({ version, isLatestVersion, versionOptions }: VersionHistoryProps) => {
  const { isMenuVisible, displayMenu } = useMenu();
  const hasMultipleVersions = versionOptions && versionOptions.length > 1;

  return (
    <button
      className={`flex flex-row items-center text-sm border border-slate-300 rounded-3xl ml-2 px-1 py-1 relative ${
        hasMultipleVersions ? 'cursor-pointer' : 'cursor-default'
      }`}
      onClick={displayMenu}
    >
      {!isLatestVersion && (
        <Tooltip content="You are viewing an older version.">
          <FontAwesomeIcon className="mr-1 text-lg" icon="circle-exclamation" />
        </Tooltip>
      )}
      <VersionDisplay option={version} showTitle={!!version.version} />
      {hasMultipleVersions && (
        <>
          <FontAwesomeIcon icon="caret-down" className="text-sm ml-1" />
          {isMenuVisible && (
            <div className="absolute left-0 top-full z-50 max-h-[300px] w-[180px] overflow-y-auto bg-white flex flex-col items-start rounded shadow-md border">
              {versionOptions.map((option) => (
                <Link key={option.id} to={option.path} className="w-full p-2 hover:bg-gray-100">
                  <VersionDisplay option={option} showTitle={false} />
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default VersionHistory;

import React, { useMemo } from 'react';
import { Field } from 'formik';
import { HeaderFieldRedline, StepFieldRedline } from 'shared/lib/types/views/redlines';
import { RunHeaderFieldRedline, RunStepFieldRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import FieldRedlineBlock from './Blocks/redline/FieldRedlineBlock';
import { getRedlineDocsSortedLatestToEarliest } from '../lib/redlineUtil';
import { getRedlineFromDoc } from 'shared/lib/redlineUtil';
import { getRedlineSourceStepPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';

/**
 * Component for rendering the form for a header field when editing a procedure.
 * Supports showing all redline changes made to the field for acceptance or rejection.
 *
 * @param fieldName - Name of the field.
 * @param fieldValue - Value of the field.
 * @param redlines - Array of header field redline docs
 * @param type - Header or step field
 * @param fieldValueType - Type of the form field, e.g., text, number, etc.
 * @param placeholder - Text rendered when form field is empty.
 * @param acceptRedline - Function called when accept redline button is pressed.
 * @param rejectRedline - Function called when reject redline button is pressed.
 */
interface FieldSetProcedureFieldProps {
  fieldName: 'name';
  fieldValue: string;
  redlines: Array<HeaderFieldRedline> | Array<StepFieldRedline>;
  fieldValueType: string;
  placeholder: string;
  error?: string;
  acceptRedline: (redline: RunHeaderFieldRedline | RunStepRedline, fieldName?: string) => void;
  rejectRedline: (redline: RunHeaderFieldRedline | RunStepRedline, fieldName?: string) => void;
}

const FieldSetProcedureField = ({
  fieldName,
  fieldValue,
  redlines,
  fieldValueType,
  placeholder,
  error,
  acceptRedline,
  rejectRedline,
}: FieldSetProcedureFieldProps) => {
  const { currentTeamId } = useDatabaseServices();
  const redlineSorted = useMemo(
    () => getRedlineDocsSortedLatestToEarliest<HeaderFieldRedline | StepFieldRedline>(redlines ?? []),
    [redlines]
  );

  return (
    <fieldset className="min-w-0 flex flex-col gap-y-2">
      {/* Display all redlines for the field. */}
      {redlineSorted &&
        redlineSorted.length > 0 &&
        redlineSorted.map((redline) => (
          <FieldRedlineBlock
            key={redline._id}
            fieldName={fieldName}
            fieldValue={fieldValue}
            runRedline={getRedlineFromDoc(redline) as RunHeaderFieldRedline | RunStepFieldRedline}
            error={error}
            acceptRedline={acceptRedline}
            rejectRedline={rejectRedline}
            contextUrl={getRedlineSourceStepPath({
              teamId: currentTeamId,
              redline,
            })}
          />
        ))}

      {/* Render form field. */}
      <Field
        type={fieldValueType}
        name={fieldName}
        placeholder={placeholder}
        className=" w-full border-1 border-gray-400 rounded"
      />
    </fieldset>
  );
};

export default FieldSetProcedureField;

import { useCallback, useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { reactSelectStyles } from '../lib/styles';
import { DraftCommandingBlock } from 'shared/lib/types/views/procedures';
import { FieldInputProps } from 'formik';
import { Command } from '../lib/models/postgres/commanding';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { Dictionary } from '../lib/models/postgres/dictionary';
import apm from '../lib/apm';

type CommandingOption = {
  value: string | number;
  label: string;
  dictionary?: string;
  dictionaryId?: number;
  description?: string;
};

type CommandUpdate = {
  name: string;
  command_id?: number;
  dictionary_id?: number;
};

const getOption = (content: DraftCommandingBlock): CommandingOption | undefined => {
  const commandName = content.name || '';
  if (!commandName) {
    return undefined;
  }

  // new approach uses a number command_id direct reference.  Old used string command name
  return {
    value: content.command_id ? content.command_id : commandName,
    label: commandName,
    dictionaryId: content.dictionary_id,
  };
};

// Return just the dictionary name and label for display in the select box
const plainOption = (option: CommandingOption): CommandingOption => {
  return {
    value: option.value,
    label: option.label,
    dictionary: option.dictionary,
    dictionaryId: option.dictionaryId,
  };
};

interface FieldSetCommandSelectProps {
  content: DraftCommandingBlock;
  field: FieldInputProps<string>;
  onChange: (command: CommandUpdate) => void;
  onClear: () => void;
}

const FieldSetCommandSelect = ({ content, field, onChange, onClear }: FieldSetCommandSelectProps) => {
  const { services } = useDatabaseServices();
  const [defaultOptions, setDefaultOptions] = useState<Array<CommandingOption>>([]);
  const [selectValue, setSelectValue] = useState<CommandingOption | undefined | null>(getOption(content));
  const [dictionaries, setDictionaries] = useState();

  const clearValue = useCallback(() => {
    setSelectValue(null);
    onClear();
  }, [onClear]);

  const onChangeHandler = useCallback(
    (option) => {
      if (!option) {
        clearValue();
        return;
      }
      const command = {
        name: option.label,
        command_id: option.value,
        dictionary_id: option.dictionaryId,
      };
      setSelectValue(plainOption(option));
      onChange(command);
    },
    [clearValue, onChange]
  );

  const { procedure } = useProcedureContext();

  useEffect(() => {
    if (!dictionaries) {
      services.dictionary.listDictionaries().then((entries: Array<Dictionary>) => {
        const ret = Object.assign({}, ...entries.map((dictionary) => ({ [dictionary.id]: dictionary.name })));
        setDictionaries(ret);
      });
    }
  }, [dictionaries, procedure.dictionary_id, services.dictionary]);

  const dictionary_ids = useMemo(() => {
    if (procedure?.dictionary_id) {
      return [procedure.dictionary_id];
    }
    return [];
  }, [procedure.dictionary_id]);

  const searchCommandingService = useCallback(
    async (searchTerm: string): Promise<Array<CommandingOption>> => {
      return services.commanding
        .searchCommands(searchTerm, dictionary_ids)
        .then((commands: Array<Command>) => {
          return commands.map((command) => ({
            value: command.id,
            label: command.name,
            dictionary: command.dictionary_name,
            dictionaryId: command.dictionary_id,
            description: command.description,
          }));
        })
        .catch(() => []);
    },
    [dictionary_ids, services.commanding]
  );

  /*
   * When dictionary selection changes, fetch commands from the server to update the
   * select box default options (shown on down arrow click) and also update the
   * selection to be valid given the new dictionary selection
   */
  useEffect(() => {
    searchCommandingService('')
      .then((options) => {
        setDefaultOptions(options);
      })
      .catch((err) => apm.captureError(err));
  }, [searchCommandingService]);

  /*
   * When the commanding content changes, we need to resolve the dictionary name from the dictionaryId
   * for display in the select since we don't store or pass dictionary name in the content for now
   */
  useEffect(() => {
    if (!selectValue) {
      return;
    }
    if (selectValue.dictionaryId && !selectValue.dictionary) {
      if (dictionaries && dictionaries[selectValue.dictionaryId]) {
        const val = dictionaries[selectValue.dictionaryId];
        setSelectValue((current) => {
          if (current) {
            return {
              ...current,
              dictionary: val,
            };
          }
        });
      }
    }
  }, [dictionaries, selectValue, services.dictionary]);

  // if content changes from props, we need to update the select value
  useEffect(() => {
    if (!content.name) {
      setSelectValue(null);
    } else {
      setSelectValue(getOption(content));
    }
  }, [content]);

  const formatOptionLabel = ({ label, dictionary, description }: CommandingOption) => (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2 items-center">
        {dictionary && <div className="font-semibold ">{dictionary}</div>}
        <div className="">{label}</div>
      </div>
      <div className="text-sm text-gray-400 truncate">{description}</div>
    </div>
  );

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <div>
          <AsyncSelect
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            name={field.name}
            loadOptions={searchCommandingService}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            placeholder="Search by name"
            value={selectValue}
            formatOptionLabel={formatOptionLabel}
            defaultOptions={defaultOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldSetCommandSelect;

import { FieldInputProps } from 'formik';
import { useMemo } from 'react';
import Select from 'react-select';
import { useSettings } from '../../contexts/SettingsContext';
import { reactSelectStyles } from '../../lib/styles';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import { ExternalData } from '../../lib/models/postgres/externalData';

type ExternalItemOption = {
  value: string | number;
  label: string;
  dictionaryName?: string;
};

interface ExternalTypeSelectFieldSetProps {
  field: FieldInputProps<string>;
  isDisabled: boolean;
  onChange: (data_type: string, dictionary_id: number) => void;
  value: string;
  dictionaryId?: number;
}

const ExternalTypeSelectFieldSet = ({
  field,
  isDisabled,
  onChange,
  value,
  dictionaryId,
}: ExternalTypeSelectFieldSetProps) => {
  const { externalDataTypes } = useSettings();

  const onChangeHandler = (option) => {
    //Skip onChange when re-selected the same value.
    if (option.value === value && option.dictionaryId === dictionaryId) {
      return;
    }
    onChange(option.value, option.dictionaryId);
  };

  const { procedure } = useProcedureContext();

  const dictionary_ids = useMemo(() => {
    if (procedure?.dictionary_id) {
      return [procedure.dictionary_id];
    }
    return [];
  }, [procedure.dictionary_id]);

  const selectOptions = useMemo(() => {
    if (!externalDataTypes) {
      return null;
    }

    let filteredExternalDataTypes: ExternalData = [];
    if (dictionary_ids.length > 0) {
      filteredExternalDataTypes = externalDataTypes.filter((type) => dictionary_ids.includes(type.dictionary_id));
    } else {
      filteredExternalDataTypes = externalDataTypes;
    }

    return filteredExternalDataTypes.map((type) => ({
      value: type.type,
      label: type.label,
      dictionaryId: type.dictionary_id,
      dictionaryName: type.dictionary_name,
    }));
  }, [dictionary_ids, externalDataTypes]);

  const selectValue = useMemo(() => {
    return selectOptions?.find((option) => option.value === value && option.dictionaryId === dictionaryId) || '';
  }, [dictionaryId, selectOptions, value]);

  const formatOptionLabel = ({ label, dictionaryName, value }: ExternalItemOption) => (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2 items-center">
        {dictionaryName && <div className="font-semibold ">{dictionaryName}</div>}
        <div className="">{label}</div>
      </div>
      <div className="text-sm text-gray-400 truncate">{value}</div>
    </div>
  );

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <div>
          <Select
            aria-label="Select Data Type"
            classNamePrefix="react-select"
            name={field.name}
            isDisabled={isDisabled}
            onChange={onChangeHandler}
            options={selectOptions}
            placeholder="Select data type"
            formatOptionLabel={formatOptionLabel}
            styles={reactSelectStyles}
            value={selectValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalTypeSelectFieldSet;

import React from 'react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';

const RepeatHourly = ({ id, hourly: { interval }, handleChange }) => (
  <div className="flex flex-row items-center">
    <span>every</span>
    <div className="col-sm-2">
      <input
        id={`${id}-interval`}
        name="repeat.hourly.interval"
        aria-label="Repeat hourly interval"
        className="rounded  border-gray-300"
        value={interval}
        onChange={numericalFieldHandler(handleChange)}
      />
    </div>
    <span>hour(s)</span>
  </div>
);
RepeatHourly.propTypes = {
  id: PropTypes.string.isRequired,
  hourly: PropTypes.shape({
    interval: PropTypes.number.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatHourly;

import hash from 'string-hash';

const COLOR_BUCKETS = [
  'bg-teal-200',
  'bg-cyan-200',
  'bg-sky-200',
  'bg-indigo-200',
  'bg-violet-200',
  'bg-purple-200',
  'bg-orange-200',
  'bg-amber-200',
  'bg-stone-200',
];

const COLOR_BUCKETS_DARK = [
  'bg-teal-500',
  'bg-cyan-500',
  'bg-sky-500',
  'bg-indigo-500',
  'bg-violet-500',
  'bg-purple-500',
  'bg-orange-500',
  'bg-amber-500',
  'bg-stone-500',
];

export const getHashColor = (name: string, dark = false): string => {
  return getHashBucket(name, dark ? COLOR_BUCKETS_DARK : COLOR_BUCKETS);
};

// Returns bucket from the given list of buckets for a string to be hashed
const getHashBucket = (id: string, buckets: Array<string>): string => {
  const index = hash(id) % buckets.length;
  return buckets[index];
};

export default getHashBucket;

import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SNIPPET_TYPE_SECTION, SNIPPET_TYPE_STEP } from '../../api/settings';
import { useAuth } from '../../contexts/AuthContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import Grid, { GridColumn } from '../../elements/Grid';
import SearchInputControlled from '../../elements/SearchInputControlled';
import { PERM } from '../../lib/auth';
import snippetUtil from '../../lib/snippetUtil';
import { Snippet, SnippetType } from 'shared/lib/types/views/procedures';
import { filterBySearchTerm } from '../../lib/gridUtils';
import Button, { BUTTON_TYPES } from '../Button';
import FlashMessage from '../FlashMessage';
import FieldSetSnippet from './FieldSetSnippet';
import ModalPreviewSnippet from './ModalPreviewSnippet';
import { SortColumn } from 'react-data-grid';
import Label from '../Label';
import { capitalizeFirstLetter } from 'shared/lib/text';
import useMenu from '../../hooks/useMenu';
import MenuContext, { MenuContextAction } from '../MenuContext';
import { MainScrollPanelId } from '../../elements/SidebarLayout';
import apm from '../../lib/apm';
import DateTimeDisplay from '../DateTimeDisplay';
import Avatar from '../../elements/Avatar';
import { ReactComponent as RectangleIcon } from '../../images/rectangle.svg';
import { ReactComponent as RectangleHistoryIcon } from '../../images/rectangle-history.svg';
import { snippetViewPath } from '../../lib/pathUtil';
import { Link } from 'react-router-dom';
import SnippetSidebarWithContent from './SnippetSidebarWithContent';
import { useUserInfo } from '../../contexts/UserContext';

const SNIPPET_SAVE_SUCCESS_MESSAGE = 'Snippet saved';
const SNIPPET_DELETE_SUCCESS_MESSAGE = 'Snippet deleted';
const MAIN_VERTICAL_PADDING = 120;
const ROW_HEIGHT = 60;

interface SnippetListProps {
  testingModule?: boolean;
}

const SnippetList = ({ testingModule = false }: SnippetListProps) => {
  const [editActiveSnippet, setEditActiveSnippet] = useState<Snippet | null>(null);
  const [showEditSnippet, setShowEditSnippet] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [snippets, setSnippets] = useState<Array<Snippet>>([]);
  const [currentSnippet, setCurrentSnippet] = useState<Snippet>();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const [searchTerm, setSearchTerm] = useState('');
  const { auth } = useAuth();
  const { isMenuVisible, setIsMenuVisible } = useMenu();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;

  const canEdit = useMemo(() => {
    return auth.hasPermission(PERM.PROCEDURES_EDIT);
  }, [auth]);

  const onEditSnippetChanged = useCallback((snippet) => {
    setEditActiveSnippet(snippet);
    setShowEditSnippet(true);
  }, []);

  const refreshSnippets = useCallback(() => {
    return services.settings
      .listSnippets({ isTestSnippet: testingModule })
      .then((snippets) => snippets.sort((a, b) => a.name.localeCompare(b.name)))
      .then(setSnippets)
      .catch(() => {
        /* Ignore */
      });
  }, [services.settings, testingModule]);

  const onCloseEditSnippet = useCallback(() => {
    setEditActiveSnippet(null);
    setCurrentSnippet(undefined);
    setShowEditSnippet(false);
    refreshSnippets().catch((err) => apm.captureError(err));
  }, [refreshSnippets]);

  const onRemoveSnippet = useCallback(
    (id) => {
      setCurrentSnippet(undefined);

      return services.settings
        .deleteSnippet(id)
        .then(() => {
          setEditActiveSnippet(null);
          setShowEditSnippet(false);
        })
        .then(refreshSnippets)
        .then(() => setSuccessMessage(SNIPPET_DELETE_SUCCESS_MESSAGE))
        .catch(() => {
          /* Ignore */
        });
    },
    [services.settings, refreshSnippets]
  );

  const onCreateSnippet = useCallback(
    (snippetType: SnippetType) => {
      if (snippetType === 'section') {
        onEditSnippetChanged(snippetUtil.getNewSectionSnippet(userId));
      }
      if (snippetType === 'step') {
        onEditSnippetChanged(snippetUtil.getNewStepSnippet(userId));
      }
    },
    [onEditSnippetChanged, userId]
  );

  const onSaveSuccess = useCallback(() => {
    setCurrentSnippet(undefined);
    return setSuccessMessage(SNIPPET_SAVE_SUCCESS_MESSAGE);
  }, []);

  useEffect(() => {
    refreshSnippets().catch((err) => apm.captureError(err));
  }, [refreshSnippets]);

  const handleCellClick = useCallback(
    (args) => {
      if (args.column.key === 'snippet_type') {
        setSearchTerm(args.row.snippet_type);
      }
    },
    [setSearchTerm]
  );

  const defaultSort = [
    {
      columnKey: 'name',
      direction: 'ASC',
    },
  ] as Array<SortColumn>;

  const columns: readonly GridColumn<Snippet>[] = [
    {
      key: 'name',
      name: 'Name',
      sortable: true,
      width: '60%',
      renderCell({ row }: { row: Snippet }) {
        const link = snippetViewPath(currentTeamId, row.snippet_id, testingModule);
        const [pathname, search] = link.split('?');
        return (
          <Link
            to={{
              pathname,
              search: search ? `?${search}` : '',
            }}
            className="block w-full"
          >
            <div className="py-3 w-full">
              <div className="flex flex-col font-medium">
                <div className="hover:underline leading-4 line-clamp-2 whitespace-normal">{row.name}</div>
                {row.description && <div className="text-gray-400 text-xs truncate">{row.description}</div>}
              </div>
            </div>
          </Link>
        );
      },
    },
    {
      key: 'snippet_type',
      name: 'Type',
      sortable: true,
      width: '20%',
      renderCell({ row }: { row: Snippet }) {
        return (
          <Label
            clickable={true}
            text={capitalizeFirstLetter(row.snippet_type)}
            color={row.snippet_type === SNIPPET_TYPE_STEP ? 'bg-stone-200' : 'bg-purple-200'}
          />
        );
      },
    },
    {
      key: 'last_edited',
      name: 'Last Edited',
      sortable: true,
      width: '20%',
      comparator: (a: Snippet, b: Snippet) => {
        if (!a.edited_at && !b.edited_at) return 0;
        if (!a.edited_at) return 1;
        if (!b.edited_at) return -1;
        return new Date(a.edited_at).getTime() - new Date(b.edited_at).getTime();
      },
      renderCell({ row }: { row: Snippet }) {
        return row.edited_at && row.edited_by ? (
          <span className="flex items-center gap-x-1 text-sm text-gray-600">
            <Avatar userId={row.edited_by} />
            <DateTimeDisplay timestamp={row.edited_at} wrap={true} hasTooltip={true} />
          </span>
        ) : (
          <span className="text-sm text-gray-400" />
        );
      },
    },
  ];

  const displayRows: Array<Snippet> = useMemo(
    () =>
      filterBySearchTerm({
        searchTerm,
        allData: snippets,
        getStrings: (snippet: Snippet) => [snippet.name, snippet.description, snippet.snippet_type],
      }),
    [searchTerm, snippets]
  );

  const menuActions: Array<MenuContextAction> = useMemo(
    () => [
      {
        type: 'label',
        label: 'Step Snippet',
        data: {
          icon: RectangleIcon,
          onClick: () => onCreateSnippet(SNIPPET_TYPE_STEP),
        },
      },
      {
        type: 'label',
        label: 'Section Snippet',
        data: {
          icon: RectangleHistoryIcon,
          onClick: () => onCreateSnippet(SNIPPET_TYPE_SECTION),
        },
      },
    ],
    [onCreateSnippet]
  );

  return (
    <div id={MainScrollPanelId} className="w-full h-screen overflow-auto">
      <Helmet>
        <title>Snippets - {showEditSnippet ? 'Edit' : 'List'}</title>
      </Helmet>
      <div className="px-5">
        <FlashMessage message={successMessage} messageUpdater={setSuccessMessage} />
        {!showEditSnippet && (
          <div className="flex flex-col pt-4 gap-2">
            <div className="flex flex-row justify-between">
              <div className="text-2xl">Snippets</div>
              {canEdit && (
                <div className="justify-end flex flex-row gap-2">
                  <Button
                    onClick={() => setIsMenuVisible((current) => !current)}
                    type={BUTTON_TYPES.PRIMARY}
                    leadingIcon={faPlus}
                    trailingIcon={faCaretDown}
                  >
                    New Snippet
                  </Button>
                  {isMenuVisible && (
                    <div className="absolute top-16 w-36 z-40">
                      <MenuContext menuContextActions={[menuActions]} hasDividers={true} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <SearchInputControlled
              placeholder="Search Snippets"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
            <Grid
              columns={columns}
              defaultSort={defaultSort}
              rows={displayRows}
              onCellClick={handleCellClick}
              usedVerticalSpace={MAIN_VERTICAL_PADDING}
              rowHeight={ROW_HEIGHT}
              emptyRowMessage="No snippets found"
            />

            {currentSnippet && <ModalPreviewSnippet snippet={currentSnippet} />}
          </div>
        )}
      </div>

      {showEditSnippet && (
        <SnippetSidebarWithContent snippet={editActiveSnippet} currentTeamId={currentTeamId}>
          <div className="px-4">
            <FieldSetSnippet
              snippet={editActiveSnippet}
              onClose={onCloseEditSnippet}
              onRemove={onRemoveSnippet}
              onSaveSuccess={onSaveSuccess}
              testingModule={testingModule}
            />
          </div>
        </SnippetSidebarWithContent>
      )}
    </div>
  );
};

export default SnippetList;

import React, { useEffect, useState, useMemo } from 'react';
import {
  InventoryDetailInputBlockDiffElement,
  RunInventoryDetailInputRecorded,
} from 'shared/lib/types/views/procedures';
import PartLabel from '../PartLabel';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import useParts from '../../hooks/useParts';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { ItemDetail } from '../../types';
import ItemSelect from '../ItemSelect';
import DiffContainer from '../../../components/Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';
import SubstepNumber from '../../../components/SubstepNumber';
import { isPartRestricted } from '../../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from '../RestrictedInfo';

interface InventoryDetailInputProps {
  content: InventoryDetailInputBlockDiffElement;
  recorded?: RunInventoryDetailInputRecorded;
  onRecordValuesChanged: (contentId: string, recorded: RunInventoryDetailInputRecorded) => void;
  blockLabel: string;
  isEnabled?: boolean;
}

const ReviewInventoryDetailInput = React.memo(
  ({ content, recorded, onRecordValuesChanged, blockLabel, isEnabled }: InventoryDetailInputProps) => {
    const { services, currentTeamId } = useDatabaseServices();
    const { getPartByRevisionId, parts, getPart } = useParts();
    const [part, setPart] = useState<Part | null>(null);
    const [itemDetails, setItemDetails] = useState<Array<ItemDetail>>([]);

    const partRevisionId = sharedDiffUtil.getDiffValue(content, 'part_revision_id', 'new') as string;
    const detailId = sharedDiffUtil.getDiffValue(content, 'detail_id', 'new') as string;

    useEffect(() => {
      services.manufacturing
        .listItemDetails()
        .then(setItemDetails)
        .catch(() => {
          /* no-op */
        });
    }, [services.manufacturing]);

    // Part load
    useEffect(() => {
      if (parts && partRevisionId && !part) {
        // Note: getPartByRevisionId actually returns the latest rev, regardless of the part_revision_id
        const latestRev = getPartByRevisionId(partRevisionId);
        if (latestRev) {
          services.manufacturing.getPart(latestRev.id, { revisionId: partRevisionId }).then(setPart);
        }
      }
    }, [parts, getPartByRevisionId, part, partRevisionId, services.manufacturing]);

    const detailName = useMemo(() => {
      const detail = itemDetails.find((detail) => detail.id === detailId);
      return detail?.name ?? '';
    }, [itemDetails, detailId]);

    const isLoading = useMemo(() => !detailName || !part, [detailName, part]);

    // if showing an already selected part that the user does not have access to
    if (content.part_revision_id && isPartRestricted(getPart(content.part_id || ''))) {
      return (
        <div className="ml-5 my-4">
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </div>
      );
    }

    return isLoading ? null : (
      <div className="flex flex-row items-center ml-4 my-4">
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        <DiffContainer
          label="Inventory detail input"
          diffChangeState={content.diff_change_state}
          isTextSticky={false}
          width="fit"
        >
          <div className="flex flex-row space-x-6 items-center m-1">
            {part && <PartLabel teamId={currentTeamId} part={part} />}
            <div className="flex flex-col w-56">
              <div className="text-sm font-medium text-gray-500">Serial</div>
              <ItemSelect
                itemId={recorded?.item_id}
                partId={part?.id}
                partRevision={part?.rev}
                onChangeItem={() => null}
                isDisabled={!isEnabled}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-sm font-medium text-gray-500">{detailName}</div>
              <input
                defaultValue={recorded?.value}
                aria-label="Enter Value"
                className=" px-2 h-[38px] py-1.5 w-36 border border-gray-400 rounded disabled:bg-gray-100 focus:text-gray-500"
                disabled={!isEnabled}
              />
            </div>
          </div>
        </DiffContainer>
      </div>
    );
  }
);

export default ReviewInventoryDetailInput;

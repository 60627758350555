import React from 'react';
import { Link } from 'react-router-dom';

import { useRunContext } from '../contexts/RunContext';
import { getScrollToUrlParams } from '../lib/scrollToUtil';

const FirstStepButton = () => {
  const { firstStepId } = useRunContext();
  return (
    <Link
      className="px-2 py-2  rounded whitespace-nowrap text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600 disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-200"
      to={getScrollToUrlParams({ id: firstStepId, type: 'step' })}
    >
      Go to First Step
    </Link>
  );
};

export default React.memo(FirstStepButton);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../elements/Tooltip';
import pluralize from 'pluralize';
import { capitalize } from 'lodash';

interface IssueIndicatorProps {
  numIssues: number;
  size?: 'small' | 'base';
}

const IssueIndicator = ({ numIssues, size = 'base' }: IssueIndicatorProps) => {
  const tooltipMessage = `${numIssues} Open ${capitalize(pluralize('issues', numIssues))}`;

  if (numIssues === 0) {
    return null;
  }
  return (
    <Tooltip content={tooltipMessage}>
      <FontAwesomeIcon
        data-testid="issue-indicator"
        className={`font-medium text-gray-400 ${size === 'small' ? 'text-sm' : ''}`}
        icon="circle-exclamation"
      />
    </Tooltip>
  );
};

export default IssueIndicator;

import { Field, useFormikContext } from 'formik';
import ProjectsEditSelect from './ProjectsEditSelect/ProjectsEditSelect';
import useAutoProcedureId from '../hooks/useAutoProcedureId';

interface FieldSetProcedureProjectProps {
  isProjectClearable: boolean;
  isAutoProcedureIdEnabled?: boolean;
  warnUserOnIdChange?: boolean;
  isDisabled?: boolean;
}

const FieldSetProcedureProject = ({
  isProjectClearable,
  isAutoProcedureIdEnabled,
  warnUserOnIdChange = false,
  isDisabled = false,
}: FieldSetProcedureProjectProps) => {
  const { values, setFieldValue, setValues } = useFormikContext<{ project_id?: string; code?: string }>();
  const { generatePlaceholderId } = useAutoProcedureId();

  return (
    <Field
      name="project_id"
      className="h-full border border-gray-400 rounded"
      component={ProjectsEditSelect}
      value={values.project_id}
      isClearable={isProjectClearable}
      isDisabled={isDisabled}
      onUpdateProject={(projectId?: string) => {
        if (isAutoProcedureIdEnabled) {
          // We need to set both project and code fields simultaneously
          if (!warnUserOnIdChange || window.confirm('Procedure ID will be updated.  OK to proceed?')) {
            setValues((prev) => ({
              ...prev,
              code: generatePlaceholderId(projectId),
              project_id: projectId,
            }));
          }
        } else {
          setFieldValue('project_id', projectId);
        }
      }}
    />
  );
};

export default FieldSetProcedureProject;

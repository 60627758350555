import {
  CustomDetail,
  Hazard,
  AssessmentMatrixMetadata,
  Analysis,
} from 'shared/lib/types/testing';
import { RowWithProjectName } from '../components/Home/GridExpandCollapseButton';

export type ConditionParams = {
  conditionId: string;
};

export type HazardParams = {
  hazardId: string;
  versionId?: string;
};

export type RequirementParams = {
  requirementId: string;
  versionId?: string;
};

export type TestCaseParams = {
  testCaseId: string;
  versionId?: string;
};

export enum ParameterType {
  FLOAT = 'Float',
  INTEGER = 'Integer',
}

export type Parameter = {
  id: string;
  name: string;
  type: ParameterType;
  description?: string;
};

export enum AssessmentSeverity {
  CATASTROPHIC = 4,
  HAZARDOUS = 3,
  MAJOR = 2,
  MINOR = 1,
  NO_EFFECT = 0,
}

export enum AssessmentProbability {
  FREQUENT = 4,
  PROBABLE = 3,
  OCCASIONAL = 2,
  REMOTE = 1,
  IMPROBABLE = 0,
}

export type TestCaseCondition = {
  condition_id: string;
  name: string;
  units: string;
  value: string;
};

export type TestCase = {
  id: string;
  name: string;
  test_case_conditions: Array<TestCaseCondition>;
  hazards?: Array<Hazard>;
  startedAt?: string;
  completed?: string;
  project_id?: string;
  quality: string;
};

export type TestMatrixCondition = {
  condition_id: string;
  name: string;
  units: string;
  begin: string;
  end: string;
  increment: string;
};

export type CustomDetailForEdit = {
  id: string;
  name: string;
  value?: string;
  updated_name?: string;
  deleted?: boolean;
};

export type HazardSettingsState = {
  details: Array<CustomDetail>;
  assessment_matrix_metadata?: AssessmentMatrixMetadata;
  loading: boolean;
};

export enum PlanState {
  ACTIVE = 'active',
  RUNNING = 'running',
  ENDED = 'ended',
  ARCHIVED = 'archived',
}

export type TestCaseFormValues = {
  name: string;
  conditionsForTestCase: Array<TestCaseCondition>;
  hazardsForTestCase: Array<Hazard>;
  projectId?: string;
};

export type HazardRow = RowWithProjectName &
  Analysis & {
    id: string;
    name: string;
    category: string;
    updated_at: string;
  };

import Toggle from 'react-toggle';
import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react-markdown';

interface PromptSnippetProps {
  onAcceptLatest: () => void;
  onDetach: () => void;
  onRemove: () => void;
  isCurrentLatest: boolean;
  currentName?: string;
  latestName: string;
  currentVersion: ReactNode;
  latestVersion: ReactNode;
}

const PromptSnippet = ({
  onAcceptLatest,
  onDetach,
  onRemove,
  isCurrentLatest,
  currentName,
  latestName,
  currentVersion,
  latestVersion,
}: PromptSnippetProps) => {
  const [isToggledToLatest, setIsToggledToLatest] = useState(true);

  const onChangeToggle = useCallback(() => {
    setIsToggledToLatest((value) => !value);
  }, []);

  return (
    <div
      className={`flex flex-col py-2 px-3 mb-2 rounded border ${
        isCurrentLatest ? 'bg-gray-100 border-gray-200' : 'bg-blue-50 border-blue-100'
      }`}
    >
      <div className="flex justify-between  mb-1">
        <div className="space-x-2">
          <span>{isCurrentLatest || !isToggledToLatest ? currentName : latestName}</span>
          {!isCurrentLatest && (
            <span className="text-red-600">
              <FontAwesomeIcon icon="puzzle-piece" size="sm" />
              This snippet has changes
            </span>
          )}
        </div>
        {!isCurrentLatest && (
          <div className="space-x-2">
            <span
              className={`relative ${isToggledToLatest ? 'text-gray-400' : 'text-gray-700'}`}
              style={{ bottom: '7px' }}
            >
              Procedure Version
            </span>
            <Toggle checked={isToggledToLatest} onChange={onChangeToggle} icons={false} aria-label="Version Toggle" />
            <span
              className={`relative ${isToggledToLatest ? 'text-gray-700' : 'text-gray-400'}`}
              style={{ bottom: '7px' }}
            >
              Latest Version
            </span>
          </div>
        )}
      </div>
      <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
        <thead>
          <tr>
            <th className="w-8"></th>
            <th className="w-auto"></th>
            <th className="w-64"></th>
          </tr>
        </thead>
        {isCurrentLatest || !isToggledToLatest ? currentVersion : latestVersion}
      </table>
      <div className="flex justify-end  mb-1">
        <div className="space-x-4">
          <button type="button" onClick={onDetach}>
            <div className="flex flex-row items-center link">
              <FontAwesomeIcon icon="unlink" size="sm" className="mr-1 mt-0.5" />
              Detach
            </div>
          </button>
          {isCurrentLatest && (
            <button type="button" onClick={onRemove}>
              <div className="flex flex-row items-center link">
                <FontAwesomeIcon icon="times" size="sm" className="mr-1 mt-0.5" />
                Delete
              </div>
            </button>
          )}
          {!isCurrentLatest && (
            <button type="button" disabled={!isToggledToLatest} onClick={onAcceptLatest}>
              <div className={`flex flex-row items-center ${isToggledToLatest ? 'link' : 'text-gray-400'}`}>
                <FontAwesomeIcon icon="check" size="sm" className="mr-1 mt-0.5" />
                Accept Changes
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptSnippet;

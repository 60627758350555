import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldInputProps } from 'formik';
import Select from 'react-select';
import { useSettings } from '../../contexts/SettingsContext';
import { useAuth } from '../../contexts/AuthContext';
import EditSelectClearIndicator from '../EditSelectClearIndicator';
import { reactSelectStyles } from '../../lib/styles';
import { Project } from 'shared/lib/types/couch/settings';
import { ACCESS } from 'shared/lib/constants/permissions';

/**
 * Formik custom select component that allows users to set the projects value when editing procedures.
 *
 * @param value - Project key, to identify project.
 * @param field - Formik field.
 * @param isClearable - Can the project be cleared?
 * @param onUpdateProject - Callback handler when project changes
 */
interface ProjectsEditSelectProps {
  value: string;
  field: FieldInputProps<string>;
  isClearable: boolean;
  onUpdateProject: (projectId?: string) => void;
  isDisabled?: boolean;
  projectAccessLevel?: ACCESS;
}

const ProjectsEditSelect = ({
  value,
  field,
  isClearable,
  onUpdateProject,
  isDisabled = false,
  projectAccessLevel = ACCESS.EDITOR,
}: ProjectsEditSelectProps) => {
  const isMounted = useRef(true);
  const { projects: allProjects } = useSettings();
  const { auth } = useAuth();

  const projects: Array<Project> = useMemo(() => {
    if (!allProjects?.projects) {
      return [];
    }

    let projects = Object.values(allProjects.projects);
    if (projectAccessLevel === ACCESS.EDITOR && auth.hasProjectOnlyEditPermissions()) {
      const validProjectIds = new Set(auth.projectsWithEditPermission());
      projects = projects.filter((project) => validProjectIds.has(project.id));
    } else if (projectAccessLevel === ACCESS.OPERATOR && auth.hasProjectOnlyOperatorPermissions()) {
      const validProjectIds = new Set(auth.projectsWithOperatorPermission());
      projects = projects.filter((project) => validProjectIds.has(project.id));
    }
    return projects.sort((a, b) => a.name.localeCompare(b.name));
  }, [allProjects, auth, projectAccessLevel]);

  // Update mounted flag when component is unmounted
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const projectOptions = useMemo(() => {
    return projects.map((project) => ({
      value: project.id,
      label: project.name,
    }));
  }, [projects]);

  // Look for project that matches project key from the saved procedure document.
  const selectValue = useMemo(() => {
    if (!value || !projectOptions.length) {
      return '';
    }

    return projectOptions.find((project) => project.value === value);
  }, [projectOptions, value]);

  const onChangeHandler = useCallback(
    (option) => {
      // Will be called with an undefined option when user clears selection.
      if (!option) {
        onUpdateProject('');
        return;
      }

      const projectId = option.value;

      // Skip onChange when re-selecting the same value.
      if (value === projectId) {
        return;
      }

      onUpdateProject(projectId);
    },
    [value, onUpdateProject]
  );

  return (
    <div className="grow w-full">
      <div className="grow relative ">
        <Select
          classNamePrefix="react-select"
          components={{ ClearIndicator: EditSelectClearIndicator }}
          isClearable={isClearable}
          name={field.name}
          onChange={onChangeHandler}
          placeholder="Select project"
          options={projectOptions}
          styles={reactSelectStyles}
          value={selectValue}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default React.memo(ProjectsEditSelect);

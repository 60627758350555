import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import procedureUtil from '../lib/procedureUtil';
import ParentReviewComment from './ParentReviewComment';
import ReviewCommentForm from './ReviewCommentForm';
import { ReviewComment } from 'shared/lib/types/views/procedures';

interface ReviewCommentingProps {
  reviewComments: Array<ReviewComment>;
  stepId: string;
  onResolveReviewComment: (commentId: string) => void;
  onUnresolveReviewComment: (commentId: string) => void;
  saveReviewComment: (comment: ReviewComment) => Promise<void>;
}

/**
 * Component for rendering review comment form and existing comments.
 */
const ReviewCommenting = ({
  reviewComments,
  stepId,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
}: ReviewCommentingProps) => {
  const [formVisible, setFormVisible] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const commentButton = (
    <button className="btn-link my-2  font-bold uppercase" onClick={() => setFormVisible(true)}>
      <FontAwesomeIcon icon="comment-alt" /> Comment
    </button>
  );

  const onSetCommentFormVisible = (isVisible) => {
    setFormVisible(isVisible);
  };

  useEffect(() => {
    if (formVisible && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [formVisible, textAreaRef]);

  const parentCommentsMap = useMemo(() => {
    /*
     * Mapping between Parents Comments' Ids and Parent Comment
     */
    return procedureUtil.parentCommentsMap(reviewComments);
  }, [reviewComments]);

  const parentChildCommentsMap = useMemo(() => {
    // Mapping between ParentId and child comments
    return procedureUtil.parentChildCommentsMap(reviewComments);
  }, [reviewComments]);

  let saveEditComment;
  if (typeof saveReviewComment === 'function') {
    saveEditComment = async (editedComment, commentId) => {
      const existingComment = reviewComments?.find((comment) => comment.id === commentId);
      const comment = {
        ...existingComment,
        text: editedComment.comment,
        mention_list: editedComment.mentions,
        parent_id: existingComment?.parent_id ?? '',
        updated_at: editedComment.updated_at,
      } as ReviewComment;

      return saveReviewComment(comment);
    };
  }

  return (
    <div className="mr-8 flex flex-row">
      {/* Left spacing to align with content. */}
      <div className="w-4 shrink-0"></div>
      <div className="flex flex-col grow">
        {/* Render existing Parent Child Review Comments */}
        {reviewComments &&
          Object.entries(parentChildCommentsMap).map(([parentId, childComments]) => (
            <ParentReviewComment
              key={parentId}
              childComments={childComments}
              parentId={parentId}
              parentComment={parentCommentsMap[parentId]}
              stepId={stepId}
              onResolveComment={onResolveReviewComment}
              onUnresolveComment={onUnresolveReviewComment}
              saveReviewComment={saveReviewComment}
              editReviewComment={saveEditComment}
              isDocStateActive={true}
            />
          ))}
        {/* Render live commenting */}
        {saveReviewComment !== undefined && (
          <div className="self-stretch relative">
            <div className="flex flex-col grow">
              <ReviewCommentForm
                parentId=""
                stepId={stepId}
                saveReviewComment={saveReviewComment}
                formVisible={formVisible}
                onSetCommentFormVisible={onSetCommentFormVisible}
                commentButton={commentButton}
                isDocStateActive={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ReviewCommenting);

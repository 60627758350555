import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ProcedureRisk } from 'shared/lib/types/views/procedures';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from '../../components/EditToolbar';
import { useMixpanel } from '../../contexts/MixpanelContext';
import Icon from '../../elements/Icon';
import ProjectTreeSelector, { EntityWithProject } from '../../elements/ProjectTreeSelector';
import FieldError from '../../elements/internal/FieldError';
import FieldLabel from '../../elements/internal/FieldLabel';
import AssessmentIndicator from '../../testing/components/AssessmentIndicator';
import { Risk } from '../types';

type RiskEntity = Risk & EntityWithProject;

interface FormProcedureRisksProps {
  procedureRisks: Array<ProcedureRisk>;
  risks: Array<Risk>;
  errors: Array<string>;
  onRemoveRisk: (index: number) => void;
  onFieldRefChanged: (id: string, element: HTMLElement | null) => void;
  onChanged: (procedureRisks: Array<ProcedureRisk>) => void;
  projectId?: string;
}

const FormProcedureRisks = ({
  procedureRisks,
  risks,
  errors,
  onRemoveRisk,
  onFieldRefChanged,
  onChanged,
  projectId,
}: FormProcedureRisksProps) => {
  const { mixpanel } = useMixpanel();

  const usedRisks = useMemo(() => {
    return new Set(procedureRisks.map((item) => item.id));
  }, [procedureRisks]);

  const getEntities = useCallback(
    (currentRiskId: number) => {
      const filtered = risks.filter((risk) => {
        return (
          // always include the selected risk if there is one
          risk.id === currentRiskId ||
          // then filter down to project if there is one and exclude already used risks
          ((!projectId || (projectId && risk.projectId === projectId)) && !usedRisks.has(risk.id))
        );
      });
      return filtered as Array<RiskEntity>;
    },
    [projectId, risks, usedRisks]
  );

  const handleRemoveRisk = useCallback(
    (index: number) => {
      onRemoveRisk(index);
      mixpanel && mixpanel.track('Remove Risk from procedure');
    },
    [mixpanel, onRemoveRisk]
  );

  const onSelect = useCallback(
    (index: number, risk?: RiskEntity) => {
      if (risk) {
        const value = { id: risk.id, title: risk.title };
        const updated = cloneDeep(procedureRisks);
        updated[index] = value;
        onChanged(updated);
        mixpanel && mixpanel.track('Modify Risk to procedure');
      }
    },
    [mixpanel, onChanged, procedureRisks]
  );

  const labelFormatter = (risk: RiskEntity) => `${risk.id} ${risk.title}`;

  const baseTemplate = useCallback(
    (risk: RiskEntity, truncate = false) => {
      const resolved = risks.find((r) => r.id === risk.id);
      const analysis = resolved
        ? {
            row_value: resolved.assessmentMatrixRow,
            column_value: resolved.assessmentMatrixColumn,
          }
        : undefined;
      return (
        <div className="w-full flex flex-row items-center">
          {analysis && <AssessmentIndicator analysis={analysis} />}
          <div className={` ml-1 ${truncate ? 'truncate' : ''}`}>
            {risk.id} - {risk.title}
          </div>
        </div>
      );
    },
    [risks]
  );

  const nodeTemplate = useCallback((risk: RiskEntity) => baseTemplate(risk), [baseTemplate]);

  const valueTemplate = useCallback((risk: RiskEntity) => baseTemplate(risk, true), [baseTemplate]);

  return (
    <div className="flex flex-col mr-2">
      <FieldLabel label="Associated Risks" />
      {procedureRisks &&
        procedureRisks.map((procedureRisk: ProcedureRisk, index: number) => (
          <div key={`risks[${index}]`} className="flex mt-2">
            <div className="inline-flex group">
              <div
                className="min-w-64"
                ref={(element) => onFieldRefChanged(`risks[${index}]`, element)}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                <ProjectTreeSelector
                  entities={getEntities(procedureRisk.id)}
                  placeholder="Select Procedure Risk"
                  currentProjectId={projectId}
                  currentEntityId={procedureRisk.id}
                  labelFormatter={labelFormatter}
                  onSelect={(_, risk) => onSelect(index, risk)}
                  isDisabled={false}
                  nodeTemplate={nodeTemplate}
                  valueTemplate={valueTemplate}
                />
                {errors && errors[index] && <FieldError errorMessage={errors[index]} />}
              </div>
              <div className="w-10 h-full flex flex-col justify-start opacity-0 group-hover:opacity-100">
                <button type="button" title="Remove Variable" tabIndex={-1} onClick={() => handleRemoveRisk(index)}>
                  <Icon element={faCircleXmark} className="self-center text-gray-400 hover:text-gray-500" />
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FormProcedureRisks;

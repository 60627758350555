import Tag from '../../components/Tag';
import { Tag as TagType } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type RemovableTagProps = {
  tag: TagType;
  onRemove: () => void;
  disabled?: boolean;
};

const RemovableTag = ({ tag, onRemove, disabled = false }: RemovableTagProps) => {
  return (
    <div className="relative group max-w-[7rem]">
      <Tag id={tag.id} name={tag.name} />
      {!disabled && <RemoveTagButton onClick={onRemove} />}
    </div>
  );
};

const RemoveTagButton = ({ onClick }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      aria-label="Remove Tag"
      icon="times-circle"
      className="absolute -top-3 right-0 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 hover:cursor-pointer  bg-white rounded-full border-2 border-white"
    />
  );
};

export default RemovableTag;

import React, { useMemo } from 'react';
import runUtil from '../../lib/runUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RunStep, Section, Step } from 'shared/lib/types/views/procedures';
import useFieldRedlines from '../../hooks/useFieldRedlines';
import revisions from '../../lib/revisions';

interface StepNameSpanProps {
  currentSection: Section;
  currentStep: Step;
  currentStepIndex: number;
}

const StepNameSpan = React.memo(({ currentSection, currentStep, currentStepIndex }: StepNameSpanProps) => {
  const fieldRedlines = useMemo(() => {
    return revisions.getStepFieldChanges(currentStep, 'name', (currentStep as RunStep).redlines ?? []);
  }, [currentStep]);
  const { latestApprovedField } = useFieldRedlines({
    fieldName: 'name',
    fieldValue: currentStep.name,
    redlines: fieldRedlines,
  });
  const stepRepeatKey = runUtil.displayRepeatKey(currentSection.steps, currentStepIndex);
  const repeatSubtitle = runUtil.isStepRepeat(currentStep) ? `(repeat ${stepRepeatKey})` : '';

  return (
    <>
      <span className="truncate" title={`${currentStep.name} ${repeatSubtitle}`}>
        {`${latestApprovedField}`}
      </span>
      <span className="w-1" />
      {runUtil.isStepRepeat(currentStep) && (
        <span className="whitespace-nowrap" aria-label={`repeat ${stepRepeatKey}`}>
          {'  '}
          <FontAwesomeIcon icon="redo" className="text-base" />
          {` ${stepRepeatKey}`}
        </span>
      )}
    </>
  );
});

export default StepNameSpan;

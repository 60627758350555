import { useCallback, useMemo, useState } from 'react';
import Button from '../../Button';
import EntityActivityDisplay from '../../ActivityDisplay/EntityActivityDisplay';
import { OperationActorType } from 'shared/lib/types/postgres/operations';
import apm from '../../../lib/apm';
import { OperationActivity } from 'shared/lib/types/operations';
import { Activity } from 'shared/lib/types/postgres/util';
import Renderer, { WrappedActivity } from './Renderer';
import { EventMap } from '../../../screens/OperationDetail';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
type DisplayProps = {
  activities: OperationActivity[];
  hasOlder: boolean;
  onLoadOlder: () => Promise<void>;
  eventMap: EventMap;
  deletedEvents: Event[];
};

const Display = ({ activities, hasOlder, onLoadOlder, eventMap, deletedEvents }: DisplayProps) => {
  const [activityAscending, setActivityAscending] = useState(true);
  const [isLoadingOlder, setIsLoadingOlder] = useState(false);

  const _onLoadOlder = useCallback(async () => {
    setIsLoadingOlder(true);
    try {
      await onLoadOlder();
    } catch (err) {
      apm.captureError(err);
    } finally {
      setIsLoadingOlder(false);
    }
  }, [onLoadOlder]);

  const wrapped: WrappedActivity[] = useMemo(() => {
    const _wrapped = activities.map((operationActivity) => ({
      id: `${operationActivity.id}`,
      operation_id: operationActivity.operation_id,
      action: operationActivity.action,
      timestamp: operationActivity.timestamp,
      user_id: operationActivity.actor_type === OperationActorType.User ? operationActivity.actor_id ?? '' : '',
      operation_activity: operationActivity,
    }));
    return activityAscending ? _wrapped.reverse() : _wrapped;
  }, [activities, activityAscending]);

  const renderer = useCallback(
    (activity: Activity) => <Renderer activity={activity} eventMap={eventMap} deletedEvents={deletedEvents} />,
    [eventMap, deletedEvents]
  );

  return (
    <>
      <div className="flex flex-row justify-between my-2">
        <strong className="flex text-xl">Activity</strong>
        <div>
          <Button
            onClick={() => setActivityAscending((current) => !current)}
            leadingIcon={activityAscending ? 'arrow-down-short-wide' : 'arrow-up-wide-short'}
            type="tertiary"
            removePadding={true}
          >
            Most Recent
          </Button>
        </div>
      </div>
      {wrapped.length === 0 && <div>No Activities</div>}
      {wrapped.length > 0 && (
        <EntityActivityDisplay
          activity={wrapped}
          activityAscending={activityAscending}
          activityRenderer={renderer}
          hasOlder={hasOlder}
          loadOlderProps={{ isLoading: isLoadingOlder, onLoad: _onLoadOlder }}
        />
      )}
    </>
  );
};

export default Display;

import React, { forwardRef } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import './JumpToDate.css';

interface DatePickerInputProps {
  onClick: () => void;
}

const buttonClasses = ' text-gray-700 hover:text-gray-900 border border-gray-300 hover:border-gray-400 bg-white';

const DatePickerDropdown = forwardRef<HTMLDivElement, DatePickerInputProps>(({ onClick }, ref) => (
  <div className={`flex items-center justify-center w-8 h-full rounded-r ${buttonClasses}`} onClick={onClick} ref={ref}>
    <FontAwesomeIcon icon="chevron-down" />
  </div>
));

const DateSelect = ({ period, scrollToDate }) => {
  const scrollTo = (jsDate) => {
    const date = DateTime.fromJSDate(jsDate, { locale: 'utc' }).setZone('utc', { keepLocalTime: true });
    scrollToDate(date);
  };

  const scrollToNow = () => {
    scrollToDate(DateTime.utc(), true);
  };

  return (
    <div className="flex flex-row h-full cursor-pointer" aria-label="Jump To Date">
      <button
        className={`flex items-center h-full px-3 rounded-l disabled:text-gray-300 disabled:border-gray-300 ${buttonClasses}`}
        disabled={!period.contains(DateTime.utc())}
        onClick={scrollToNow}
      >
        Now
      </button>
      <ReactDatePicker
        className="h-full"
        onChange={scrollTo}
        customInput={<DatePickerDropdown onClick={() => null} />}
        includeDateIntervals={[
          {
            start: period.start.setZone('local', { keepLocalTime: true }).toJSDate(),
            end: period.end.setZone('local', { keepLocalTime: true }).toJSDate(),
          },
        ]}
      />
    </div>
  );
};

export default DateSelect;

import React, { MouseEventHandler } from 'react';
import { useNavState } from '../../contexts/NavContext';

export interface ToolbarProps {
  children: React.ReactNode;
  bg?: string;
  includeVerticalGap?: boolean;
  /** @deprecated */
  secondary?: boolean;
  /** @deprecated */
  onClick?: MouseEventHandler;
  /** @deprecated */
  ariaLabel?: string;
}

const Toolbar = ({
  children,
  bg,
  includeVerticalGap = true,
  secondary = false,
  onClick,
  ariaLabel = 'Sticky Toolbar',
}: ToolbarProps) => {
  const navState = useNavState();

  return (
    <>
      <div
        className={`h-10 right-0
          ${navState.isCollapsed ? 'left-16' : 'left-64'}
          ${secondary ? 'top-10' : 'top-0'}
          pl-2 pr-4
          fixed print:hidden transition-all
          flex flex-row justify-between shadow-md
          ${bg ?? 'bg-gray-100'}
          ${secondary ? 'z-40' : 'z-50'}
        `}
        onClick={onClick}
        aria-label={ariaLabel}
        role="toolbar"
      >
        {children}
      </div>
      {includeVerticalGap && <div className="h-8" />}
    </>
  );
};

export default React.memo(Toolbar);

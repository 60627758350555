import React, { useMemo, useCallback, useState } from 'react';
import Modal from './Modal';
import DateTimeDisplay from './DateTimeDisplay';
import { useRunContext } from '../contexts/RunContext';
import { PERM } from '../lib/auth';
import { useAuth } from '../contexts/AuthContext';
import { AxiosResponse } from 'axios';
import Button from './Button';
import SuggestedEditIcon from './SuggestedEdits/SuggestedEditIcon';

/*
 * Displays a fullscreen, blocking modal for showing suggested edits.
 */
interface SuggestEditsModalProps {
  children: React.ReactNode;
  hideModal: () => void;
  allRedlineUserIds: Array<string>;
  latestTimestamp: string;
  acceptAction?: () => Promise<AxiosResponse | void>;
  redlinePending: boolean;
  isBlueline: boolean;
}

export default function SuggestedEditsModal({
  children,
  hideModal,
  allRedlineUserIds,
  latestTimestamp,
  acceptAction,
  redlinePending,
  isBlueline,
}: SuggestEditsModalProps) {
  const { auth } = useAuth();
  const { isUserParticipant, isRunning, run } = useRunContext();
  const [isAccepting, setIsAccepting] = useState(false);

  const hasIncludeRedlinePermissions = useMemo(() => {
    return run && auth.hasPermission(PERM.PROCEDURES_EDIT, run.project_id);
  }, [auth, run]);

  const isAcceptDisplayed = useMemo(
    () => isRunning && hasIncludeRedlinePermissions && acceptAction,
    [acceptAction, hasIncludeRedlinePermissions, isRunning]
  );

  const isAcceptEnabled = useMemo(() => {
    return isUserParticipant && !isAccepting;
  }, [isUserParticipant, isAccepting]);

  const onAccept = useCallback(() => {
    if (!acceptAction) {
      return;
    }
    setIsAccepting(true);
    acceptAction()
      .then(() => hideModal())
      .finally(() => setIsAccepting(false));
  }, [acceptAction, hideModal]);

  return (
    <Modal
      isPrimaryActionVisible={false}
      onSecondaryAction={hideModal}
      size="lg"
      secondaryActionTitle="Close"
      border={isBlueline ? 'blue' : 'red'}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-end mb-2">
          <div className="flex flex-row items-center">
            <h1 className="mb-0">Suggested Edits</h1>
            {redlinePending && <h2 className="italic ml-1">(Pending)</h2>}
          </div>
          <div className="flex flex-none items-center mr-1  space-x-1">
            <SuggestedEditIcon isBlueline={isBlueline} />
          </div>
        </div>

        <div className="flex flex-col w-full py-2 px-3 bg-gray-50 rounded border border-black border-opacity-10">
          <div className="flex flex-row items-center justify-between mb-2">
            <div className="">
              {allRedlineUserIds.join(', ')}, <DateTimeDisplay timestamp={latestTimestamp} />
            </div>
            {isAcceptDisplayed && (
              <Button type="secondary" leadingIcon="check" onClick={onAccept} isDisabled={!isAcceptEnabled}>
                {isAccepting ? 'Updating...' : 'Include In Run'}
              </Button>
            )}
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
}

import React from 'react';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import { reactSelectStyles } from '../../../lib/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalDataValue, FieldInputExternalSearchBlock } from 'shared/lib/types/views/procedures';

import DiffContainer from '../../Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

const SEARCH_RESULTS_NAME = 'search-results';
const BUTTON_COLOR_CLASSES = 'text-white bg-blue-500 border border-blue-500 hover:bg-blue-600 hover:border-blue-600';
const BUTTON_DISABLED_CLASSES = 'disabled:text-gray-400 disabled:bg-gray-200 disabled:border-transparent';

interface FieldInputExternalSearchReviewProps {
  block: FieldInputExternalSearchBlock;
  recorded?: { value: ExternalDataValue };
  isEnabled?: boolean;
  onRecordValuesChanged?: (recorded: { value: ExternalDataValue }) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
}

const FieldInputExternalSearchReview = React.memo(
  ({ block, scrollMarginTopValueRem }: FieldInputExternalSearchReviewProps) => {
    return (
      <DiffContainer
        label="External search field input"
        diffChangeState={
          sharedDiffUtil.isChanged(block?.external_search_type, 'data_type') ||
          block?.external_search_type?.filter_options?.some((filter) => diffUtil.isDiffPair(filter))
            ? ARRAY_CHANGE_SYMBOLS.MODIFIED
            : undefined
        }
        width="fit"
        isTextSticky={false}
      >
        <div style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }} className="grow">
          <Formik
            initialValues={{ searchText: '' }}
            onSubmit={() => {
              /* no-op */
            }}
            validateOnChange={false}
          >
            {(props) => (
              <Form>
                <div className="w-full mt-2 flex flex-wrap flex-col">
                  <div className="flex flex-wrap items-end">
                    {/* Search Textbox */}
                    <div className="flex items-end gap-1">
                      <FontAwesomeIcon
                        icon="search"
                        aria-label="Search"
                        className="absolute text-gray-300 text-xl px-2 pb-[10px]"
                      />
                      <div className="flex">
                        <Field
                          name="searchText"
                          type="text"
                          disabled={true}
                          placeholder={
                            block?.external_search_type?.data_type
                              ? `Search ${sharedDiffUtil.getDiffValue(block.external_search_type, 'data_type', 'new')}`
                              : 'Search text'
                          }
                          className="px-8 py-1 h-[38px]  border border-gray-400 rounded"
                        />
                      </div>

                      {/* Search Button */}
                      <button
                        className={`w-24 h-[38px] py-2 rounded ${BUTTON_COLOR_CLASSES} ${BUTTON_DISABLED_CLASSES}`}
                        disabled={true}
                        type="button"
                      >
                        Search
                      </button>
                    </div>
                    <div className="flex items-center mt-2">
                      <div className="justify-end flex ml-2 mr-1">Results</div>
                      {/* Search results & select */}
                      <div className="w-64 flex">
                        <div className="w-full">
                          <Select
                            key={block?.external_search_type?.data_type || `${SEARCH_RESULTS_NAME}-key`}
                            styles={reactSelectStyles}
                            classNamePrefix="react-select"
                            name={SEARCH_RESULTS_NAME}
                            isLoading={false}
                            options={[]}
                            placeholder="Select item"
                            value={null}
                            isDisabled={true}
                            isClearable={true}
                            isSearchable={true}
                            defaultOptions
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DiffContainer>
    );
  }
);

export default FieldInputExternalSearchReview;

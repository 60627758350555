import React, { useMemo } from 'react';
import MenuContext, { MenuContextAction } from './MenuContext';
import { RUN_STATUS } from 'shared/lib/runUtil';

interface MenuEndProcedureProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const MenuEndProcedure = ({ onSubmit, onCancel }: MenuEndProcedureProps) => {
  const menuActions: Array<MenuContextAction> = useMemo(
    () => [
      {
        type: 'label',
        label: 'End run with status:',
        data: {
          disabled: true,
        },
      },
      {
        type: 'radioButtons',
        path: 'status',
        data: [
          {
            label: 'Success',
            value: RUN_STATUS.SUCCESS,
          },
          {
            label: 'Abort',
            value: RUN_STATUS.ABORT,
          },
          {
            label: 'Failure',
            value: RUN_STATUS.FAILURE,
          },
        ],
      },
      {
        type: 'fieldTextInput',
        path: 'comment',
        data: {
          placeholder: 'Notes (Optional)',
        },
      },
      {
        type: 'submitButtons',
        data: [
          {
            onClick: onCancel,
            label: 'Cancel',
            buttonType: 'secondary',
            ariaLabel: 'Cancel',
          },
          {
            onClick: onSubmit,
            label: 'Confirm',
            buttonType: 'primary',
            ariaLabel: 'Confirm',
          },
        ],
      },
    ],
    [onSubmit, onCancel]
  );

  return <MenuContext menuContextActions={[menuActions]} className="font-medium text-sm" />;
};

export default MenuEndProcedure;

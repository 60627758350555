export const None = ({ ...rest }) => <MissingValueLabel {...rest} text="None" />;

export const NotSet = ({ ...rest }) => <MissingValueLabel {...rest} text="Not Set" />;

type MissingValueLabelProps = {
  text: string;
  size?: 'sm' | 'base';
};

const MissingValueLabel = ({ text, size = 'base' }: MissingValueLabelProps) => {
  const sizeClass = size === 'sm' ? '' : 'text-lg';
  const classNames = `${sizeClass} text-gray-400`;
  return <span className={classNames}>{text}</span>;
};

export default MissingValueLabel;

import React, { useCallback, useMemo } from 'react';
import Select, { StylesConfig, Option } from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { ReviewSettings } from 'shared/lib/reviewUtil';

const REVIEW_TYPE_SELECT_STYLES: StylesConfig = {
  ...reactSelectStyles,
  singleValue: (base) => ({
    ...base,
    position: 'static',
    transform: 'none',
    minWidth: 'fit-content',
    maxWidth: 'none',
  }),
  menu: (base) => ({
    ...base,
    width: 'max-content',
  }),
};

interface ReviewSettingsSelectReviewTypeProps {
  reviewTypeOrGroupId: ReviewSettings['reviewTypeOrGroupId'];
  reviewerGroupMap: ReviewSettings['reviewerGroupMap'];
  reviewTypes: ReviewSettings['reviewTypes'];
  options: Array<Option>;
  onSelect: ({ reviewTypeOrGroupId, reviewerGroups }) => void;
}

const ReviewSettingsSelectReviewType = React.memo(
  ({ reviewTypeOrGroupId, reviewerGroupMap, reviewTypes, options, onSelect }: ReviewSettingsSelectReviewTypeProps) => {
    const selectedOption = useMemo(() => {
      if (!reviewTypeOrGroupId) {
        return null;
      }
      if (reviewTypes) {
        const reviewType = reviewTypes.find((rt) => rt.id === reviewTypeOrGroupId);
        if (!reviewType) {
          return null;
        }
        return { value: reviewType.id, label: reviewType.name };
      }

      const reviewerGroups = reviewerGroupMap[reviewTypeOrGroupId];
      if (!reviewerGroups || reviewerGroups.length !== 1) {
        return null;
      }
      return { value: reviewerGroups[0].id, label: reviewerGroups[0].name };
    }, [reviewTypeOrGroupId, reviewTypes, reviewerGroupMap]);

    const onChange = useCallback(
      (option) => {
        if (!option) {
          return;
        }

        onSelect({
          reviewTypeOrGroupId: option.value,
          reviewerGroups: reviewerGroupMap[option.value],
        });
      },
      [onSelect, reviewerGroupMap]
    );

    return (
      <Select
        classNamePrefix="react-select"
        className="text-sm w-fit"
        placeholder="Select Review Type"
        onChange={onChange}
        options={options}
        value={selectedOption}
        aria-label="Select Review Type"
        styles={REVIEW_TYPE_SELECT_STYLES}
      />
    );
  }
);

export default ReviewSettingsSelectReviewType;

import { ReactElement } from 'react';

export type FieldDefinition<T> = {
  label: string;
  formatter: (entity: T) => ReactElement | string;
  editor?: (entity: T) => ReactElement | string;
  fullWidth?: boolean;
  visible?: boolean;
};

export interface FieldProps {
  label: string;
  formatter: ReactElement | string;
  isEditing?: boolean;
  editor?: ReactElement | string;
  fullWidth?: boolean;
  error?: string;
  visible?: boolean;
  pt?: string;
}

const Field = ({ label, formatter, error, isEditing, editor, fullWidth, visible = true, pt = 'pt-0' }: FieldProps) => {
  if (!visible) {
    return <></>;
  }
  if (fullWidth) {
    return (
      <div aria-label={`${label} ${isEditing ? 'editor' : 'value'}`} className="flex w-full">
        {isEditing && editor ? (
          <div className="flex flex-col w-full">
            <label className="text-sm text-gray-500">{label}</label>
            {editor}
            <span className="text-red-700 text-sm">{error}</span>
          </div>
        ) : (
          formatter
        )}
      </div>
    );
  } else {
    return (
      <div className="flex w-full flex-row items-start gap-x-2">
        <div className={`flex-none w-24 break-all font-medium  ${pt}`}>{label}</div>
        <div
          aria-label={`${label} ${isEditing ? 'editor' : 'value'}`}
          className={`flex-grow  ${isEditing ? '' : 'truncate'}`}
        >
          {isEditing && editor ? editor : formatter}
        </div>
        <span className="text-red-700 text-sm">{error}</span>
      </div>
    );
  }
};

export default Field;

import { cloneDeep } from 'lodash';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { Filter } from '../../components/Home/FilterItems';
import ListHeader from '../../components/Home/ListHeader';
import usePersistedView from '../../components/Home/usePersistedView';
import TabBar, { TabProps } from '../../components/TabBar/TabBar';
import UploadCsvModal from '../../components/UploadCsvModal';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useNavState } from '../../contexts/NavContext';
import { useSettings } from '../../contexts/SettingsContext';
import { inventoryAddPath } from '../../lib/pathUtil';
import ExportInventoryModal from '../components/ExportInventoryModal';
import InventoryGrid from '../components/InventoryGrid';
import WelcomeModal from '../components/WelcomeModal';
import useItems from '../hooks/useItems';
import useLocations from '../hooks/useLocations';
import useManufacturingAuth from '../hooks/useManufacturingAuth';
import useParts from '../hooks/useParts';
import csvLib, { ITEM_COLUMNS, getMissingColumnHeaders, getMissingColumnsError } from '../lib/csv';
import { isNewSerialNumber } from '../lib/items';
import { getPartRevisionId } from '../lib/parts';
import { Item } from '../types';

const entityName = 'Parts Inventory';
const entitiesName = entityName;

export enum InventoryTab {
  Available = 'available',
  Unavailable = 'unavailable',
}

const INVENTORY_TABS: ReadonlyArray<TabProps<InventoryTab>> = [
  { id: InventoryTab.Available, label: 'Available' },
  { id: InventoryTab.Unavailable, label: 'Unavailable' },
];

const Inventory = () => {
  const { projectId } = useNavState();
  const persistedView = usePersistedView();
  const { services, currentTeamId } = useDatabaseServices();
  const { projects, isBuildsPermissionsEnabled } = useSettings();
  const [showImportModal, setShowImportModal] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [activeTab, setActiveTab] = useState<InventoryTab>(InventoryTab.Available);
  const [selectedRows, setSelectedRows] = useState((): ReadonlySet<string> => new Set());
  const [exportItemsModalVisible, setExportItemsModalVisible] = useState(false);

  const { locations } = useLocations();
  const { hasEditPermission, hasOperatorPermission, hasWorkspaceViewerPermission } = useManufacturingAuth();
  const canPotentiallyEditBuilds = hasWorkspaceViewerPermission || isBuildsPermissionsEnabled();
  const { allItems } = useItems();
  const { parts } = useParts();

  const example = {
    header: ['Part Number', 'Rev', 'Quantity', 'Serial / Lot #', 'Location ID'],
    data: [
      ['C-1', 'B', '50', 'A001', 'S1B1'],
      ['C-1', 'B', '50', 'A002', 'S1B2'],
      ['C-1', 'B', '50', 'B001', ''],
      ['G-1', 'X', '100', '', ''],
      ['A-1', 'A', '1', 'PA-01-001', 'W-800'],
      ['A-1', 'A', '1', 'PA-01-003', ''],
      ['A-1', 'A', '1', 'PA-01-004', ''],
    ],
  };

  const onImport = async (file: File) => {
    setImportError('');
    setIsImporting(true);

    try {
      const missingColumns = await getMissingColumnHeaders(file, ITEM_COLUMNS);
      if (missingColumns.length > 0) {
        const missingColumnsError = getMissingColumnsError(missingColumns, 'items');
        setImportError(missingColumnsError);
        setIsImporting(false);
        return;
      }

      const { items: itemsFromCsv, csvRowCount } = await csvLib.parseItemsCsv(file);
      let importCount = 0;
      const duplicateSerialNums: string[] = [];

      if (itemsFromCsv.length > 0) {
        // build out a 'parts lookup by part_no' map
        const partsByPartNo = {};
        for (const part of parts || []) {
          partsByPartNo[part.part_no.toLowerCase()] = part;
        }

        // build out a 'locations lookup by location code' map
        const locationsByCode = {};
        for (const location of Object.values(locations || {})) {
          const locationCode = location.full_code || location.code;
          locationsByCode[locationCode] = location;
        }

        const itemsToImport: Partial<Item>[] = [];
        for (const csvItem of itemsFromCsv) {
          // part
          const part: Part = partsByPartNo[csvItem.part_no as string];
          if (!part || !part.revisions?.some((revision) => revision.revision.toLowerCase() === csvItem.rev)) {
            continue;
          }
          // check if duplicate serial # in csv or if it is already set on an existing item
          if (
            csvItem.serial &&
            (!isNewSerialNumber(csvItem.serial, part.id, itemsToImport) ||
              !(allItems && isNewSerialNumber(csvItem.serial, part.id, allItems)))
          ) {
            duplicateSerialNums.push(csvItem.serial);
            continue;
          }

          const { amount, serial, lot, location_id } = csvItem;
          const item: Partial<Item> = { amount, serial, lot, location_id };

          const itemPart = cloneDeep(part);
          itemPart.rev = csvItem.rev as string;
          item.part = itemPart;
          item.part_revision_id = getPartRevisionId(itemPart);

          // tracking
          if (part.tracking === 'serial') {
            if (!item.serial) {
              continue;
            }
            item.amount = 1;
          } else {
            item.serial = undefined;
          }

          if (part.tracking === 'lot') {
            if (!item.lot) {
              continue;
            }
          } else {
            item.lot = undefined;
          }

          // location
          if (item.location_id) {
            const location = locationsByCode[item.location_id];
            item.location_id = location ? location.id : undefined;
          } else {
            item.location_id = undefined;
          }

          itemsToImport.push(item);
        }

        if (itemsToImport.length > 0) {
          const importedItems: Item[] = await services.manufacturing.addItems(itemsToImport);
          importCount = importedItems.length;
        }
      }

      const duplicateSerialNumsMessage = `Failed to import ${pluralize(
        'item',
        duplicateSerialNums.length,
        true
      )} with duplicate serial numbers (${[...new Set(duplicateSerialNums)].join(', ')})`;

      if (importCount === 0) {
        let importErrorMessage = 'Unable to import any new items from file.';
        if (duplicateSerialNums.length > 0) {
          importErrorMessage += ` ${duplicateSerialNumsMessage}`;
        }
        setImportError(importErrorMessage);
      } else {
        const importCountStr = pluralize('item', importCount, true);
        const rowCountStr = pluralize('row', csvRowCount, true);
        setShouldRefresh(true);
        let importMessage = `Imported ${importCountStr} after parsing ${rowCountStr}.`;
        if (duplicateSerialNums.length > 0) {
          importMessage += ` ${duplicateSerialNumsMessage}`;
        }
        window.alert(importMessage);
        setShowImportModal(false);
      }
    } catch (error) {
      setImportError('Unexpected error occurred. Please try again.');
    }
    setIsImporting(false);
  };

  const onExport = () => {
    const selectedItems = (allItems ?? []).filter((item) => selectedRows.has(item.id));

    const csvExport = csvLib.createItemsExport(selectedItems, locations || {});
    // escape double quotes in content
    csvExport.forEach((row) => {
      row.forEach((field, index) => {
        if (typeof field === 'string') {
          row[index] = field.replace(/"/g, '""');
        }
      });
    });

    return csvExport;
  };

  const onExportComponentTrees = () => {
    const selectedItems = (allItems ?? []).filter((item) => selectedRows.has(item.id));

    csvLib.exportItemsWithComponentTree(selectedItems, parts || [], allItems || [], locations || {});
    setExportItemsModalVisible(false);
  };

  const actions = useMemo(() => {
    if (canPotentiallyEditBuilds) {
      return (
        <div className="flex flex-row gap-x-1">
          {hasEditPermission && (
            <Button
              type={BUTTON_TYPES.TERTIARY}
              onClick={() => setShowImportModal(true)}
              title={`Import ${entitiesName}`}
              leadingIcon="upload"
            >
              Import
            </Button>
          )}
          {hasOperatorPermission && (
            <Button type="primary" leadingIcon="plus" to={inventoryAddPath(currentTeamId)}>
              Add
            </Button>
          )}
        </div>
      );
    }
  }, [canPotentiallyEditBuilds, currentTeamId, hasEditPermission, hasOperatorPermission]);

  if (allItems === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col flex-grow px-5">
      <WelcomeModal />
      {showImportModal && (
        <UploadCsvModal
          modalSize="md"
          dataType="Parts Inventory"
          example={example}
          footerText="Note: Columns 'Part Number', 'Rev', and 'Quantity' are required."
          isUploading={isImporting}
          uploadErrorMessage={importError}
          onSubmit={onImport}
          onCancel={() => {
            setImportError('');
            setShowImportModal(false);
          }}
        />
      )}
      {exportItemsModalVisible && (
        <ExportInventoryModal
          onCancel={() => setExportItemsModalVisible(false)}
          onExport={onExport}
          onExportComponentTrees={onExportComponentTrees}
        />
      )}
      <ListHeader
        name={entitiesName}
        isLoading={false}
        persistedView={persistedView}
        filters={new Set([...(projectId ? [] : [Filter.Projects]), Filter.Location])}
        tagOptions={[]}
        actions={actions}
        showViewTabToggle={false}
      />

      <div className="mr-2">
        <div className="flex w-full">
          <div className="divide-x">
            <span className="text-gray-400 w-42 mr-2 ml-1">
              {selectedRows.size} {pluralize('item', selectedRows.size)} selected
            </span>
            <span className="w-28 text-center mb-2">
              <Button
                isDisabled={selectedRows.size === 0}
                type={BUTTON_TYPES.TERTIARY}
                onClick={() => setExportItemsModalVisible(true)}
                leadingIcon="download"
                title="Export Selected Items"
              >
                Export Selected Items
              </Button>
            </span>
          </div>
        </div>
        <TabBar tabs={INVENTORY_TABS} selectedTab={activeTab} setSelectedTab={setActiveTab} />
      </div>
      <InventoryGrid
        searchTerm={persistedView.searchTerm}
        setSearchTerm={persistedView.setSearchTerm}
        locationIdsFilter={persistedView.selectedLocationIds}
        shouldRefresh={shouldRefresh}
        onRefreshComplete={() => setShouldRefresh(false)}
        activeTab={activeTab}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        projects={projects}
        selectedProjectIds={projectId ? new Set([projectId]) : persistedView.selectedProjectIds}
      />
    </div>
  );
};

export default Inventory;

import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Modal from '../../Modal';
import { CouchLikeOperation } from 'shared/lib/types/operations';
import apm from '../../../lib/apm';
import { OperationFormValues, SaveResult } from './types';
import { NAME_EXISTS_MSG } from '../lib/operationUtil';

interface EditOperationModalProps {
  operation: CouchLikeOperation;
  onSave: (values: OperationFormValues) => Promise<SaveResult>;
  onCancel: () => void;
}

const EditOperationModal = ({ operation, onSave, onCancel }: EditOperationModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Formik
      initialValues={{
        name: operation.name,
        description: operation.description,
      }}
      onSubmit={(values, { setErrors }) => {
        setIsSubmitting(true);
        onSave(values)
          .then((result) => {
            if (!result.success) {
              if (result.message === 'Request failed with status code 409') {
                setErrors({ name: NAME_EXISTS_MSG });
                setIsSubmitting(false);
              }
            }
          })
          .catch((err) => apm.captureError(err));
      }}
    >
      {({ dirty, isValid, errors }) => (
        <Form>
          <Modal
            title="Edit Operation"
            primaryActionTitle="Save"
            onSecondaryAction={onCancel}
            isPrimaryActionEnabled={isValid && dirty && !isSubmitting}
            secondaryActionTitle="Cancel"
          >
            <div className="flex flex-col w-full">
              <label htmlFor="name" className="font-semibold  uppercase">
                Name
              </label>
              <Field
                id="name"
                name="name"
                placeholder="Name"
                type="text"
                className=" border-1 border-gray-400 rounded bg-gray-200"
                disabled={true}
              />
              {errors.name && <div className="text-red-700">{errors.name}</div>}

              <label htmlFor="description" className="mt-2 font-semibold  uppercase">
                Description
              </label>
              <Field
                id="description"
                name="description"
                placeholder="Description"
                type="text"
                className=" border-1 border-gray-400 rounded"
              />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default EditOperationModal;

import { Link } from 'react-router-dom';
import { inventoryItemPath } from '../../lib/pathUtil';

type CheckedInItemLinkProps = {
  teamId: string;
  itemId: string;
  trackingId: string;
};

const CheckedInItemLink = ({ teamId, itemId, trackingId }: CheckedInItemLinkProps) => (
  <Link to={inventoryItemPath(teamId, itemId)}>
    <div className=" p-1 text-blue-600">{trackingId}</div>
  </Link>
);

export default CheckedInItemLink;

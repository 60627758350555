import React from 'react';
import MenuContext, { MenuContextAction } from '../components/MenuContext';
import useMenu from '../hooks/useMenu';
import Button, { BUTTON_TYPES } from '../components/Button';

interface ThreeDotMenuProps {
  menuActions: Array<MenuContextAction>;
  menuLabel: string;
  hasPadding?: boolean;
  boxSize?: 'sm' | 'md' | 'narrow';
  isHorizontal?: boolean;
  buttonSize?: 'sm' | 'lg' | 'xl' | 'base';
  rightAligned?: boolean;
}

const MENU_BOX_SIZE_CLASSES = {
  sm: 'w-7 h-8',
  md: 'w-8 h-9',
  narrow: 'h-8',
};
const ThreeDotMenu = ({
  menuActions,
  menuLabel,
  hasPadding = true,
  boxSize = 'md',
  isHorizontal = false,
  buttonSize = 'xl',
  rightAligned = false,
}: ThreeDotMenuProps) => {
  const { isMenuVisible, displayMenu } = useMenu();

  if (!menuActions.length) {
    return <></>;
  }

  return (
    <div className="flex-none shrink-0 relative print:hidden">
      <div className={`${hasPadding ? 'p-0.5' : ''}`}>
        <div
          className={`flex justify-center cursor-pointer hover:bg-slate-200 rounded-md ${MENU_BOX_SIZE_CLASSES[boxSize]}`}
        >
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon={isHorizontal ? 'ellipsis' : 'ellipsis-v'}
            onClick={displayMenu}
            ariaLabel={menuLabel}
            iconTextColor="text-gray-500"
            size={buttonSize}
            removePadding={true}
          />
        </div>
      </div>

      {isMenuVisible && (
        <div
          className={`absolute top-8 ${
            rightAligned ? 'left-2' : 'right-2'
          } bg-white flex flex-col min-w-50 shadow-md border rounded z-50`}
        >
          <MenuContext menuContextActions={[menuActions]} className="font-medium text-sm" />
        </div>
      )}
    </div>
  );
};

export default ThreeDotMenu;

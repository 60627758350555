import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo, useState } from 'react';

import Select from 'react-select';
import Button from '../Button';
import { PALLETTE, Color, Size, Tool, Sizes } from './AnnotationUtil';
import ToolbarButton from './ToolbarButton';

const colorSelectStyles = {
  control: (base) => ({
    ...base,
    background: 'rgba(229, 231, 235)', // bg-gray-200
    border: 0,
    padding: 0,
    '&:hover': { backgroundColor: 'rgba(203, 213, 225)' }, // bg-slate-300
  }),
  menu: (base) => ({
    ...base,
    background: 'rgba(229, 231, 235)', // bg-gray-200
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected || isFocused ? 'rgba(203, 213, 225)' : null,
  }),
};

type ToolOption = {
  label: JSX.Element;
  value: Tool;
};

const tools: Array<ToolOption> = [
  {
    label: <FontAwesomeIcon icon="arrow-right" />,
    value: 'Arrow',
  },
  {
    label: <FontAwesomeIcon icon="slash" />,
    value: 'Line',
  },
  {
    label: <FontAwesomeIcon icon="pen" />,
    value: 'Pen',
  },
  {
    label: <FontAwesomeIcon icon="square" />,
    value: 'Rect',
  },
  {
    label: <FontAwesomeIcon icon="circle" />,
    value: 'Ellipse',
  },
  {
    label: <FontAwesomeIcon icon="font" />,
    value: 'Text',
  },
];

type ColorOption = {
  label: JSX.Element;
  value: Color;
};

const colors: Array<ColorOption> = PALLETTE.map((c) => {
  return {
    label: <div className="border-slate-300 border w-4 h-4 rounded-full" style={{ backgroundColor: c }} />,
    value: c,
  };
});

type SizeOption = {
  label: JSX.Element;
  value: Size;
};

const textSizes: Array<SizeOption> = [
  {
    label: <span>XS</span>,
    value: Sizes.XS,
  },
  {
    label: <span>SM</span>,
    value: Sizes.SM,
  },
  {
    label: <span>MD</span>,
    value: Sizes.MD,
  },
  {
    label: <span>LG</span>,
    value: Sizes.LG,
  },
];

const strokeSizes: Array<SizeOption> = [
  {
    label: <div className="w-9 h-0.5 bg-gray-700" />,
    value: Sizes.XS,
  },
  {
    label: <div className="w-9 h-1 bg-gray-700" />,
    value: Sizes.SM,
  },
  {
    label: <div className="w-9 h-1.5 bg-gray-700" />,
    value: Sizes.MD,
  },
  {
    label: <div className="w-9 h-2 bg-gray-700" />,
    value: Sizes.LG,
  },
];

interface AnnotationToolbarProps {
  onChangeTool: (tool: Tool) => void;
  onChangeColor: (color: Color) => void;
  onChangeSize: (size: Size) => void;
  onDeleteSelected: () => void;
  onUndo: () => void;
  onRedo: () => void;
  onSave: () => void;
  onClose: () => void;
  canSave: boolean;
  canUndo: boolean;
  canRedo: boolean;
  canDelete: boolean;
}
const AnnotationToolbar = ({
  onChangeTool,
  onChangeColor,
  onChangeSize,
  onDeleteSelected,
  onUndo,
  onRedo,
  onSave,
  onClose,
  canSave,
  canUndo,
  canRedo,
  canDelete,
}: AnnotationToolbarProps) => {
  const [selectedTool, setSelectedTool] = useState(tools[0]);
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(1);

  const sizeOptions = useMemo(() => {
    return selectedTool?.value === 'Text' ? textSizes : strokeSizes;
  }, [selectedTool]);

  const onToolChange = useCallback(
    (selection: ToolOption) => {
      setSelectedTool(selection);
      onChangeTool(selection.value);
    },
    [onChangeTool]
  );

  const onColorChange = useCallback(
    (selection: ColorOption) => {
      setSelectedColor(selection);
      onChangeColor(selection.value);
    },
    [onChangeColor]
  );

  const onSizeChange = useCallback(
    (selection: SizeOption) => {
      setSelectedSizeIndex(sizeOptions.indexOf(selection));
      onChangeSize(selection.value);
    },
    [onChangeSize, sizeOptions]
  );

  const confirmClose = () => {
    if (!canSave) {
      onClose();
    } else {
      if (window.confirm('Are you sure you want to close?  Your annotations will be discarded.')) {
        onClose();
      }
    }
  };

  return (
    <div className="flex flex-row rounded-t-md min-w-fit p-2 bg-gray-200">
      <div className="flex gap-1 flex-row justify-between">
        {tools.map((tool) => {
          return (
            <ToolbarButton
              key={tool.value}
              onClick={() => onToolChange(tool)}
              isDisabled={false}
              isSelected={tool === selectedTool}
              title={tool.value}
            >
              {tool.label}
            </ToolbarButton>
          );
        })}

        <div className="bg-slate-300 w-[1px]"></div>
        <Select
          className="w-16 text-sm"
          classNamePrefix="react-select"
          value={selectedColor}
          styles={colorSelectStyles}
          onChange={onColorChange}
          placeholder="T"
          options={colors}
          aria-label="Color Selector"
          components={{ IndicatorSeparator: () => null }}
        />
        <Select
          className="w-16 text-sm font-semibold"
          classNamePrefix="react-select"
          value={sizeOptions[selectedSizeIndex]}
          styles={colorSelectStyles}
          onChange={onSizeChange}
          placeholder="T"
          options={sizeOptions}
          aria-label="Size Selector"
          components={{ IndicatorSeparator: () => null }}
        />

        <div className="bg-slate-300 w-[1px]"></div>
        <ToolbarButton title="Delete shape" isDisabled={!canDelete} onClick={onDeleteSelected}>
          <FontAwesomeIcon className={canDelete ? 'text-red-500' : 'text-gray-300'} icon="trash-can" />
        </ToolbarButton>
        <ToolbarButton title="Undo" isDisabled={!canUndo} onClick={onUndo}>
          <FontAwesomeIcon icon="undo" />
        </ToolbarButton>
        <ToolbarButton title="Redo" isDisabled={!canRedo} onClick={onRedo}>
          <FontAwesomeIcon icon="redo" />
        </ToolbarButton>

        <div className="bg-slate-300 w-[1px]"></div>

        <div className="my-2 ml-1">
          <Button title="Merge annotations and save" isDisabled={!canSave} onClick={onSave} size="sm" type="primary">
            Finalize
          </Button>
        </div>

        <ToolbarButton title="Discard and Close" isDisabled={false} onClick={confirmClose}>
          <FontAwesomeIcon icon="close" />
        </ToolbarButton>
      </div>
    </div>
  );
};
export default AnnotationToolbar;

import React, { Fragment, useMemo } from 'react';
import { Form, Formik } from 'formik';
import FieldSetText from '../FieldSetText';
import UpdateOnDirty from '../UpdateOnDirty';
import { generateHiddenClassString } from '../../lib/styles';
import Spacer from '../Spacer';
import MenuSubmitSuggestedEdit from '../MenuSubmitSuggestedEdit';

/**
 * Form for redlining a content block.
 * Currently only supports text blocks.
 * TODO: This component is deprecated, can remove after "text" and "alert" blocks
 * are refactored to use new components and interfaces.
 *
 * @param {Object} props
 * @param {Object} props.block
 * @param {boolean} [props.isHidden]
 * @param {(dirty: boolean) => void} props.onDirtyChanged
 * @param {() => void} props.onCancel
 * @param {(...values: Array<any>) => void} props.onSubmit
 * @param {import('formik').FormikConfig['innerRef']} [props.formikRef]
 * @param {import('shared/lib/types/views/procedures').Step} [props.step]
 */
export default function FormBlockEdit({ block, isHidden, onDirtyChanged, onCancel, onSubmit, formikRef, step }) {
  const runOnly = useMemo(
    () =>
      step &&
      'run_only' in step &&
      /** @type {import('shared/lib/types/views/procedures').RunAddedStep} */ (step)?.run_only,
    [step]
  );

  return (
    <Fragment>
      <div className={generateHiddenClassString('mt-2 mr-8 flex', isHidden)}>
        <Spacer />

        {/* Form */}
        <Formik
          innerRef={formikRef}
          initialValues={{ ...block, editType: runOnly ? 'Blueline' : 'Redline' }}
          onSubmit={onSubmit}
        >
          {({ errors, dirty, handleSubmit, isSubmitting, setValues }) => (
            <Form onSubmit={handleSubmit} className="w-full">
              <UpdateOnDirty onDirtyChanged={onDirtyChanged} />
              <div className="w-full relative">
                <div className="w-full  group flex py-1">
                  {/* Render form fields for text content */}
                  {block.type.toLowerCase() === 'text' && (
                    <FieldSetText
                      path=""
                      block={block}
                      contentErrors={errors}
                      showsLabels={false}
                      setFieldValue={(_, values) => setValues(values)}
                    />
                  )}
                </div>
              </div>
              <MenuSubmitSuggestedEdit
                isRedlineSubmitDisabled={!dirty || isSubmitting}
                isRedlineCancelDisabled={isSubmitting}
                onRedlineCancel={onCancel}
                onSubmit={handleSubmit}
                runOnly={runOnly}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
}

import React, { Fragment, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { SignoffButton } from './types';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import { MenuContextAction } from './MenuContext';
import TooltipOverlay from './TooltipOverlay';
import { useSettings, PIN_SIGNOFF_ENABLED_KEY } from '../contexts/SettingsContext';

interface SignoffButtonsProps {
  isComplete: boolean;
  buttons: Array<SignoffButton>;
  isDisabled: boolean;
  leadingIcon?: IconName;
  revokeApproval: null | (() => void);
  isRevokeEnabled?: boolean;
  showVerticalSpaceInPrint?: boolean;
  tooltip?: JSX.Element;
  isActive?: boolean;
  isPinSignoffEnabled?: boolean;
  onPinSignoff?: () => void;
  isOptionMenuAlwaysVisible?: boolean;
}

const SignoffButtons = React.memo(
  ({
    isComplete,
    buttons,
    isDisabled,
    leadingIcon,
    revokeApproval,
    isRevokeEnabled = true,
    showVerticalSpaceInPrint = false,
    tooltip,
    isActive = true,
    isPinSignoffEnabled = false,
    onPinSignoff,
    isOptionMenuAlwaysVisible = false,
  }: SignoffButtonsProps) => {
    const { getSetting } = useSettings();

    const isPinSignoffItemEnabled = !isComplete && isPinSignoffEnabled && onPinSignoff;
    const isRevokeItemEnabled = isComplete && isRevokeEnabled && revokeApproval && !isDisabled;
    const showOptionsMenu = isOptionMenuAlwaysVisible || isPinSignoffItemEnabled || isRevokeItemEnabled;

    const isPinSignoffOptionShown = getSetting(PIN_SIGNOFF_ENABLED_KEY, false);

    // TODO: OLY-905 clean up the enabled, disabled, shown logic here
    const menuItems = [
      ...(isOptionMenuAlwaysVisible && isPinSignoffOptionShown
        ? [
            {
              type: 'label',
              label: 'Sign off as...',
              data: {
                icon: 'circle-check',
                title: 'Sign off as another user',
                onClick: () => onPinSignoff && onPinSignoff(),
                disabled: !isPinSignoffItemEnabled,
              },
            },
          ]
        : []),
      ...(isOptionMenuAlwaysVisible || isRevokeItemEnabled
        ? [
            {
              type: 'label',
              label: 'Revoke Approval',
              data: {
                icon: 'times-circle',
                title: 'Revoke Approval',
                onClick: () => revokeApproval && revokeApproval(),
                disabled: !isRevokeItemEnabled,
              },
            },
          ]
        : []),
    ];

    const allButtonsDisabled = useMemo(() => {
      return buttons.every((button) => button.isDisabled);
    }, [buttons]);

    const containerBackgroundClasses = useMemo(() => {
      return isComplete
        ? 'bg-emerald-200'
        : isActive && (allButtonsDisabled || isDisabled)
        ? 'bg-gray-200'
        : 'bg-white';
    }, [allButtonsDisabled, isActive, isComplete, isDisabled]);

    const containerBorderClasses = useMemo(() => {
      if (isComplete) {
        return 'outline-emerald-400';
      }
      return 'outline-gray-400';
    }, [isComplete]);

    const getButtonBackgroundClasses = useCallback(
      (button: SignoffButton): string => {
        if (!isComplete) {
          // If the button is disabled use a gray background and text.
          if (isActive && (button.isDisabled || isDisabled)) {
            return 'bg-gray-200 disabled:text-gray-400';
          } else {
            // If the button is enabled, use a black text and a white background.
            return `text-black bg-white ${isActive ? 'hover:bg-gray-100' : ''}`;
          }
        }
        return '';
      },
      [isActive, isComplete, isDisabled]
    );

    const buttonCompleteClasses = useMemo(() => {
      return `${isComplete ? 'cursor-default' : ''}`;
    }, [isComplete]);

    const LeadingIconComponent = () => (
      <>
        {leadingIcon && (
          <div className={`flex items-center h-full ${isComplete ? 'text-emerald-500' : 'text-gray-500'}`}>
            <FontAwesomeIcon icon={[isComplete ? 'fas' : 'far', leadingIcon]} />
          </div>
        )}
      </>
    );

    const getSingleButtonClasses = useCallback(
      (button: SignoffButton) => {
        const padding = showOptionsMenu ? 'pl-1.5 pr-0.5 print:pr-1.5' : button.label ? 'px-1.5' : 'px-2.5';
        const rounded = `rounded-l-md ${
          showVerticalSpaceInPrint ? 'print:rounded-none print:rounded-t-md' : showOptionsMenu ? '' : 'rounded-r-md'
        }`;
        const backgroundColor = getButtonBackgroundClasses(button);
        const cursor =
          isActive && !isDisabled && !button.isDisabled && !isComplete ? 'cursor-pointer' : 'cursor-default';
        const pointerEvents = isActive ? '' : 'pointer-events-none'; // To allow click events to bubble to parent.
        return `flex flex-row flex-nowrap items-center h-full min-h-8 ${padding} ${rounded} ${backgroundColor} ${buttonCompleteClasses} ${cursor} ${pointerEvents}`;
      },
      [
        showOptionsMenu,
        showVerticalSpaceInPrint,
        getButtonBackgroundClasses,
        isActive,
        isDisabled,
        isComplete,
        buttonCompleteClasses,
      ]
    );

    const multiButtonRoundedRight = useCallback(() => {
      if (showVerticalSpaceInPrint) {
        return 'print:rounded-r-none print:rounded-tr-md';
      }
      if (showOptionsMenu) {
        return '';
      }
      return 'rounded-r-md';
    }, [showOptionsMenu, showVerticalSpaceInPrint]);

    const getMultiButtonClasses = useCallback(
      (button: SignoffButton, buttonIndex: number) => {
        const padding = buttonIndex === buttons.length - 1 && showOptionsMenu ? 'pl-1.5 pr-0.5 print:pr-1.5' : 'px-1.5';
        const roundedLeft = buttonIndex === 0 && !leadingIcon ? 'rounded-l-md' : '';
        const roundedRight = buttonIndex === buttons.length - 1 ? multiButtonRoundedRight : '';
        const rounded = `${roundedLeft} ${roundedRight}`;
        const backgroundColor = getButtonBackgroundClasses(button);
        const cursor =
          isActive && !isDisabled && !button.isDisabled && !isComplete ? 'cursor-pointer' : 'cursor-default';
        const pointerEvents = isActive ? '' : 'pointer-events-none'; // To allow click events to bubble to parent.
        return `flex flex-row flex-nowrap items-center h-full min-h-8 ${padding} ${rounded} ${backgroundColor} ${buttonCompleteClasses} ${cursor} ${pointerEvents}`;
      },
      [
        buttons.length,
        showOptionsMenu,
        leadingIcon,
        multiButtonRoundedRight,
        getButtonBackgroundClasses,
        isActive,
        isDisabled,
        isComplete,
        buttonCompleteClasses,
      ]
    );

    const getButtonAriaLabel = useCallback((button) => {
      const descriptor = [button.label ?? '', (button.userIds ?? []).join('')].join(' ').trim();
      return `Signoff${descriptor ? `: ${descriptor}` : ''}`;
    }, []);

    return (
      <div
        className={`min-h-8 h-fit flex flex-col rounded-md ${
          showVerticalSpaceInPrint ? 'print:outline print:outline-1 print:outline-gray-400' : ''
        }`}
      >
        <div
          className={`min-h-8 h-fit flex flex-row flex-nowrap shrink items-center text-gray-600 rounded-md ${
            showVerticalSpaceInPrint ? 'print:rounded-none print:rounded-t-md' : ''
          } outline outline-1 ${containerBackgroundClasses} ${containerBorderClasses}`}
        >
          <TooltipOverlay content={tooltip} visible={Boolean(tooltip)} height="full">
            <div className="flex flex-row items-center h-full">
              {leadingIcon && buttons.length > 1 && (
                <div className="ml-1.5 mr-0.5">
                  <LeadingIconComponent />
                </div>
              )}
              <div className="flex flex-row items-center h-full divide-x divide-gray-400">
                {buttons.map((button, buttonIndex) => (
                  <button
                    type="button"
                    key={button.id}
                    className={
                      buttons.length === 1 ? getSingleButtonClasses(button) : getMultiButtonClasses(button, buttonIndex)
                    }
                    disabled={isDisabled || isComplete || !isActive || button.isDisabled}
                    onClick={button.onClick}
                    aria-label={getButtonAriaLabel(button)}
                  >
                    <div className="flex flex-row gap-x-1.5">
                      {leadingIcon && buttons.length === 1 && <LeadingIconComponent />}
                      {button.label && (
                        <span
                          className={`text-sm font-medium min-w-10 break-all ${
                            button.type === 'user_id' ? '' : 'uppercase'
                          }`}
                        >
                          {button.label}
                        </span>
                      )}

                      {button.userIds && (
                        <span className="text-sm font-light normal-case whitespace-nowrap">
                          {button.userIds.join(', ')}
                        </span>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </TooltipOverlay>
          {showOptionsMenu && (
            <div className="rounded-r-md flex items-center h-full">
              <ThreeDotMenu
                menuActions={menuItems as Array<MenuContextAction>}
                menuLabel="Signoff Options"
                hasPadding={false}
                boxSize="sm"
                rightAligned={true}
              />
            </div>
          )}
        </div>
        {showVerticalSpaceInPrint && <div className="print:py-5" />}
      </div>
    );
  }
);

export default SignoffButtons;

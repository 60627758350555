import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { runViewPath } from '../lib/pathUtil';
import { RUN_STATUS, RUN_STATE } from 'shared/lib/runUtil';

export type RunStatus = {
  id: string;
  status: string | undefined;
  state: string;
};
interface RunStatusBadgeProps {
  runStatus: RunStatus;
}

const RunStatusBadge = ({ runStatus }: RunStatusBadgeProps) => {
  const { currentTeamId } = useDatabaseServices();
  const statusDotColorClass = useMemo(() => {
    if (runStatus.status === RUN_STATUS.SUCCESS) {
      return 'text-emerald-500';
    }
    if (runStatus.status === RUN_STATUS.ABORT) {
      return 'text-orange-500';
    }
    if (runStatus.status === RUN_STATUS.FAILURE) {
      return 'text-red-500';
    }
    if (!runStatus.status) {
      if (runStatus.state === RUN_STATE.RUNNING) {
        return 'text-blue-500';
      }
      if (runStatus.state === RUN_STATE.PAUSED) {
        return 'text-yellow-400';
      }
    }
    return 'text-gray-300';
  }, [runStatus]);

  const statusDotSizeClass = useMemo(() => {
    return 'leading-5';
  }, []);

  const runLinkLabel = useMemo(() => {
    if (runStatus.status) {
      return runStatus.status;
    }

    if (runStatus.state === RUN_STATE.RUNNING) {
      return 'running';
    }
    if (runStatus.state === RUN_STATE.PAUSED) {
      return 'paused';
    }

    return 'ended';
  }, [runStatus]);

  return (
    <div className="flex">
      <Link to={runViewPath(currentTeamId, runStatus.id)} className="flex flex-row items-center">
        <div className={`mb-1 text-3xl ${statusDotColorClass} ${statusDotSizeClass}`}>•</div>
        <div className="ml-1 capitalize link">{runLinkLabel}</div>
      </Link>
    </div>
  );
};

export default RunStatusBadge;

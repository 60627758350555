import Tooltip from '../elements/Tooltip';
import AntToggle from '../elements/AntToggle';

interface FieldSetToggleParams {
  label: string;
  tooltip?: string;
  value: boolean;
  onChange?: () => void;
  isDisabled?: boolean;
  hasPadding?: boolean;
  grow?: boolean;
}

const FieldSetToggle = ({
  label,
  tooltip,
  value,
  onChange = () => null,
  isDisabled = false,
  hasPadding = false,
  grow = true,
}: FieldSetToggleParams) => {
  return (
    <Tooltip content={tooltip} visible={tooltip !== undefined && tooltip !== ''} position="left" grow={grow}>
      <label
        className={`flex flex-row ${grow ? 'grow' : ''} gap-x-2 items-center ${hasPadding ? 'p-1.5' : ''} ${
          isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <AntToggle
          checked={value}
          onChange={onChange}
          sx={{ input: { cursor: isDisabled ? 'not-allowed' : 'pointer' } }}
          inputProps={{
            'aria-label': `${label} Toggle`,
            'aria-checked': value,
          }}
          disabled={isDisabled}
        />
        <div
          className={`flex flew-row gap-x-1 flex-nowrap text-sm whitespace-nowrap ${isDisabled ? 'text-gray-400' : ''}`}
        >
          <span>{label}</span>
          {/* Set a width to prevent the size of the span from changing, which causes the UI to jump around.*/}
          <span className="w-12">{value ? 'Enabled' : 'Disabled'}</span>
        </div>
      </label>
    </Tooltip>
  );
};

export default FieldSetToggle;
